import GitInfo from './git-version.json';
import Timestamp from './build-timestamp.json';

export const environment = {
	production: true,
	homeAirport: 1,
	apiScheduleURL: 'http://demo.msural.ru:82',
	apiCommunicationURL: 'http://demo.msural.ru:83',
	apiReferanceURL: 'http://demo.msural.ru:81',
	apiAdministrationURL: 'http://demo.msural.ru:83',
	apiSlotCoordinationURL: 'http://demo.msural.ru:91',
	apiWeightBalanceURL: 'http://demo.msural.ru:1200',
	apiLostFoundURL: 'http://dev.msural.ru:1301',
	apiWorkflowURL: 'http://localhost:3500',
	apiTestURL: 'http://demo.msural.ru:86',
	apiInformationalURL: 'http://demo.msural.ru:96',
	apiTechProcessesURL: 'http://demo.msural.ru:112',
	apiBoardingControlURL: 'http://localhost',
	apiReportURL: 'http://demo.msural.ru:1007',
	apiMaterialAccountingURL: 'http://dev.msural.ru:1700',
	apiJSONServer: 'http://demo.msural.ru:3000',
	apiLogServer: 'http://dev.msural.ru:3000',
	typeEnterprise: 'airport',

	keycloak: {
		// Url of the Identity Provider
		issuer: 'http://dev.msural.ru:8080/auth/',
		// Realm
		realm: 'msu',
		// The SPA's id.
		// The SPA is registerd with this id at the auth-serverß
		clientId: 'frontend',
	},
	applicationVersion: require('../../package.json').version,
	applicationBuild: GitInfo.hash + ' ' + Timestamp.date,
};
