import { Injectable, Inject, Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { KeycloakProfile } from 'keycloak-js';
import { ToastrService } from 'ngx-toastr';
import { NgxLoggerLevel } from 'ngx-logger';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import 'moment-timezone';
import moment from 'moment';
import { Output, EventEmitter } from '@angular/core';

/**
 * Перечисление типов модулей системы
 * ВНИМАНИЕ! Идентификаторы перечисления используются в разных местах системы
 * при добавление нового модуля, ОБЯЗАТЕЛЬНО добавлять его в конец списка
 * во избежании нарушения нумерации элементов
 */
export enum ModuleName {
  Unknown = 0, // Неизвестный модуль
  Schedule, // Суточный план полетов
  SlotCoord, // Сезонное расписание
  Workflow, // Технические процессы обсуживания воздушных судов
  MobileApron, // Мобильный перрон
  Reports, // Отчеты и логи
  MasterData, // Нормативно справочная информация
  ComMan, // Обработки сообщений AFTN / SITA/ Email
  BoardingControl, // Контроль пассажиров в чистой зоне
  Administration, // Администрирование системы
  ParkingPlace, // Места стоянок
  WeightBalance, // Центровка воздушных судов
  LostFound, // Поиск багажа
  MaterialAccounting, // Учет бытового имущества на борту ВС
  Test // Тестовый модуль для всего подряд
}

/**
 * Перечисление типов предприятия для поставляемой системы
 */
export enum TypeEnterprise {
  Unknown, // Неизвестный тип предприятия
  Airport, // Аэропорт
  Airline // Авиакомпания
}

/**
 * Перечисление типов отображения времени в системе
 */
export enum LocalTimeFormat {
  UTC = 'UTC', // Координированное универсальное время
  LOC = 'LOC' // Локальное время на рабочем месте пользователя
}

@Injectable()

export class GlobalSettings {

    @Output() changeDateFormatEvent = new EventEmitter<LocalTimeFormat>();
    @Output() changeTimeZoneEvent = new EventEmitter<string>();

    private _keycloakConfig: KeycloakConfig = new KeycloakConfig();
    // Текущий язык системы для пользователя из Cookie файла
    private _defaultConfig = new DefaultConfig();
    private _language = '';
    // Текущее отображаемое время в системе UTC / LOC
    private _time: LocalTimeFormat = LocalTimeFormat.UTC;
    // Часовой пояс из настроек пользователя
    private _timezone = '';
    // Смещение часового пояса относительно Зулу
    private _timezoneOffset = '';
    // Часовой пояс по умолчанию опреденный из настроек пользователя
    private _timezoneDefault = '';
    private _perPage = null;
    private _displayedPeriod = null;
    private _partnerLogo = '';
    private _aftnMaxMessageLength = null;
    private _sitatexTypeRuAddress = '';
    private _userConfig = new UserConfig();
    private _userDetail = new UserDetail();
    private _comManFolders: Array<Folder> = [];
    private _workspaces: Array<Folder> = [];
    private _loggerSettings = new Logger();
    private _jsonServer = '';
    private _loadComplete = false;
    private _userSessionUUID = NIL_UUID;
    private _userId = '';
    private _linearCenteringGraphicsType = '';
    // Список доступных языков интерфейса, отображается в левой главной
    // панели приложения вызываемой через меню
    languages = [{
        lang: 'en',
        name: 'English'
      },
      {
        lang: 'ru',
        name: 'Русский'
      },
      /*{
        lang: 'es',
        name: 'Spanish'
      }*/
    ];

    userNotifications: Array<UserNotification>;

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

    constructor(
      private cookieService: CookieService,
      private http: HttpClient,
      @Inject(Injector) private injector: Injector) {
        this._timezoneDefault = moment.tz.guess();
        this._timezone = moment.tz.guess();
        this._userSessionUUID = uuidv4();
        this.loadDefaultConfig();
    }

    get randomUuid(): string {
      return uuidv4();
    }

    get userSessionUUID(): string {
      return this._userSessionUUID;
    }

    // Чтобы избежать ошибки циклической зависимости
    private get _toastrService(): ToastrService {
      return this.injector.get(ToastrService);
    }

    private waitTimer(timer) {
      return new Promise<void>(resolve => {
          timer = timer || 100;
          setTimeout(_ => {
              resolve();
          }, timer);
      });
    }

    async loadComplete() {
      while (!this._loadComplete) {
        await this.waitTimer(100);
      }
    }

    async loadLogServerConfig() {
      await this.http.get(this.apiJSONServer + '/logs')
      .toPromise()
      .then(data => {
        if (data) {
          Object.assign(this._loggerSettings, data);
        }
      });
    }

    get loggerSettings(): Logger {
      return this._loggerSettings;
    }

    async loadJSONServerConfig() {
        await this.http.get('/assets/cfg/appConfig.json')
        .toPromise()
        .then(data => {
            this._jsonServer = data['apiJSONServer'];
        });
    }

    async loadKeycloakConfig() {
        await this.loadJSONServerConfig();
        await this.http.get(this.apiJSONServer + '/keycloak')
        .toPromise()
        .then(data => {
            if (data) {
                Object.assign(this._keycloakConfig, data);
            }
        });
    }

    get keycloakConfig(): KeycloakConfig {
        return this._keycloakConfig;
    }

    async loadDefaultConfig() {
        await this.loadJSONServerConfig();
        await this.loadLogServerConfig();
        await this.http.get(this.apiJSONServer + '/defaultConfig')
        .subscribe(data => {
            if (data) {
                Object.assign(this._defaultConfig, data);
                // Часовой пояс устанавливается из параметров
                // окружения пользователя
                this._defaultConfig.timezone = this._timezoneDefault;
            }
        });
    }

    get language(): string {
        return this._language;
    }

    /**
     * Функция возвращает значения определенного часвого пояса в настройках
     * учетной записи пользователя
     */
    get timezone(): string {
        return this._timezone;
    }

    get timezone2(): string {
      if (this._time === LocalTimeFormat.LOC) {
        return this._timezone;
      } else {
        return 'Etc/UTC';
      }
    }

    /**
     * Функция возвращает значение смещения часового пояса
     */
     get timezoneOffset(): string {
      return this._timezoneOffset;
     }

     /**
     * Функция устанавливает значение смещения часового пояса
     */
      set timezoneOffset(offset) {
        this._timezoneOffset = offset;
       }

    /**
     * Функция возвращает значения часвого пояса из переменных окружения
     * браузера пользователя
     */
    get timezoneDefault(): string {
      return this._timezoneDefault;
    }

    /**
     * Функция возвращает текущее формат отображения времени в системе
     * UTC или LOC
     */
    get time(): LocalTimeFormat {
      return this._time;
    }

    get timeOffset(): string {
      if (this._time === LocalTimeFormat.LOC) {
        return moment.tz(this._timezone).format('Z');
      } else {
        return moment.tz('Etc/UTC').format('Z');
      }
    }

    get perPage(): number {
        return this._perPage;
    }

    get displayedPeriod(): number {
      return this._displayedPeriod;
    }

    get partnerLogo(): string {
      if (this._partnerLogo == '') {
        return '';
      } else {
        return 'assets/img/partners/' + this._partnerLogo;
      }
    }

    get aftnMaxMessageLength(): number {
      return this._aftnMaxMessageLength;
    }

    get sitatexTypeRuAddress(): string {
      return this._sitatexTypeRuAddress;
    }

    get linearCenteringGraphicsType(): string {
      return this._linearCenteringGraphicsType;
    }

    async setLinearCenteringGraphicsType(value: string) {
      this._userConfig.linearCenteringGraphicsType = value;
      this._linearCenteringGraphicsType = value;
      await this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                        JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    keycloakSetUserProfile(profile: KeycloakProfile) {
        this._userDetail.keycloakUserProfile = profile;
    }

    keycloakSetUserRoles(roles: string[]) {
        this._userDetail.keycloakUserRoles = roles;
    }

    // ! не используемый метод
    getUserProfile(): KeycloakProfile {
        return this._userDetail.keycloakUserProfile;
    }

    getUserRoles(): string[] {
        return this._userDetail.keycloakUserRoles;
    }

    getUserDetail(): UserDetail {
        return this._userDetail;
    }

    async getUserSettings(user: string) {
        await this.http.get(this.apiJSONServer + '/users')
        .toPromise()
        .then((data: UserConfig[]) => {
            let findUser = false;
            let userIndex = -1;
            for (let i = 0; i < data.length; i++) {
                if (data[i].id === user) {
                    findUser = true;
                    userIndex = i;
                    break;
                }
            }
            // При нахождении пользователя загрузка всех его параметров в
            // класс пользовательского окружения
            if (findUser) {
                Object.assign(this._userConfig, data[userIndex]);
                this._language = this._userConfig.lang || this._defaultConfig.lang;
                this._timezone = this._userConfig.timezone || this._defaultConfig.timezone;
                if (data[userIndex].time.toUpperCase() === 'LOC') {
                  this._time = LocalTimeFormat.LOC;
                } else if (data[userIndex].time.toUpperCase() === 'UTC') {
                  this._time = LocalTimeFormat.UTC;
                } else {
                  this._time = this._defaultConfig.time;
                }
                // this._time = this._userConfig.time || this._defaultConfig.time;
                this._perPage = this._userConfig.perPage || 20;
                this._displayedPeriod = this._userConfig.displayedPeriod || 24;
                this._linearCenteringGraphicsType = this._userConfig.linearCenteringGraphicsType || this._defaultConfig.linearCenteringGraphicsType;
            } else {
              // Если пользователь не найден, создание окружения по умолчанию
              this._userConfig.id = user;
              this._userConfig.lang = this._defaultConfig.lang;
              this._userConfig.timezone = this._defaultConfig.timezone;
              this._userConfig.time = this._defaultConfig.time;
              this.insertUserSetting();
              this._language = this._defaultConfig.lang;
              this._timezone = this._defaultConfig.timezone;
              this._time = this._defaultConfig.time;
              this._perPage = 20;
              this._displayedPeriod = 24;
              this._linearCenteringGraphicsType = this._defaultConfig.linearCenteringGraphicsType;
            }
            this._partnerLogo = this._defaultConfig.partnerLogo;
            this._aftnMaxMessageLength = this._defaultConfig.aftnMaxMessageLength;
            this._sitatexTypeRuAddress = this._defaultConfig.sitatexTypeRuAddress;
            this._loadComplete = true;
            this.changeDateFormatEvent.emit(this._time);
            this.changeTimeZoneEvent.emit(this._timezone);
        });
    }

    async setLanguage(lang: string) {
        this._userConfig.lang = lang;
        await this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                        JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    async setTime(time: LocalTimeFormat) {
        this._userConfig.time = time;
        this._time = time;
        await this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                        JSON.stringify(this._userConfig), this.httpOptions).toPromise();
        this.changeDateFormatEvent.emit(time);
    }

    async setTimezone(timezone: string) {
      this._userConfig.timezone = timezone;
      this._timezone = timezone;
      await this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                      JSON.stringify(this._userConfig), this.httpOptions).toPromise();
      this.changeTimeZoneEvent.emit(timezone);
  }

  async setPerPage(perPage: number) {
    this._userConfig.perPage = perPage;
    await this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                        JSON.stringify(this._userConfig), this.httpOptions).toPromise();
  }

  async setDisplayedPeriod(displayedPeriod: number) {
    this._userConfig.displayedPeriod = displayedPeriod;
    await this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                        JSON.stringify(this._userConfig), this.httpOptions).toPromise();
  }

    get loadableModule(): number {
      return this._userConfig.loadableModule;
    }

    async setLoadableModule(module: number) {
      this._userConfig.loadableModule = module;
      await this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                        JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    async insertUserSetting() {
        await this.http.post(this.apiJSONServer + '/users', JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    getNotifications() {
      return this._userConfig.notifications.sort((i1, i2) => {
        return (i2.id - i1.id);
      });
    }

    addNotification(title, text, date) {
      this._userConfig.notifications = this._userConfig.notifications.sort((i1, i2) => {
        return (i2.id - i1.id);
      });
      if (this._userConfig.notifications.length > 39) {
        this._userConfig.notifications.length = 39;
      }
      const id = this._userConfig.notifications.length ? this._userConfig.notifications[0].id + 1 : 0;
      this._userConfig.notifications.push({id, title, text, date});
      this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    removeNotification(id) {
      this._userConfig.notifications = this._userConfig.notifications.filter(el => {
        if (el.id !== id) {
          return el;
        }
      });
      this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    showNotification(title: string, message: string) {
      this.addNotification(title, message, Date.now());
      this._toastrService.success(title, message, {
        timeOut: 5000,
        positionClass: 'toast-top-center',
        easeTime: 150,
        enableHtml: true,
        closeButton: true
      });
      const audio = new Audio();
      audio.src = '../../assets/audio/new_message.mp3';
      audio.load();
      audio.play();
      this.getNotifications();
    }

    /**
     * Функция вывода браузерных сообщений HTML5 Notifications
     * @param {string} body - тело уведомления (основной текст),
     * в разных браузерах и ОС обрезается по-разному
     * (например, в Chrome под Win 8.1 максимальная длина – 200 символов);
     * @param {string} dir - направление отображения уведомления, может быть auto, ltr (слева направо) или rtl (справа налево);
     * @param {string} lang - язык уведомления;
     * @param {string} tag - уникальный идентификатор уведомления, с помощь которого можно заменить его другим уведомлением или удалить;
     * @param {string} icon - URL изображения, которое будет показано в уведомлении (рекомендуется разрешение 40×40 px).
    */

    sendBrowserNotification(title, options) {
      // Проверим, поддерживает ли браузер HTML5 Notifications
      if (!('Notification' in window)) {
        alert('Ваш браузер не поддерживает HTML Notifications, его необходимо обновить.');
      } else if (Notification.permission === 'granted') {
        // Если права на отправку уведомлений есть, отправим уведомление
        const notification = new Notification(title, options);
        function clickFunc() {
          // Пользователь кликнул на уведомление
        }
        notification.onclick = clickFunc;
      } else if (Notification.permission !== 'denied') {
        // Если прав нет, пытаемся их получить
        Notification.requestPermission(function (permission) {
          // Если права успешно получены, отправляем уведомление
          if (permission === 'granted') {
            const notification = new Notification(title, options);
          } else {
            // Юзер отклонил наш запрос на показ уведомлений
            alert('Вы запретили показывать уведомления.');
          }
        });
      } else {
        // Пользователь ранее отклонил наш запрос на показ уведомлений
        // В этом месте мы можем спросить заново например.
      }
    }

    getComManUserFilters() {
      return this._userConfig.comManUserFilters.sort((i1, i2) => i2.id - i1.id);
    }

    addComManUserFilter(name, folder, data) {
      this._userConfig.comManUserFilters = this._userConfig.comManUserFilters.sort((i1, i2) => i2.id - i1.id);
      const id = this._userConfig.comManUserFilters.length ? this._userConfig.comManUserFilters[0].id + 1 : 0;
      const filter = JSON.stringify(data);
      this._userConfig.comManUserFilters.push({id, name, folderId: folder.id, folderName: folder.name, data: filter});
      this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                  JSON.stringify(this._userConfig), this.httpOptions).toPromise();
      return id;
    }

    updateComManUserFilter(id, data) {
      const filter = JSON.stringify(data);
      this._userConfig.comManUserFilters = this._userConfig.comManUserFilters.map(o => {
        if (o.id === id) {
          const newObject = {
            id,
            name: o.name,
            folderId: o.folderId,
            folderName: o.folderName,
            data: filter
          };
          return newObject;
        } else {
          return o;
        }
      });
      this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    removeComManUserFilter(id: number) {
      this._userConfig.comManUserFilters = this._userConfig.comManUserFilters.filter(el => el.id !== id);
      this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    setComManFolders(data) {
      this._comManFolders = data;
    }

    getComManFolders() {
      return this._comManFolders;
    }

    setWorkspaces(data) {
      this._workspaces = data;
    }

    getWorkspaces() {
      return this._workspaces;
    }

    get homeAirport(): number {
      if (this._defaultConfig.homeAirport) {
          return Number(this._defaultConfig.homeAirport);
      } else {
          return environment.homeAirport;
      }
    }

    get defaultAirportId(): number {
      if (this._defaultConfig.defaultAirportId) {
          return this._defaultConfig.defaultAirportId;
      } else {
          return -1;
      }
    }

    get defaultAirlineId(): number {
      if (this._defaultConfig.defaultAirlineId) {
          return this._defaultConfig.defaultAirlineId;
      } else {
          return -1;
      }
    }

    get apiScheduleURL(): string {
        if (this._defaultConfig.apiScheduleURL) {
            return this._defaultConfig.apiScheduleURL;
        } else {
            return environment.apiScheduleURL;
        }
    }

    get apiCommunicationURL(): string {
        if (this._defaultConfig.apiCommunicationURL) {
            return this._defaultConfig.apiCommunicationURL;
        } else {
            return environment.apiCommunicationURL;
        }
    }

    get apiReferanceURL(): string {
        if (this._defaultConfig.apiReferanceURL) {
            return this._defaultConfig.apiReferanceURL;
        } else {
            return environment.apiReferanceURL;
        }
    }

    get apiAdministrationURL(): string {
        if (this._defaultConfig.apiAdministrationURL) {
            return this._defaultConfig.apiAdministrationURL;
        } else {
            return environment.apiAdministrationURL;
        }
    }

    get apiSlotCoordinationURL(): string {
        if (this._defaultConfig.apiSlotCoordinationURL) {
            return this._defaultConfig.apiSlotCoordinationURL;
        } else {
            return environment.apiSlotCoordinationURL;
        }
    }

    get apiWeightBalanceURL(): string {
        if (this._defaultConfig.apiWeightBalanceURL) {
            return this._defaultConfig.apiWeightBalanceURL;
        } else {
            return environment.apiWeightBalanceURL;
        }
    }

    get apiLostFoundURL(): string {
        if (this._defaultConfig.apiLostFoundURL) {
            return this._defaultConfig.apiLostFoundURL;
        } else {
            return environment.apiLostFoundURL;
        }
    }

    get apiWorkflowURL(): string {
        if (this._defaultConfig.apiWorkflowURL) {
            return this._defaultConfig.apiWorkflowURL;
        } else {
            return environment.apiWorkflowURL;
        }
    }

    get apiTestURL(): string {
        if (this._defaultConfig.apiTestURL) {
            return this._defaultConfig.apiTestURL;
        } else {
            return environment.apiTestURL;
        }
    }

    get apiInformationalURL(): string {
        if (this._defaultConfig.apiInformationalURL) {
            return this._defaultConfig.apiInformationalURL;
        } else {
            return environment.apiInformationalURL;
        }
    }

    get apiTechProcessesURL(): string {
        if (this._defaultConfig.apiTechProcessesURL) {
            return this._defaultConfig.apiTechProcessesURL;
        } else {
            return environment.apiTechProcessesURL;
        }
    }

    get apiBoardingControlURL(): string {
        if (this._defaultConfig.apiBoardingControlURL) {
            return this._defaultConfig.apiBoardingControlURL;
        } else {
            return environment.apiBoardingControlURL;
        }
    }

    get apiReportURL(): string {
        if (this._defaultConfig.apiReportURL) {
            return this._defaultConfig.apiReportURL;
        } else {
            return environment.apiReportURL;
        }
    }

    get apiMaterialAccountingURL(): string {
      if (this._defaultConfig.apiMaterialAccountingURL) {
          return this._defaultConfig.apiMaterialAccountingURL;
      } else {
          return environment.apiMaterialAccountingURL;
      }
  }

    get apiLogServer(): string {
      if (this._defaultConfig.apiLogServer) {
          return this._defaultConfig.apiLogServer;
      } else {
          return environment.apiLogServer;
      }
  }

    get apiJSONServer(): string {
        if (this._jsonServer) {
            return this._jsonServer;
        } else {
            console.warn('Not load application config. Check JSON server URL in /asssets/cfg/appConfog.json')
            return '';
        }
    }

    get typeEnterprise(): TypeEnterprise {
      if (this._defaultConfig.typeEnterprise) {
        if (this._defaultConfig.typeEnterprise.toUpperCase() == 'AIRPORT') {
          return TypeEnterprise.Airport;
        } else if (this._defaultConfig.typeEnterprise.toUpperCase() == 'AIRLINE') {
          return TypeEnterprise.Airline;
        } else {
          console.warn('Type enterprise is NOT SET - check typeEnterprise parameter in JSON server. Defaul: airport/airline')
          return TypeEnterprise.Unknown;
        }
      } else {
        if (environment.typeEnterprise.toUpperCase() == 'AIRPORT') {
          return TypeEnterprise.Airport;
        } else if (environment.typeEnterprise.toUpperCase() == 'AIRLINE') {
          return TypeEnterprise.Airline;
        } else {
          console.warn('Type enterprise is NOT SET - check typeEnterprise parameter in JSON server. Defaul: airport/airline')
          return TypeEnterprise.Unknown;
        }
      }
    }

    get showFolderThree(): boolean {
      return this._userConfig.showFolderThree;
    }

    updateShowFolderThree(value: boolean) {
      this._userConfig.showFolderThree = value;
      this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    get copyTextForReply(): boolean {
      return this._userConfig.copyTextForReply;
    }

    updateCopyTextForReply(value: boolean) {
      this._userConfig.copyTextForReply = value;
      this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                JSON.stringify(this._userConfig), this.httpOptions).toPromise();
    }

    get favoriteFolderId(): number {
      if (this._userConfig.favoriteFolderId) {
        return this._userConfig.favoriteFolderId;
      } else {
        return this._comManFolders.length > 0 ? +this._comManFolders[0].id : null;
      }
    }

    async setFavoriteFolderId(id: number) {
      this._userConfig.favoriteFolderId = id;
      await this.http.put<UserConfig>(this.apiJSONServer + '/users/' + this._userConfig.id,
                                      JSON.stringify(this._userConfig), this.httpOptions).toPromise();
  }
}

export class KeycloakConfig {
    constructor() {
        this.issuer = '';
        this.realm = '';
        this.clientId = '';
    }
    issuer: string;
    realm: string;
    clientId: string;
}

export class DefaultConfig {
    constructor() {
        this.lang = '';
        this.time = LocalTimeFormat.UTC;
        this.timezone = '';
        this.homeAirport = '';
        this.defaultAirportId = null;
        this.defaultAirlineId = null;
        this.apiScheduleURL = '';
        this.apiCommunicationURL = '';
        this.apiReferanceURL = '';
        this.apiAdministrationURL = '';
        this.apiSlotCoordinationURL = '';
        this.apiWeightBalanceURL = '';
        this.apiLostFoundURL = '';
        this.apiWorkflowURL = '';
        this.apiTestURL = '';
        this.apiInformationalURL = '';
        this.apiTechProcessesURL = '';
        this.apiBoardingControlURL = '';
        this.apiReportURL = '';
        this.apiMaterialAccountingURL = '';
        this.apiJSONServer = '';
        this.apiLogServer = '';
        this.typeEnterprise = '';
        this.partnerLogo = '';
        this.aftnMaxMessageLength = 1500;
        this.sitatexTypeRuAddress = '';
        this.linearCenteringGraphicsType = 'takeoff';
    }
    // Язык системы по умолчанию
    lang: string;
    // Формат отображения времени в системе UTC или LOC для пользователя
    time: LocalTimeFormat;
    // Часовой пояс по UTC
    timezone: string;
    // Идентификатор базового предприятия
    homeAirport: string;
    // Код аэроопрта по умолчанию
    defaultAirportId: number;
    // Код авиакомпании по умолчанию
    defaultAirlineId: number;
    // Адреса эндпоинтов
    apiScheduleURL: string;
    apiCommunicationURL: string;
    apiReferanceURL: string;
    apiAdministrationURL: string;
    apiSlotCoordinationURL: string;
    apiWeightBalanceURL: string;
    apiLostFoundURL: string;
    apiWorkflowURL: string;
    apiTestURL: string;
    apiInformationalURL: string;
    apiTechProcessesURL: string;
    apiBoardingControlURL: string;
    apiReportURL: string;
    apiMaterialAccountingURL: string;
    // Адрес JSON сервера с настройками приложения
    apiJSONServer: string;
    // Адрес сервера логирования
    apiLogServer: string;
    // Тип предприятия: Аэропорт / Авиакомпания
    typeEnterprise: string;
    // Логотип для отображения в правом углу приложения
    partnerLogo: string;
    // Максимальная длинна сообщения при отправке по каналу AFTN
    aftnMaxMessageLength: number;
    // Технический адрес Sitatex для отправки сообщений в сегмент TypeRU
    sitatexTypeRuAddress: string;
    // Тип отображаемого линейного графика в модуле центровки
    linearCenteringGraphicsType: string;
}

export class UserConfig {
    constructor() {
        this.lang = '';
        this.timezone = '';
        this.time = LocalTimeFormat.UTC;
        this.perPage = null;
        this.displayedPeriod = null;
        this.notifications = [];
        this.comManUserFilters = [];
        this.showFolderThree = false;
        this.loadableModule = 0;
        this.copyTextForReply = false;
        this.favoriteFolderId = null;
    }
    id?: string;
    // Язык по умолчанию
    lang: string;
    // Часовой пояс по UTC
    timezone: string;
    // Формат отображения времени в системе UTC или LOC для пользователя
    time: LocalTimeFormat | string;
    // Количество сообщений на странице в модуле обработки сообщений
    perPage: number;
    // Отображаемый период
    displayedPeriod: number;
    // Список уведомлений
    notifications: Array<UserNotification>;
    // Пользовательские фильтры
    comManUserFilters: Array<UserFilter>;
    // Флаг отбражения списка папок в модуле обработки сообщений
    showFolderThree: boolean;
    // Идентификатор загружаемого модуля по умолчанию из enum ModuleName
    loadableModule: number;
    // Копировали ли текст сообщения при ответе
    copyTextForReply: boolean;
    // Тип отображаемого линейного графика в модуле центровки
    linearCenteringGraphicsType: string;
    // Избранная папка в модуле обработки сообщений
    favoriteFolderId: number;
}

export class UserNotification {
  id: number;
  title: string;
  text: string;
  date: Date;
}

export class UserFilter {
  constructor() {
    this.id = undefined;
  }

  id: number;
  name: string;
  folderId: number;
  folderName: string;
  data: string;
  count?: number;
}

export class Module {
  name: ModuleName;
  title: string;
  text: string;
  date: Date;
}

export class UserDetail {
    keycloakUserProfile: KeycloakProfile;
    keycloakUserRoles: string[] = [];

    // Функция возвращает логин авторизованного пользователя
    get login(): string {
      return this.keycloakUserProfile.username;
    }

    // Функция возвращает Фамилию и Имя авторизованного пользователя
    get fullName(): string {
      return this.keycloakUserProfile.firstName + ' ' + this.keycloakUserProfile.lastName;
    }

    get roles(): string {
      if (this.keycloakUserRoles) {
        return this.keycloakUserRoles.join(',');
      } else {
        return '';
      }
    }

    /**
     * Функции проверки прав на модули, задаются ролями на стороне Keycloak
     */

    isModulePermission(name: string) {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isAdmin() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_admin') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isSchedule() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_schedule') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isSlotCoordination() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_slotcoord') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isWorkflow() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_workflow') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isComModule() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_commodule') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isMobileApron() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_mobileapron') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isLogsReports() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_reports') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isMasterData() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_masterdata') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isBoardingControl() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_boardingcontrol') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isTest() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_test') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isParking() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_parking') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isBalance() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_balance') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isLostFound() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_lostfound') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isFlights() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_flights') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isInforming() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_informing') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isMaterialAccounting() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_materialaccounting') !== -1) {
        return true;
      } else {
        return false;
      }
    }

    get isUserTrace() {
      if (this.keycloakUserRoles && this.keycloakUserRoles.indexOf('aac_usertrace') !== -1) {
        return true;
      } else {
        return false;
      }
    }
}

export class Folder {
  id: string;
  name: string;
  rule: string;
}

export class Logger {
  // Минимальный уровень журнала в браузере
  level: NgxLoggerLevel;
  // Минимальный уровень для отправки на сервер
  serverLogLevel: NgxLoggerLevel;
  // Полное отключение журнала в консоли
  disableConsoleLogging: true;
  // Авторизационные данные для отправки сообщений на сервер логирования
  private _authorization: string;

  set authorization(value) {
    this._authorization = value;
  }

  get authorization(): string {
    return btoa(this._authorization);
  }
}
