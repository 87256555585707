import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MasterDataService } from '@shared/data-provider/master-data/api/masterData.service';
import { MasterDataActions } from './master-data.actions';
import { catchError, map, merge, mergeMap, of } from 'rxjs';

@Injectable()
export class MasterDataEffects {
	createItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MasterDataActions.createItem),
			mergeMap(parameters =>
				this.masterDataService
					.insertRecord(parameters.name, null, parameters.payload)
					.pipe(
						map(payload => MasterDataActions.createItemSuccess({ payload })),
						catchError(error =>
							of(MasterDataActions.errorMessage({ payload: error }))
						)
					)
			)
		);
	});

	deleteItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MasterDataActions.deleteItem),
			mergeMap(parameters =>
				this.masterDataService
					.updateRecord(parameters.name, parameters.id, parameters.payload)
					.pipe(
						map(payload => MasterDataActions.deleteItemSuccess({ payload })),
						catchError(error =>
							of(MasterDataActions.errorMessage({ payload: error }))
						)
					)
			)
		);
	});

	listItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MasterDataActions.listItem),
			mergeMap(parameters =>
				this.masterDataService.selectRecords(parameters.name).pipe(
					map(payload => MasterDataActions.listItemSuccess({ payload })),
					catchError(error =>
						of(MasterDataActions.errorMessage({ payload: error }))
					)
				)
			)
		);
	});

	readItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MasterDataActions.readItem),
			mergeMap(parameters =>
				this.masterDataService
					.selectRecord(parameters.name, parameters.id)
					.pipe(
						map(payload => MasterDataActions.readItemSuccess({ payload })),
						catchError(error =>
							of(MasterDataActions.errorMessage({ payload: error }))
						)
					)
			)
		);
	});

	updateItem$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MasterDataActions.updateItem),
			mergeMap(parameters =>
				this.masterDataService
					.updateRecord(parameters.name, parameters.id, parameters.payload)
					.pipe(
						map(payload => MasterDataActions.updateItemSuccess({ payload })),
						catchError(error =>
							of(MasterDataActions.errorMessage({ payload: error }))
						)
					)
			)
		);
	});

	constructor(
		private readonly actions$: Actions,
		private readonly masterDataService: MasterDataService
	) {}
}
