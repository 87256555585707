import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { ModuleName } from '@settings/global-settings';
import { MasterDataService } from '@shared/data-provider/master-data-api/api/masterData.service';
import { convertDateToUTC } from '@shared/functions/dateFunctions';
import { NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs';
import { MasterDataFunctionsService } from '../../services/master-data.service';
import { CustomFormValidators } from '@shared/custom-form-validators';
import { DelaySource } from '@shared/data-provider/master-data-api/model/delaySource';
import { SettingsService } from '@core/services/settings/settings.service';

interface ExtendedDelaySource extends DelaySource {
	nameEn?: string;
	nameRu?: string;
	nameLocal?: string;
	dtRangeStart?: Date;
	dtRangeFinish?: Date;
}

@Component({
	selector: 'app-delay-sources',
	templateUrl: './delay-sources.component.html',
	styleUrls: ['./delay-sources.component.less'],
})
export class DelaySourcesComponent implements OnInit {
	constructor(
		private apiMasterData: MasterDataService,
		private settingsService: SettingsService,
		private modal: NzModalService,
		private fb: UntypedFormBuilder,
		private globalI18n: GlobalI18n,
		private functionsService: MasterDataFunctionsService
	) {}

	private formValidators: CustomFormValidators = new CustomFormValidators();

	private _list: ExtendedDelaySource[] = [];
	selectedItem: ExtendedDelaySource;
	form: FormGroup;

	settingValue = {
		loadingList: false,
		loadingModal: false,
		saving: false,
		searchName: '',
		isVisibleModal: false,
		pageIndex: 1,
	};

	listOfColumn = [];

	ngOnInit() {
		this.loadList();
		this.listOfColumn = [
			{
				title: this.globalI18n.getMessage(ModuleName.MasterData, 'columnName'),
				compare: (a: ExtendedDelaySource, b: ExtendedDelaySource) => a.nameLocal.localeCompare(b.nameLocal),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(ModuleName.MasterData, 'columnLastUpdate'),
				compare: (a: ExtendedDelaySource, b: ExtendedDelaySource) =>
					Date.parse(a.lastupdate) - Date.parse(b.lastupdate),
				priority: false,
			},
		];
	}

	loadList() {
		this.settingValue.loadingList = true;
		this.apiMasterData.selectRecords('delay_sources').subscribe((data: DelaySource[]) => {
			this._list = data.map(item => {
				return {
					...item,
					nameEn: item.name[0],
					nameRu: item.name[1],
					nameLocal: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
					dtRangeStart: new Date(item.dtRange[0]),
					dtRangeFinish: new Date(item.dtRange[1]),
				};
			});
			this.settingValue.loadingList = false;
		});
	}

	get listOfDisplay(): ExtendedDelaySource[] {
		// При установленных фильтрах необходимо сбросить пагинацию на 1 страницу
		if (this.settingValue.searchName.length !== 0) {
			this.onPageIndexChanged(1);
		}
		return this._list.filter(item => {
			return (
				item.nameRu.toLowerCase().indexOf(this.settingValue.searchName.toLowerCase()) != -1 ||
				item.nameEn.toLowerCase().indexOf(this.settingValue.searchName.toLowerCase()) != -1
			);
		});
	}

	openItem(id: number) {
		this.settingValue.loadingList = true;
		return this.apiMasterData
			.selectRecord('delay_sources', id.toString())
			.pipe(
				map((item: DelaySource) => {
					return {
						...item,
						nameEn: item.name[0],
						nameRu: item.name[1],
						nameLocal: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
						dtRangeStart: new Date(item.dtRange[0]),
						dtRangeFinish: new Date(item.dtRange[1]),
					};
				})
			)
			.subscribe((data: ExtendedDelaySource) => {
				this.selectedItem = data;
				this.form = this.createForm(data);
				this.showModal();
				this.settingValue.loadingList = false;
			});
	}

	addNew() {
		this.selectedItem = {};
		this.form = this.createForm();
		this.showModal();
	}

	createForm(data?: ExtendedDelaySource) {
		return this.fb.group({
			nameEn: [data ? data.nameEn : '', [Validators.required, this.formValidators.onlyEnglish()]],
			nameRu: [data ? data.nameRu : '', [Validators.required]],
			dtRangeStart: [data ? convertDateToUTC(new Date(data.dtRangeStart)) : new Date(), [Validators.required]],
			dtRangeFinish: [
				data ? convertDateToUTC(new Date(data.dtRangeFinish)) : new Date('2099-12-31 23:59'),
				[Validators.required],
			],
		});
	}

	add() {
		this.form.markAllAsTouched();
		if (this.form.valid) {
			this.settingValue.saving = true;
			this.apiMasterData.insertRecord('delay_sources', null, this.createFromForm()).subscribe({
				next: () => {
					this.closeModal();
					this.loadList();
				},
				error: err => {
					this.displayError(err);
				},
				complete: () => {
					this.settingValue.saving = false;
				},
			});
		}
	}

	save() {
		this.form.markAllAsTouched();
		if (this.form.valid) {
			this.settingValue.saving = true;
			this.apiMasterData
				.updateRecord('delay_sources', this.selectedItem.id.toString(), this.createFromForm())
				.subscribe({
					next: () => {
						this.closeModal();
						this.loadList();
					},
					error: err => this.displayError(err),
					complete: () => {
						this.settingValue.saving = false;
					},
				});
		}
	}

	delete() {
		this.settingValue.saving = true;
		this.form.get('dtRangeFinish').setValue(new Date());
		this.apiMasterData.updateRecord('delay_sources', this.selectedItem.id.toString(), this.createFromForm()).subscribe({
			next: () => {
				this.closeModal();
				this.loadList();
			},
			error: err => this.displayError(err),
			complete: () => {
				this.settingValue.saving = false;
			},
		});
	}

	duplicate() {
		this.selectedItem.id = undefined;
		this.selectedItem.lastupdate = undefined;
	}

	showDeleteConfirm(): void {
		this.modal.confirm({
			nzTitle: this.globalI18n.getMessage(ModuleName.MasterData, 'deleteItem'),
			nzOkText: this.globalI18n.getMessage(ModuleName.MasterData, 'yes'),
			nzOkType: 'primary',
			nzOkDanger: true,
			nzOnOk: () => this.delete(),
			nzCancelText: this.globalI18n.getMessage(ModuleName.MasterData, 'no'),
			nzOnCancel: () => console.log('Cancel'),
		});
	}

	createFromForm(): DelaySource {
		return {
			...this.selectedItem,
			...this.form.value,
			name: [this.form.get('nameEn').value, this.form.get('nameRu').value],
			dtRange: [this.form.get('dtRangeStart').value, this.form.get('dtRangeFinish').value],
		};
	}

	downloadCsvFile() {
		this.functionsService.downloadCsvFile('delay_sources', this.settingValue.loadingList);
	}

	downloadXmlFile() {
		this.functionsService.downloadXmlFile('delay_sources', this.settingValue.loadingList);
	}

	onPageIndexChanged(index: number) {
		this.settingValue.pageIndex = index;
	}

	showModal(): void {
		this.settingValue.isVisibleModal = true;
	}

	closeModal(): void {
		this.settingValue.isVisibleModal = false;
	}

	displayError(err): void {
		this.modal.create({
			nzTitle: 'Error',
			nzContent: err.message,
			nzClosable: false,
		});
	}
}
