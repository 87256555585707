<div nz-row nzJustify="space-between">
	<div nz-col>
		<button
			nz-button
			nzType="default"
			(click)="addNew()"
			i18n="@@ref.main.tab.tails.buttonNew">
			New
		</button>
		<button
			nz-button
			nz-dropdown
			[nzDropdownMenu]="menu"
			i18n="@@ref.main.tab.tails.buttonExport">
			Export
			<span nz-icon nzType="down"></span>
		</button>
		<nz-dropdown-menu #menu="nzDropdownMenu">
			<ul nz-menu>
				<li nz-menu-item (click)="downloadCsvFile()">CSV</li>
				<li nz-menu-item (click)="downloadXmlFile()">XML</li>
			</ul>
		</nz-dropdown-menu>
	</div>

	<div nz-col nz-row>
		<div nz-col>
			<nz-input-group
				[nzAddOnBefore]="searchValueBeforeTemplate"
				[nzSuffix]="iataClearTpl">
				<input
					type="text"
					nz-input
					[(ngModel)]="settingValue.searchIata"
					placeholder="IATA" />
			</nz-input-group>
			<ng-template #searchValueBeforeTemplate>
				<span i18n="@@ref.main.tab.tails.fieldSearch">Search</span>
			</ng-template>
			<ng-template #iataClearTpl>
				<span
					nz-icon
					class="ant-input-clear-icon"
					nzTheme="fill"
					nzType="close-circle"
					*ngIf="settingValue.searchIata"
					(click)="settingValue.searchIata = ''"></span>
			</ng-template>
		</div>
		<div nz-col>
			<nz-input-group [nzSuffix]="nameClearTpl">
				<input
					type="text"
					nz-input
					[(ngModel)]="settingValue.searchName"
					placeholder="Name"
					i18n-placeholder="@@ref.main.tab.tails.fieldSearchName" />
			</nz-input-group>
			<ng-template #nameClearTpl>
				<span
					nz-icon
					class="ant-input-clear-icon"
					nzTheme="fill"
					nzType="close-circle"
					*ngIf="settingValue.searchName"
					(click)="settingValue.searchName = ''"></span>
			</ng-template>
		</div>
	</div>
</div>

<nz-table
	#sortTable
	[nzData]="listOfDisplay"
	nzTableLayout="fixed"
	[nzLoading]="settingValue.loadingList"
	nzSize="small"
	nzPaginationType="small"
	nzShowSizeChanger
	nzPageSize="15"
	[nzPageIndex]="settingValue.pageIndex"
	(nzPageIndexChange)="onPageIndexChanged($event)"
	[nzPageSizeOptions]="[15, 30, 50]">
	<thead>
		<tr>
			<th
				*ngFor="let column of listOfColumn"
				[nzSortFn]="column.compare"
				[nzSortPriority]="column.priority">
				{{ column.title }}
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let item of sortTable.data" (click)="openItem(item.id)">
			<td>{{ item.code }}</td>
			<td>{{ item.nameLocal }}</td>
			<td>{{ item.regularityLocal }}</td>
			<td>{{ item.lastupdate | date : 'dd.MM.yyy HH:mm' }}</td>
		</tr>
	</tbody>
</nz-table>

<nz-modal
	[(nzVisible)]="settingValue.isVisibleModal"
	[nzTitle]="modalTitle"
	[nzContent]="modalContent"
	[nzFooter]="modalFooter"
	(nzOnCancel)="closeModal()"
	nzWidth="1000px">
	<ng-template #modalTitle>
		<span *ngIf="!selectedItem.id">
			<span i18n="@@ref.main.modal.movement.headerAppend">Movement append</span>
			&nbsp;{{ selectedItem.nameLocal }}
		</span>
		<span *ngIf="selectedItem.id">
			<span i18n="@@ref.main.modal.movement.headerEdit">Edit movement</span>
			&nbsp;{{ selectedItem.nameLocal }}
		</span>
	</ng-template>

	<ng-template #modalContent>
		<form nz-form [formGroup]="form" nzLayout="vertical">
			<div nz-row [nzGutter]="16">
				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>
							<span i18n="@@ref.main.modal.movement.field.iataCode">
								Code
							</span>
						</nz-form-label>
						<nz-form-control nzHasFeedback [nzErrorTip]="iataErrorTpl">
							<input nz-input formControlName="code" />
							<ng-template #iataErrorTpl let-control>
								<ng-container *ngIf="control.hasError('required')">
									<span i18n="@@ref.main.modal.movement.err.iataCodeReq">
										Code is required
									</span>
								</ng-container>
								<ng-container *ngIf="control.hasError('reqLength')">
									<span i18n="@@ref.main.modal.movement.err.iataCodeMinLength">
										Code length is
									</span>
									{{ form.controls['code'].errors.reqLength.reqLength }}
								</ng-container>
								<ng-container *ngIf="control.hasError('onlyEnglishAndNumbers')">
									<span i18n="@@ref.main.modal.movement.err.iataCodeEnOnly">
										Code is English only
									</span>
								</ng-container>
							</ng-template>
						</nz-form-control>
					</nz-form-item>
				</div>

				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>
							<span i18n="@@ref.main.modal.movement.field.regularity"
								>Regularity</span
							>
						</nz-form-label>
						<nz-form-control nzHasFeedback [nzErrorTip]="regularityErrorTpl">
							<nz-select
								[nzOptions]="listOfDisplayRegularities"
								formControlName="regularityId">
							</nz-select>
							<ng-template #regularityErrorTpl let-control>
								<ng-container *ngIf="control.hasError('required')">
									<span i18n="@@ref.main.modal.movement.err.regularityReq">
										Regularity is required
									</span>
								</ng-container>
							</ng-template>
						</nz-form-control>
					</nz-form-item>
				</div>

				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>
							<span i18n="@@ref.main.modal.movement.field.englishName">
								English name
							</span>
						</nz-form-label>
						<nz-form-control nzHasFeedback [nzErrorTip]="nameEnErrorTpl">
							<input nz-input formControlName="nameEn" />
							<ng-template #nameEnErrorTpl let-control>
								<ng-container *ngIf="control.hasError('required')">
									<span i18n="@@ref.main.modal.movement.err.englishNameReq">
										English name is required
									</span>
								</ng-container>
								<ng-container *ngIf="control.hasError('onlyEnglish')">
									<span i18n="@@ref.main.modal.movement.err.englishNameEnOnly">
										Name is English only
									</span>
								</ng-container>
							</ng-template>
						</nz-form-control>
					</nz-form-item>
				</div>

				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>
							<span i18n="@@ref.main.modal.movement.field.localName">
								Local name
							</span>
						</nz-form-label>
						<nz-form-control nzHasFeedback [nzErrorTip]="nameRuErrorTpl">
							<input nz-input formControlName="nameRu" />
							<ng-template #nameRuErrorTpl let-control>
								<ng-container *ngIf="control.hasError('required')">
									<span i18n="@@ref.main.modal.movement.err.localNameReq">
										Local name is required
									</span>
								</ng-container>
							</ng-template>
						</nz-form-control>
					</nz-form-item>
				</div>

				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>
							<span i18n="@@ref.main.modal.movement.field.dtRangeStart">
								Date range start
							</span>
						</nz-form-label>
						<nz-form-control [nzErrorTip]="dtRangeStartErrorTpl">
							<nz-date-picker
								formControlName="dtRangeStart"
								nzFormat="dd.MM.yyyy"
								nzUtcOffset="00:00"></nz-date-picker>
							<ng-template #dtRangeStartErrorTpl let-control>
								<ng-container *ngIf="control.hasError('required')">
									<span i18n="@@ref.main.modal.movement.err.dtRangeStartReq">
										Date range start is required
									</span>
								</ng-container>
							</ng-template>
						</nz-form-control>
					</nz-form-item>
				</div>

				<div nz-col nzSpan="12">
					<nz-form-item>
						<nz-form-label nzRequired>
							<span i18n="@@ref.main.modal.movement.field.dtRangeFinish">
								Date range finish
							</span>
						</nz-form-label>
						<nz-form-control [nzErrorTip]="dtRangeFinishErrorTpl">
							<nz-date-picker
								formControlName="dtRangeFinish"
								[nzFormat]="'dd.MM.yyyy'"
								nzUtcOffset="00:00"></nz-date-picker>
							<ng-template #dtRangeFinishErrorTpl let-control>
								<ng-container *ngIf="control.hasError('required')">
									<span i18n="@@ref.main.modal.movement.err.dtRangeFinishReq">
										Date range finish is required
									</span>
								</ng-container>
							</ng-template>
						</nz-form-control>
					</nz-form-item>
				</div>
			</div>
		</form>
	</ng-template>

	<ng-template #modalFooter>
		<div nz-row nzJustify="space-between">
			<div nz-col>
				<button
					*ngIf="selectedItem.id"
					nz-button
					nzType="default"
					(click)="duplicate()"
					i18n="@@ref.main.modal.tail.button.duplicate">
					Duplicate
				</button>
				<button
					*ngIf="selectedItem.id"
					nz-button
					nzType="primary"
					nzDanger
					(click)="showDeleteConfirm()">
					Delete
				</button>
			</div>

			<div nz-col>
				<button
					*ngIf="selectedItem.id"
					nz-button
					nzType="primary"
					(click)="save()"
					[nzLoading]="settingValue.saving"
					i18n="@@ref.main.modal.tail.button.save"
					[disabled]="!form.valid">
					Save
				</button>
				<button
					*ngIf="!selectedItem.id"
					nz-button
					nzType="primary"
					(click)="add()"
					[nzLoading]="settingValue.saving"
					i18n="@@ref.main.modal.tail.button.append"
					[disabled]="!form.valid">
					Append
				</button>
				<button
					nz-button
					nzType="default"
					(click)="closeModal()"
					i18n="@@ref.main.modal.tail.button.close">
					Close
				</button>
			</div>
		</div>
	</ng-template>
</nz-modal>
