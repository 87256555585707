<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="ahm-tab" data-toggle="tab" href="#ahm" role="tab" aria-controls="ahm"
      aria-selected="true">
      AHM
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       id="desktops-tab"
       data-toggle="tab"
       href="#desktops"
       role="tab"
       aria-controls="desktops"
       aria-selected="false"
       (click)="changeTab($event);"
       i18n="adm|Weight and balance@@adm.wb.main.tabs.desktop">
      Desktops
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       id="filters-tab"
       data-toggle="tab"
       href="#filters"
       role="tab"
       aria-controls="filters"
       aria-selected="false"
       (click)="changeTab($event);"
       i18n="adm|Weight and balance@@adm.wb.main.tabs.filters">
      Filters
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       id="network-tab"
       data-toggle="tab"
       href="#network"
       role="tab"
       aria-controls="network"
       aria-selected="false"
       i18n="adm|Weight and balance@@adm.wb.main.tabs.routeNetwork">
      Route network
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       id="groups-tab"
       data-toggle="tab"
       href="#groups"
       role="tab"
       aria-controls="groups"
       aria-selected="false"
       (click)="changeTab($event);"
       i18n="adm|Weight and balance@@adm.wb.main.tabs.groups">
      Groups
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       id="users-tab"
       data-toggle="tab"
       href="#users"
       role="tab"
       aria-controls="users"
       aria-selected="false"
       (click)="changeTab($event);"
       i18n="adm|Weight and balance@@adm.wb.main.tabs.users">
      Users
    </a>
  </li>
</ul>
<div class="tab-content p-3" id="myTabContent">
  <div class="tab-pane fade show active" id="ahm" role="tabpanel" aria-labelledby="ahm-tab">

    <div *ngIf="view.activeWindow.includes('ahm-card')" class="mb-3 p-3 aero-top-panel shadow">
      <button class="btn btn-sm btn-ico btn-light-gray" (click)="selectAhm(ahm.id)">
        <i class="material-icons">arrow_back_ios</i>
        <span i18n="adm|Weight and balance - button ahm list@@adm.wb.main.button.ahmList">AHM list</span>
      </button>
    </div>
    <div class="weight-balance-content" *ngIf="view.activeWindow === 'ahm-list'">
      <div class="row py-3">
        <div class="col-7"></div>
        <div class="col-5 pr-0">
          <div class="d-flex">
            <div class="filter-block-wrap mr-2 w-100" (click)="showFilter = !showFilter">
              <div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
                <div class="filter-element mr-2 my-1" *ngIf="filterParams.tail && filterParams.tail.length > 0">
                  <span i18n="wb|Filter - tail@@wb.filter.field.tail">Tail</span>&nbsp;{{filterParams.tail}}
                  <i class="material-icons ml-2" (click)="clearFilterParametr('tail', $event)">close</i>
                </div>
                <div class="filter-element mr-2 my-1" *ngIf="filterParams.removed">
                  <span i18n="wb|Filter - removed@@wb.filter.field.removed">Outdate revision</span>
                  <i class="material-icons ml-2" (click)="clearFilterParametr('removed', $event)">close</i>
                </div>
              </div>
              <div class="d-flex align-items-center text-dark-gray">
                <i class="material-icons mx-1">arrow_drop_down</i>
              </div>
            </div>
            <!-- Filter -->
            <div class="filter-block border p-2 bg-white font-small" *ngIf="showFilter">
              <div class="container">
                <div class="row pb-2">
                  <div class="col-4 px-1">
                    <label for="filter-date-from" class="mr-2 mb-0" i18n="wb|Filter - tail@@wb.filter.field.tail">
                      Tail
                    </label>
                  </div>
                  <div class="col-8 px-1">
                    <ng-select class="form-control custom-sm p-0"
                               [items]="references.tails"
                               bindLabel="tail"
                               bindValue="tail"
                               [multiple]="true"
                               [(ngModel)]="filterParams.tail"
                               [loading]="selectLoadAnimation.tails"
                               [virtualScroll]="true">
                    </ng-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 px-1">
                    <div class="input-group-prepend mb-3">
                      <div class="custom-control custom-switch d-flex" style="min-width: fit-content;">
                        <input type="checkbox" class="custom-control-input" id="showOutdateRevisions"
                          name="showOutdateRevisions" [(ngModel)]="filterParams.removed"
                          [checked]="filterParams.removed">
                        <!-- (click)="showArchiveAhm()"-->
                        <label class="custom-control-label" for="showOutdateRevisions"
                          i18n="adm|Weight and balance@@adm.wb.main.checkbox.showOutdateRevisions">
                          Show outdate revisions
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <button class="btn btn-gray-blue-dark btn-sm float-right ml-2" (click)="showFilter = !showFilter"
                i18n="wb|Filter - close@@wb.filter.button.close">
                Close
              </button>
              <button class="btn btn-blue btn-sm float-right ml-2" (click)="filterApp()"
                i18n="wb|Filter - find@@wb.filter.button.find">
                Find
              </button>
              <button class="btn btn-blue btn-sm float-right" (click)="clearFilter()"
                i18n="wb|Filter - clear all@@wb.filter.button.clearAll">
                Clear all
              </button>
            </div>
            <button type="button" class="btn btn-xs btn-ico btn-light-gray" ngbTooltip="Filter on/off"
              i18n-ngbTooltip="cmn|Main buttons - filter on/off@@cmn.main.button.filterOnOff"
              [ngClass]="{'btn-active' : filterApply}" (click)="filterSwitch()">
              <i class="material-icons">filter_alt</i>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8 pr-0">
          <div class="card">
            <div class="card-body p-2">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <div>
                  <p class="font-weight-bold text-blue h5 mb-0">AHM</p>
                </div>
                <div>
                  <button class="btn btn-sm btn-ico btn-red mr-2" [disabled]="ahm.id === undefined"
                    (click)="deleteAhm()">
                    <i class="material-icons">delete</i>
                    <span i18n="adm|Weight and balance - button delete@@adm.wb.main.button.delete">Delete</span>
                  </button>
                  <button class="btn btn-sm btn-ico btn-light-gray mr-2" [disabled]="ahm.id === undefined"
                    (click)="copyAhm(modalAhm)">
                    <i class="material-icons">file_copy</i>
                    <span i18n="adm|Weight and balance - button copy@@adm.wb.main.button.copy">Copy</span>
                  </button>
                  <button class="btn btn-sm btn-ico btn-light-gray mr-2" [disabled]="ahm.id === undefined"
                    (click)="editAhm(modalAhm)">
                    <i class="material-icons">edit</i>
                    <span i18n="adm|Weight and balance - button edit@@adm.wb.main.button.edit">Edit</span>
                  </button>
                  <button class="btn btn-sm btn-ico btn-blue" (click)="createAhm(modalAhm)">
                    <i class="material-icons">add</i>
                    <span i18n="adm|Weight and balance - button add@@adm.wb.main.button.add">Add</span>
                  </button>
                </div>
              </div>
              <table class="contenteditable w-100">
                <thead class="bg-blue color-white">
                  <tr>
                    <th i18n="adm|Weight and balance - table airline@@adm.wb.main.table.airline">Airline</th>
                    <th i18n="adm|Weight and balance - table aircraft@@adm.wb.main.table.aircraft">Aircraft</th>
                    <th i18n="adm|Weight and balance - table revision@@adm.wb.main.table.revision">Revision</th>
                    <th i18n="adm|Weight and balance - table release date@@adm.wb.main.table.releaseDate">Release date
                    </th>
                    <th i18n="adm|Weight and balance - table completed by@@adm.wb.main.table.completedBy">Completed by
                    </th>
                    <th
                      i18n="adm|Weight and balance - table reason changes overview@@adm.wb.main.table.reasonChangesOverview">
                      Reason/Changes overview</th>
                    <th *ngIf="filterApply && filterParams.removed"
                      i18n="adm|Weight and balance - table reason changes overview@@adm.wb.main.table.status">Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ahms"
                      (click)="selectAhm(item.id)"
                      (dblclick)="editAhm(modalAhm)"
                      [ngClass]="{'bg-gray': item.id == ahm.id}"
                      class="cursor-default">
                    <td>{{ item.airlineName }}</td>
                    <td>{{ item.aircraftTypeName }}</td>
                    <td>{{ item.revision }}</td>
                    <td>{{ item.dtRangeStart | date: 'dd.MM.yyyy' }}</td>
                    <td></td>
                    <td>{{ item.remark }}</td>
                    <td *ngIf="filterApply && filterParams.removed" class="font-weight-bold">{{ item.deprecated ?
                      'Deprecated' : '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-4" *ngIf="ahm.id !== undefined">
          <div class="card">
            <div class="card-body p-2">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <p class="font-weight-bold text-blue h6 mb-0" i18n="adm|Weight and balance@@adm.wb.main.groupTail">Tail
                </p>
                <div>
                  <button class="btn btn-xs btn-ico btn-red mr-2" [disabled]="!selectedAhmDataId"
                    (click)="deleteAhmData(selectedAhmDataId)">
                    <i class="material-icons">delete</i>
                  </button>
                  <button class="btn btn-xs btn-ico btn-light-gray mr-2" [disabled]="!selectedAhmDataId"
                    (click)="copyAhmData(selectedAhmDataId)">
                    <i class="material-icons">file_copy</i>
                  </button>
                  <button class="btn btn-xs btn-ico btn-light-gray mr-2" [disabled]="!selectedAhmDataId"
                    (click)="editAhmData(selectedAhmDataId)">
                    <i class="material-icons">edit</i>
                  </button>
                  <button class="btn btn-xs btn-ico btn-blue" (click)="createAhmData()">
                    <i class="material-icons">add</i>
                  </button>
                </div>
              </div>
              <table class="contenteditable w-100" *ngIf="ahmsData.length > 0">
                <thead class="bg-blue color-white">
                  <tr>
                    <th i18n="adm|Weight and balance - table ac registration@@adm.wb.main.table.acRegistration">A/C
                      registration</th>
                    <th i18n="adm|Weight and balance - table last update@@adm.wb.main.table.lastUpdate">Last update</th>
                    <th *ngIf="filterApply && filterParams.removed"
                      i18n="adm|Weight and balance - table reason changes overview@@adm.wb.main.table.status">Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr (click)="selectedAhmDataId = ahm.id" *ngFor="let ahm of ahmsData" class="cursor-default"
                    [ngClass]="{'bg-gray': selectedAhmDataId == ahm.id}" (dblclick)="editAhmData(selectedAhmDataId)">
                    <td>{{ ahm.tailName }}</td>
                    <td>{{ ahm.lastupdate | date: 'dd.MM.yyyy HH:mm' : 'UTC+0' }}</td>
                    <td *ngIf="filterApply && filterParams.removed" class="font-weight-bold">{{ ahm.deprecated ?
                      'Deprecated' : '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card weight-balance-content" *ngIf="view.activeWindow === 'ahm-card-data'">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">
          <div>
            <p class="font-weight-bold text-blue h5 mb-3"
              i18n="adm|Weight and balance - ahmData input data AHM@@adm.wb.main.ahmData.inputDataAHM">Input data from
              AHM</p>
            <p>
              <span i18n="adm|Weight and balance - ahmData airline@@adm.wb.main.ahmData.release">Release:</span>
              <span class="font-weight-bold mr-2"> {{ ahm.dtRangeStart | date: 'dd.MM.yyyy' }}</span>
              <span i18n="adm|Weight and balance - ahmData airline@@adm.wb.main.ahmData.revision">Revision:</span>
              <span class="font-weight-bold mr-2"> {{ ahm.revision }}</span>
              <span i18n="adm|Weight and balance - ahmData airline@@adm.wb.main.ahmData.airline">Airline:</span>
              <span class="font-weight-bold mr-2"> {{ ahmData.airlineIata }}</span>
              <span i18n="adm|Weight and balance - ahmData airline@@adm.wb.main.ahmData.aircraft">Aircraft:</span>
              <span class="font-weight-bold mr-2"> {{ ahm.aircraftTypeName }}</span>
              <span i18n="adm|Weight and balance - ahmData acRegistration@@adm.wb.main.ahmData.acRegistration">A/C
                registration:</span>
              <span class="font-weight-bold"> {{ ahmData.tailName }}</span>
            </p>
          </div>
          <div class="float-right">
            <button class="btn btn-sm btn-blue" *ngIf="view.userAction === 'add-ahm-data'" (click)="addAhmData()"
              i18n="adm|Weight and balance - button add ahm@@adm.wb.main.button.addAhm">Add AHM</button>
            <button class="btn btn-sm btn-light-gray mr-3" type="button" *ngIf="view.userAction === 'edit-ahm-data' && isUserDeveloper()"
              (click)="copyToClipboardAhmCompartmentsCrews()">
              <span *ngIf="copyToClipboardAnimation" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span *ngIf="copyToClipboardAnimation" class="ml-2" i18n="adm|Weight and balance@@adm.wb.main.button.copyProgress">Copy...</span>
              <span *ngIf="!copyToClipboardAnimation" i18n="adm|Weight and balance@@adm.wb.main.button.copyJsonCompartmentsCrews">Copy Compartments and Crew JSON</span>
            </button>
            <button class="btn btn-sm btn-light-gray mr-3" type="button" *ngIf="view.userAction === 'edit-ahm-data' && isUserDeveloper()"
              (click)="copyToClipboardAhm()">
              <span *ngIf="copyToClipboardAnimation" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span *ngIf="copyToClipboardAnimation" class="ml-2" i18n="adm|Weight and balance@@adm.wb.main.button.copyProgress">Copy...</span>
              <span *ngIf="!copyToClipboardAnimation" i18n="adm|Weight and balance@@adm.wb.main.button.copyJson">Copy JSON</span>
            </button>
            <button class="btn btn-sm btn-light-gray mr-3" type="button" *ngIf="view.userAction === 'edit-ahm-data'"
              (click)="exportAhmData()"
              i18n="adm|Weight and balance - button export@@adm.wb.main.button.exportAhm">Export to Excel</button>
            <button class="btn btn-sm btn-blue" *ngIf="view.userAction === 'edit-ahm-data'" (click)="saveAhmData()"
              i18n="adm|Weight and balance - button save ahm@@adm.wb.main.button.saveAhm">Save AHM</button>
          </div>
        </div>
        <ul class="nav nav-tabs" id="myTab1" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="operational-items-tab" data-toggle="tab"
              i18n="adm|Weight and balance - tab operational items@@adm.wb.main.tab.operationalItems"
              href="#operational-items" role="tab">Operational items</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="standard-units-tab" data-toggle="tab"
              i18n="adm|Weight and balance - tab standard units@@adm.wb.main.tab.standardUnits" href="#standard-units"
              role="tab">Standard units and codes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="flight-information-tab" data-toggle="tab"
              i18n="adm|Weight and balance - tab flight information@@adm.wb.main.tab.flightInformation"
              href="#flight-information" role="tab">Flight information</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="centre-charts-tab" data-toggle="tab"
              (click)="getGravity(ahmData.centreOfGravityChart)"
              i18n="adm|Weight and balance - tab centre charts@@adm.wb.main.tab.centreCharts" href="#centre-charts"
              role="tab">Centre of gravity charts</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="fuel-tab" data-toggle="tab"
              i18n="adm|Weight and balance - tab fuel@@adm.wb.main.tab.fuel" href="#fuel" role="tab">Fuel</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="holds-compartments-tab" data-toggle="tab"
              i18n="adm|Weight and balance - tab holds and compartments@@adm.wb.main.tab.holdsAndCompartments"
              (click)="generateBaysMap(bayMap)"
              href="#holds-compartments" role="tab">Holds and compartments</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="cabin-equipment-tab" data-toggle="tab"
              i18n="adm|Weight and balance - tab holds and compartments@@adm.wb.main.tab.cabinAndEquipment"
              href="#cabin-equipment" role="tab">Cabin and equipment</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="crew-composition-tab" data-toggle="tab"
              i18n="adm|Weight and balance - tab crew composition@@adm.wb.main.tab.crewComposition"
              href="#crew-composition" role="tab">Crew composition</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="stabilizer-trim-tab" data-toggle="tab"
              i18n="adm|Weight and balance - tab stabilizer trim@@adm.wb.main.tab.stabilizer" href="#stabilizer-trim"
              role="tab">Stabilizer Trim</a>
          </li>
        </ul>

        <div class="tab-content pt-3" id="myTabContent1">
          <div class="tab-pane fade show active" id="operational-items" role="tabpanel" aria-labelledby="operational-items-tab">
            <div class="row">
              <div class="col-md-6">
                <div class="card h-100">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.potableWater">Potable water code</p>
                    <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td
                            i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.potableWater.table.potableWaterCode">
                            Potable water code</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.potableWater.table.remark">
                            Remark</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.potableWater.table.weight">
                            Weight</td>
                          <td>+/-</td>
                          <td
                            i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.potableWater.table.indexPerWeightUnit">
                            Index</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.potableWater.table.standard">
                            Standard</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.dowChanges.potableWater)">
                        <tr *ngFor="let potableWater of ahmData.dowChanges.potableWater; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td contenteditable="true" [innerHTML]="potableWater.code"
                            (blur)="potableWater.code = $event.target.innerHTML"></td>
                          <td contenteditable="true" [innerHTML]="potableWater.remark"
                            (blur)="potableWater.remark = $event.target.innerHTML"></td>
                          <td contenteditable="true" [innerHTML]="potableWater.weight"
                            (keydown)="inputNotLetter($event)"
                            (blur)="potableWater.weight = toNumber($event.target.innerHTML)"></td>
                          <td (click)="potableWater.index = -potableWater.index" class="font-weight-bold h5"
                            [ngClass]="{'text-red': potableWater.index < 0, 'text-green': potableWater.index > 0}">
                            {{ potableWater.index < 0 ? '-' : '+' }} </td>
                          <td contenteditable="true"
                            [innerHTML]="potableWater.index < 0 ? -potableWater.index : potableWater.index"
                            (keydown)="inputNotLetter($event)"
                            (blur)="potableWater.index = toNumber(potableWater.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                          </td>
                          <td class="text-center">
                            <input type="checkbox" name="potableWaterStandard" id="potableWaterStandard"
                              class="d-hover m-auto" [(ngModel)]="potableWater.standard"
                              (click)="setStandard(ahmData.dowChanges.potableWater, 'standard', i)"
                              [checked]="potableWater.standard">
                          </td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(ahmData.dowChanges.potableWater, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue"
                        (click)="addItemByClass(ahmData.dowChanges.potableWater, dowChangesPotableWater)"
                        i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.potableWater.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card h-100">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.pantry">Pantry weight / pantry code</p>
                    <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.pantry.table.pantryCode">Pantry
                            code</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.pantry.table.remark">Remark</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.pantry.table.pantryWeight">
                            Pantry weight</td>
                          <td>+/-</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.pantry.table.index">Index</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.pantry.table.standard">Standard
                          </td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.dowChanges.pantry)">
                        <tr *ngFor="let pantry of ahmData.dowChanges.pantry; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td contenteditable="true" [innerHTML]="pantry.code"
                            (blur)="pantry.code = $event.target.innerHTML"></td>
                          <td contenteditable="true" [innerHTML]="pantry.remark"
                            (blur)="pantry.remark = $event.target.innerHTML"></td>
                          <td contenteditable="true" [innerHTML]="pantry.weight" (keydown)="inputNotLetter($event)"
                            (blur)="pantry.weight = toNumber($event.target.innerHTML)"></td>
                          <td (click)="pantry.index = -pantry.index" class="font-weight-bold h5"
                            [ngClass]="{'text-red': pantry.index < 0, 'text-green': pantry.index > 0}">
                            {{ pantry.index < 0 ? '-' : '+' }} </td>
                          <td contenteditable="true" [innerHTML]="pantry.index < 0 ? -pantry.index : pantry.index"
                            (keydown)="inputNotLetter($event)"
                            (blur)="pantry.index = toNumber(pantry.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                          </td>
                          <td class="text-center">
                            <input type="checkbox" name="pantryStandard" id="pantryStandard" class="d-hover m-auto"
                              [(ngModel)]="pantry.standard"
                              (click)="setStandard(ahmData.dowChanges.pantry, 'standard', i)"
                              [checked]="pantry.standard">
                          </td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(ahmData.dowChanges.pantry, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue"
                        (click)="addItemByClass(ahmData.dowChanges.pantry, dowChangesPotableWater)"
                        i18n="adm|Weight and balance@@adm.wb.tab.operationalItems.pantry.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="standard-units" role="tabpanel" aria-labelledby="standard-units-tab">
            <div class="row">
              <div class="col-6">
                <div class="card mb-4">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupUnits">Units of measure</p>
                    <div class="row">
                      <div class="col-6">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.standartInits.groupUnits.weight">
                              Weight
                            </span>
                          </div>
                          <ng-select [items]="units.weight" class="w-100 custom-sm" bindLabel="englishName"
                            bindValue="englishName" name="unitsWeight" id="unitsWeight"
                            [(ngModel)]="ahmData.units.weight">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.tab.standartInits.groupUnits.length">
                              Length
                            </span>
                          </div>
                          <ng-select [items]="units.length" class="w-100 custom-sm" bindLabel="englishName"
                            bindValue="englishName" name="unitsLength" id="unitsLength"
                            [(ngModel)]="ahmData.units.length">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units weight@@adm.wb.tab.standartInits.groupUnits.volume">
                              Volume
                            </span>
                          </div>
                          <ng-select [items]="units.volume" class="w-100 custom-sm" bindLabel="englishName"
                            bindValue="englishName" name="unitsVolume" id="unitsVolume"
                            [(ngModel)]="ahmData.units.volume">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units liquid volume@@adm.wb.tab.standartInits.groupUnits.liquidVolume">
                              Liquid volume
                            </span>
                          </div>
                          <ng-select [items]="units.liquidVolume" class="w-100 custom-sm" bindLabel="englishName"
                            bindValue="englishName" name="unitsLiquidVolume" id="unitsLiquidVolume"
                            [(ngModel)]="ahmData.units.liquidVolume">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew">Crew weight</p>
                    <div class="row mb-3">
                      <div class="col-6">
                        <p i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew.cockpit">Cockpit</p>
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units crew weight@@adm.wb.tab.standartInits.groupCrew.crewWeight">
                              Crew weight
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" name="cockpitCrewWeight"
                            id="cockpitCrewWeight" [(ngModel)]="ahmData.standartWeights.crew.cockpit.weight" />
                        </div>
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units hand baggage@@adm.wb.tab.standartInits.groupCrew.handBaggage">
                              Hand baggage
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" name="cockpitHandBaggage"
                            id="cockpitHandBaggage" [(ngModel)]="ahmData.standartWeights.crew.cockpit.handLuggage" />
                        </div>
                      </div>
                      <div class="col-6">
                        <p i18n="adm|Weight and balance@@adm.wb.tab.standartInits.groupCrew.cabin">Cabin</p>
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units crew weight@@adm.wb.tab.standartInits.groupCrew.crewWeight">
                              Crew weight
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" name="cabinCrewWeight"
                            id="cabinCrewWeight" [(ngModel)]="ahmData.standartWeights.crew.cabin.weight" />
                        </div>
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units hand baggage@@adm.wb.tab.standartInits.groupCrew.handBaggage">
                              Hand baggage
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" name="cabinHandBaggage"
                            id="cabinHandBaggage" [(ngModel)]="ahmData.standartWeights.crew.cabin.handLuggage" />
                        </div>
                      </div>
                    </div>
                    <p class="m-0"
                      i18n="adm|Weight and balance - standard units hand baggage included@@adm.wb.tab.standartInits.groupCrew.handBaggageIncluded">
                      Hand baggage weight is included in the crew weights
                    </p>
                    <label class="mr-3">
                      <input type="radio" name="handLuggageIncluded" id="handLuggageIncludedYes"
                        [checked]="ahmData.standartWeights.crew.handLuggageIncluded === true" [value]="true"
                        [(ngModel)]="ahmData.standartWeights.crew.handLuggageIncluded">
                      <span
                        i18n="adm|Weight and balance - yes@@adm.wb.tab.standartInits.groupCrew.button.yes">Yes</span>
                    </label>
                    <label>
                      <input type="radio" name="handLuggageIncluded" id="handLuggageIncludedNo"
                        [checked]="!ahmData.standartWeights.crew.handLuggageIncluded" [value]="false"
                        [(ngModel)]="ahmData.standartWeights.crew.handLuggageIncluded">
                      <span i18n="adm|Weight and balance - no@@adm.wb.tab.standartInits.groupCrew.button.no">No</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card mb-4">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants">
                      Constants
                    </p>
                    <div class="row">
                      <div class="col-6">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants.k">
                              K
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.k" />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units constants@@adm.wb.tab.standartInits.groupConstants.c">
                              C
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.c" />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - standard units reference arm@@adm.wb.tab.standartInits.groupConstants.referenceArm">
                              Reference arm
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm"
                            [(ngModel)]="ahmData.referenceArm" />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - weight@@adm.wb.tab.standartInits.groupConstants.macRc">
                              Mac RC
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.macRc" />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - weight@@adm.wb.tab.standartInits.groupConstants.lemacRc">
                              Lemac RC
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.lemacLerc" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="flight-information" role="tabpanel" aria-labelledby="flight-information">
            <div class="row">
              <div class="col-6 mb-4">
                <div class="card h-100 mb-3">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance - table ac registration@@adm.wb.tab.flightInfo.groupAcReg.table.acRegistration">
                      A/C registration</p>
                    <table class="w-100 contenteditable">
                      <tbody>
                        <tr>
                          <td
                            i18n="adm|Weight and balance - table ac registration@@adm.wb.tab.flightInfo.groupAcReg.table.acRegistration">
                            A/C registration</td>
                          <td>
                            <div class="w-100" *ngIf="!selectLoadAnimation.tailsFiltered; else tailsLoad">
                              <ng-select class="w-100 custom-sm" [items]="references.tailsFiltered"
                                [virtualScroll]="true" bindLabel="tail" bindValue="id" name="ahmDataTailId"
                                [hideSelected]="true" [searchFn]="customSelectSearch"
                                [loading]="selectLoadAnimation.tails" (scroll)="onScrollAirlines($event, 'tails')"
                                (scrollToEnd)="onScrollToEndAirlines('tails')" [(ngModel)]="ahmData.tailId"
                                #ahmDataTailId="ngModel" placeholder="Enter tail for search">
                                <ng-template ng-option-tmp let-item="item">
                                  <span class="font-weight-bold">{{item.tail}}</span><br>
                                  IATA:&nbsp;{{item.aircraftTypeIata}},&nbsp;ICAO:&nbsp;{{item.aircraftTypeIcao}}
                                </ng-template>
                              </ng-select>
                            </div>
                            <ng-template #tailsLoad>
                              <div class="d-flex align-items-center pl-2 bg-light-gray rounded">
                                <div class="spinner-border spinner-border-sm float-left" role="status"
                                  aria-hidden="true">
                                </div>
                                <div class="float-left pl-2"
                                  i18n="adm|Weight and balance - loading@@adm.wb.main.loading">
                                  Loading...
                                </div>
                              </div>
                            </ng-template>
                          </td>
                        </tr>
                        <tr>
                          <td
                            i18n="adm|Weight and balance - flight information crew standart@@adm.wb.flightInformation.crewStandard">
                            Crew standard</td>
                          <td class="font-weight-bold">{{ ahmData.crewStandart }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-4">
                <div class="card">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.flightInfo.groupAircraftMaxWeight">Aircraft maximum
                      weights</p>
                    <div class="row">
                      <div class="col-6 mb-3">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - flight information zero fuel weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.zeroFuelWeight">
                              Zero fuel weight
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm"
                            [(ngModel)]="ahmData.zeroFuelWeight" />
                        </div>
                      </div>
                      <div class="col-6 mb-3">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - flight information landing weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.landingWeight">
                              Landing weight
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm"
                            [(ngModel)]="ahmData.landingWeight" />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - flight information take off weight@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.takeOffWeight">
                              Take off weight
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm"
                            [(ngModel)]="ahmData.takeOffWeight" />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - flight information ramp taxi@@adm.wb.tab.flightInfo.groupAircraftMaxWeight.rampTaxi">
                              Ramp/Taxi
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm"
                            [(ngModel)]="ahmData.rampTaxiWeight" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card mb-3">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance - flight information passenger weights@@adm.wb.tab.flightInfo.groupPassengerWeights">
                      Passenger weights
                    </p>
                    <table class="w-100 contenteditable mb-3">
                      <thead>
                        <tr class="font-weight-bold bg-blue color-white">
                          <td
                            i18n="adm|Weight and balance - table season@@adm.wb.tab.flightInfo.groupPassengerWeights.table.season">
                            Season</td>
                          <td
                            i18n="adm|Weight and balance - table adult male@@adm.wb.tab.flightInfo.groupPassengerWeights.table.adultMale">
                            Adult / Male</td>
                          <td
                            i18n="adm|Weight and balance - table female@@adm.wb.tab.flightInfo.groupPassengerWeights.table.female">
                            Female</td>
                          <td
                            i18n="adm|Weight and balance - table children@@adm.wb.tab.flightInfo.groupPassengerWeights.table.children">
                            Children</td>
                          <td
                            i18n="adm|Weight and balance - table infant@@adm.wb.tab.flightInfo.groupPassengerWeights.table.infant">
                            Infant</td>
                          <td
                            i18n="adm|Weight and balance - table hand baggage@@adm.wb.tab.flightInfo.groupPassengerWeights.table.handBaggage">
                            Hand baggage</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            i18n="adm|Weight and balance - table winter@@adm.wb.tab.flightInfo.groupPassengerWeights.table.winter">
                            Winter</td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.adult">
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.female">
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.child">
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.infant">
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.winter.handLuggage">
                          </td>
                        </tr>
                        <tr>
                          <td
                            i18n="adm|Weight and balance - table summer@@adm.wb.tab.flightInfo.groupPassengerWeights.table.summer">
                            Summer</td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.adult">
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.female">
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.child">
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.infant">
                          </td>
                          <td>
                            <input type="number" [(ngModel)]="ahmData.standartWeights.passengers.summer.handLuggage">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p class="m-0"
                      i18n="adm|Weight and balance - light information hand baggage weight is included@@adm.wb.tab.flightInfo.groupPassengerWeights.handBaggageWeightIncluded">
                      Hand baggage weight is included in the passenger weights
                    </p>
                    <label class="mr-3">
                      <input type="radio" name="baggageIncluded" id="baggageIncludedYes"
                        [checked]="ahmData.standartWeights.passengers.handLuggageIncluded === true" [value]="true"
                        [(ngModel)]="ahmData.standartWeights.passengers.handLuggageIncluded">
                      <span
                        i18n="adm|Weight and balance - yes@@adm.wb.tab.flightInfo.groupPassengerWeights.button.yes">Yes</span>
                    </label>
                    <label>
                      <input type="radio" name="baggageIncluded" id="baggageIncludedNo"
                        [checked]="ahmData.standartWeights.passengers.handLuggageIncluded === false" [value]="false"
                        [(ngModel)]="ahmData.standartWeights.passengers.handLuggageIncluded">
                      <span
                        i18n="adm|Weight and balance - no@@adm.wb.tab.flightInfo.groupPassengerWeights.button.no">No</span>
                    </label>
                    <div class="d-flex align-items-center mt-3">
                      <p class="mb-0 mr-3 text-nowrap"
                        i18n="adm|Weight and balance - flight information passenger weight includes@@adm.wb.tab.flightInfo.groupPassengerWeights.passengerWeightIncludes">
                        Passenger weight includes carry-on weight
                      </p>
                      <div class="input-group w-auto">
                        <input type="number" class="form-control custom-sm" name="includesCarryOn" id="includesCarryOn">
                        <div class="input-group-append custom-sm">
                          <span class="input-group-text font-small py-0"
                            i18n="adm|Weight and balance - kg@@adm.wb.tab.flightInfo.groupPassengerWeights.kg">kg</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="centre-charts" role="tabpanel" aria-labelledby="centre-charts-tab">
            <div class="card mb-3">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3 pb-2 border-bottom"
                  i18n="adm|Weight and balance - fuel weights@@adm.wb.tab.centreOfGravity.idealTrimLine">Ideal trim line</p>
                <table class="contenteditable w-100 mb-3">
                  <thead class="bg-blue color-white font-weight-bold text-center">
                    <tr>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.idealTrimLine.table.weight" rowspan="2">Weight</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.idealTrimLine.table.idealTrimLine" colspan="2">Ideal trim line</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.idealTrimLine.table.idealTrimAreaFwd" colspan="2">Ideal trim area Fwd</td>
                      <td i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.idealTrimLine.table.idealTrimAreaAft" colspan="2">Ideal trim area Aft</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>%MAC/RC</td>
                      <td>Index</td>
                      <td>%MAC/RC</td>
                      <td>Index</td>
                      <td>%MAC/RC</td>
                      <td>Index</td>
                      <td width="35px"></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of ahmData.idealTrimLine; let i_condition = index">
                      <td>
                        <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="item.weight" />
                      </td>
                      <td>
                        <input type="number"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="item.line.mac"
                              (blur)="item.line.index = getIndexOfMac(item.line.mac, item.weight)" />
                      </td>
                      <td>
                        <input type="number"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="item.line.index"
                              (blur)="item.line.mac = getMacOfIndex(item.line.index, item.weight)" />
                      </td>
                      <td>
                        <input type="number"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="item.fwd.mac"
                              (blur)="item.fwd.index = getIndexOfMac(item.fwd.mac, item.weight)" />
                      </td>
                      <td>
                        <input type="number"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="item.fwd.index"
                              (blur)="item.fwd.mac = getMacOfIndex(item.fwd.index, item.weight)" />
                      </td>
                      <td>
                        <input type="number"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="item.aft.mac"
                              (blur)="item.aft.index = getIndexOfMac(item.aft.mac, item.weight)" />
                      </td>
                      <td>
                        <input type="number"
                              class="w-100 form-control custom-sm"
                              [(ngModel)]="item.aft.index"
                              (blur)="item.aft.mac = getMacOfIndex(item.aft.index, item.weight)" />
                      </td>
                      <td>
                        <i class="material-icons m-0 text-red font-weight-bold pointer" ngbTooltip="Delete"
                          (click)="deleteElement(ahmData.idealTrimLine, i_condition)"
                          i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">clear</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button class="btn btn-xs btn-blue float-right"
                  (click)="addItemByClass(ahmData.idealTrimLine, idealTrimLine)"
                  *ngIf="ahmData.idealTrimLine && ahmData.idealTrimLine.length < 2"
                  i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.idealTrimLine.button.add">
                  Add
                </button>
              </div>
            </div>
            <div class="card mb-3" *ngFor="let centreOfGravityChart of ahmData.centreOfGravityChart; let i_cgc = index">
              <div class="card-body">
                <p class="font-weight-bold text-blue h5 mb-3 pb-2 border-bottom"
                  i18n="adm|Weight and balance - fuel weights@@adm.wb.tab.centreOfGravity.fuelWeights">Fuel weights</p>
                <div class="row">
                  <div class="col-4 border-right">
                    <div class="input-group flex-nowrap">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.note">
                          Note
                        </span>
                      </div>
                      <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="centreOfGravityChart.note" />
                    </div>
                    <div class="d-flex justify-content-between align-items-center pt-3">
                      <p class="font-weight-bold my-3"
                        i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.applicationRules">
                        Application rules
                      </p>
                      <button class="btn btn-xs btn-blue"
                        (click)="addItemByClass(centreOfGravityChart.conditions, centreOfGravityChartCondition)"
                        i18n="adm|Weight and balance - button add rule@@adm.wb.tab.fuel.groupFuel.button.addRule">
                        Add rule
                      </button>
                    </div>
                    <table class="w-100 contenteditable mb-3">
                      <thead>
                        <tr class="font-weight-bold bg-blue color-white">
                          <td
                            i18n="adm|Weight and balance - table type@@adm.wb.tab.centreOfGravityChart.rules.table.type"
                            width="40%" class="p-2">Type</td>
                          <td
                            i18n="adm|Weight and balance - table from@@adm.wb.tab.centreOfGravityChart.rules.table.from"
                            class="p-2">From</td>
                          <td i18n="adm|Weight and balance - table to@@adm.wb.tab.centreOfGravityChart.rules.table.to"
                            class="p-2">To</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let condition of centreOfGravityChart.conditions; let i_condition = index">
                          <td>
                            <ng-select [items]="chartTypes" class="w-100 custom-sm bg-white" bindLabel="nameEnglish"
                              bindValue="id" name="chartType" id="chartType" [(ngModel)]="condition.type">
                            </ng-select>
                          </td>
                          <td>
                            <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="condition.from" />
                          </td>
                          <td>
                            <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="condition.to" />
                          </td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer" ngbTooltip="Delete"
                              (click)="deleteElement(centreOfGravityChart.conditions, i_condition)"
                              i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between align-items-center border-top mt-3 pt-3">
                      <p class="font-weight-bold my-3"
                        i18n="adm|Weight and balance - centure of gravity@@adm.wb.tab.centreOfGravity.centreOfGravity">
                        Centure of gravity
                      </p>
                      <button type="button" class="btn btn-xs btn-blue"
                        (click)="getGravity(ahmData.centreOfGravityChart)"
                        i18n="adm|Weight and balance buttons - refresh chart@@adm.wb.main.button.refreshChart">
                        Refresh
                      </button>
                    </div>
                    <div id="gravityCentreBlock_{{i_cgc}}" class="mt-3" #gravityCentreBlock_{{i_cgc}}></div>
                  </div>
                  <div class="col-8">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="zeroFuel-tab" data-toggle="tab"
                          i18n="adm|Weight and balance - zero fuel@@adm.wb.tab.centreOfGravity.zeroFuel"
                          href="#zeroFuel_{{i_cgc}}" role="tab">Zero fuel</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="takeOff-tab" data-toggle="tab"
                          i18n="adm|Weight and balance - take off@@adm.wb.tab.centreOfGravity.takeOff"
                          href="#takeOff_{{i_cgc}}" role="tab">Take off</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="landing-tab" data-toggle="tab"
                          i18n="adm|Weight and balance - landing@@adm.wb.tab.centreOfGravity.landing"
                          href="#landing_{{i_cgc}}" role="tab">Landing</a>
                      </li>
                    </ul>
                    <div class="tab-content pt-3" id="myTabContent">
                      <div class="tab-pane fade show active" id="zeroFuel_{{i_cgc}}" role="tabpanel"
                        aria-labelledby="zeroFuel-tab">
                        <div class="row">
                          <div class="col-6">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="mb-0 font-weight-bold"
                                i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.groupZeroFuel.fwd">FWD</p>
                              <button class="btn-ico btn btn-xs btn-light-gray"
                                (click)="addItemByClass(centreOfGravityChart.fwd.zeroFuel, itemEl)">
                                +
                              </button>
                            </div>
                            <table class="w-100 contenteditable text-center mb-3">
                              <thead>
                                <tr class="font-weight-bold">
                                  <td width="35px"></td>
                                  <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                                  <td></td>
                                  <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                                  <td width="35px"></td>
                                </tr>
                              </thead>
                              <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.zeroFuel)">
                                <tr *ngFor="let element of centreOfGravityChart.fwd.zeroFuel; let i = index" cdkDrag>
                                  <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                                  <td contenteditable="true" [innerHTML]="element.weight"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                                  <td (click)="element.index = -element.index" class="font-weight-bold h5"
                                    [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                    {{ element.index < 0 ? '-' : '+' }} </td>
                                  <td contenteditable="true"
                                    [innerHTML]="element.index < 0 ? -element.index : element.index"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                                  </td>
                                  <td>
                                    <i class="material-icons m-0 text-red font-weight-bold pointer"
                                      (click)="deleteElement(centreOfGravityChart.fwd.zeroFuel, i)">clear</i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-6">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="mb-0 font-weight-bold"
                                i18n="adm|Weight and balance@@adm.wb.tab.centreOfGravity.groupZeroFuel.aft">AFT</p>
                              <button class="btn-ico btn btn-xs btn-light-gray"
                                (click)="addItemByClass(centreOfGravityChart.aft.zeroFuel, itemEl)">
                                +
                              </button>
                            </div>
                            <table class="w-100 contenteditable text-center mb-3">
                              <thead>
                                <tr class="font-weight-bold">
                                  <td width="35px"></td>
                                  <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                                  <td></td>
                                  <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                                  <td width="35px"></td>
                                </tr>
                              </thead>
                              <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.zeroFuel)">
                                <tr *ngFor="let element of centreOfGravityChart.aft.zeroFuel; let i = index" cdkDrag>
                                  <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                                  <td contenteditable="true" [innerHTML]="element.weight"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                                  <td (click)="element.index = -element.index" class="font-weight-bold h5"
                                    [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                    {{ element.index < 0 ? '-' : '+' }} </td>
                                  <td contenteditable="true"
                                    [innerHTML]="element.index < 0 ? -element.index : element.index"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                                  </td>
                                  <td>
                                    <i class="material-icons m-0 text-red font-weight-bold pointer"
                                      (click)="deleteElement(centreOfGravityChart.aft.zeroFuel, i)">clear</i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="takeOff_{{i_cgc}}" role="tabpanel" aria-labelledby="takeOff-tab">
                        <div class="row">
                          <div class="col-6">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="mb-0 font-weight-bold"
                                i18n="adm|Weight and balance - charts take off@@adm.wb.tab.centreOfGravity.fwd">FWD</p>
                              <button class="btn-ico btn btn-xs btn-light-gray"
                                (click)="addItemByClass(centreOfGravityChart.fwd.takeOff, itemEl)">
                                +
                              </button>
                            </div>
                            <table class="w-100 contenteditable text-center mb-3">
                              <thead>
                                <tr class="font-weight-bold">
                                  <td width="35px"></td>
                                  <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                                  <td></td>
                                  <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                                  <td width="35px"></td>
                                </tr>
                              </thead>
                              <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.takeOff)">
                                <tr *ngFor="let element of centreOfGravityChart.fwd.takeOff; let i = index" cdkDrag>
                                  <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                                  <td contenteditable="true" [innerHTML]="element.weight"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                                  <td (click)="element.index = -element.index" class="font-weight-bold h5"
                                    [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                    {{ element.index < 0 ? '-' : '+' }} </td>
                                  <td contenteditable="true"
                                    [innerHTML]="element.index < 0 ? -element.index : element.index"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                                  </td>
                                  <td>
                                    <i class="material-icons m-0 text-red font-weight-bold pointer"
                                      (click)="deleteElement(centreOfGravityChart.fwd.takeOff, i)">clear</i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-6">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="mb-0 font-weight-bold"
                                i18n="adm|Weight and balance - charts take off@@adm.wb.tab.centreOfGravity.aft">AFT</p>
                              <button class="btn-ico btn btn-xs btn-light-gray"
                                (click)="addItemByClass(centreOfGravityChart.aft.takeOff, itemEl)">
                                +
                              </button>
                            </div>
                            <table class="w-100 contenteditable text-center mb-3">
                              <thead>
                                <tr class="font-weight-bold">
                                  <td width="35px"></td>
                                  <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                                  <td></td>
                                  <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                                  <td width="35px"></td>
                                </tr>
                              </thead>
                              <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.takeOff)">
                                <tr *ngFor="let element of centreOfGravityChart.aft.takeOff; let i = index" cdkDrag>
                                  <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                                  <td contenteditable="true" [innerHTML]="element.weight"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                                  <td (click)="element.index = -element.index" class="font-weight-bold h5"
                                    [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                    {{ element.index < 0 ? '-' : '+' }} </td>
                                  <td contenteditable="true"
                                    [innerHTML]="element.index < 0 ? -element.index : element.index"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                                  </td>
                                  <td>
                                    <i class="material-icons m-0 text-red font-weight-bold pointer"
                                      (click)="deleteElement(centreOfGravityChart.aft.takeOff, i)">clear</i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="landing_{{i_cgc}}" role="tabpanel" aria-labelledby="landing-tab">
                        <div class="row">
                          <div class="col-6">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="mb-0 font-weight-bold"
                                i18n="adm|Weight and balance - charts landing@@adm.wb.tab.centreOfGravity.groupLanding.fwd">
                                FWD</p>
                              <button class="btn-ico btn btn-xs btn-light-gray"
                                (click)="addItemByClass(centreOfGravityChart.fwd.landing, itemEl)">
                                +
                              </button>
                            </div>
                            <table class="w-100 contenteditable text-center mb-3">
                              <thead>
                                <tr class="font-weight-bold">
                                  <td width="35px"></td>
                                  <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                                  <td></td>
                                  <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                                  <td width="35px"></td>
                                </tr>
                              </thead>
                              <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.fwd.landing)">
                                <tr *ngFor="let element of centreOfGravityChart.fwd.landing; let i = index" cdkDrag>
                                  <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                                  <td contenteditable="true" [innerHTML]="element.weight"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                                  <td (click)="element.index = -element.index" class="font-weight-bold h5"
                                    [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                    {{ element.index < 0 ? '-' : '+' }} </td>
                                  <td contenteditable="true"
                                    [innerHTML]="element.index < 0 ? -element.index : element.index"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                                  </td>
                                  <td>
                                    <i class="material-icons m-0 text-red font-weight-bold pointer"
                                      (click)="deleteElement(centreOfGravityChart.fwd.landing, i)">clear</i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-6">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                              <p class="mb-0 font-weight-bold"
                                i18n="adm|Weight and balance - charts landing@@adm.wb.tab.centreOfGravity.groupLanding.aft">
                                AFT</p>
                              <button class="btn-ico btn btn-xs btn-light-gray"
                                (click)="addItemByClass(centreOfGravityChart.aft.landing, itemEl)">
                                +
                              </button>
                            </div>
                            <table class="w-100 contenteditable text-center mb-3">
                              <thead>
                                <tr class="font-weight-bold">
                                  <td width="35px"></td>
                                  <td i18n="adm|Weight and balance - table weight@@adm.wb.table.weight">Weight</td>
                                  <td></td>
                                  <td i18n="adm|Weight and balance - table index@@adm.wb.table.index">Index</td>
                                  <td width="35px"></td>
                                </tr>
                              </thead>
                              <tbody cdkDropList (cdkDropListDropped)="drop($event, centreOfGravityChart.aft.landing)">
                                <tr *ngFor="let element of centreOfGravityChart.aft.landing; let i = index" cdkDrag>
                                  <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                                  <td contenteditable="true" [innerHTML]="element.weight"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                                  <td (click)="element.index = -element.index" class="font-weight-bold h5"
                                    [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                                    {{ element.index < 0 ? '-' : '+' }} </td>
                                  <td contenteditable="true"
                                    [innerHTML]="element.index < 0 ? -element.index : element.index"
                                    (keydown)="inputNotLetter($event)"
                                    (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                                  </td>
                                  <td>
                                    <i class="material-icons m-0 text-red font-weight-bold pointer"
                                      (click)="deleteElement(centreOfGravityChart.aft.landing, i)">clear</i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" class="btn ml-3 btn-red float-right"
                  (click)="deleteElement(ahmData.centreOfGravityChart, i_cgc)"
                  i18n="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                  Delete
                </button>
              </div>
            </div>
            <div class="text-right">
              <button class="btn btn-blue" (click)="addItemByClass(ahmData.centreOfGravityChart, centreOfGravityChart)"
                i18n="adm|Weight and balance - button add@@adm.wb.tab.fuel.groupFuel.button.add">
                Add
              </button>
            </div>
          </div>
          <div class="tab-pane fade" id="fuel" role="tabpanel" aria-labelledby="fuel-tab">
            <div class="input-group flex-nowrap mb-3">
              <div class="input-group-prepend custom-sm">
                <span class="input-group-text font-small py-0"
                  i18n="adm|Weight and balance - fuel taxi fuel weight@@adm.wb.tab.fuel.taxiFuelWeight">
                  Taxi fuel weight
                </span>
              </div>
              <input type="number" class="w-100 form-control custom-sm" [(ngModel)]="ahmData.taxiFuel" />
            </div>
            <div class="row">
              <div class="col-lg-6 mb-3" *ngFor="let effectOfFuel of ahmData.effectOfFuel; let indexFuel = index">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <div>
                        <p class="font-weight-bold text-blue h5 mb-2"
                          i18n="adm|Weight and balance - fuel fuel@@adm.wb.tab.fuel.groupFuel">Fuel</p>
                        <label>
                          <input type="checkbox" [(ngModel)]="effectOfFuel.default"
                            (click)="setStandard(ahmData.effectOfFuel, 'default', indexFuel)"
                            [checked]="effectOfFuel.default">&nbsp;<span
                            i18n="adm|Weight and balance - fuel@@adm.wb.tab.fuel.groupFuel.standard">Standard</span>
                        </label>
                      </div>
                      <div>
                        <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                          (click)="copyItem(ahmData.effectOfFuel, ahmData.effectOfFuel[indexFuel])"
                          i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                          <i class="material-icons color-white">content_copy</i>
                        </button>
                        <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                          (click)="deleteElement(ahmData.effectOfFuel, indexFuel)"
                          i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                          <i class="material-icons color-white">delete</i>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 pr-0">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - fuel fuel density@@adm.wb.tab.fuel.groupFuel.fuelDensity">
                              Density
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm"
                            [(ngModel)]="effectOfFuel.density" />
                        </div>
                      </div>
                      <div class="col-md-4 pr-0">
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - fuel fuel density min@@adm.wb.tab.fuel.groupFuel.fuelDensityMin">
                              Density min
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - fuel fuel density max@@adm.wb.tab.fuel.groupFuel.fuelDensityMax">
                              Density max
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm" />
                        </div>
                      </div>
                    </div>
                    <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td
                            i18n="adm|Weight and balance - table fuel weight@@adm.wb.tab.fuel.groupFuel.table.fuelWeight">
                            Fuel Weight</td>
                          <td></td>
                          <td i18n="adm|Weight and balance - table index@@adm.wb.tab.fuel.groupFuel.table.index">Index
                          </td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, effectOfFuel.balance)">
                        <tr *ngFor="let element of effectOfFuel.balance; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                            (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                          <td (click)="element.index = -element.index" class="font-weight-bold h5"
                            [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                            {{ element.index < 0 ? '-' : '+' }} </td>
                          <td contenteditable="true" [innerHTML]="element.index < 0 ? -element.index : element.index"
                            (keydown)="inputNotLetter($event)"
                            (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                          </td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(effectOfFuel.balance, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue" (click)="addItemByClass(effectOfFuel.balance, itemEl)"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.fuel.groupFuel.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="card bg-gray"
                  (click)="addItem(ahmData.effectOfFuel, {density: null, default:false, balance: []})">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="h1">+</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="holds-compartments" role="tabpanel" aria-labelledby="holds-compartments-tab">
            <div class="row mb-4">
              <div class="col-lg-9 pr-0">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="autoCaclulationIndexCenter" [(ngModel)]="autoCalculateIndexCenter">
                        <label class="custom-control-label"
                               for="autoCaclulationIndexCenter"
                               i18n="adm|Weight and balance - table hold@@adm.wb.tab.holdsAndCompartments.hold.autoCalcIndexCenter">Automatically
                          calculate index/center</label>
                      </div>
                      <button *ngIf="isUserDeveloper()"
                              class="btn btn-sm btn-light-gray ml-auto"
                              type="button"
                              (click)="calculateBulkPositions()"
                              i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.hold.button.calcBulkPositions">Calculate bulk positions</button>
                    </div>
                    <hr>
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.groupFwd">FWD</p>
                    <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td i18n="adm|Weight and balance - table hold@@adm.wb.tab.holdsAndCompartments.groupFwd.table.hold">
                            Hold</td>
                          <td i18n="adm|Weight and balance - table bay@@adm.wb.tab.holdsAndCompartments.groupFwd.table.bay">
                            Bay</td>
                          <td i18n="adm|Weight and balance - table bulk@@adm.wb.tab.holdsAndCompartments.groupFwd.table.bulk">
                            Bulk</td>
                          <td i18n="adm|Weight and balance - table uld name@@adm.wb.tab.holdsAndCompartments.groupFwd.table.uldSize">
                            ULD or size</td>
                          <td i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.groupFwd.table.maxWeight">
                            Max weight</td>
                          <td i18n="adm|Weight and balance - table vol@@adm.wb.tab.holdsAndCompartments.groupFwd.table.vol">
                            Vol</td>
                          <td i18n="adm|Weight and balance - table orientation@@adm.wb.tab.holdsAndCompartments.groupFwd.table.orientation">
                            Orientation</td>
                          <td i18n="adm|Weight and balance - table center@@adm.wb.tab.holdsAndCompartments.groupFwd.table.center">
                            Center</td>
                          <td></td>
                          <td i18n="adm|Weight and balance - table index@@adm.wb.tab.holdsAndCompartments.groupFwd.table.index">
                            Index</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.holdsAndCompartments.fwd)">
                        <tr *ngFor="let element of ahmData.holdsAndCompartments.fwd; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td contenteditable="true" [innerHTML]="element.group"
                            (blur)="element.group = $event.target.innerHTML"></td>
                          <td contenteditable="true" [innerHTML]="element.name"
                            (blur)="element.name = $event.target.innerHTML;"></td>
                          <td>
                            <input type="checkbox"
                              name="potableWaterStandard"
                              id="potableWaterStandard"
                              class="d-hover m-auto"
                              (click)="changeBulk(element, $event.target.checked);"
                              value="1"
                              [checked]="element.type === 'bulk'">
                          </td>
                          <td *ngIf="element.type !== 'bulk'">
                            <ng-select class="form-control custom-sm p-0"
                                       [items]="ahm.uldTypes"
                                       bindLabel="name"
                                       bindValue="name"
                                       [multiple]="true"
                                       [closeOnSelect]="false"
                                       [(ngModel)]="element.uldTypes"
                                       [virtualScroll]="true"
                                       (change)="changeUld(element, $event)">
                            </ng-select>
                          </td>
                          <td *ngIf="element.type === 'bulk'">
                            <div class="input-group flex-nowrap">
                              <input placeholder="From"
                                     type="number"
                                     class="w-100 form-control custom-sm"
                                     [(ngModel)]="element.from"
                                     i18n-placeholder="adm|Weight and balance - table from to@@adm.wb.tab.holdsAndCompartments.table.from"/>
                              <input placeholder="To"
                                     type="number"
                                     class="w-100 form-control custom-sm"
                                     [(ngModel)]="element.to"
                                     i18n-placeholder="adm|Weight and balance - table from to@@adm.wb.tab.holdsAndCompartments.table.to"/>
                            </div>
                          </td>
                          <td contenteditable="true"
                              [innerHTML]="element.maxWeight"
                              (keydown)="inputNotLetter($event)"
                              (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true"
                              [innerHTML]="element.volume"
                              (keydown)="inputNotLetter($event)"
                              (blur)="element.volume = toNumber($event.target.innerHTML)"></td>
                          <td>
                            <ng-select *ngIf="element.type !== 'bulk'"
                                       class="form-control custom-sm p-0"
                                       [items]="['LR', 'FB']"
                                       [(ngModel)]="element.orientation"
                                       [disabled]="element.type === 'bulk'">
                            </ng-select>
                          </td>
                          <td contenteditable="true"
                              [innerHTML]="element.centroid"
                              (keydown)="inputNotLetter($event)"
                              (blur)="element.centroid = toNumber($event.target.innerHTML); calculateIndexForBay(element);">
                          </td>
                          <td (click)="element.index = -element.index;"
                              class="font-weight-bold h5"
                              [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                            {{ element.index < 0 ? '-' : '+' }}
                          </td>
                          <td contenteditable="true"
                              [innerHTML]="element.index < 0 ? -element.index : element.index"
                              (keydown)="inputNotLetter($event)"
                              (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML); calculateCenterForBay(element);">
                          </td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(ahmData.holdsAndCompartments.fwd, i);">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue"
                        (click)="addItemByClass(ahmData.holdsAndCompartments.fwd, holdsAndCompartments)"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupFwd.button.add">
                        Add
                      </button>
                    </div>

                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.groupAft">AFT</p>
                    <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td i18n="adm|Weight and balance - table hold@@adm.wb.tab.holdsAndCompartments.groupFwd.table.hold">
                            Hold</td>
                          <td i18n="adm|Weight and balance - table bay@@adm.wb.tab.holdsAndCompartments.groupFwd.table.bay">
                            Bay</td>
                          <td i18n="adm|Weight and balance - table bulk@@adm.wb.tab.holdsAndCompartments.groupFwd.table.bulk">
                            Bulk</td>
                          <td i18n="adm|Weight and balance - table uld name@@adm.wb.tab.holdsAndCompartments.groupFwd.table.uldSize">
                            ULD or size</td>
                          <td i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.groupFwd.table.maxWeight">
                            Max weight</td>
                          <td i18n="adm|Weight and balance - table vol@@adm.wb.tab.holdsAndCompartments.groupFwd.table.vol">
                            Vol</td>
                          <td i18n="adm|Weight and balance - table orientation@@adm.wb.tab.holdsAndCompartments.groupFwd.table.orientation">
                            Orientation</td>
                          <td i18n="adm|Weight and balance - table center@@adm.wb.tab.holdsAndCompartments.groupFwd.table.center">
                            Center</td>
                          <td></td>
                          <td i18n="adm|Weight and balance - table index@@adm.wb.tab.holdsAndCompartments.groupFwd.table.index">
                            Index</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.holdsAndCompartments.aft)">
                        <tr *ngFor="let element of ahmData.holdsAndCompartments.aft; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td contenteditable="true"
                              [innerHTML]="element.group"
                              (blur)="element.group = $event.target.innerHTML"></td>
                          <td contenteditable="true"
                              [innerHTML]="element.name"
                              (blur)="element.name = $event.target.innerHTML;"></td>
                          <td>
                            <input type="checkbox"
                              name="potableWaterStandard"
                              id="potableWaterStandard"
                              class="d-hover m-auto"
                              (click)="changeBulk(element, $event.target.checked);"
                              value="1"
                              [checked]="element.type === 'bulk'">
                          </td>
                          <td *ngIf="element.type !== 'bulk'">
                            <ng-select class="form-control custom-sm p-0"
                                       [items]="ahm.uldTypes"
                                       bindLabel="name"
                                       bindValue="name"
                                       [multiple]="true"
                                       [closeOnSelect]="false"
                                       [(ngModel)]="element.uldTypes"
                                       [virtualScroll]="true"
                                       (change)="changeUld(element, $event)">
                            </ng-select>
                          </td>
                          <td *ngIf="element.type === 'bulk'">
                            <div class="input-group flex-nowrap">
                              <input placeholder="From"
                                     type="number"
                                     class="w-100 form-control custom-sm"
                                     [(ngModel)]="element.from"
                                     i18n-placeholder="adm|Weight and balance - table from to@@adm.wb.tab.holdsAndCompartments.table.from"/>
                              <input placeholder="To"
                                     type="number"
                                     class="w-100 form-control custom-sm"
                                     [(ngModel)]="element.to"
                                     i18n-placeholder="adm|Weight and balance - table from to@@adm.wb.tab.holdsAndCompartments.table.to"/>
                            </div>
                          </td>
                          <td contenteditable="true"
                              [innerHTML]="element.maxWeight"
                              (keydown)="inputNotLetter($event)"
                              (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true"
                              [innerHTML]="element.volume"
                              (keydown)="inputNotLetter($event)"
                              (blur)="element.volume = toNumber($event.target.innerHTML)"></td>
                          <td>
                            <ng-select *ngIf="element.type !== 'bulk'"
                                       class="form-control custom-sm p-0"
                                       [items]="['LR', 'FB']"
                                       [(ngModel)]="element.orientation"
                                       [disabled]="element.type === 'bulk'">
                            </ng-select>
                          </td>
                          <td contenteditable="true"
                              [innerHTML]="element.centroid"
                              (keydown)="inputNotLetter($event)"
                              (blur)="element.centroid = toNumber($event.target.innerHTML); calculateIndexForBay(element);">
                          </td>
                          <td (click)="element.index = -element.index" class="font-weight-bold h5"
                            [ngClass]="{'text-red': element.index < 0, 'text-green': element.index > 0}">
                            {{ element.index < 0 ? '-' : '+' }} </td>
                          <td contenteditable="true"
                              [innerHTML]="element.index < 0 ? -element.index : element.index"
                              (keydown)="inputNotLetter($event)"
                              (blur)="element.index = toNumber(element.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML); calculateCenterForBay(element);">
                          </td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(ahmData.holdsAndCompartments.aft, i);">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue"
                        (click)="addItemByClass(ahmData.holdsAndCompartments.aft, holdsAndCompartments)"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupAft.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.acSize">
                      AC size
                    </p>
                    <table class="w-100 text-center mb-3 contenteditable">
                      <thead>
                        <tr class="font-weight-bold">
                          <td i18n="adm|Weight and balance - table bay@@adm.wb.tab.holdsAndCompartments.acSize.name">Name</td>
                          <td i18n="adm|Weight and balance - table bay@@adm.wb.tab.holdsAndCompartments.acSize.from">From</td>
                          <td i18n="adm|Weight and balance - table bay@@adm.wb.tab.holdsAndCompartments.acSize.to">To</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let size of ahmData.holdsAndCompartments.acSize; let i = index">
                          <td>
                            <ng-select class="form-control custom-sm p-0"
                                       [items]="['aft', 'fwd', 'bulk']"
                                       [(ngModel)]="size.name">
                            </ng-select>
                          </td>
                          <td contenteditable="true" [innerHTML]="size.start" (keydown)="inputNotLetter($event)"
                            (blur)="size.start = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="size.finish" (keydown)="inputNotLetter($event)"
                            (blur)="size.finish = toNumber($event.target.innerHTML)"></td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(ahmData.holdsAndCompartments.acSize, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue"
                        (click)="addItem(ahmData.holdsAndCompartments.acSize, {name: '', start: null, finish: null})"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupAft.button.add">
                        Add
                      </button>
                    </div>
                    <p class="font-weight-bold text-blue h5 mb-3" i18n="adm|Weight and balance@@adm.wb.tab.bayMap.doors">
                      Doors
                    </p>
                    <table class="w-100 text-center mb-3 contenteditable">
                      <thead>
                        <tr class="font-weight-bold">
                          <td i18n="adm|Weight and balance - table bay@@adm.wb.tab.holdsAndCompartments.doors.from">From</td>
                          <td i18n="adm|Weight and balance - table bay@@adm.wb.tab.holdsAndCompartments.doors.to">To</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let door of ahmData.holdsAndCompartments.doors; let i = index">
                          <td contenteditable="true" [innerHTML]="door.from" (keydown)="inputNotLetter($event)"
                            (blur)="door.from = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="door.to" (keydown)="inputNotLetter($event)"
                            (blur)="door.to = toNumber($event.target.innerHTML)"></td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(ahmData.holdsAndCompartments.doors, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue"
                        (click)="addItem(ahmData.holdsAndCompartments.doors, {centroid: null, size: null})"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupAft.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="text-right mb-3">
                  <button class="btn btn-blue" (click)="generateBaysMap(bayMap)"
                      i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.groupAft.button.generateBayMap">
                    Generate bay map
                  </button>
                </div>
                <div class="example-boundary d-flex position-relative flex-row-reverse w-100" #bayMap
                    [ngStyle]="{'width': (trunkMap.width * trunkMap.proportions) + 'px'}">
                  <!-- <div *ngIf="trunkMap.loading; else: map" class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <ng-template #map> -->
                    <div class="bay-map" *ngFor="let compartment of trunkMap.trunkMap; let i = index"
                      [ngStyle]="{'width': (compartment.width * trunkMap.proportions) + 'px',
                                  'height': (trunkMap.height * trunkMap.proportions) + 'px'}">
                      <div class="ac-doors"
                          *ngFor="let door of compartment.doors; let i_door = index"
                          [ngStyle]="{'width': door.width * trunkMap.proportions + 'px',
                                    'right': (door.right * trunkMap.proportions) + 'px'}"></div>
                      <div *ngFor="let mapItem of compartment.bays"
                            class="map-item map-item-{{mapItem.category}} {{ mapItem.classes?.join(' ') }}"
                            [ngStyle]="{'width': mapItem.styles.width * trunkMap.proportions + 'px',
                                        'height': mapItem.styles.height * trunkMap.proportions + 'px',
                                        'top': mapItem.styles.top * trunkMap.proportions + 'px',
                                        'z-index': mapItem.styles.index,
                                        'bottom': mapItem.styles.bottom * trunkMap.proportions + 'px',
                                        'right': mapItem.styles.right * trunkMap.proportions + 'px'}">
                        <div class="map-item-name text-right px-2">
                          {{mapItem.name}}
                        </div>
                      </div>
                    </div>
                  <!-- </ng-template> -->
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-md-6">
                <div class="card">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.uldTypes">ULD types</p>
                    <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td
                            i18n="adm|Weight and balance - table name@@adm.wb.tab.holdsAndCompartments.uldTypes.table.name">
                            Name</td>
                          <td
                            i18n="adm|Weight and balance - table weight@@adm.wb.tab.holdsAndCompartments.uldTypes.table.weight">
                            Weight</td>
                          <td
                            i18n="adm|Weight and balance - table max weight@@adm.wb.tab.holdsAndCompartments.uldTypes.table.maxWeight">
                            Max weight</td>
                          <td
                            i18n="adm|Weight and balance - table max volume@@adm.wb.tab.holdsAndCompartments.uldTypes.table.maxVolume">
                            Max volume</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.uldTypes)">
                        <tr *ngFor="let element of ahmData.uldTypes; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td contenteditable="true" [innerHTML]="element.name"
                            (blur)="element.name = $event.target.innerHTML"></td>
                          <td contenteditable="true" [innerHTML]="element.weight" (keydown)="inputNotLetter($event)"
                            (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="element.maxWeight" (keydown)="inputNotLetter($event)"
                            (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="element.maxVolume" (keydown)="inputNotLetter($event)"
                            (blur)="element.maxVolume = toNumber($event.target.innerHTML)"></td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(ahmData.uldTypes, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue" (click)="addItemByClass(ahmData.uldTypes, uldTypes)"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.uldTypes.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="card">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.groupWeightLimit">
                      Combined load limits
                    </p>
                    <div class="row mb-3" *ngFor="let limit of ahmData.holdsAndCompartments.limits; let i = index">
                      <div class="col-6 pr-0">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.weightLimit.сompartments">Compartments
                            </span>
                          </div>
                          <ng-select class="custom-sm p-0 w-100" [items]="compartmentsGroup" [multiple]="true"
                            [closeOnSelect]="true" bindLabel="name" bindValue="name" (focus)="getCompartments()"
                            [(ngModel)]="limit.compartments">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group flex-nowrap">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.weightLimit.maxWeight">Max
                              weight
                            </span>
                          </div>
                          <input type="number" class="form-control custom-sm" [(ngModel)]="limit.maxWeight" />
                          <i class="material-icons m-0 text-red font-weight-bold pointer"
                            (click)="deleteElement(ahmData.holdsAndCompartments.limits, i)">clear</i>
                        </div>
                      </div>
                    </div>
                    <!-- (click)="addItemByClass(ahmData.holdsAndCompartments.limits, weightLimit)" -->
                    <div class="text-right">
                      <button class="btn btn-blue"
                        (click)="addItemByClass(ahmData.holdsAndCompartments.limits, weightLimit)"
                        (blur)="getCompartments()"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.holdsAndCompartments.weightLimit.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="cabin-equipment" role="tabpanel" aria-labelledby="cabin-equipment-tab">
            <div class="row mb-3">
              <div class="col-md-6 mb-3"
                *ngFor="let configuration of ahmData.configurations; let indexConfiguration = index">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                      <p class="font-weight-bold text-blue h5 mb-3"
                        i18n="adm|Weight and balance - button add@@adm.wb.tab.cabinAndEquipment.groupCabinArea">Cabin
                        area information</p>
                      <div>
                        <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                          (click)="copyItem(ahmData.configurations, ahmData.configurations[indexConfiguration])"
                          i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                          <i class="material-icons color-white">content_copy</i>
                        </button>
                        <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                          (click)="deleteElement(ahmData.configurations, indexConfiguration)"
                          i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                          <i class="material-icons color-white">delete</i>
                        </button>
                      </div>
                    </div>
                    <div class="input-group flex-nowrap mb-3">
                      <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                          i18n="adm|Weight and balance - weight@@adm.wb.tab.cabinAndEquipment.groupCabinArea.name">
                          Name
                        </span>
                      </div>
                      <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="configuration.name" />
                    </div>
                    <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.section">
                            Section</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.rowFrom">
                            Row from</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.rowTo">Row
                            to</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.maxSeats">
                            Max seats</td>
                          <td></td>
                          <td
                            i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.table.indexPerWeightUnit">
                            Index per weight unit</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, configuration.sections)">
                        <tr *ngFor="let section of configuration.sections; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td contenteditable="true" [innerHTML]="section.name"
                            (blur)="section.name = clearTags($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="section.rowFrom" (keydown)="inputNotLetter($event)"
                            (blur)="section.rowFrom = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="section.rowTo" (keydown)="inputNotLetter($event)"
                            (blur)="section.rowTo = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="section.maxPassengers"
                            (keydown)="inputNotLetter($event)"
                            (blur)="section.maxPassengers = toNumber($event.target.innerHTML)"></td>
                          <td (click)="section.index = -section.index" class="font-weight-bold h5"
                            [ngClass]="{'text-red': section.index < 0, 'text-green': section.index > 0}">
                            {{ section.index < 0 ? '-' : '+' }} </td>
                          <td contenteditable="true" [innerHTML]="section.index < 0 ? -section.index : section.index"
                            (keydown)="inputNotLetter($event)"
                            (blur)="section.index = toNumber(section.index >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                          </td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(configuration.sections, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right">
                      <button class="btn btn-blue"
                        (click)="addItemByClass(configuration.sections, configurationSection)"
                        i18n="adm|Weight and balance@@adm.wb.tab.cabinAndEquipment.groupCabinArea.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="card bg-gray" (click)="addItem(ahmData.configurations, {name: '', sections: []})">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="h1">+</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="crew-composition" role="tabpanel" aria-labelledby="crew-composition-tab">
            <div class="row">
              <div class="col-md-6">
                <div class="card mb-3">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.cockpitSeats">Cockpit seats
                    </p>
                    <app-composition-seats-table [seats]="ahmData.seats.crew.cockpit" (deleteItem)="deleteElement(ahmData.seats.crew.cockpit, $event)"></app-composition-seats-table>
                    <div class="text-right">
                      <button class="btn btn-blue" (click)="addItemByClass(ahmData.seats.crew.cockpit, location)"
                        i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card mb-3">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.cabinSeats">Cabin seats
                    </p>
                    <app-composition-seats-table [seats]="ahmData.seats.crew.cabin" (deleteItem)="deleteElement(ahmData.seats.crew.cabin, $event)"></app-composition-seats-table>
                    <div class="text-right">
                      <button class="btn btn-blue" (click)="addItemByClass(ahmData.seats.crew.cabin, location)"
                        i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.button.add">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="card">
                  <div class="card-body">
                    <p class="font-weight-bold text-blue h5 mb-3"
                      i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition">Crew composition
                    </p>
                    <app-crew-table [crew]="ahmData.dowChanges.crew"
                                    [seats]="ahmData.seats.crew"
                                    (deleteItemEvent)="deleteElement(ahmData.dowChanges.crew, $event)">
                    </app-crew-table>
                    <!-- <table class="w-100 contenteditable text-center mb-3">
                      <thead>
                        <tr class="font-weight-bold">
                          <td width="35px"></td>
                          <td
                            i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.cockpitCrew">
                            Cockpit сrew</td>
                          <td
                            i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.cabinCrew">
                            Cabin сrew</td>
                          <td
                            i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.extraCrew">
                            Extra сrew</td>
                          <td
                            i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.standartConfiguration">
                            Standard configuration</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.dow">
                            DOW</td>
                          <td i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.table.doi">
                            DOI</td>
                          <td width="35px"></td>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event, ahmData.dowChanges.crew)">
                        <tr *ngFor="let crew of ahmData.dowChanges.crew; let i = index" cdkDrag>
                          <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                          <td contenteditable="true" [innerHTML]="crew.cockpit" (keydown)="inputNotLetter($event)"
                            (blur)="crew.cockpit = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="crew.cabin" (keydown)="inputNotLetter($event)"
                            (blur)="crew.cabin = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="crew.extra" (keydown)="inputNotLetter($event)"
                            (blur)="crew.extra = toNumber($event.target.innerHTML)"></td>
                          <td class="text-center">
                            <input type="checkbox" name="crewStandard" id="crewStandard" class="d-hover m-auto"
                              [(ngModel)]="crew.standard" (click)="setStandard(ahmData.dowChanges.crew, 'standard', i)"
                              [checked]="crew.standard">
                          </td>
                          <td contenteditable="true" [innerHTML]="crew.dow" (keydown)="inputNotLetter($event)"
                            (blur)="crew.dow = toNumber($event.target.innerHTML)"></td>
                          <td contenteditable="true" [innerHTML]="crew.doi" (keydown)="inputNotLetter($event)"
                            (blur)="crew.doi = toNumber($event.target.innerHTML)"></td>
                          <td>
                            <i class="material-icons m-0 text-red font-weight-bold pointer"
                              (click)="deleteElement(ahmData.dowChanges.crew, i)">clear</i>
                          </td>
                        </tr>
                      </tbody>
                    </table> -->
                    <!-- <div class="text-right">
                      <button class="btn btn-blue" (click)="addItemByClass(ahmData.dowChanges.crew, dowChangesCrew)"
                        i18n="adm|Weight and balance@@adm.wb.tab.crewComposition.groupCrewComposition.button.add">
                        Add
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="stabilizer-trim" role="tabpanel" aria-labelledby="stabilizer-trim-tab">
            <div class="card mb-3" *ngFor="let stabilizer of ahmData.stabilizerTrim; let indexStab = index">
              <div class="card-body">
                <div class="input-group flex-nowrap mb-3">
                  <div class="input-group-prepend custom-sm">
                    <span class="input-group-text font-small py-0"
                      i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.note">
                      Note
                    </span>
                  </div>
                  <input type="text" class="w-100 form-control custom-sm" [(ngModel)]="stabilizer.note" />
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-6"
                    *ngFor="let stabilizerTrim of stabilizer.values; let indexStabTo = index">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                          <p class="font-weight-bold text-blue h5 mb-3"
                            i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.settings">Settings</p>
                          <div>
                            <button type="button" class="btn btn-xs mr-2 btn-ico btn-blue" ngbTooltip="Duplicate"
                              (click)="copyItem(stabilizer.values, stabilizer.values[indexStabTo])"
                              i18n-ngbTooltip="adm|Weight and balance buttons - duplicate@@adm.wb.main.button.duplicate">
                              <i class="material-icons color-white">content_copy</i>
                            </button>
                            <button type="button" class="btn btn-xs mr-2 btn-ico btn-red" ngbTooltip="Delete"
                              (click)="deleteElement(stabilizer.values, indexStabTo)"
                              i18n-ngbTooltip="adm|Weight and balance buttons - delete@@adm.wb.main.button.delete">
                              <i class="material-icons color-white">delete</i>
                            </button>
                          </div>
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                          <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0"
                              i18n="adm|Weight and balance - weight@@adm.wb.tab.stabilizerTrim.weight">
                              Weight
                            </span>
                          </div>
                          <input type="number" class="w-100 form-control custom-sm"
                            [(ngModel)]="stabilizerTrim.weight" />
                        </div>
                        <table class="w-100 contenteditable text-center mb-3">
                          <thead>
                            <tr class="font-weight-bold">
                              <td width="35px"></td>
                              <td i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.table.mac">%MAC</td>
                              <td></td>
                              <td i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.table.stab">STAB</td>
                              <td width="35px"></td>
                            </tr>
                          </thead>
                          <tbody cdkDropList (cdkDropListDropped)="drop($event, stabilizerTrim.trims)">
                            <tr *ngFor="let trim of stabilizerTrim.trims; let i = index" cdkDrag>
                              <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                              <td [innerHTML]="trim.mac" (keydown)="inputNotLetter($event)"
                                (blur)="trim.mac = toNumber($event.target.innerHTML)" contenteditable="true"></td>
                              <td (click)="trim.trim = -trim.trim" class="font-weight-bold h5"
                                [ngClass]="{'text-red': trim.trim < 0, 'text-green': trim.trim > 0}">
                                {{ trim.trim < 0 ? '-' : '+' }} </td>
                              <td contenteditable="true" [innerHTML]="trim.trim < 0 ? -trim.trim : trim.trim"
                                (keydown)="inputNotLetter($event)"
                                (blur)="trim.trim = toNumber(trim.trim >= 0 ? $event.target.innerHTML : '-' + $event.target.innerHTML)">
                              </td>
                              <td>
                                <i class="material-icons m-0 text-red font-weight-bold pointer"
                                  (click)="deleteElement(stabilizerTrim.trims, i)">clear</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="text-right">
                          <button class="btn btn-blue" (click)="addItemByClass(stabilizerTrim.trims, stabToTrims)"
                            i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.button.add">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-6">
                    <div class="card bg-gray" (click)="addItemByClass(stabilizer.values, stabToValues)">
                      <div class="card-body d-flex align-items-center justify-content-center">
                        <div class="h1">+</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 text-right">
              <button class="btn btn-blue" (click)="addItemByClass(ahmData.stabilizerTrim, stabilizerTrim)"
                i18n="adm|Weight and balance@@adm.wb.tab.stabilizerTrim.button.add">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card weight-balance-content" *ngIf="view.activeWindow === 'ahm-card'">
      <div class="card-body">
        <p class="font-weight-bold text-blue h5 mb-3">AHM</p>
      </div>
    </div>
    <!-- Modal -->


    <ng-template #modalAhm let-modal>
      <form #modalAhmForm="ngForm">
        <!--<div class="loading" *ngIf="loading">
        <div class="cssload-spin-box"></div>
      </div>-->
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            <span *ngIf="view.userAction === 'add-ahm'" i18n="adm|Weight and balance@@adm.wb.modal.editAhm.addAhm">Add
              AHM</span>
            <span *ngIf="view.userAction === 'edit-ahm'"
              i18n="adm|Weight and balance@@adm.wb.modal.editAhm.editAhm">Edit
              AHM</span>
            <span *ngIf="view.userAction === 'copy-ahm'"
              i18n="adm|Weight and balance@@adm.wb.modal.editAhm.copyAhm">Copy
              AHM</span>
          </h5>
          <button type="button" class="close mp-0" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body bg-light-gray">
          <div class="row mb-2">
            <div class="col-8 pr-0">
              <div class="card bg-none">
                <div class="card-body p-3 bg-white rounded">
                  <p class="m-0 h6 card__header--important">
                    <i class="material-icons">apartment</i>
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline">Airline</span>
                  </p>
                  <div class="row">
                    <div class="col-4 pr-0">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.iata">IATA</span><span
                          class="required-asterisk">*</span>
                      </label>
                      <ng-select class="form-control custom-sm p-0" [items]="references.airlines" bindLabel="iata"
                        bindValue="id" [(ngModel)]="ahm.airlineId" #airlineIata="ngModel" name="airlineIata"
                        [virtualScroll]="true" [loading]="selectLoadAnimation.airlines" required>
                      </ng-select>
                      <div *ngIf="!airlineIata?.valid && (airlineIata?.dirty || airlineIata?.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="airlineIata.errors.required"
                          i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.req">
                          IATA is required
                        </div>
                      </div>
                    </div>
                    <div class="col-4 pr-0">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.icao">ICAO</span><span
                          class="required-asterisk">*</span>
                      </label>
                      <ng-select class="form-control custom-sm p-0" [items]="references.airlines" bindLabel="icao"
                        bindValue="id" [(ngModel)]="ahm.airlineId" name="airlineIcao" #airlineIcao="ngModel"
                        [virtualScroll]="true" [loading]="selectLoadAnimation.airlines" required>
                      </ng-select>
                      <div *ngIf="!airlineIcao?.valid && (airlineIcao?.dirty || airlineIcao?.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="airlineIcao.errors.required"
                          i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.icao.req">
                          ICAO is required
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.name">Name</span><span
                          class="required-asterisk">*</span>
                      </label>
                      <ng-select class="form-control custom-sm p-0" [items]="references.airlines" bindLabel="name"
                        bindValue="id" [(ngModel)]="ahm.airlineId" name="airlineName" #airlineName="ngModel"
                        [virtualScroll]="true" [loading]="selectLoadAnimation.airlines" required>
                      </ng-select>
                      <div *ngIf="!airlineName?.valid && (airlineName?.dirty || airlineName?.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="airlineName.errors.required"
                          i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAirline.name.req">
                          Name is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="card bg-none">
                <div class="card-body p-3 bg-white rounded">
                  <p class="m-0 h6 card__header--important">
                    <i class="material-icons">airplanemode_active</i>
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAircraft">Aircraft</span>
                  </p>
                  <label class="col-form-label col-form-label-sm">
                    <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAircraft.type">Type</span><span
                      class="required-asterisk">*</span>
                  </label>
                  <ng-select class="form-control custom-sm p-0" [items]="references.aircraft_types" bindLabel="iata"
                    bindValue="id" [(ngModel)]="ahm.aircraftTypeId" #aircraftTypeId="ngModel" name="aircraftTypeId"
                    [virtualScroll]="true" [loading]="selectLoadAnimation.aircraft_types" required>
                  </ng-select>
                  <div *ngIf="!aircraftTypeId?.valid && (aircraftTypeId?.dirty || aircraftTypeId?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="aircraftTypeId.errors.required"
                      i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAircraft.type.req">
                      Type is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <ul class="nav nav-tabs" id="ahmCardTabs" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="ahmCard-ahm-tab" data-toggle="tab" href="#ahmCard-ahm" role="tab"
                    aria-controls="ahmCard-ahm" aria-selected="true">
                    AHM
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="ahmCard-uld-types-tab" data-toggle="tab" href="#ahmCard-uld-types" role="tab"
                    aria-controls="ahmCard-uld-types" aria-selected="false" (click)="loadWorkspaces()"
                    i18n="adm|Weight and balance@@adm.wb.modal.editAhm.tabs.uldTypes">
                    ULD types
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="ahmCard-cargo-tab" data-toggle="tab" href="#ahmCard-cargo" role="tab"
                    aria-controls="ahmCard-cargo" aria-selected="false"
                    i18n="adm|Weight and balance@@adm.wb.modal.editAhm.tabs.cargoCompatibility">
                    Cargo compatibility
                  </a>
                </li>
              </ul>
              <div class="tab-content p-3 bg-white border border-top-0" id="myTabContent">
                <div class="tab-pane fade show active" id="ahmCard-ahm" role="tabpanel"
                  aria-labelledby="ahmCard-ahm-tab">
                  <!-- <p class="m-0 h6 card__header--important">
                        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm">AHM</span>
                      </p> -->
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.revision">Revision</span><span
                          class="required-asterisk">*</span>
                      </label>
                      <input type="number" class="form-control" name="ahmRevision" [(ngModel)]="ahm.revision"
                        #ahmRevision="ngModel" required>
                      <div *ngIf="!ahmRevision?.valid && (ahmRevision?.dirty || ahmRevision?.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="ahmRevision.errors.required"
                          i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.revision.req">
                          AHM revision is required
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.complatedBy">Completed
                          by</span>
                      </label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="col-4">
                      <label class="col-form-label col-form-label-sm">
                        <span
                          i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.reasonChangesOverview">Reason/Changes
                          overview</span>
                      </label>
                      <input type="text" class="form-control" name="ahmRemark" [(ngModel)]="ahm.remark">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.dateRangeStart">Release date /
                          Date range start</span>
                      </label>
                      <input type="date" name="dtRangeStart" #dtRangeStart="ngModel"
                        [ngModel]="ahm.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                        (blur)="ahm.dtRangeStart = parseDate($event.target.value)" class="form-control my-1" required>
                      <div *ngIf="!dtRangeStart?.valid && (dtRangeStart?.dirty || dtRangeStart?.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="dtRangeStart.errors.required"
                          i18n="ref|Modal season - error date range finish is req@@ref.main.modal.season.err.dtRangeFinishReq">
                          Date range start is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <label class="col-form-label col-form-label-sm">
                        <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.groupAhm.dateRangeFinish">Date range
                          finish</span>
                      </label>
                      <input type="date" name="dtRangeFinish" #dtRangeFinish="ngModel"
                        [ngModel]="ahm.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                        (blur)="ahm.dtRangeFinish = parseDate($event.target.value)" class="form-control my-1" required>
                      <div *ngIf="!dtRangeFinish?.valid && (dtRangeFinish?.dirty || dtRangeFinish?.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="dtRangeFinish.errors.required"
                          i18n="ref|Modal season - error date range finish is req@@ref.main.modal.season.err.dtRangeFinishReq">
                          Date range finish is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="ahmCard-uld-types" role="tabpanel"
                  aria-labelledby="ahmCard-uld-types-tab">
                  <!-- <p class="font-weight-bold text-blue h5 mb-3"
                        i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.uldTypes">ULD types</p> -->
                  <table class="w-100 contenteditable text-center mb-3">
                    <thead>
                      <tr class="font-weight-bold">
                        <td width="35px"></td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.table.code">
                          ULD code
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.table.basicSize">
                          Basic size
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.table.type">
                          Type
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.table.beginNumber">
                          Begin serial number
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.table.endNumber">
                          End serial number
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.table.tareWeight">
                          Tare weight
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.table.maxWeight">
                          Max weight
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.table.maxVolume">
                          Max volume
                        </td>
                        <td width="35px"></td>
                      </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="drop($event, ahm.uldTypes)">
                      <tr *ngFor="let element of ahm.uldTypes; let i = index" cdkDrag>
                        <td>
                          <i class="material-icons pointer"
                             cdkDragHandle>drag_handle</i>
                        </td>
                        <td contenteditable="true"
                            [textContent]="element.name"
                            (blur)="element.name = $event.target.textContent.toUpperCase()"></td>
                        <td>
                          <ng-select class="form-control custom-sm p-0"
                                     [items]="references.uld_base_sizes"
                                     bindLabel="nameLocal"
                                     name="uld_base_sizes{{i}}"
                                     bindValue="id"
                                     [(ngModel)]="element.baseSizeId"
                                     [loading]="element.uld_base_sizes"
                                     [virtualScroll]="true">
                            <ng-template ng-option-tmp
                                         let-item="item">
                              <span class="font-weight-bold">{{item.nameLocal}}</span>&nbsp;
                              <small>{{item.width}} /{{item.length}} (w/l)</small>
                            </ng-template>
                          </ng-select>
                        </td>
                        <td>
                          <ng-select class="form-control custom-sm p-0"
                                     [items]="['pallet', 'container']"
                                     name="uld_category_{{i}}"
                                     [(ngModel)]="element.category"></ng-select>
                        </td>
                        <td contenteditable="true"
                            [innerHTML]="element.serials.start"
                            (keydown)="inputNotLetter($event)"
                            (blur)="element.serials.start = toNumber($event.target.innerHTML)"></td>
                        <td contenteditable="true"
                            [innerHTML]="element.serials.finish"
                            (keydown)="inputNotLetter($event)"
                            (blur)="element.serials.finish = toNumber($event.target.innerHTML)"></td>
                        <td contenteditable="true"
                            [innerHTML]="element.weight"
                            (keydown)="inputNotLetter($event)"
                            (blur)="element.weight = toNumber($event.target.innerHTML)"></td>
                        <td contenteditable="true"
                            [innerHTML]="element.maxWeight"
                            (keydown)="inputNotLetter($event)"
                            (blur)="element.maxWeight = toNumber($event.target.innerHTML)"></td>
                        <td contenteditable="true"
                            [innerHTML]="element.maxVolume"
                            (keydown)="inputNotLetter($event)"
                            (blur)="element.maxVolume = toNumber($event.target.innerHTML)"></td>
                        <td>
                          <i class="material-icons m-0 text-red font-weight-bold pointer"
                             (click)="deleteElement(ahm.uldTypes, i)">clear</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-right">
                    <button class="btn btn-sm btn-blue" (click)="addUldTypes()"
                      i18n="adm|Weight and balance@@adm.wb.modal.editAhm.uldTypes.button.add">
                      Add
                    </button>
                  </div>
                </div>
                <div class="tab-pane fade" id="ahmCard-cargo" role="tabpanel" aria-labelledby="ahmCard-cargo-tab">
                  <p class="font-weight-bold text-blue h5 mb-3"
                    i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.restrictionTypes">Restriction types</p>
                  <table class="w-100 contenteditable mb-3">
                    <thead class="text-center">
                      <tr class="font-weight-bold">
                        <td width="35px"></td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.restrictionTypes.table.code">
                          Code
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.restrictionTypes.table.description">
                          Description
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.restrictionTypes.table.distance">
                          Distance, cm
                        </td>
                        <td width="35px"></td>
                      </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="drop($event, ahm.cargoSymbols)">
                      <tr *ngFor="let element of ahm.cargoSymbols; let i = index" cdkDrag>
                        <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                        <td class="text-center" contenteditable="true" [innerHTML]="element.code"
                          (blur)="element.code = $event.target.innerHTML"></td>
                        <td contenteditable="true" [innerHTML]="element.description"
                          (blur)="element.description = $event.target.innerHTML"></td>
                        <td class="text-center" contenteditable="true" [innerHTML]="element.distance"
                          (keydown)="inputNotLetter($event)"
                          (blur)="element.distance = toNumber($event.target.innerHTML)"></td>
                        <td>
                          <i class="material-icons m-0 text-red font-weight-bold pointer"
                            (click)="deleteElement(ahm.cargoSymbols, i)">clear</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-right">
                    <button class="btn btn-sm btn-blue" (click)="addItemByClass(ahm.cargoSymbols, cargoSymbols)"
                      i18n="adm|Weight and balance@@adm.wb.modal.editAhm.cargoSymbols.button.add">
                      Add
                    </button>
                  </div>
                  <p class="font-weight-bold text-blue h5 mb-3 mt-3"
                    i18n="adm|Weight and balance@@adm.wb.tab.holdsAndCompartments.cargoRestrictions">Cargo restrictions
                  </p>
                  <table class="w-100 contenteditable mb-3">
                    <thead class="text-center">
                      <tr class="font-weight-bold">
                        <td width="35px"></td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.cargoRestrictions.table.type1">
                          Type 1
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.cargoRestrictions.table.type2">
                          Type 2
                        </td>
                        <td i18n="adm|Weight and balance@@adm.wb.modal.editAhm.cargoRestrictions.table.restrictionCode">
                          Restriction code
                        </td>
                        <td width="35px"></td>
                      </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="drop($event, ahm.cargoRestrictions)">
                      <tr *ngFor="let element of ahm.cargoRestrictions; let i = index" cdkDrag>
                        <td><i class="material-icons pointer" cdkDragHandle>drag_handle</i></td>
                        <td class="text-center">
                          <ng-select class="form-control custom-sm p-0"
                                    [items]="references.imp_codes"
                                    bindLabel="iata"
                                    bindValue="iata"
                                    name="impCode1{{i}}"
                                    [(ngModel)]="element.impCode1">
                          </ng-select>
                        </td>
                        <td>
                          <ng-select class="form-control custom-sm p-0"
                                    [items]="references.imp_codes"
                                    bindLabel="iata"
                                    bindValue="iata"
                                    name="impCode2{{i}}"
                                    [(ngModel)]="element.impCode2">
                          </ng-select>
                        </td>
                        <td>
                          <ng-select class="form-control custom-sm p-0"
                                    [items]="ahm.cargoSymbols"
                                    bindLabel="code"
                                    bindValue="code"
                                    name="symbol{{i}}"
                                    [(ngModel)]="element.symbol">
                          </ng-select>
                        </td>
                        <td>
                          <i class="material-icons m-0 text-red font-weight-bold pointer"
                            (click)="deleteElement(ahm.cargoRestrictions, i)">clear</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-right">
                    <button class="btn btn-sm btn-blue" (click)="addItemByClass(ahm.cargoRestrictions, cargoRestrictions)"
                      i18n="adm|Weight and balance@@adm.wb.modal.editAhm.cargoSymbols.button.add">
                      Add
                    </button>
                  </div>
                  <!-- <table class="w-100 contenteditable text-center mb-3">
                    <thead>
                      <tr class="font-weight-bold">
                        <th>IMP</th>
                        <th *ngFor="let name of references.imp_codes; let i = index">
                          {{ name.iata }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of references.imp_codes; let i = index">
                        <td>{{ row.iata }}</td>
                        <td *ngFor="let col of references.imp_codes; let j = index" contenteditable="true"
                          [innerHTML]="getCargoCode(col.iata, row.iata)?.symbol"
                          (blur)="setCargoCode(col.iata, row.iata, $event.target.innerHTML)"></td>
                      </tr>
                    </tbody>
                  </table> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-light-gray">
          <button *ngIf="view.userAction === 'add-ahm'" type="button" class="btn btn-sm btn-blue"
            (click)="addAhm(modal.dismiss)">
            <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.append">Append</span>
          </button>
          <button *ngIf="view.userAction === 'copy-ahm'" type="button" class="btn btn-sm btn-blue"
            (click)="saveCopedAhm(modal.dismiss)">
            <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.copy">Save a copy</span>
          </button>
          <button *ngIf="view.userAction === 'edit-ahm'" type="button" class="btn btn-sm btn-blue"
            (click)="saveAhm(modal.dismiss)">
            <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.save">Save</span>
          </button>
          <button type="button" class="btn btn-sm btn-gray-blue-dark ml-2" data-dismiss="modal"
            (click)="modal.dismiss()">
            <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.close">Close</span>
          </button>
        </div>
      </form>
    </ng-template>

    <div *ngIf="errorMessage" class="ms-modal-message">
      <div class="modal-content w-25">
        <div class="modal-header" [ngClass]="{'bg-info': errorType === 'info',
                                    'bg-warning': errorType === 'warning',
                                    'bg-danger': errorType === 'error'}">
          <h5 *ngIf="errorType === 'info'" class="modal-title"
            i18n="adm|Weight and balance modal message error - header information@@adm.wb.main.modalMessage.errMes.header.inf">
            Information
          </h5>
          <h5 *ngIf="errorType === 'warning'" class="modal-title"
            i18n="adm|Weight and balance modal message error - header watning@@adm.wb.main.modalMessage.errMes.header.warn">
            Warning
          </h5>
          <h5 *ngIf="errorType === 'error'" class="modal-title"
            i18n="adm|Weight and balance modal message error - header error@@adm.wb.main.modalMessage.errMes.header.err">
            Error
          </h5>
          <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{errorMessage}}
        </div>
        <div class="modal-footer">
          <button type="button" *ngIf="view.modalType==='setAnswer'" class="btn btn-sm btn-blue"
            (click)="userAnswer = true"
            i18n="adm|Weight and balance modal message error - button yes@@adm.wb.main.modalMessage.errMes.button.yes">
            Yes
          </button>
          <button type="button" *ngIf="view.modalType==='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
            (click)="userAnswer = false"
            i18n="adm|Weight and balance modal message error - button no@@adm.wb.main.modalMessage.errMes.button.no">
            No
          </button>
          <button type="button" *ngIf="view.modalType!=='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
            (click)="errorMessage = null"
            i18n="adm|Weight and balance modal create message error - button close@@adm.wb.modalCreateMessage.buttons.close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="desktops" role="tabpanel" aria-labelledby="desktops-tab">
    <div class="row my-2">
      <div class="col-6">
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal" data-target=".modal-workspace"
          (click)="createWorkspace()">
          <i class="material-icons">add</i>
          <span i18n="adm|Communication module tab filters - button new@@adm.comMod.tab.filters.button.new">New</span>
        </button>
      </div>
    </div>
    <table class="table table-hover w-100">
      <thead class="text-center text-uppercase">
        <tr>
          <th i18n="adm|Weight and balance - button ahm list@@adm.wb.table.column.name">
            Name
          </th>
          <th i18n="adm|Weight and balance - button ahm list@@adm.wb.table.column.lastUpdate">
            Last update
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let workspace of workspaces" data-toggle="modal" data-target=".modal-workspace"
          (click)="loadWorkspace(workspace.id)">
          <td>{{ workspace.name }}</td>
          <td>{{ workspace.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
        </tr>
      </tbody>
    </table>
    <div class="modal fade bd-example-modal-xl modal-workspace" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <form #modalWorkspaceForm="ngForm">
            <!--<div class="loading" *ngIf="loading">
              <div class="cssload-spin-box"></div>
            </div>-->
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalScrollableTitle">
                <span *ngIf="view.userAction === 'add-workspace'"
                  i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.addWorkspace">Add
                  workspace</span>
                <span *ngIf="view.userAction === 'edit-workspace'"
                  i18n="adm|Weight and balance@@adm.wb.modal.editorkspace.editWorkspace">Edit
                  workspace</span>
                <span *ngIf="view.userAction === 'copy-workspace'"
                  i18n="adm|Weight and balance@@adm.wb.modal.editorkspace.copyWorkspace">Copy
                  workspace</span>
              </h5>
              <button #closeWorkspace type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body bg-light-gray">
              <div class="row">
                <div class="col-4">
                  <label i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.groupWorkspace.name">
                    Workspace name
                  </label>
                  <input type="text" id="workspaceName" class="form-control custom-sm" name="workspaceName"
                    #workspaceName="ngModel" [(ngModel)]="workspace.name" value="{{ workspace.name }}" required>
                  <div *ngIf="!workspaceName?.valid && (workspaceName?.dirty || workspaceName?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="workspaceName.errors.required"
                      i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.groupWorkspace.modal.workspace.err.name">
                      Name is required
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.groupWorkspace.field.dtStart">
                    Date range start
                  </label>
                  <input type="date" class="form-control" name="workspaceStart"
                    [ngModel]="workspace.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                    (blur)="workspace.dtRangeStart = parseDate($event.target.value)" #workspaceStart="ngModel">
                  <div *ngIf="!workspaceStart?.valid && (workspaceStart?.dirty || workspaceStart?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="workspaceStart.errors.required"
                      i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.groupWorkspace.modal.workspace.err.dtRangeStart">
                      Date range start is required
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.groupWorkspace.field.dtFinish">
                    Date range finish
                  </label>
                  <input type="date" class="form-control" name="workspaceFinish"
                    [ngModel]="workspace.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                    (blur)="workspace.dtRangeFinish = parseDate($event.target.value)" #workspaceFinish="ngModel">
                  <div *ngIf="!workspaceFinish?.valid && (workspaceFinish?.dirty || workspaceFinish?.touched)"
                    class="invalid-feedback d-block">
                    <div *ngIf="workspaceFinish.errors.required"
                      i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.groupWorkspace.modal.workspace.err.dtRangeFinish">
                      Date range finish is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer bg-light-gray">
              <button *ngIf="view.userAction === 'add-workspace'" type="button" class="btn btn-sm btn-blue"
                (click)="addWorkspace()">
                <span i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.button.append">Append</span>
              </button>
              <button *ngIf="view.userAction === 'edit-workspace'" type="button" class="btn btn-sm btn-blue"
                (click)="saveWorkspace()">
                <span i18n="adm|Weight and balance@@adm.wb.modal.editWorkspace.button.save">Save</span>
              </button>
              <button type="button" class="btn btn-sm btn-gray-blue-dark ml-2" data-dismiss="modal">
                <span i18n="adm|Weight and balance@@adm.wb.modal.editAhm.button.close">Close</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="filters" role="tabpanel" aria-labelledby="filters-tab">
    <app-filters [airlines]="references.airlines"
                 [airports]="references.airports"
                 [aircraftTypes]="references.aircraft_types"
                 [tails]="references.tails"
                 [workspaces]="workspaces"></app-filters>
  </div>
  <div class="tab-pane fade" id="network" role="tabpanel" aria-labelledby="network-tab">
    <route-network [airlines]="references.airlines" [airports]="references.airports"></route-network>
  </div>
  <div class="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="groups-tab">
    <div class="row my-2">
      <div class="col-6">
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal" data-target=".modal-group"
          (click)="createGroup()">
          <i class="material-icons">add</i>
          <span i18n="adm|Weight and balance - button ahm list@@adm.wb.button.new">New</span>
        </button>
      </div>
    </div>
    <table class="table table-hover w-100">
      <thead class="text-center text-uppercase">
        <tr>
          <th i18n="adm|Weight and balance - button ahm list@@adm.wb.table.column.name">
            Name
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let groupItem of groups" data-toggle="modal" data-target=".modal-group" (click)="loadGroup(groupItem.id)">
          <td>{{ groupItem.name }}</td>
        </tr>
      </tbody>
    </table>
    <div class="modal fade bd-example-modal-xl modal-group" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <form #groupForm="ngForm">
            <div class="loading" *ngIf="loading">
              <div class="cssload-spin-box"></div>
            </div>
            <div class="modal-header">
              <h5 *ngIf="view.userAction==='action-append'" class="modal-title"
                i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.header.add">
                Append group
              </h5>
              <h5 *ngIf="view.userAction==='action-update'" class="modal-title"
                i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.header.edit">
                Edit group
              </h5>
              <button #closeGroup type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row mb-3">
                <div class="col-12">
                  <label i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.field.name">
                    Name
                  </label>
                  <div class="input-group">
                    <input id="groupName" type="text" class="form-control form-control-sm" name="groupName"
                      #groupName="ngModel" [(ngModel)]="group.name" required>
                    <div *ngIf="!groupName?.valid && (groupName?.dirty || groupName?.touched)"
                      class="invalid-feedback d-block">
                      <div *ngIf="groupName.errors.required"
                        i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.err.nameReq">
                        Name is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <p class="font-weight-bold text-blue"
                    i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.settings">
                    Settings
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <label i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.field.workspace">
                        Workspace
                      </label>
                    </div>
                    <div class="col-6">
                      <label i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.field.Mode">
                        Mode
                      </label>
                    </div>
                  </div>
                  <div class="row mb-2" *ngFor="let workspace of group.workspaces; let i = index">
                    <div class="col-6">
                      <ng-select class="form-control custom-sm p-0"
                                [items]="workspaces" bindLabel="name" bindValue="id"
                                [(ngModel)]="workspace.id" #workspaceId="ngModel" appendTo="body"
                                name="workspaceId_{{workspace.id}}" [virtualScroll]="true" required>
                      </ng-select>
                      <div *ngIf="!workspaceId?.valid && (workspaceId?.dirty || workspaceId?.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="workspaceId.errors.required"
                          i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.err.folderReq">
                          Workspace is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <ng-select class="form-control custom-sm p-0" [items]="modes" [(ngModel)]="workspace.mode"
                          #workspaceMode="ngModel" appendTo="body" name="folderMode_{{workspace.id}}"
                          [virtualScroll]="true" required>
                          <ng-option></ng-option>
                        </ng-select>
                        <button type="button" class="btn btn-outline-danger btn-sm ml-2"
                          (click)="deleteElement(groups, group.id)">
                          <i class="material-icons m-0">clear</i>&nbsp;
                        </button>
                      </div>
                      <div *ngIf="!workspaceMode?.valid && (workspaceMode?.dirty || workspaceMode?.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="workspaceMode.errors.required"
                          i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.err.folderModeReq">
                          Mode is required
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <button type="button" class="btn btn-sm btn-blue float-right"
                              (click)="addItemByClass(group.workspaces, userWorkspace)">
                        <i class="material-icons m-0">add</i>
                        <span i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.button.addWorkspace">
                          Add workspace
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" *ngIf="view.userAction==='action-append'" class="btn btn-sm btn-blue"
                (click)="addGroup()" i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.button.append">
                Append
              </button>
              <button type="button" *ngIf="view.userAction==='action-update'" class="btn btn-sm btn-blue"
                (click)="updateGroup(group.id)"
                i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.button.save">
                Save
              </button>
              <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal"
                i18n="adm|Weight and balance - button ahm list@@adm.wb.modal.groups.button.close">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
    <div class="row my-2">
      <div class="col-6">
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal" data-target=".modal-user"
          (click)="createUser()">
          <i class="material-icons">add</i>
          <span i18n="adm|Weight and balance - button ahm list@@adm.wb.button.new">New</span>
        </button>
      </div>
    </div>
    <table class="table table-hover w-100">
      <thead class="">
        <tr #trUsers>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance tab users - table data column enabled@@adm.wb.tab.users.table.column.enabled">
            Enabled
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance tab users - table data column username@@adm.wb.tab.users.table.column.username">
            Username
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance tab users - table data column firstName@@adm.wb.tab.users.table.column.firstName">
            First name
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance tab users - table data column lastName@@adm.wb.tab.users.table.column.lastName">
            Last name
          </th>
          <th class="text-center text-uppercase" i18n="adm|Weight and balance tab users - table data column groups@@adm.wb.tab.users.table.column.groups">
            Groups
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users" data-toggle="modal" data-target=".modal-user"
          (click)="loadUser(user.id)">
          <td class="text-center">
            <span *ngIf="user.enabled" class="material-icons text-green">check</span>
            <span *ngIf="!user.enabled" class="material-icons text-dark-gray">close</span>
          </td>
          <td>{{ user.username }}</td>
          <td>{{ user.firstName }}</td>
          <td>{{ user.lastName }}</td>
          <td>
            <ng-container *ngFor="let userGroup of user.groups; let i = index">
              {{ userGroup.name }}{{ i + 1 < user.groups.length ? ',' : '' }}
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal fade bd-example-modal-xl modal-user" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <form #userForm="ngForm">
            <div class="loading" *ngIf="loading">
              <div class="cssload-spin-box"></div>
            </div>
            <div class="modal-header">
              <h5 *ngIf="view.userAction==='action-append'"
                  class="modal-title"
                  i18n="adm|Weight and balance - button users@@adm.wb.modal.users.header.add">
                Append group
              </h5>
              <h5 *ngIf="view.userAction==='action-update'"
                  class="modal-title"
                  i18n="adm|Weight and balance - button users@@adm.wb.modal.users.header.edit">
                Edit user
              </h5>
              <button #closeUser type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row mb-3">
                <div class="col-4">
                  <label i18n="adm|Weight and balance - button users@@adm.wb.users.field.name">
                    Username
                  </label>
                  <div class="input-group">
                    <input id="username"
                          type="text"
                          class="form-control form-control-sm"
                          name="username"
                          #username="ngModel"
                          [(ngModel)]="user.username"
                          required>
                    <div *ngIf="!username?.valid && (username?.dirty || username?.touched)"
                          class="invalid-feedback d-block">
                      <div *ngIf="username.errors.required"
                            i18n="adm|Weight and balance users - error username is required@@adm.wb.users.err.usernameReq">
                        Username is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label i18n="adm|Weight and balance users - field first name@@adm.wb.users.field.firstName">
                    First name
                  </label>
                  <div class="input-group">
                    <input id="firstName"
                          type="text"
                          class="form-control form-control-sm"
                          name="firstName"
                          #firstName="ngModel"
                          [(ngModel)]="user.firstName"
                          required>
                    <div *ngIf="!firstName?.valid && (firstName?.dirty || firstName?.touched)"
                          class="invalid-feedback d-block">
                      <div *ngIf="firstName.errors.required"
                            i18n="adm|Weight and balance users - error firstName is required@@adm.wb.users.err.firstNameReq">
                        First name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label i18n="adm|Weight and balance users - field last name@@adm.wb.users.field.lastName">
                    Last name
                  </label>
                  <div class="input-group">
                    <input id="lastName"
                          type="text"
                          class="form-control form-control-sm"
                          name="lastName"
                          #lastName="ngModel"
                          [(ngModel)]="user.lastName"
                          required>
                    <div *ngIf="!lastName?.valid && (lastName?.dirty || lastName?.touched)"
                          class="invalid-feedback d-block">
                      <div *ngIf="lastName.errors.required"
                            i18n="adm|Weight and balance users - error lastName is required@@adm.wb.users.err.lastNameReq">
                        Last name is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-10">
                  <label i18n="adm|Weight and balance users - field groups@@dm.wb.modal.users.field.groups">
                    Groups
                  </label>
                  <ng-select class="form-control custom-sm p-0"
                             [items]="groups"
                             bindLabel="name"
                             bindValue="id"
                             [(ngModel)]="user.groupsArray"
                             #userGroups="ngModel"
                             appendTo="body"
                             [multiple]="true"
                             name="userGroups"
                             [closeOnSelect]="false"
                             [virtualScroll]="true"
                             required>
                    <ng-template ng-option-tmp let-item="item">{{item.name}}</ng-template>
                  </ng-select>
                  <div *ngIf="!userGroups?.valid && (userGroups?.dirty || userGroups?.touched)"
                       class="invalid-feedback d-block">
                    <div *ngIf="userGroups.errors.required"
                          i18n="adm|Weight and balance groups - error groups is required@@dm.wb.modal.groups.err.groupsReq">
                      Groups is required
                    </div>
                  </div>
                </div>
                <div class="col-2 pt-4 mt-2">
                  <div class="form-check">
                    <input class="form-check-input"
                            type="checkbox"
                            id="enabled"
                            name="enabled"
                            [(ngModel)]="user.enabled">
                    <label class="form-check-label" for="enabled"
                          i18n="adm|Weight and balance users - field enabled@@adm.wb.modal.users.field.enabled">
                      Enabled
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button"
                      *ngIf="view.userAction==='action-append'"
                      class="btn btn-sm btn-blue"
                      (click)="addUser()"
                      i18n="adm|Weight and balance users - button append@@adm.wb.modal.users.button.append">
                Append
              </button>
              <button type="button"
                      *ngIf="view.userAction==='action-update'"
                      class="btn btn-sm btn-blue"
                      (click)="updateUser(user.id)"
                      i18n="adm|Weight and balance users - button save@@adm.wb.modal.users.button.save">
                Save
              </button>
              <button type="button"
                      class="btn btn-sm btn-gray-blue-dark"
                      data-dismiss="modal"
                      i18n="adm|Weight and balance users - button close@@adm.wb.modal.users.button.close">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>