import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { HomeEffects } from './state/home.effects';
import { StoreModule } from '@ngrx/store';
import * as fromHome from './state/home.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		HomeRoutingModule,
		EffectsModule.forFeature([HomeEffects]),
		StoreModule.forFeature(fromHome.homeFeatureKey, fromHome.reducer),
	],
})
export class HomeModule {}
