import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.less']
})
export class ModalMessageComponent implements OnInit {

  error = {
    errorMessage: '',
    errorType: '',
    errorBay: '',
    errorDetail: '',
  };

  modalType = '';
  userAnswer = null;
  userAction = null;

  constructor() { }

  ngOnInit(): void {
  }

  async waitAnswer(mess: string, errType: string) {
    this.error.errorMessage = mess;
    this.error.errorType = errType;
    this.modalType = 'setAnswer';
    const res = await this.checkAnswer();
    return res;
  }

  checkAnswer() {
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (this.userAnswer !== null) {
          const answer = this.userAnswer;
          this.clearErrorMess();
          clearInterval(interval);
          resolve(answer);
        }
      }, 500);
   });
  }

  showErrorMess(errorMessage: string, errorType: string, detail?: string) {
    this.error.errorMessage = errorMessage;
    this.error.errorType = errorType;
    this.error.errorDetail = detail;
  }

  clearErrorMess() {
    this.error.errorMessage = '';
    this.error.errorType = '';
    this.modalType = '';
    this.userAnswer = null;
  }

}
