import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export class CustomFormValidators {

  lengthValidator(length: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (value && value.length !== length) {
        return { reqLength: { actualLength: value.length, reqLength: length } };
      }
      return null;
    };
  }

  maxLengthValidator(length: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (value && value.length > length) {
        return { maxLength: { actualLength: value.length, maxLength: length } };
      }
      return null;
    };
  }

  minLengthValidator(length: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (value && value.length < length) {
        return { minLength: { actualLength: value.length, minLength: length } };
      }
      return null;
    };
  }

  onlyEnglish(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const onlyAlphaNumeric = /^[a-zA-Z0-9()\.,-\/\\_*\s]+$/.test(value);
      if (value && !onlyAlphaNumeric) {
        return { onlyEnglish: true };
      }
      return null;
    };
  }

  onlyEnglishAndNumbers(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      const onlyAlphaNumeric = /^[a-zA-Z0-9]+$/.test(value);
      if (value && !onlyAlphaNumeric) {
        return { onlyEnglishAndNumbers: true };
      }
      return null;
    };
  }

  maxValue(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as number;
      if (value && value > max) {
        return { maxValue: { actualLength: value, maxValue: max } };
      }
      return null;
    };
  }

  maxMinValue(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as number;
      if (value && (value < min || value > max)) {
        return { maxMinValue:  { minValue: min, maxValue: max } };
      }
      return null;
    };
  }

  requiredFieldValidator(requiredFields: Array<string>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allEmpty = requiredFields.some((fieldName) => control.get(fieldName).value);
      return allEmpty ? null : {allEmpty: true};
    };
  }
  

}
