<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom">
		<nz-space>
			<p
				*nzSpaceItem
				class="text-black-50">
				Administration
			</p>
		</nz-space>
	</nz-header>
	<nz-content class="h-100 bg-white p-1">
		<router-outlet></router-outlet>
	</nz-content>
	<!-- <nz-footer></nz-footer> -->
</nz-layout>
