import { Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { MasterDataService } from 'src/app/shared/data-provider/master-data-api/api/masterData.service';

@Injectable({
	providedIn: 'root',
})
export class MasterDataFunctionsService {
	constructor(
		private apiMasterData: MasterDataService,
		private fileSaverService: FileSaverService
	) {}

	downloadCsvFile(name, loader: boolean) {
		this.apiMasterData
			.selectRecords(name, 'csv', 'events', true, {
				httpHeaderAccept: 'text/csv',
			})
			.subscribe((data: any) => {
				if (data.status === 200 && data.body) {
					loader = false;
					const blob = new Blob([data.body], { type: 'application' });
					this.fileSaverService.save(blob, name + '.csv');
				} else {
					loader = true;
				}
			});
	}

	downloadXmlFile(name, loader: boolean) {
		this.apiMasterData
			.selectRecords('aircraft_types', 'xml', 'events', true, {
				httpHeaderAccept: 'application/xml',
			})
			.subscribe((data: any) => {
				if (data.status === 200 && data.body) {
					loader = false;
					const blob = new Blob([data.body], { type: 'application' });
					this.fileSaverService.save(blob, name + '.xml');
				} else {
					loader = true;
				}
			});
	}
}
