import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { Office } from '../types/offices';
import { retry, catchError } from 'rxjs/operators';
import { User, UserGroup } from '../types/users';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable({
	providedIn: 'root',
})
export class LostFoundRestApiService {
	private apiLostFoundUrl = null;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiLostFoundUrl = config.apiLostFoundURL;
		});
	}

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	// Offices
	getOffices(): Observable<Office[]> {
		return this.http
			.get<Office[]>(this.apiLostFoundUrl + '/admin/offices')
			.pipe(retry(1), catchError(this.handleError));
	}

	getOffice(id: string): Observable<Office> {
		return this.http
			.get<Office>(this.apiLostFoundUrl + '/admin/offices/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addOffice(data: Office): Observable<Office> {
		return this.http
			.post<Office>(this.apiLostFoundUrl + '/admin/offices', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateOffice(id: string, data: Office): Observable<Office> {
		return this.http
			.put<Office>(this.apiLostFoundUrl + '/admin/offices/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Users
	getUsers(): Observable<User[]> {
		return this.http.get<User[]>(this.apiLostFoundUrl + '/admin/users').pipe(retry(1), catchError(this.handleError));
	}

	getUser(id: string): Observable<User> {
		return this.http
			.get<User>(this.apiLostFoundUrl + '/admin/users/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addUser(data: User): Observable<User> {
		return this.http
			.post<User>(this.apiLostFoundUrl + '/admin/users', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateUser(id: string, data: User): Observable<User> {
		return this.http
			.put<User>(this.apiLostFoundUrl + '/admin/users/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Groups
	getGroups(): Observable<UserGroup[]> {
		return this.http
			.get<UserGroup[]>(this.apiLostFoundUrl + '/admin/groups')
			.pipe(retry(1), catchError(this.handleError));
	}

	getGroup(id: string): Observable<UserGroup> {
		return this.http
			.get<UserGroup>(this.apiLostFoundUrl + '/admin/groups/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addGroup(data: UserGroup): Observable<UserGroup> {
		return this.http
			.post<UserGroup>(this.apiLostFoundUrl + '/admin/groups', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateGroup(id: string, data: User): Observable<UserGroup> {
		return this.http
			.put<UserGroup>(this.apiLostFoundUrl + '/admin/groups/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getAirports(): Observable<any[]> {
		return this.http
			.get<any[]>(this.apiLostFoundUrl + '/master_data/airports')
			.pipe(retry(1), catchError(this.handleError));
	}

	getAirlines(): Observable<any[]> {
		return this.http
			.get<any[]>(this.apiLostFoundUrl + '/master_data/airlines')
			.pipe(retry(1), catchError(this.handleError));
	}

	handleError(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		window.alert(errorMessage);
		return throwError(error.error);
	}
}
