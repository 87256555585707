import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgSelectFunctions } from '@shared/functions/ngSelectFunctions';
import { parseDate, parseDateReactiveForm } from '@shared/functions/dateFunctions';
import { LANGUAGES } from '@shared/const';
import { GetReferencesService, ReferencesService } from '../../services/references.service';
import { References, REFERENCES_LIST } from '../../types/types';
import { LostFoundModuleRestApiService } from '../../services/lost-found-rest-api.service';
import { LostItems } from '../../types/lost-items';
import { Storage } from '../../types/references';
import { ADMIN_DATA } from '../../types/const';
import { FileSaverService } from 'ngx-filesaver';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormsService } from '../../services/forms.service';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleName } from '@settings/global-settings';
import { GlobalI18n } from '@settings/global-i18n';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';
import { DomSanitizer } from '@angular/platform-browser';
import { File } from 'src/app/lost-found-module/types/files';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SettingsService } from '@core/services/settings/settings.service';

@Component({
  selector: 'lost-statement',
  templateUrl: './lost-statement.component.html',
  styleUrls: ['./lost-statement.component.less']
})
export class LostStatementComponent implements OnInit {
  private _success = new Subject<{ text: string, type: string }>();
  messageAlert = {
    text: '',
    type: '',
  };
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

  @ViewChild(ModalMessageComponent) modalError: ModalMessageComponent;

  references: References = new References();
  ngSelectFunctions = new NgSelectFunctions();
  statement: LostItems = new LostItems();
  statementForm: UntypedFormGroup = new UntypedFormGroup({});

  parseDate = parseDate;
  parseDateReactiveForm = parseDateReactiveForm;

  ADMIN_DATA = ADMIN_DATA;
  LANGUAGES = LANGUAGES;

  loading = false;
  storages$: Array<Storage>;
  files: Array<File> = [];
  imageBlobUrl: string | ArrayBuffer | null = null;
  loadingPhotoProcess = null;

  constructor(
    public restApi: LostFoundModuleRestApiService,
    public referencesService: ReferencesService,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private adminReferances: GetReferencesService,
    private fileSaverService: FileSaverService,
    private router: Router,
    private formsService: FormsService,
    private modalService: NgbModal,
    public globalI18n: GlobalI18n,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // Для алерта с сообщениями
    this._success.subscribe((message) => {
      this.messageAlert.text = message.text;
      this.messageAlert.type = message.type;
    });
    this._success.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
    const referencesList = [
      REFERENCES_LIST.airports,
      REFERENCES_LIST.airlines,
      REFERENCES_LIST.baggage_internals,
      REFERENCES_LIST.baggage_internal_categories,
    ];
    this.loading = true;
    // добавить фильтрацию по ап после того, как справочник будет заполнен
    this.storages$ = this.adminReferances.storages;
    Promise.all([
      this.referencesService.loadReferences(referencesList).then(data => {
        this.references = data;
      }),
      this.referencesService.loadStatementTypes(null, [6], this.settingsService.language).then(data => {
        this.references.statement_types = data;
      }),
      this.referencesService.loadMasterData().then(data => {
        if (data.storages && data.storages.length > 0) {
          this.ADMIN_DATA.storages = data.storages;
        }
      })
    ]).finally(() => {
      this.activatedRoute.params.subscribe(async params => {
        if (params.id !== '0') {
          await this.loadLostItem(params.id).then(() => {
            this.loading = false;
            this.loadPhotos();
          });
        } else {
          await this.createLostItem();
          this.loading = false;
        }
      });
    });
  }

  @HostListener('window:keydown', [])
  onWindowKeyDown(evt) {
    // Получаем объект event
    evt = evt || window.event;
    // Определяем нажатие Ctrl+S
    if ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 83) {
      // Блокируем появление диалога о сохранении
      if (evt.preventDefault) evt.preventDefault();
      evt.returnValue = false;
      // Запускаем функцию сохранения
      this.saveLostItem();
      return false;
    }
  }

  loadPhotos() {
    this.restApi.getFilesLostItem(this.statement.id, 'image').then(data => {
      this.files = data;
    });
  }

  loadPhoto(id: string, content) {
    this.restApi.getFileLostItem(this.statement.id, 'image', id).subscribe(data => {
      this.createImageFromBlob(data);
    });
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      windowClass: 'custom-ngb-modal-window',
      backdropClass: 'custom-ngb-modal-backdrop'
    });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageBlobUrl = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  uploadPhoto(file) {
    const uploadData = new FormData();
    if (file.files[0].size > 10485760) {
      const errorMessage = this.globalI18n.getMessage(ModuleName.LostFound, 'maxSize10MB');
      const errorType = 'error';
      this.modalError.showErrorMess(errorMessage, errorType);
      file.value = "";
      return false;
    }
    uploadData.append('file', file.files[0]);
    uploadData.append('name', file.files[0].name);
    this.restApi.uploadFilesLostItem(this.statement.id, 'image', uploadData).subscribe(data => {
      if (data?.total && data?.loaded) {
        this.loadingPhotoProcess = Math.round(data.loaded * 100 / data.total);
      } else if (data.status === 201) {
        this.loadingPhotoProcess = null;
        this.loadPhotos();
      } else {
        this.loadingPhotoProcess = null;
      }
    });
  }

  deletePhoto(id: string, event) {
    event.stopPropagation();
    this.restApi.deleteFileLostItem(this.statement.id, 'image', id).subscribe(data => {
      this.loadPhotos();
    });
  }

  showPhoto(file: File) {
    if (file.preview != '') {
      return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/' + file.extension.substring(1) + ';base64,' + file.preview);
    } else {
      return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAADwCAYAAAA+VemSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATASURBVHgB7d2xctt0HMDxn2kG0oXwBmJjzAO0hxnZyhO0jEx46V3tDilL4y15g4SRKR3ZyJC98ASEjTFM2RL+yqlQelEs2bKsf/P53PnsJqlzl/NX0k+yrAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+jUKljKZTHa2t7fH19fXO8EqzsvbfD4/D1oTcEsvXrwoRqPRUXo4DjqT/qbHV1dXPwq5nQdBYy9fvnya7t6k25dB13ZTxM8eP37819nZ2e9BI9bADc1ms920ufw2WLv0d/46rYlPg4U+CRpJL6qToBfViEIDAm5gOp0+SXdF0Jci7WsYBwsJuIG09h0HfXsSLLQVNHHXoaLTtMl3HrSWFoxF1O/N/yxYSMArSi/Cn/b394+D1tJm8rO08BsHS7MJDRkTMGRMwJAxAUPGBAwZEzBkTMCQMQFDxgQMGRMwZEzAkDEBQ8YEDBkTMGRMwJAxAUPGBAwZEzBkTMCQMQFDxgQMGRMwZEzAkDEBQ8YEzL9cjyg/AubGbDbbG41GJ+UFzINsCJh31z5+lR7ulBEH2RDwPVeucT+49vHudDo9CLIg4HsurXHLWIsPvjypronMwAk4A5PJZCfWoJx7o/46vEfm4eET8MCVl+Dc3t5+23XE5R7nau6tU87DR8GgCXjAyjVgiqhcSxYp4r3oSPW8TeIcm4eHTcADliL7Nf6bTyddHaet4i0a/vjE8eHhEvBAVWu+4v2vdXGctpp7x23+Txm8eXiYBDxA5dyb7ia3fGulubTB3FunMA8Pk4AHpppP75o7y7l0Ess97yoRLvV7WS8BD0i5p7maexftcd57/vz5brTQcu6tc2AeHhYBD0i1p7lo8KM7W1tbJ00PLS0z99YpFwTrOi5NewIeiBTZD3H73Fun0aGlFebeu36veXggBDwA1fuRD6O9Ow/xdDD31nliHh4GAW/Ye3PvUu46tFSdWVTEehy0ncPpnoA37OHDh7edTNDGrYeWqrl3rYG1mcNZDwFvUBlZ2nR+Fqv73yGe8kyijufeOkW1AGJDBLwh1dz7KrqzVz5ntTndW1TlAsg8vDkC3oBq59LSc2+Nm0/TWPPcW2fPWy03Q8AbUHMSfRd2Y81zb42bHXHm4f4JuGcLTqLPWaenPNLMVtC3cvP5OD5OO+Wm9Hw+Pw96IeCevX79+ruAjtiEhowJGDImYMiYgCFjAoaMCRgyJmDImIAhYwKGjAkYMuatlCsajUZPZ7PZV0Fr19fXRbASATdzccf3yk99DDr3d7CQTehm3gS9urq6Og0WGgWNTKfTP6L/T7q4r8739/e/CBayBm4obSY7DbAnab/Ct0EjD4JGzs7Ozh89evRnenGN0z8/DdbhIi0ov09r31+CRgTcQor4txTxz+nh5ylkH2rerdMU7zfz+fw0aMwMvKTqUxjf3VhSWhBeXF5enh4eHl4EAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAzeP4QPIF1EwVULAAAAAElFTkSuQmCC');
    }
  }

  get internalElements() {
    return this.statementForm.get('internalElements') as UntypedFormArray;
  }

  async createLostItem() {
    this.statement = new LostItems();
    this.statementForm = await this.formsService.createStatementLostForm(this.statement);
  }

  async loadLostItem(id: string) {
    const xRequestId = this.settingsService.general.randomUuid;
    const statement = await this.restApi.getLostItem(id, xRequestId);
    this.statement = new LostItems(statement);
    this.statementForm = await this.formsService.createStatementLostForm(statement);
  }

  getContent() {
    return this.internalElements.controls.map(el => {
      const elements = (el.get('internals') as UntypedFormArray).controls.map(internal => internal.get('description').value).join(', ');
      return (this.settingsService.language ===
        'RU'
        ? (el.get('categoryName') as UntypedFormArray).at(0).value
        : (el.get('categoryName') as UntypedFormArray).at(1).value)
        + ': ' + elements;
    }).join('; ');
  }

  async saveLostItem() {
    this.statement = new LostItems(this.statementForm.value);
    const xRequestId = this.settingsService.general.randomUuid;
    await this.restApi.updateLostItem(this.statement, xRequestId).then(result => {
      // this.router.navigate(['/lostitems/' + this.statement.id]);
      const message = this.globalI18n.getMessage(ModuleName.LostFound, 'saved');
      this._success.next({ text: message, type: 'success' });
    }).catch(err => {
      const errorMessage = `${this.globalI18n.getMessage(ModuleName.LostFound, 'claimNotSaved')} ${err.detail}`;
      const errorType = 'error';
      this.modalError.showErrorMess(errorMessage, errorType);
      // const message = this.globalI18n.getMessage(ModuleName.LostFound, 'fillInAllRequiredFields');
      // this._success.next({ text: message, type: 'danger' });
      return false;
    });
  }

  async addLostItem() {
    this.statement = new LostItems(this.statementForm.value);
    const xRequestId = this.settingsService.general.randomUuid;
    const data = await this.restApi.addLostItem(this.statement, xRequestId);
    const location = data.headers.get('Location');
    const newStatementId = location.replace('/lost-items/', '');
    this.router.navigate(['/lostitems/' + newStatementId]);
    return;
  }

  customSearchAirport(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4 ?
      (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
      (item.code && item.code[1] && item.code[1].toLowerCase().indexOf(term) > -1)
      : term.length < 5 ?
        (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
        :
        (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1);
  }

  customSearchAirline(term: string, item) {
    term = term.toLowerCase();
    return term.length < 3 ?
      (item.iata && item.iata.toLowerCase().indexOf(term) > -1)
      : term.length < 4 ?
        (item.icao && item.icao.toLowerCase().indexOf(term) > -1)
        :
        (item.name && item.name[0] && item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name && item.name[1] && item.name[1].toLowerCase().indexOf(term) > -1);
  }

  /**
   * Функция добавления нового элемента в массив вложений
   * каждый Элемент принадлежит к Категории, все Элементы нужно помещать внутрь
   * нужной Категории для дальнейшего правильно вывода пользователю
   * @param id Идентификатор места багажа
   */
  addBaggageInternalElement({ internalElement, category, baggageInternalDescription, baggageInternalCategoryId }) {
    // Флаг проверки существования Категории в текущем наборе данных
    let categoryIsExist = false;
    // Поиск существующей Категории элементов для добавления нового Элемента
    if (this.statement.internalElements) {
      for (const element of this.statement.internalElements) {
        // Если есть группы с нужной Категорией, добавление элемент в данную группу
        if (element.categoryId === internalElement.categoryId) {
          // Проверка на существование данного Элемента, что бы избежать дублирование
          if (element.internals.find(el => el.id === internalElement.id) === undefined) {
            // Добавление элемента в виде объекта
            element.internals.push({
              id: internalElement.id,
              name: internalElement.name,
              description: baggageInternalDescription
            });
          }
          // Установка флага что Категория найдена
          categoryIsExist = true;
          break;
        }
      }
    } else {
      // Если раздела не существует, происходит его создание
      this.statement.internalElements = [];
    }

    // Если Категории не было, ее необходимо создать и записать туда Элемент
    if (!categoryIsExist) {
      this.statement.internalElements.push({
        categoryId: baggageInternalCategoryId,
        categoryName: category.name,
        description: '',
        internals: [{ id: internalElement.id, name: internalElement.name, description: baggageInternalDescription }]
      });
    }
  }

  downloadDocument(id: string, type: string) {
    const xRequestId = this.settingsService.general.randomUuid;
    return this.restApi.detDocument(id, type, xRequestId).subscribe(data => {
      const blob = new Blob([data], { type: 'application' });
      this.fileSaverService.save(blob, type + '_' + this.statement.name + '.xlsx');
    });
  }

  get baggage_statuses() {
    for (const item of this.references.statement_types.data) {
      if (item.id === 6) {
        return item.baggage_statuses;
      }
    }
    return [];
  }

  async sendToArchive() {
    this.statement.archived = true;
    await this.saveLostItem();
  }

  async restoreFromArchive() {
    this.statement.archived = false;
    await this.saveLostItem();
  }

}
