<div class="modal-dialog modal-xl">
    <div class="modal-content bg-light-gray">
        <form #filterForm="ngForm">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalContent">
                    <span i18n="laf|Damage modal@@laf.modal.damage.header">Damage</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="font-weight-bold text-gray-blue-dark h6"
                    i18n="laf|Damage modal@@laf.modal.damage.locationOfDamage">
                    Locations of damage
                </p>
                <div class="row w-50 mx-auto">
                    <div class="col pr-0 text-center">
                        <div><img src="../../assets/img/lost-found/Side1.png" alt=""></div>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.side">Side</span> 1
                    </div>
                    <div class="col pr-0 text-center">
                        <div><img src="../../assets/img/lost-found/Side2.png" alt=""></div>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.side">Side</span> 2
                    </div>
                    <div class="col pr-0 text-center">
                        <div><img src="../../assets/img/lost-found/End1.png" alt=""></div>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.end">End</span> 1
                    </div>
                    <div class="col pr-0 text-center">
                        <div><img src="../../assets/img/lost-found/End2.png" alt=""></div>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.end">End</span> 2
                    </div>
                    <div class="col text-center">
                        <div><img src="../../assets/img/lost-found/Top.png" alt=""></div>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.top">Top</span>
                        <div><img src="../../assets/img/lost-found/Bottom.png" alt=""></div>
                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.bottom">Bottom</span>
                    </div>
                </div>

                <hr>

                <div class="row px-3">
                    <div class="d-flex w-100 justify-content-between mb-2">
                        <p class="font-weight-bold text-gray-blue-dark h6"
                            i18n="laf|Damage modal@@laf.modal.damage.damageList">
                            List of damage
                        </p>
                    </div>

                    <table class="w-100 font-small table-bordered" [formGroup]="parentForm">
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="40%" />
                        <thead class="bg-light-gray font-weight-bold text-center">
                            <tr>
                                <th i18n="laf|Damage modal@@laf.modal.damage.table.head.side">Side</th>
                                <th i18n="laf|Damage modal@@laf.modal.damage.table.head.level">Level</th>
                                <th i18n="laf|Damage modal@@laf.modal.damage.table.head.type">Type</th>
                                <th i18n="laf|Damage modal@@laf.modal.damage.table.head.description">Description</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white" formArrayName="damages">
                            <ng-container *ngFor="let damage of damages.controls; let i=index">
                                <tr [formGroupName]="i">
                                    <td>
                                        <div class="d-flex justify-content-around">
                                            <ng-select class="w-100 custom-sm bg-white" [items]="DamagesList"
                                                [selectOnTab]="true" [virtualScroll]="true"
                                                [bindLabel]="language == 'EN' ? 'nameEn' : 'nameRu'"
                                                bindValue="id" formControlName="side" (change)="changeValue(damage);">
                                            </ng-select>
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <ng-select class="w-100 custom-sm bg-white" [items]="damage_levels.data"
                                            [bindLabel]="language == 'EN' ? 'nameEnglish' : 'nameLocal'"
                                            bindValue="id" [selectOnTab]="true" [virtualScroll]="true"
                                            (change)="changeType(damage, $event)" formControlName="levelId">
                                            <ng-template ng-option-tmp let-item="item">
                                                <div class="font-small">
                                                    <span class="font-weight-bold">
                                                        {{item.code}}</span>&nbsp;
                                                        {{language == 'EN' ? item.nameEnglish : item.nameLocal}}
                                                </div>
                                            </ng-template>
                                            <ng-template ng-label-tmp let-item="item">
                                                <div class="font-small">
                                                    <span class="font-weight-bold">
                                                        {{item.code}}</span>&nbsp;
                                                        {{language == 'EN' ? item.nameEnglish : item.nameLocal}}
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </td>
                                    <td class="align-middle">
                                        <ng-select class="w-100 custom-sm bg-white" [items]="damage_types.data"
                                            [bindLabel]="language == 'EN' ? 'nameEnglish' : 'nameLocal'"
                                            bindValue="id" [selectOnTab]="true" [virtualScroll]="true"
                                            formControlName="typeId" (change)="changeLevel(damage, $event)">
                                            <ng-template ng-option-tmp let-item="item">
                                                <div class="font-small">
                                                    <span class="font-weight-bold">
                                                        {{item.code}}</span>&nbsp;
                                                        {{language == 'EN' ? item.nameEnglish : item.nameLocal}}
                                                </div>
                                            </ng-template>
                                            <ng-template ng-label-tmp let-item="item">
                                                <div class="font-small">
                                                    <span class="font-weight-bold">
                                                        {{item.code}}</span>&nbsp;
                                                        {{language == 'EN' ? item.nameEnglish : item.nameLocal}}
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </td>
                                    <td><input type="text" class="w-100 form-control custom-sm" formControlName="description" /></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-red mr-3"
                    *ngIf="parentForm.touched && !parentForm.valid && damages.errors && damages.hasError('noValue')"
                    i18n="laf|Damage modal@@laf.modal.damage.button.close">
                    You must fill in at least one line
                </div>
                <button type="button" class="btn btn-sm btn-blue" [attr.data-dismiss]="parentForm.valid ? 'modal' : ''"
                    i18n="laf|Damage modal@@laf.modal.damage.button.ok" (click)="closeForm()"
                    [disabled]="!parentForm.valid">
                    OK
                </button>
                <!-- <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal"
          i18n="laf|Content modal@@laf.modal.damage.button.close">
          Close
        </button> -->
            </div>
        </form>
    </div>
</div>