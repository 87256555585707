<div
	class="position-absolute"
	style="z-index: 1">
	<ul
		nz-menu
		nzMode="inline"
		nzSelectable="false"
		nzTheme="light"
		[nzInlineCollapsed]="true"
		style="border-right-width: 0px; width: 48px; margin-top: 8px"
		class="pr-2 text-center">
		<li
			nz-submenu
			[nzOpen]="true">
			<span title>
				<i class="ri-menu-line ri-xl"></i>
			</span>
			<ul>
				<li>
					<ul
						nz-menu-group
						nzTitle="Modules"
						i18n-nzTitle="@@nav.modules.name">
						<ul>
							<ng-container *ngFor="let module of availableModules">
								<ng-container *ngIf="module.id === Module.Administration; else standardItem">
									<li
										nz-submenu
										nzTitle="{{ module.name }}">
										<ul>
											<li nz-menu-item
												*ngIf="isUserHasRoleForAccessToModule(Module.ComMan)"
												[routerLink]="['admin/communication']"
												i18n="adm|Main left menu - communication module@@adm.main.leftMenu.item.comMod">
												Communication module
											</li>
											<li nz-menu-item
												[routerLink]="['admin/informational']"
												i18n="adm|Main left menu - informing@@adm.main.leftMenu.item.informing"
												*ngIf="isUserHasRoleForAccessToModule(Module.VisualInforming)">
												Informing
											</li>
											<li nz-menu-item
												[routerLink]="['admin/technical']"
												i18n="adm|Main left menu - technical processes@@adm.main.leftMenu.item.techProcesses"
												*ngIf="isUserHasRoleForAccessToModule(Module.Workflow)">
												Technical processes
											</li>
											<li nz-menu-item
												[routerLink]="['admin/boarding-control']"
												i18n="adm|Main left menu - boarding control@@adm.main.leftMenu.item.boardingControl"
												*ngIf="isUserHasRoleForAccessToModule(Module.BoardingControl)">
												Boarding control
											</li>
											<li nz-menu-item
												[routerLink]="['admin/weight-balance']"
												i18n="adm|Main left menu - weight balance@@adm.main.leftMenu.item.weightBalance"
												*ngIf="isUserHasRoleForAccessToModule(Module.WeightBalance)">
												Weight balance
											</li>
											<li nz-menu-item
												[routerLink]="['admin/lost-found']"
												i18n="adm|Main left menu - weight balance@@adm.main.leftMenu.item.lostFound"
												*ngIf="isUserHasRoleForAccessToModule(Module.LostFound)">
												Lost and found
											</li>
											<li nz-menu-item
												[routerLink]="['admin/flights']"
												i18n="adm|Main left menu - flights@@adm.main.leftMenu.item.flights"
												*ngIf="isUserHasRoleForAccessToModule(Module.Schedule)">
												Flights
											</li>
										</ul>
									</li>
								</ng-container>
								<ng-template #standardItem>
									<li
										nz-menu-item
										[routerLink]="[module.path]">
										{{ module.name }}
									</li>
								</ng-template>
							</ng-container>
						</ul>
						<!-- <li nz-menu-item>Home1</li>
            <li nz-menu-item>Option 6</li>
            <li nz-submenu
                nzTitle="Submenu">
              <ul>
                <li nz-menu-item>Option 7</li>
                <li nz-menu-item>Option 8</li>
              </ul>
            </li> -->
					</ul>
				</li>
				<li nz-menu-divider></li>
				<li>
					<ul
						nz-menu-group
						nzTitle="System"
						i18n-nzTitle="@@nav.system.name">
						<ul>
							<li
								nz-menu-item
								[routerLink]="['/settings']">
								<i class="ri-settings-3-line ri-xl mr-2"></i><span i18n="@@nav.system.settings">Settings</span>
							</li>
							<li
								nz-menu-item
								(click)="logout()">
								<i class="ri-logout-box-r-line ri-xl mr-2"></i><span i18n="@@nav.system.logOut">Logout</span>
							</li>
						</ul>
					</ul>
				</li>
				<li nz-menu-divider></li>
				<li
					nz-menu-item
					[routerLink]="['/home']">
					<i class="ri-user-line ri-lg mr-2"></i>
					<span>{{ userFullName }}</span>
				</li>
			</ul>
		</li>
	</ul>
</div>

<div class="h-100">
	<router-outlet></router-outlet>
</div>
