import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Flight, Terminal } from '../types/flights-reference';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable({
	providedIn: 'root',
})
export class FlightsRestApiService {
	private apiScheduleUrl = null;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiScheduleUrl = config.apiScheduleURL;
		});
	}

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	// Stands
	getStands(): Observable<Flight[]> {
		return this.http.get<Flight[]>(this.apiScheduleUrl + '/admin/stands').pipe(retry(1), catchError(this.handleError));
	}

	getStand(id: number): Observable<Flight> {
		return this.http
			.get<Flight>(this.apiScheduleUrl + '/admin/stands/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addStand(data: any): Observable<Flight> {
		return this.http
			.post<Flight>(this.apiScheduleUrl + '/admin/stands', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateStand(id: number, data: any): Observable<Flight> {
		return this.http
			.put<Flight>(this.apiScheduleUrl + '/admin/stands/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Terminals
	getTerminals(): Observable<Terminal[]> {
		return this.http
			.get<Terminal[]>(this.apiScheduleUrl + '/admin/terminals')
			.pipe(retry(1), catchError(this.handleError));
	}

	getTerminal(id: number): Observable<Terminal> {
		return this.http
			.get<Terminal>(this.apiScheduleUrl + '/admin/terminals/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addTerminal(data: any): Observable<Terminal> {
		return this.http
			.post<Terminal>(this.apiScheduleUrl + '/admin/terminals', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateTerminal(id: number, data: any): Observable<Terminal> {
		return this.http
			.put<Terminal>(this.apiScheduleUrl + '/admin/terminals/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	handleError(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		window.alert(errorMessage);
		return throwError(error.error);
	}
}
