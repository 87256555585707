import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Message, MessageDetail, MessageTemplate } from './message';
import { Folder, FolderSetting } from './folder';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Channel, Urgency, Type, Flight } from './reference';
import { AdministrationContact, AdministrationSender } from '../administration-module/types/com-module';
import { ReferanceAirline, ReferanceAirport } from '../referance-module-data/referance';
import { isCyrillic, transliterationEnRu, transliterationRuEn, mtk2Ru } from '@shared/functions/transliteration';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable({
	providedIn: 'root',
})
export class CommunicationModuleRestApiService {
	private apiCommunicationUrl: string;
	private apiReferenceUrl: string;

	// Транслитерация адресов
	mtk2Ru = [
		'А',
		'Б',
		'В',
		'Г',
		'Д',
		'Е',
		'Ж',
		'З',
		'И',
		'Й',
		'К',
		'Л',
		'М',
		'Н',
		'О',
		'П',
		'Р',
		'С',
		'Т',
		'У',
		'Ф',
		'Х',
		'Ц',
		'Ь',
		'Ы',
		'Я',
	];
	mtk2Lat = [
		'A',
		'B',
		'W',
		'G',
		'D',
		'E',
		'V',
		'Z',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'R',
		'S',
		'T',
		'U',
		'F',
		'H',
		'C',
		'X',
		'Y',
		'Q',
	];

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiCommunicationUrl = config.apiCommunicationURL;
			this.apiReferenceUrl = config.apiReferanceURL;
		});
	}

	setDefaultHttpHeader(requestId?): Object {
		// Формирование заголовков для отслеживания запросов
		// X-Correlation-ID идентификатор пользовательской сессии
		// X-Request-ID идентификатор события / запроса
		const httpOptions = {};
		httpOptions['headers'] = {
			'Content-Type': 'application/json',
			'X-Correlation-ID': this.settingsService.general.userSessionUuid,
			'X-Request-ID': requestId === undefined ? this.settingsService.general.randomUuid : requestId,
		};
		return httpOptions;
	}

	getMessages(getParams, timestamp, link, xRequestId?): Observable<any[]> {
		const params = this.createParams(getParams);
		let type;
		if (getParams.all != null) {
			type = 'all';
		} else {
			type = getParams.depth;
		}

		const paramsArray = params.keys().map(x => `${x}=${params.get(x)}`);

		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['params'] = params;
		return this.http
			.get<Message[]>(
				this.apiCommunicationUrl + `${link}/${type}/messages/${getParams.limit}/${getParams.page}`,
				httpOptions
			)
			.pipe(
				map(item => [item, timestamp]),
				retry(1),
				catchError(this.handleError)
			);
	}

	getOneMessage(id: number, folderId: number, xRequestId?): Observable<MessageDetail> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<MessageDetail>(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getChainMessage(id: number, folderId: number, xRequestId?): Observable<MessageDetail[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<MessageDetail[]>(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/chain`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getMessageFlights(id: number, xRequestId?): Observable<Array<number>> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Array<number>>(this.apiCommunicationUrl + `/messages/${id}/flights`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	createMessage(message: any, folderId, xRequestId?): Promise<HttpResponse<any>> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['observe'] = 'response';
		return this.http
			.post<HttpResponse<any>>(this.apiCommunicationUrl + `/messages/${folderId}`, JSON.stringify(message), httpOptions)
			.pipe(
				map(resp => {
					return resp;
				}),
				retry(1),
				catchError(this.handleError)
			)
			.toPromise();
	}

	loadDraft(id: number, folderId: number, xRequestId?): Observable<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<any>(this.apiCommunicationUrl + `/folders/${folderId}/drafts/${id}`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	deleteDraft(id: number, folderId: number, xRequestId?): Observable<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.delete<any>(this.apiCommunicationUrl + `/folders/${folderId}/drafts/${id}`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	saveDraft(message: any, folderId: number, xRequestId?): Observable<HttpResponse<any>> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['observe'] = 'response';
		return this.http
			.post<HttpResponse<any>>(this.apiCommunicationUrl + `/folders/${folderId}/drafts`, JSON.stringify(message), {
				observe: 'response',
			})
			.pipe(
				map(resp => {
					return resp;
				}),
				retry(1),
				catchError(this.handleError)
			);
	}

	updateDraft(message: any, id: number, folderId: number, xRequestId?): Observable<HttpResponse<any>> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['observe'] = 'response';
		return this.http
			.put<HttpResponse<any>>(this.apiCommunicationUrl + `/folders/${folderId}/drafts/${id}`, JSON.stringify(message), {
				observe: 'response',
			})
			.pipe(
				map(resp => {
					return resp;
				}),
				retry(1),
				catchError(this.handleError)
			);
	}

	updateMessage(id: number, message: any, xRequestId?): Observable<MessageDetail> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put<MessageDetail>(this.apiCommunicationUrl + '/messages/' + id, JSON.stringify(message), httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	readMessage(id: number, folderId: number, xRequestId?): Promise<MessageDetail> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put<MessageDetail>(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/read`, {}, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	readAllMessages(folderId: number, xRequestId?): Promise<MessageDetail> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post<MessageDetail>(this.apiCommunicationUrl + `/folders/${folderId}/readAll`, {}, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	unreadMessage(id: number, folderId: number, xRequestId?): Promise<MessageDetail> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put<MessageDetail>(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/unread`, {}, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// TODO Удалить
	// Эта ф-ция не используется
	deleteAirport(id: number, folder: string, xRequestId) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.delete<MessageDetail>(this.apiReferenceUrl + '/messages/' + id + '/' + folder, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getFolders(xRequestId?): Observable<Folder[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Folder[]>(this.apiCommunicationUrl + '/folders', httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getCountMessages(getParams, link, xRequestId?): Observable<number> {
		const params = this.createParams(getParams);
		let type;
		if (getParams.all != null) {
			type = 'all';
		} else {
			type = getParams.depth;
		}

		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['params'] = params;
		return this.http
			.get<number>(this.apiCommunicationUrl + `${link}/${type}/count`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getCountFilteredMessages(getParams, id: number, xRequestId?): Observable<number> {
		const params = this.createParams(getParams);
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		httpOptions['params'] = params;
		return this.http
			.get<number>(this.apiCommunicationUrl + `/folders/${id}/unread`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getAllFolders(xRequestId?): Observable<Folder[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Folder[]>(this.apiCommunicationUrl + '/admin/folders?current=true', httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getFolder(id: number, xRequestId?): Observable<FolderSetting> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<FolderSetting>(this.apiCommunicationUrl + '/admin/folders/' + id, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Не используемая фунция, вызовов не найдено
	addFolder(foder: any, xRequestId?): Observable<Folder> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post<Folder>(this.apiCommunicationUrl + '/folders/current', JSON.stringify(foder), httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getChannels(xRequestId?): Promise<Channel> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Channel>(this.apiCommunicationUrl + '/master_data/channels', httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getSenders(channel, folder, xRequestId?): Promise<AdministrationSender[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<AdministrationSender[]>(
				this.apiCommunicationUrl + '/admin/senders_by_channel_and_folder/' + channel + '/' + folder,
				httpOptions
			)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getContacts(xRequestId?): Promise<AdministrationContact[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<AdministrationContact[]>(this.apiCommunicationUrl + '/admin/contacts?current=true', httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getUrgencies(channel?, xRequestId?): Promise<Urgency[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		if (channel == null) {
			return this.http
				.get<Urgency[]>(this.apiCommunicationUrl + '/master_data/urgencies', httpOptions)
				.pipe(retry(1), catchError(this.handleError))
				.toPromise();
		} else {
			return this.http
				.get<Urgency[]>(this.apiCommunicationUrl + '/master_data/urgencies_by_channel/' + channel, httpOptions)
				.pipe(retry(1), catchError(this.handleError))
				.toPromise();
		}
	}

	getTypes(xRequestId?): Promise<Type[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Type[]>(this.apiCommunicationUrl + '/master_data/message_types', httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Airlines
	getAirlines(xRequestId?): Promise<ReferanceAirline[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<ReferanceAirline[]>(this.apiCommunicationUrl + '/master_data/airlines', httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Airports
	getAirports(xRequestId?): Promise<ReferanceAirport[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<ReferanceAirport[]>(this.apiCommunicationUrl + '/master_data/airports', httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getFligtsList(xRequestId?): Observable<Flight[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Flight[]>(this.apiCommunicationUrl + '/flights', httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	saveMessageFligts(messageId: number, ids: Array<number>, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post(this.apiCommunicationUrl + `/messages/${messageId}/links`, ids, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	copyToFolder(id: number, folderId: number, folderIds: string, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/copyTo/${folderIds}`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getFoldersForMessage(id: number, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<any>(this.apiCommunicationUrl + `/messages/${id}/folders`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	sendToArchive(id: number, folderId: number, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/archive`, {}, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	notSendToArchive(id: number, folderId: number, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/denyAutoArchive`, {}, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	restoreFromArchive(id: number, folderId: number, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/restore`, {}, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	reparseMessage(id: number, folderId: number, message: string, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/reparse`, message, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	reSendMessage(id: number, message: MessageDetail, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + '/messages/' + id, JSON.stringify(message), httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Templates
	getTemplates(folderId: number, xRequestId?): Observable<MessageTemplate[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<MessageTemplate[]>(this.apiCommunicationUrl + `/folders/${folderId}/templates?current=true`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	getTemplate(folderId: number, id: number, xRequestId?): Observable<MessageTemplate> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<MessageTemplate>(this.apiCommunicationUrl + `/folders/${folderId}/templates/${id}`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	saveTemplate(folderId: number, template: MessageTemplate, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post(this.apiCommunicationUrl + `/folders/${folderId}/templates`, JSON.stringify(template), httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateTemplate(folderId: number, id: number, template: MessageTemplate, xRequestId?): Promise<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + `/folders/${folderId}/templates/` + id, JSON.stringify(template), httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Marks
	getMarks(xRequestId?): Observable<any> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<any>(this.apiCommunicationUrl + '/marks', httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	setMark(id: number, folderId: number, mark: string, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/mark/${mark}`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	unsetMark(id: number, folderId: number, xRequestId?) {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.put(this.apiCommunicationUrl + `/messages/${id}/folders/${folderId}/unmark`, httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	translationAddress(recipients) {
		const result = [];
		const ru = new RegExp('[' + mtk2Ru.join() + ']', 'i');
		const lat = /[a-zA-Z]/;
		const pattern = /[^@]+@[^@]+\.[a-zA-Z]{2,6}/;
		recipients.forEach(address => {
			address = address.toUpperCase();
			if (address.length <= 8 && (lat.test(address) || ru.test(address))) {
				if (isCyrillic(address)) {
					result.push(transliterationRuEn(address));
				} else {
					result.push(transliterationEnRu(address));
				}
			}
			result.push(address);
		});
		return [...new Set(result)].join(',');
	}

	createParams(getParams) {
		let params = new HttpParams();
		if (getParams.airlineValue.length > 0) {
			params = params.append('airline', getParams.airlineFlag + getParams.airlineValue);
		}
		if (getParams.typeValue.length > 0) {
			params = params.append('messageType', getParams.typeFlag + getParams.typeValue);
		}
		if (getParams.routeValue.length > 0) {
			params = params.append('destination', getParams.routeFlag + getParams.routeValue);
		}
		if (getParams.channelValue.length > 0) {
			params = params.append('channel', getParams.channelFlag + getParams.channelValue);
		}
		if (getParams.flight.length > 0) {
			params = params.append('flight', getParams.flight);
		}
		if (getParams.recipient != '') {
			params = params.append('recipient', getParams.recipientFlag + this.translationAddress(getParams.recipient));
		}
		if (getParams.sender != '') {
			params = params.append('sender', getParams.senderFlag + this.translationAddress(getParams.sender));
		}
		if (getParams.urgencyValue.length > 0) {
			params = params.append('urgency', getParams.urgencyFlag + getParams.urgencyValue);
		}
		if (getParams.subject != '') {
			params = params.append('subject', getParams.subject);
		}
		if (getParams.textValue.length > 0) {
			params = params.append('message', getParams.textFlag + getParams.textValue.join('||||'));
		}
		if (getParams.aftnNumber.length > 0) {
			params = params.append('aftnNumber', getParams.aftnNumber);
		}
		if (getParams.userValue != '') {
			params = params.append('username', getParams.userFlag + getParams.userValue);
		}
		if (getParams.unread == true) {
			params = params.append('unread', getParams.unread.toString());
		}
		if (getParams.noFlight == true) {
			params = params.append('noFlight', getParams.noFlight.toString());
		}
		if (getParams.direction != null && getParams.direction != -1) {
			params = params.append('direction', getParams.direction);
		}
		if (getParams.start != null) {
			params = params.append('start', getParams.start);
		}
		if (getParams.finish != null) {
			params = params.append('finish', getParams.finish);
		}
		if (getParams.mark != null) {
			params = params.append('mark', getParams.mark);
		}
		return params;
	}

	// Error handling
	handleError(error) {
		let errorMessage = '';
		let errorDetail: any = null;
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorDetail = error.error;
			errorDetail.status = error.status;
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}

		if (errorDetail) {
			return throwError(errorDetail);
		} else {
			return throwError(errorMessage);
		}
	}
}
