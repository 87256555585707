<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom pr-2">
		<div class="row justify-content-between font-small align-items-center">
			<div class="col-auto">
				<button
					type="button"
					class="btn btn-sm btn-blue mr-2"
					data-toggle="modal"
					data-target=".statement-card"
					(click)="createStatement()"
					i18n="laf|Main - control panel@@laf.main.controlPanel.button.baggageClaim">
					Baggage claim
				</button>
				<!-- <button class="btn btn-sm btn-blue mr-2" i18n="laf|Main - control panel@@laf.main.controlPanel.button.claimOnFind"
					disabled>Claim on the find</button> -->

				<div class="btn-group">
					<button
						type="button"
						class="btn btn-light-gray btn-sm"
						[routerLink]="['/lostfound/manual']"
						i18n="laf|Main - control panel@@laf.main.controlPanel.button.manualSearche">
						Manual search
					</button>
				</div>
			</div>
			<div class="col d-flex flex-row-reverse">
				<!-- <button class="btn btn-ico btn-light-gray btn-xs mr-2"><span class="material-icons">filter_none</span></button> -->
				<div class="dropdown">
					<button
						type="button"
						class="btn btn-xs btn-ico btn-light-gray dropdown-toggle"
						ngbTooltip="Filter by flag"
						i18n-ngbTooltip="laf|Main - control panel@@laf.main.controlPanel.button.filterByFlag"
						id="dropdownFlags"
						data-toggle="dropdown">
						<i
							class="material-icons"
							[ngStyle]="{ color: viewParams.filterParams.mark }"
							>assistant_photo</i
						>
					</button>
					<div
						class="dropdown-menu"
						aria-labelledby="dropdownFlags">
						<button
							type="button"
							*ngFor="let mark of marks"
							class="dropdown-item"
							(click)="changeMark(mark)">
							<i
								class="material-icons"
								[ngStyle]="{ color: mark }"
								>assistant_photo</i
							>
							{{ mark }}
						</button>
						<div class="dropdown-divider"></div>
						<button
							type="button"
							class="dropdown-item font-small"
							(click)="changeMark()">
							<i
								class="material-icons"
								style="color: #5c5c5c"
								>assistant_photo</i
							>
							<span i18n="laf|Main - control panel@@laf.main.controlPanel.button.clearFilter">Clear filter</span>
						</button>
					</div>
				</div>
			</div>
			<div class="col-5 pl-0">
				<div class="d-flex w-100">
					<div
						class="filter-block-wrap mr-2 w-100"
						(click)="viewParams.showFilter()">
						<div class="input-group-prepend">
							<span
								class="input-group-text border-0 rounded-0 py-1"
								i18n="laf|Main - control panel@@laf.main.controlPanel.find"
								>Find</span
							>
							<div class="filter-elements d-flex pl-2 text-nowrap flex-wrap">
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.start">
									<i
										class="material-icons mx-1"
										(click)="clearFilterParametr('start', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.from">From:</span>&nbsp;
									{{ viewParams.filterParams.start | date : 'dd.MM.yyyy HH:mm' }}
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.finish">
									<i
										class="material-icons mx-1"
										(click)="clearFilterParametr('finish', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.to">To:</span>&nbsp;
									{{ viewParams.filterParams.finish | date : 'dd.MM.yyyy HH:mm' }}
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.status !== null && viewParams.filterParams.status !== ''">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('status', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.status">Status:</span>&nbsp;
									{{ getById(references.baggage_statuses?.data, viewParams.filterParams.status)?.nameLocal }}
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.airport?.length > 0">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('airport', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.airport">Airports:</span>&nbsp;
									<span *ngFor="let airport of viewParams.filterParams.airport">
										{{ getById(references.airports?.data, airport)?.iata }}
									</span>
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.name?.length > 0">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('name', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.claimNumber">Claim number:</span>&nbsp;
									<span *ngFor="let name of viewParams.filterParams.name">{{ name }}</span>
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.tag?.length > 0">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('tag', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.baggageTag">Baggage tag:</span>&nbsp;
									<span *ngFor="let tag of viewParams.filterParams.tag">{{ tag }}</span>
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.passenger !== null && viewParams.filterParams.passenger !== ''">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('passenger', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.passenger">Passenger:</span>&nbsp;
									{{ viewParams.filterParams.passenger }}
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.pnr !== null && viewParams.filterParams.pnr !== ''">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('pnr', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.pnr">Pnr:</span>&nbsp;
									{{ viewParams.filterParams.pnr }}
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.flight !== null && viewParams.filterParams.flight !== ''">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('flight', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.flight">Flight:</span>&nbsp;
									{{ viewParams.filterParams.flight }}
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.baggageType?.length > 0">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('baggageType', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.type">Type:</span>&nbsp;
									<span
										[ngClass]="{ del: viewParams.filterParams.baggageTypeFlag === '!' }"
										*ngFor="let type of viewParams.filterParams.baggageType">
										{{ getById(references.baggage_types?.data, type)?.code }}
									</span>
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.baggageColor?.length > 0">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('baggageColor', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.color">Color:</span>&nbsp;
									<span
										[ngClass]="{ del: viewParams.filterParams.baggageColorFlag === '!' }"
										*ngFor="let color of viewParams.filterParams.baggageColor">
										{{ getById(references.baggage_colors?.data, color)?.code }}
									</span>
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.baggageMaterial?.length > 0">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('baggageMaterial', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.material">Material:</span>&nbsp;
									<span
										[ngClass]="{ del: viewParams.filterParams.baggageMaterialFlag === '!' }"
										*ngFor="let material of viewParams.filterParams.baggageMaterial">
										{{ getById(references.baggage_materials?.data, material)?.code }}
									</span>
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.baggageExternalElements?.length > 0">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('baggageExternalElements', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.externalElements">External Elements:</span>&nbsp;
									<span
										[ngClass]="{ del: viewParams.filterParams.baggageExternalElementsFlag === '!' }"
										*ngFor="let elem of viewParams.filterParams.baggageExternalElements">
										{{ getById(references.baggage_elements?.data, elem)?.code }}
									</span>
								</div>
								<div
									class="filter-element mr-2 my-1"
									*ngIf="viewParams.filterParams.airline?.length > 0">
									<i
										class="material-icons ml-2"
										(click)="clearFilterParametr('airline', $event)"
										>close</i
									>
									<span i18n="laf|Filter@@laf.main.filter.airline">Airline:</span>&nbsp;
									<span *ngFor="let airline of viewParams.filterParams.airline">
										{{ getById(references.airlines?.data, airline)?.iata }}
									</span>
								</div>
							</div>
						</div>
						<div class="d-flex align-items-center text-dark-gray">
							<i class="material-icons mx-1">arrow_drop_down</i>
						</div>
					</div>
					<!-- Filter -->
					<div
						class="filter-block border p-2 bg-white font-small"
						*ngIf="viewParams.filterShow">
						<div class="container">
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-aftn-number"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.claimNumber">
										Claim number
									</label>
								</div>
								<div
									class="col-9 px-1"
									*ngIf="!viewParams.filterLoadAnimation['name']; else filterStatementLoad">
									<ng-select
										class="custom-sm p-0"
										[(ngModel)]="viewParams.filterParams.name"
										[multiple]="true"
										[addTag]="addTagClaim"
										[selectOnTab]="true">
										<ng-template
											ng-tag-tmp
											let-search="searchTerm">
											<b i18n="laf|Filter@@laf.main.filter.field.baggageTagAdd">Add:</b>&nbsp;{{ search }}
										</ng-template>
									</ng-select>
								</div>
								<ng-template #filterStatementLoad>
									<div class="col-9 px-1">
										<div class="d-flex align-items-center pl-2 bg-light-gray rounded">
											<div
												class="spinner-border spinner-border-sm float-left"
												role="status"
												aria-hidden="true"></div>
											<div
												class="float-left pl-2"
												i18n="laf|Filter@@laf.main.filter.field.statementNumberMessage">
												Parsing an claim string...
											</div>
										</div>
									</div>
								</ng-template>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-flight"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.baggageTag">
										Baggage tag
									</label>
								</div>
								<div
									class="col-9 px-1"
									*ngIf="!viewParams.filterLoadAnimation['tag']; else filterBaggageTagLoad">
									<ng-select
										class="custom-sm p-0"
										[(ngModel)]="viewParams.filterParams.tag"
										[multiple]="true"
										[addTag]="addTagBaggageTag"
										[selectOnTab]="true">
										<ng-template
											ng-tag-tmp
											let-search="searchTerm">
											<b i18n="laf|Filter@@laf.main.filter.field.baggageTagAdd">Add:</b>&nbsp;{{ search }}
										</ng-template>
									</ng-select>
								</div>
								<ng-template #filterBaggageTagLoad>
									<div class="col-9 px-1">
										<div class="d-flex align-items-center pl-2 bg-light-gray rounded">
											<div
												class="spinner-border spinner-border-sm float-left"
												role="status"
												aria-hidden="true"></div>
											<div
												class="float-left pl-2"
												i18n="laf|Filter@@laf.main.filter.field.baggageTagMessage">
												Parsing an baggage tag numbers string...
											</div>
										</div>
									</div>
								</ng-template>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-passenger-name"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.passengerName">
										Passenger name
									</label>
								</div>
								<div class="col-9 px-1">
									<input
										type="text"
										id="filter-passenger-name"
										[(ngModel)]="viewParams.filterParams.passenger"
										class="form-control form-control-sm"
										name="filter-passenger-name"
										appUpperCase />
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-pnr"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.pnr">
										PNR
									</label>
								</div>
								<div class="col-9 px-1">
									<input
										id="filter-pnr"
										class="form-control form-control-sm"
										[(ngModel)]="viewParams.filterParams.pnr"
										name="filter-pnr"
										appUpperCase />
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-pnr"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.airports">
										Airports
									</label>
								</div>
								<div class="col-9 px-1">
									<ng-select
										class="w-100 custom-sm p-0"
										[items]="userReferences.airports?.data"
										[multiple]="true"
										bindLabel="iata"
										bindValue="id"
										[virtualScroll]="true"
										[loading]="userReferences.airports.loading"
										[(ngModel)]="viewParams.filterParams.airport"
										(scroll)="onScrollNgSelect($event, 'airports')"
										(scrollToEnd)="onScrollToEndNgSelect('airports')"
										[searchFn]="customSelectSearch"
										[selectOnTab]="true">
										<ng-template
											ng-option-tmp
											let-item="item">
											<div class="font-small">
												<span class="font-weight-bold">{{ item.iata }}</span
												>&nbsp;{{ item.name }}
											</div>
										</ng-template>
									</ng-select>
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.baggageType"
										>Type
									</label>
								</div>
								<div class="col-3 px-1">
									<select
										class="form-control form-control-sm py-0"
										[(ngModel)]="viewParams.filterParams.baggageTypeFlag">
										<option
											value=""
											i18n="laf|Filter@@laf.main.filter.field.baggageTypeContain">
											Contain
										</option>
										<option
											value="!"
											i18n="laf|Filter@@laf.main.filter.field.baggageTypeNotContain">
											Not contain
										</option>
									</select>
								</div>
								<div class="col-6 px-1">
									<ng-select
										class="w-100 custom-sm p-0"
										bindLabel="code"
										bindValue="id"
										[multiple]="true"
										[(ngModel)]="viewParams.filterParams.baggageType"
										[loading]="references.baggage_types.loading"
										[items]="references.baggage_types?.data"
										[searchFn]="customSelectSearchOnBaggageDetailsTwoCode"
										[selectOnTab]="true">
										<ng-template
											ng-option-tmp
											let-item="item">
											<div class="font-small">
												<span class="font-weight-bold">{{ item.code }}</span
												>&nbsp;{{ item.nameEnglish }}
											</div>
										</ng-template>
									</ng-select>
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.baggageColor"
										>Color
									</label>
								</div>
								<div class="col-3 px-1">
									<select
										class="form-control form-control-sm py-0"
										[(ngModel)]="viewParams.filterParams.baggageColorFlag">
										<option
											value=""
											i18n="laf|Filter@@laf.main.filter.field.baggageColorContain">
											Contain
										</option>
										<option
											value="!"
											i18n="laf|Filter@@laf.main.filter.field.baggageColorNotContain">
											Not contain
										</option>
									</select>
								</div>
								<div class="col-6 px-1">
									<ng-select
										class="w-100 custom-sm p-0"
										[multiple]="true"
										bindLabel="code"
										bindValue="id"
										[(ngModel)]="viewParams.filterParams.baggageColor"
										[loading]="references.baggage_colors.loading"
										[items]="references.baggage_colors?.data"
										[searchFn]="customSelectSearchOnBaggageDetailsTwoCode"
										[selectOnTab]="true">
										<ng-template
											ng-option-tmp
											let-item="item">
											<div class="font-small">
												<span class="font-weight-bold">{{ item.code }}</span
												>&nbsp;{{ item.nameEnglish }}
											</div>
										</ng-template>
									</ng-select>
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.baggageMaterial"
										>Material
									</label>
								</div>
								<div class="col-3 px-1">
									<select
										class="form-control form-control-sm py-0"
										[(ngModel)]="viewParams.filterParams.baggageMaterialFlag">
										<option
											value=""
											i18n="laf|Filter@@laf.main.filter.field.baggageMaterialContain">
											Contain
										</option>
										<option
											value="!"
											i18n="laf|Filter@@laf.main.filter.field.baggageMaterialNotContain">
											Not contain
										</option>
									</select>
								</div>
								<div class="col-6 px-1">
									<ng-select
										class="w-100 custom-sm p-0"
										bindLabel="code"
										bindValue="id"
										[multiple]="true"
										[(ngModel)]="viewParams.filterParams.baggageMaterial"
										[loading]="references.baggage_materials.loading"
										[items]="references.baggage_materials?.data"
										[searchFn]="customSelectSearchOnBaggageDetailsOneCode"
										[selectOnTab]="true">
										<ng-template
											ng-option-tmp
											let-item="item">
											<div class="font-small">
												<span class="font-weight-bold">{{ item.code }}</span
												>&nbsp;{{ item.nameEnglish }}
											</div>
										</ng-template>
									</ng-select>
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.baggageExternalElements"
										>External elements
									</label>
								</div>
								<div class="col-3 px-1">
									<select
										class="form-control form-control-sm py-0"
										[(ngModel)]="viewParams.filterParams.baggageExternalElementsFlag">
										<option
											value=""
											i18n="laf|Filter@@laf.main.filter.field.baggageExternalElementsContain">
											Contain
										</option>
										<option
											value="!"
											i18n="laf|Filter@@laf.main.filter.field.baggageExternalElementsNotContain">
											Not contain
										</option>
									</select>
								</div>
								<div class="col-6 px-1">
									<ng-select
										class="w-100 custom-sm p-0"
										bindLabel="code"
										bindValue="id"
										[multiple]="true"
										[(ngModel)]="viewParams.filterParams.baggageExternalElements"
										[loading]="references.baggage_elements.loading"
										[items]="references.baggage_elements?.data"
										[searchFn]="customSelectSearchOnBaggageDetailsOneCode"
										[selectOnTab]="true">
										<ng-template
											ng-option-tmp
											let-item="item">
											<div class="font-small">
												<span class="font-weight-bold">{{ item.code }}</span
												>&nbsp;{{ item.nameEnglish }}
											</div>
										</ng-template>
									</ng-select>
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-pnr"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.airline">
										Airline
									</label>
								</div>
								<div class="col-9 px-1">
									<ng-select
										class="w-100 custom-sm p-0"
										[items]="userReferences.airlines?.data"
										[multiple]="true"
										bindLabel="iata"
										bindValue="id"
										[virtualScroll]="true"
										[(ngModel)]="viewParams.filterParams.airline"
										[loading]="userReferences.airlines.loading"
										(scroll)="onScrollNgSelect($event, 'airlines')"
										(scrollToEnd)="onScrollToEndNgSelect('airlines')"
										[searchFn]="customSelectSearch"
										[selectOnTab]="true">
										<ng-template
											ng-option-tmp
											let-item="item">
											<div class="font-small">
												<span class="font-weight-bold">{{ item.iata }}</span
												>&nbsp;{{ item.name }}
											</div>
										</ng-template>
									</ng-select>
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-flight"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.flight">
										Flight
									</label>
								</div>
								<div class="col-9 px-1">
									<input
										id="filter-flight"
										class="form-control form-control-sm"
										[(ngModel)]="viewParams.filterParams.flight"
										name="filter-flight"
										appUpperCase />
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-status-eq"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.status">
										Status
									</label>
								</div>
								<div class="col-3 px-1">
									<select
										id="filter-status-eq"
										class="form-control form-control-sm mr-2 py-0"
										name="filter-status-eq"
										[(ngModel)]="viewParams.filterParams.statusFlag">
										<option
											value=""
											i18n="laf|Filter@@laf.main.filter.field.statusEqual">
											Equal
										</option>
										<option
											value="!"
											i18n="laf|Filter@@laf.main.filter.field.statusNotEqual">
											Not equal
										</option>
									</select>
								</div>
								<div class="col-6 px-1">
									<ng-select
										class="custom-sm p-0"
										[virtualScroll]="true"
										[hideSelected]="true"
										[(ngModel)]="viewParams.filterParams.status"
										bindLabel="nameLocal"
										bindValue="id"
										[items]="references.baggage_statuses?.data">
									</ng-select>
								</div>
							</div>
							<hr />
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-date-from"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.dateFrom">
										Date from
									</label>
								</div>
								<div class="col-3 px-1">
									<input
										type="time"
										class="form-control"
										[ngModel]="viewParams.filterParams.start | date : 'HH:mm'"
										(blur)="viewParams.filterParams.start = parseDate(filterStartDate.value, $event.target.value)"
										name="filterStartTime"
										#filterStartTime />
								</div>
								<div class="col-6 px-1">
									<input
										type="date"
										class="form-control custom-sm"
										[ngModel]="viewParams.filterParams.start | date : 'yyyy-MM-dd'"
										(blur)="
											viewParams.filterParams.start = parseDate($event.target.value, filterStartTime.value || '00:00')
										"
										name="filterStartDate"
										#filterStartDate />
								</div>
							</div>
							<div class="row pb-2">
								<div class="col-3 px-1">
									<label
										for="filter-date-from"
										class="mr-2 mb-0"
										i18n="laf|Filter@@laf.main.filter.field.dateTo">
										Date to
									</label>
								</div>
								<div class="col-3 px-1">
									<input
										type="time"
										class="form-control"
										[ngModel]="viewParams.filterParams.finish | date : 'HH:mm'"
										(blur)="viewParams.filterParams.finish = parseDate(filterFinishDate.value, $event.target.value)"
										name="filterFinishTime"
										#filterFinishTime />
								</div>
								<div class="col-6 px-1">
									<input
										type="date"
										class="form-control custom-sm"
										[ngModel]="viewParams.filterParams.finish | date : 'yyyy-MM-dd'"
										(blur)="
											viewParams.filterParams.finish = parseDate($event.target.value, filterFinishTime.value || '00:00')
										"
										name="filterFinishDate"
										#filterFinishDate />
								</div>
							</div>
						</div>
						<hr />
						<button
							type="button"
							class="btn btn-gray-blue-dark btn-sm float-right ml-2"
							(click)="viewParams.filterShow = !viewParams.filterShow"
							i18n="laf|Filter@@laf.main.filter.button.close">
							Close
						</button>
						<button
							type="button"
							class="btn btn-blue btn-sm float-right ml-2"
							(click)="filterApp()"
							i18n="laf|Filter@@laf.main.filter.button.fing">
							Find
						</button>
						<button
							type="button"
							class="btn btn-blue btn-sm float-right"
							(click)="clearFilter()"
							i18n="laf|Filter@@laf.main.filter.button.clearAll">
							Clear all
						</button>
					</div>
					<button
						type="button"
						class="btn btn-xs btn-ico btn-light-gray mr-2"
						ngbTooltip="Filter on/off"
						i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.filterOnOff"
						[ngClass]="{ 'btn-active': viewParams.filterApply }"
						(click)="filterSwitch()">
						<i class="material-icons">filter_alt</i>
					</button>
					<!-- <button class="btn btn-ico btn-light-gray btn-xs"><span class="material-icons">library_books</span></button> -->
					<button
						type="button"
						class="btn btn-xs btn-ico btn-light-gray mr-2"
						ngbTooltip="View archive"
						placement="left"
						i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.viewArchive"
						[ngClass]="{ 'btn-active': viewParams.filterParams.archived }"
						(click)="filterArchive()">
						<i class="material-icons">library_books</i>
					</button>
				</div>
			</div>
		</div>
	</nz-header>
	<nz-content class="h-100 bg-white">
			<div
				class="d-flex content">
				<div class="bg-light-gray border-right folders-block h-100 pb-5 col-auto px-0">
					<div
						class="d-flex align-items-center pointer p-2 header-group"
						[routerLink]="['/lostfound']"
						routerLinkActive="bg-gray">
						<i class="material-icons mr-2 text-gray-blue-dark">work</i>
						<span
							class="mr-3"
							i18n="laf|Left panel@@laf.main.leftPanel.baggageSearch"
							>Baggage search</span
						>
					</div>
					<div
						class="collapse multi-collapse show"
						id="tracingLuggageMenu">
						<div class="mp-0 left-menu">
							<div class="pointer pl-3 ty-1 py-2">
								<div class="input-group-prepend d-flex justify-content-between">
									<label
										class="w-100 m-0"
										for="statementsAll"
										i18n="laf|Left panel@@laf.main.leftPanel.claimAll">
										Claim | All
									</label>
									<div
										class="custom-control custom-switch"
										ngbTooltip="Show all"
										i18n-ngbTooltip="laf|Filter@@laf.main.filter.button.showAll">
										<input
											type="checkbox"
											class="custom-control-input"
											id="statementsAll"
											name="statementsAll"
											[(ngModel)]="viewParams.showedStatement.statementsAll"
											[checked]="viewParams.showedStatement.statementsAll"
											(change)="baggageTableComponent.loadStatements()" />
										<label
											class="custom-control-label"
											for="statementsAll"></label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="collapse multi-collapse show"
						id="tracingLuggageMenu">
						<div
							*ngFor="let statement of references?.statement_types?.data"
							class="pl-4 ty-1 mt-1 mb-1 pr-2 m-0">
							<label
								class="m-0 d-flex align-items-center pointer"
								(click)="viewParams.toggleDisabledItem()">
								<input
									type="checkbox"
									class="d-hover mr-2"
									(click)="toggleItem(viewParams.showedStatement.statements, statement.id)"
									[checked]="
										viewParams.showedStatement.statements.includes(statement.id) ||
										viewParams.showedStatement.statementsAll
									"
									[disabled]="viewParams.showedStatement.statementsAll" />
								<span class="mr-3 mb-0">{{ language == 'EN' ? statement.nameEnglish : statement.nameLocal }}</span>
								<span
									class="badge badge-dark badge-pill ml-auto"
									*ngIf="statement.count && statement.count > 0"
									>{{ statement.count }}</span
								>
							</label>
							<hr
								*ngIf="statement.id === 1 || statement.id === 8"
								class="my-1 bg-gray-blue-dark" />
						</div>
					</div>
					<div
						class="collapse multi-collapse show"
						id="tracingLuggageMenu">
						<div class="mp-0 left-menu">
							<div class="pointer pl-3 ty-1 py-2">
								<div class="input-group-prepend d-flex justify-content-between">
									<label
										class="w-100 m-0"
										for="timesAll"
										i18n="laf|Left panel@@laf.main.leftPanel.searchExpiration">
										Search expiration
									</label>
								</div>
							</div>
						</div>
					</div>
					<div
						class="collapse multi-collapse show"
						id="tracingLuggageMenu">
						<div
							*ngFor="let time of LOCAL_REFERENCES.times"
							class="pl-4 ty-1 mt-1 mb-1 pr-2 m-0">
							<label class="m-0 d-flex align-items-center pointer">
								<input
									type="checkbox"
									class="d-hover mr-2"
									(click)="toggleItem(viewParams.showedStatement.times, time.value)"
									[checked]="viewParams.showedStatement.times.includes(time.value)" />
								<span class="mr-3 mb-0">{{ language == 'EN' ? time.nameEnglish : time.nameLocal }}</span>
								<!-- <span class="badge badge-dark badge-pill ml-auto">2</span> -->
							</label>
						</div>
					</div>
					<div
						class="collapse multi-collapse show"
						id="tracingLuggageMenu">
						<div class="mp-0 left-menu">
							<div class="pointer pl-3 ty-1 py-2">
								<div class="input-group-prepend d-flex justify-content-between">
									<label
										class="w-100 m-0"
										for="storageAll"
										i18n="laf|Left panel@@laf.main.leftPanel.storageExpiration">
										Storage expiration
									</label>
								</div>
							</div>
						</div>
					</div>
					<div
						class="collapse multi-collapse show"
						id="tracingLuggageMenu">
						<div
							*ngFor="let storage of LOCAL_REFERENCES.storage"
							class="pl-4 ty-1 mt-1 mb-1 pr-2 m-0">
							<label class="m-0 d-flex align-items-center pointer">
								<input
									type="checkbox"
									class="d-hover mr-2"
									(click)="toggleItem(viewParams.showedStatement.times, storage.value)"
									[checked]="viewParams.showedStatement.times.includes(storage.value)" />
								<span class="mr-3 mb-0">{{ language == 'EN' ? storage.nameEnglish : storage.nameLocal }}</span>
								<span class="badge badge-dark badge-pill ml-auto"></span>
							</label>
						</div>
					</div>
					<div
						class="d-flex align-items-center pointer p-2 header-group"
						[routerLink]="['/lostitems']"
						routerLinkActive="bg-gray">
						<i class="material-icons mr-2 text-gray-blue-dark">local_grocery_store</i>
						<span
							class="mr-3"
							i18n="laf|Left panel@@laf.main.leftPanel.lostItems"
							>Lost items</span
						>
					</div>
				</div>
				<div class="p-0 col h-100 bg-white d-flex flex-column">
					<baggage-table
						[references]="references"
						[marks]="marks"></baggage-table>
				</div>
			</div>

			<div
				class="modal fade bd-example-modal-lg statement-card"
				data-backdrop="static"
				tabindex="-1"
				role="dialog"
				aria-labelledby="myExtraLargeModalLabel"
				aria-hidden="true"
				*ngIf="!loading">
				<div class="modal-dialog modal-dialog-centered modal-lg">
					<div class="modal-content bg-light-gray">
						<form>
							<div
								class="loading"
								*ngIf="loading">
								<div class="cssload-spin-box"></div>
							</div>
							<div class="modal-header">
								<h5
									class="modal-title"
									id="exampleModalScrollableTitle"
									*ngIf="!statement.id">
									<span i18n="laf|Statement tabs@@laf.modal.newStatement.newClaim">New claim</span>
								</h5>
								<button
									type="button"
									#closeButton
									class="close"
									data-dismiss="modal"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div
								class="modal-body"
								[formGroup]="statementForm">
								<div class="row mb-3">
									<div class="col-6">
										<div class="input-group flex-nowrap">
											<div class="input-group-prepend custom-sm">
												<span class="input-group-text font-small py-0">
													<span i18n="laf|Statement tabs@@laf.modal.newStatement.airport"> Airport </span>&nbsp;
													<span class="required-asterisk">*</span>
												</span>
											</div>
											<ng-select
												#newClaimAirport
												class="w-100 custom-sm bg-white"
												[items]="userReferences.airports.data"
												bindLabel="iata"
												bindValue="id"
												[selectOnTab]="true"
												[virtualScroll]="true"
												[loading]="userReferences.airports.loading"
												(scroll)="onScrollNgSelect($event, 'airports')"
												(scrollToEnd)="onScrollToEndNgSelect('airports')"
												[searchFn]="customSelectSearch"
												(close)="newClaimAirline.focus()"
												formControlName="airportId">
												<ng-template
													ng-option-tmp
													let-item="item">
													<div class="font-small">
														<span class="font-weight-bold">{{ item.iata }}</span
														>&nbsp;{{ item.name }}
													</div>
												</ng-template>
											</ng-select>
										</div>
									</div>
									<div class="col-6">
										<div class="input-group flex-nowrap">
											<div class="input-group-prepend custom-sm">
												<span
													class="input-group-text font-small py-0"
													i18n="laf|Statement tabs@@laf.modal.newStatement.airline">
													Airline
												</span>
											</div>
											<ng-select
												#newClaimAirline
												class="w-100 custom-sm bg-white"
												[items]="userReferences.airlines.data"
												bindLabel="iata"
												bindValue="id"
												[selectOnTab]="true"
												[virtualScroll]="true"
												[loading]="userReferences.airlines.loading"
												(scroll)="onScrollNgSelect($event, 'airlines')"
												(scrollToEnd)="onScrollToEndNgSelect('airlines')"
												[searchFn]="customSelectSearch"
												(close)="newClaimReason.focus()"
												formControlName="airlineId">
												<ng-template
													ng-option-tmp
													let-item="item">
													<div class="font-small">
														<span class="font-weight-bold">{{ item.iata }}</span
														>&nbsp;{{ item.name }}
													</div>
												</ng-template>
											</ng-select>
										</div>
									</div>
								</div>
								<div class="input-group flex-nowrap mb-3">
									<div class="input-group-prepend custom-sm">
										<span
											class="input-group-text font-small py-0"
											i18n="laf|Statement tabs@@laf.modal.newStatement.reason"
											>Reason</span
										>
									</div>
									<ng-select
										#newClaimReason
										class="w-100 custom-sm bg-white"
										bindValue="id"
										[bindLabel]="language == 'EN' ? 'nameEnglish' : 'nameLocal'"
										[selectOnTab]="true"
										[loading]="references.statement_types.loading"
										[items]="references.statement_types.data"
										(close)="newClaimPnr.focus()"
										formControlName="typeId">
									</ng-select>
								</div>
								<p
									class="font-weight-bold text-blue mt-3"
									i18n="laf|Statement tabs@@laf.modal.newStatement.details">
									Details
								</p>
								<div class="row">
									<div
										class="col-6"
										formArrayName="passenger">
										<div class="input-group flex-nowrap">
											<div class="input-group-prepend custom-sm">
												<span
													class="input-group-text font-small py-0"
													i18n="laf|Statement tabs@@laf.modal.newStatement.pnr"
													>PNR</span
												>
											</div>
											<input
												#newClaimPnr
												type="text"
												class="w-100 form-control custom-sm"
												appUpperCase
												formControlName="pnr" />
										</div>
									</div>
									<ng-container formArrayName="baggage">
										<div
											class="col-6 mb-3 d-flex"
											*ngFor="let baggageItem of statementFormBaggages.controls; let i_baggage = index"
											[formGroupName]="i_baggage">
											<div class="input-group flex-nowrap">
												<div class="input-group-prepend custom-sm">
													<span
														class="input-group-text font-small py-0"
														i18n="laf|Statement tabs@@laf.modal.newStatement.baggageTag"
														>Baggage tag</span
													>
												</div>
												<input
													type="text"
													class="w-100 form-control custom-sm"
													formControlName="tag"
													appUpperCase />
											</div>
											<button
												type="button"
												class="btn btn-ico btn-light-gray btn-xs ml-2"
												*ngIf="statementFormBaggages.controls.length - 1 === i_baggage"
												style="height: 29px"
												(click)="addBaggageToStatement()">
												<span class="material-icons">add</span>
											</button>
										</div>
									</ng-container>
									<div class="col"></div>
								</div>
							</div>
							<div class="modal-footer">
								<button
									type="button"
									*ngIf="!statement.id"
									class="btn btn-sm btn-blue"
									(click)="addStatement()"
									[disabled]="appendingLoader">
									<span
										class="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
										*ngIf="appendingLoader"></span>
									<span
										i18n="laf|Baggage modal@@laf.modal.newStatement.button.append"
										*ngIf="!appendingLoader"
										>Append</span
									>
									<span
										i18n="laf|Baggage modal@@laf.modal.newStatement.button.appending"
										*ngIf="appendingLoader"
										>Appending...</span
									>
								</button>
								<button
									type="button"
									class="btn btn-sm btn-gray-blue-dark"
									data-dismiss="modal"
									i18n="laf|Baggage modal@@laf.modal.newStatement.button.close">
									Close
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<app-modal-message></app-modal-message>
	</nz-content>
	<!-- <nz-footer></nz-footer> -->
</nz-layout>
