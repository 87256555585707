import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '@core/services/settings/settings.service';

@Component({
	selector: 'mfp-master-data',
	templateUrl: './master-data.component.html',
	styleUrls: ['./master-data.component.less'],
})
export class MasterDataComponent implements OnInit {
	constructor(
		private settingsService: SettingsService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) {}

	selectedTab: number;

	get language() {
		return this.settingsService.language;
	}

	ngOnInit(): void {
		if (this.activatedRoute.snapshot.firstChild) {
			// Получаем сегмент пути текущей страницы
			const currentUrlSegment = this.activatedRoute.snapshot.firstChild.url.toString();
			const currentTab = this.LIST_OF_REFERENCES.findIndex(
				// Ищем соответствующую вкладку
				tab => tab.slug === currentUrlSegment
			);
			// Установка активной вкладки
			this.selectedTab = currentTab !== -1 ? currentTab : 0;
		} else {
			this.selectedTab = 0;
			this.router.navigate(['/master-data/tails']);
		}
	}

	LIST_OF_REFERENCES = [
		{ nameRu: 'Рег. номера ВС', nameEn: 'Tails', slug: 'tails' },
		{ nameRu: 'Воздушные суда', nameEn: 'A/C Types', slug: 'aircraft-types' },
		{
			nameRu: 'Тип воздушного судна',
			nameEn: 'A/C Kinds',
			slug: 'aircraft-kinds',
		},
		{
			nameRu: 'Класс воздушного судна',
			nameEn: 'A/C Classes',
			slug: 'aircraft-classes',
		},
		{ nameRu: 'Авиакомпании', nameEn: 'Airlines', slug: 'airlines' },
		{ nameRu: 'Аэропорты', nameEn: 'Airports', slug: 'airports' },
		{ nameRu: 'Города', nameEn: 'Cities', slug: 'cities' },
		{ nameRu: 'Страны', nameEn: 'Countries', slug: 'countries' },
		{ nameRu: 'Регионы', nameEn: 'Regions', slug: 'regions' },
		{ nameRu: 'Причины задержки', nameEn: 'Delays', slug: 'delays' },
		{
			nameRu: 'Источники задержки',
			nameEn: 'Delay sources',
			slug: 'delay-sources',
		},
		{ nameRu: 'Типы движения', nameEn: 'Movements', slug: 'movement-types' },
		{ nameRu: 'Регулярность', nameEn: 'Regularities', slug: 'regularities' },
		{ nameRu: 'Сезоны', nameEn: 'Seasons', slug: 'seasons' },
		{ nameRu: 'Стоянки', nameEn: 'Stands', slug: 'stands' },
		{ nameRu: 'Типы стоянок', nameEn: 'Stand Types', slug: 'stand-types' },
		{ nameRu: 'Терминалы', nameEn: 'Terminals', slug: 'terminals' },
	];
}
