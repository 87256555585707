/**
 * Класс данных о рейсе, в нем содержится урезанная информация для
 * отрисовки в табличном виде списка рейсов, отдельно прилет и вылет
 */
export class FlightListItem {
  constructor() {
    this.arrDep = '';
    this.flight = '';
    this.movementType = '';
    this.geoType = '';
    this.aircraftType = '';
    this.tail = '';
    this.times = [];
    this.crew = [];
    this.pax = [0, 0, 0];
    this.class = [0, 0];
    this.baggage = [0, 0, 0];
    this.cargo = 0;
    this.mail = 0;
    this.stand = '';
    this.status = 0;
    this.statusName = '';
    this.nextFlightId = 0;
    this.belts = '';
    this.baggageTimes = [];
    this.baggageStatus = 0;
    this.counters = '';
    this.checkinTimes = [];
    this.checkinStatus = 0;
    this.gates = '';
    this.boardingTimes = [];
    this.boardingStatus = 0;

    this.statusArrival = '';
    this.statusDeparture = '';
    this.statusComplated = '0';
    this.statusActual = '0';
    this.statusExpected = '0';
    this.gate = '';
    this.statusTimeArrive = '';
    this.statusTimeDeparture = '';
    this.statusPlace = '';
    this.handicapped = ['0', '0'];
    this.noArrivalFlight = '';
    this.noDepartureFlight = '';
  }

  id: number;
  arrDep: string;
  flight: string;
  airline: string;
  movementType: string;
  geoType: string;
  aircraftType: string;
  tail: string;
  route: {
    iata: string,
    icao: string,
    name: string
  };
  times: Array<Date>;
  crew: Array<number>;
  pax: Array<number>;
  class: Array<number>;
  baggage: Array<number>;
  cargo: number;
  mail: number;
  stand: string;
  status: number;
  statusName: string;
  dtBlock: Date;
  nextFlightId: number;
  belts: string;
  baggageTimes: Array<Date>;
  baggageStatus: number;
  counters: string;
  checkinTimes: Array<Date>;
  checkinStatus: number;
  gates: string;
  boardingTimes: Array<Date>;
  boardingStatus: number;

  statusArrival: string;
  statusDeparture: string;
  statusComplated: string;
  statusActual: string;
  statusExpected: string;
  gate: string;
  statusTimeArrive: string;
  statusTimeDeparture: string;
  statusPlace: string;
  handicapped: Array<string>;
  noArrivalFlight: string;
  noDepartureFlight: string;
  terminal: string;

  /**
   * Функция проверки даты, так как она может быть пустой или равна
   * "0001-01-01T00:00:00Z"
   * @param {Date} value Дата для проверки
   * @return {boolean} Истина если дата коректная, иначе ложь
   */
  private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  // Функция получения Планового время прибытия рейса
  get scheduleDatetime(): Date {
    if (this.isDateNull(this.times[0])) {
      return new Date(this.times[0]);
    } else {
      return null;
    }
  }

  // Функция получения Ожидаемого время прибытия рейса
  get estimatedDatetime(): Date {
    if (this.isDateNull(this.times[1])) {
      return new Date(this.times[1]);
    } else {
      return null;
    }
  }

  // Функция получения Времени касания для прилетного рейса или
  // Времени взлета для вылетного рейса
  get touchDownOrTakeOffDatetime(): Date {
    if (this.isDateNull(this.times[2])) {
      return new Date(this.times[2]);
    } else {
      return null;
    }
  }

  // Функция получения Времени установки тормозных колодок или
  // Времени съема тормозных колодок
  get blockOnOrBockOffDatetime(): Date {
    if (this.isDateNull(this.dtBlock)) {
      return new Date(this.dtBlock);
    } else {
      return null;
    }
  }

  // Функция получения Времени начала выдачи багажа
  get baggageClaimStart(): Date {
    if (this.isDateNull(this.baggageTimes[0])) {
      return new Date(this.baggageTimes[0]);
    } else {
      return null;
    }
  }

  // Функция получения Времени окончания выдачи багажа
  get baggageClaimFinish(): Date {
    if (this.isDateNull(this.baggageTimes[1])) {
      return new Date(this.baggageTimes[1]);
    } else {
      return null;
    }
  }

  // Функция получения Времени начала регистрации
  get checkinStart(): Date {
    if (this.isDateNull(this.checkinTimes[0])) {
      return new Date(this.checkinTimes[0]);
    } else {
      return null;
    }
  }

  // Функция получения Времени окончания регистрации
  get checkinFinish(): Date {
    if (this.isDateNull(this.checkinTimes[1])) {
      return new Date(this.checkinTimes[1]);
    } else {
      return null;
    }
  }

  // Функция получения Времени начала посадки
  get boardingStart(): Date {
    if (this.isDateNull(this.boardingTimes[0])) {
      return new Date(this.boardingTimes[0]);
    } else {
      return null;
    }
  }

  // Функция получения Времени окончания посадки
  get boardingFinish(): Date {
    if (this.isDateNull(this.boardingTimes[1])) {
      return new Date(this.boardingTimes[1]);
    } else {
      return null;
    }
  }
}

/**
 * Класс для хранения данных по прилетным и вылетным рейсам,
 * необходимым для отрисовки в табличном виде списка рейсов в модуле
 */
export class Flights {
  arrival: Array<FlightListItem> = [];
  departure: Array<FlightListItem> = [];
}

/**
 * Класс для хранения данных по маршруту движения воздушного судна
 */
export class Route {
  constructor() {
    this.orderNumber = undefined;
    this.airportId = undefined;
    this.airportIata = '';
    this.isReserve = false;
    this.delayId = 0;
    this.dtArrival = [null, null, null];
    this.dtDeparture = [null, null, null];
    this.adult = [null, null, null, null];
    this.child = [null, null, null, null];
    this.infant = [null, null, null, null];
    this.business = [null, null, null, null];
    this.economy = [null, null, null, null];
    this.baggageWeight = [null, null, null, null];
    this.baggageUnit = [null, null, null, null];
    this.baggagePayed = [null, null, null, null];
    this.cargo = [null, null, null, null];
    this.mail = [null, null, null, null];
  }

  orderNumber: number;
  airportId: number;
  airportIata: string;
  isReserve: boolean;
  delayId: number;
  dtArrival: Array<Date>;
  dtDeparture: Array<Date>;
  adult: Array<number>;
  child: Array<number>;
  infant: Array<number>;
  business: Array<number>;
  economy: Array<number>;
  baggageWeight: Array<number>;
  baggageUnit: Array<number>;
  baggagePayed: Array<number>;
  cargo: Array<number>;
  mail: Array<number>;

  private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  get dtArrivalScheduled(): Date {
    if (this.isDateNull(this.dtArrival[0])) {
      return this.dtArrival[0];
    } else {
      return null;
    }
  }

  set dtArrivalScheduled(value: Date) {
    this.dtArrival[0] = value;
  }

  get dtArrivalEstimated(): Date {
    if (this.isDateNull(this.dtArrival[1])) {
      return this.dtArrival[1];
    } else {
      return null;
    }
  }

  set dtArrivalEstimated(value: Date) {
    this.dtArrival[1] = value;
  }

  get dtArrivalFact(): Date {
    if (this.isDateNull(this.dtArrival[2])) {
      return this.dtArrival[2];
    } else {
      return null;
    }
  }

  set dtArrivalFact(value: Date) {
    this.dtArrival[2] = value;
  }

  get dtDepartureScheduled(): Date {
    if (this.isDateNull(this.dtDeparture[0])) {
      return this.dtDeparture[0];
    } else {
      return null;
    }
  }

  set dtDepartureScheduled(value: Date) {
    this.dtDeparture[0] = value;
  }

  get dtDepartureEstimated(): Date {
    if (this.isDateNull(this.dtDeparture[1])) {
      return this.dtDeparture[1];
    } else {
      return null;
    }
  }

  set dtDepartureEstimated(value: Date) {
    this.dtDeparture[1] = value;
  }

  get dtDepartureFact(): Date {
    if (this.isDateNull(this.dtDeparture[2])) {
      return this.dtDeparture[2];
    } else {
      return null;
    }
  }

  set dtDepartureFact(value: Date) {
    this.dtDeparture[2] = value;
  }

}

/**
 * Класс для хранения данных по процессам рейса
 */
export class Processes {
  constructor() {
    this.arrival = new Process();
    this.baggage = new Process();
    this.checkin = new Process();
    this.boarding = new Process();
    this.departure = new Process();
  }
  arrival: Process;
  baggage: Process;
  checkin: Process;
  boarding: Process;
  departure: Process;
}

/**
 * Класс хранения данных о процессе
 */
export class Process {
  constructor() {
    this.start = null;
    this.finish = null;
    this.statusId = undefined;
    this.resources = [];
  }
  start: Date;
  finish: Date;
  statusId: number;
  resources: Array<ProcessesResources>;

  private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  get dtStart(): Date {
    if (this.isDateNull(this.start)) {
      return this.start;
    } else {
      return null;
    }
  }

  set dtStart(value: Date) {
    this.start = value;
  }

  get dtFinish(): Date {
    if (this.isDateNull(this.finish)) {
      return this.finish;
    } else {
      return null;
    }
  }

  set dtFinish(value: Date) {
    this.finish = value;
  }
}

/**
 * Класс хранения данных по ресурсам используемым для выполнения процесса
 */
export class ProcessesResources {
  constructor() {
    this.num = null;
    this.start = null;
    this.finish = null;
    this.fullTime = true;
    this.priority = false;
  }
  num: number;
  start: Date;
  finish: Date;
  priority: boolean;
  fullTime: boolean;

  private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  get dtStart(): Date {
    if (this.isDateNull(this.start)) {
      return this.start;
    } else {
      return null;
    }
  }

  set dtStart(value: Date) {
    this.start = value;
  }

  get dtFinish(): Date {
    if (this.isDateNull(this.finish)) {
      return this.finish;
    } else {
      return null;
    }
  }

  set dtFinish(value: Date) {
    this.finish = value;
  }
}

/**
 * Класс хранения детальных данных о рейсе для вывода в карточку рейса
 */
export class Flight {
  constructor(private homeAirport: number) {
    this.airlineId = undefined;
    this.airline = '';
    this.flight = '';
    this.movementTypeId = undefined;
    this.geoTypeIdA = undefined;
    this.geoTypeIdD = undefined;
    this.aircraftTypeIdA = undefined;
    this.aircraftTypeIdD = undefined;
    this.tailAId = 0;
    this.tailDId = 0;
    this.cancelled = [false, false];
    this.dtBlock = [null, null];
    this.deleted = false;
    this.flightOperator = [true, true];
    this.crew = [null, null];
    this.cateringWeight = 0;
    this.weapon = [false, false];
    this.atow = 0;
    this.mtow = 0;
    this.fuel = [null, null];
    this.route = [new Route(), new Route()];
    this.processes = new Processes();
    this.show = true;
    this.lmg = [null, null]
  }

  id: number;
  airlineId: number;
  airline: string;
  flight: string;
  movementTypeId: number;
  geoTypeIdA: number;
  geoTypeIdD: number;
  aircraftTypeIdA: number;
  aircraftTypeIdD: number;
  tailAId: number;
  tailDId: number;
  cancelled: Array<boolean>;
  dtBlock: Array<Date>;
  deleted: boolean;
  flightOperator: Array<boolean>;
  crew: Array<number>;
  cateringWeight: number;
  captain: string;
  weapon: Array<boolean>;
  atow: number;
  mtow: number;
  fuel: Array<number>;
  route: Array<Route>;
  processes: Processes;
  messages: Array<any>;
  nextFlightId: number;
  standAId: number;
  standDId: number;
  terminalAId: number;
  terminalAName: string;
  terminalDId: number;
  terminalDName: string;
  delaySourceId: number;
  delaySourceName: Array<string>;
  delaySourceOperatorId: number;
  delaySourceOperatorName: string;
  show: boolean;
  lmg: Array<number>;
  geoTypeA: string;
  geoTypeD: string;

  private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  get blockOn(): Date {
    if (this.isDateNull(this.dtBlock[0])) {
      return this.dtBlock[0];
    } else {
      return null;
    }
  }

  set blockOn(value: Date) {
    this.dtBlock[0] = value;
  }

  get blockOff(): Date {
    if (this.isDateNull(this.dtBlock[1])) {
      return this.dtBlock[1];
    } else {
      return null;
    }
  }

  set blockOff(value: Date) {
    this.dtBlock[1] = value;
  }

  public isDeparture(value: number) {
    let result = false;
    let flag = false;
    for (const route of this.route) {
      if (route.airportId === this.homeAirport) {
        flag = true;
      } else if (flag == true && route.airportId === value) {
        result = true;
      }
    }
    return result;
  }

  public isArrivial(value: number) {
    let result = false;
    let flag = false;
    let num = 0;
    let i = 0;
    for (const route of this.route) {
      if (route.airportId == value && route.airportId != this.homeAirport) {
        flag = true;
        num = i;
      } else if (flag == true && route.airportId == this.homeAirport && i == num + 1) {
        result = true;
      }
      i++;
    }
    return result;
  }

}

/**
 * Класс харнения данных о комбинированном рейсе для одного из видов отображения
 */
export class FlightCombined {
  idA: number;
  flightA: string;
  movementTypeA: string;
  geoTypeA: string;
  aircraftType: string;
  tail: string;
  //routeA: Array<string>;
  routeA: {
    iata: string,
    icao: string,
    name: string
  };
  timesA: Array<Date>;
  crewA: Array<number>;
  paxA: Array<number>;
  classA: Array<number>;
  baggageA: Array<number>;
  cargoA: number;
  mailA: number;
  standA: string;
  statusA: number;
  statusAName: string;
  dtBlockA: Date;
  idD: number;
  flightD: string;
  movementTypeD: string;
  geoTypeD: string;
  //routeD: Array<string>;
  routeD: {
    iata: string,
    icao: string,
    name: string
  };
  timesD: Array<Date>;
  crewD: Array<number>;
  paxD: Array<number>;
  classD: Array<number>;
  baggageD: Array<number>;
  cargoD: number;
  mailD: number;
  standD: string;
  statusD: number;
  statusDName: string;
  dtBlockD: Date;

  /**
   * Функция проверки даты, так как она может быть пустой или равна
   * "0001-01-01T00:00:00Z"
   * @param {Date} value Дата для проверки
   * @return {boolean} Истина если дата коректная, иначе ложь
   */
  private isDateNull(value: Date): boolean {
    if (!value || +value[0] < 2) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Функция получения Планового время прибытия рейса
   */
  get scheduleDateTimeArrival(): Date {
    if (this.timesA && this.timesA.length >= 1) {
      if (this.isDateNull(this.timesA[0])) {
        return new Date(this.timesA[0]);
      } else {
        return null;
      }
    }
  }

  /**
   * Функция получения Планового время отправления рейса
   */
  get scheduleDateTimeDeparture(): Date {
    if (this.timesD && this.timesD.length >= 1) {
      if (this.isDateNull(this.timesD[0])) {
        return new Date(this.timesD[0]);
      } else {
        return null;
      }
    }
  }

  /**
   * Функция получения Ожидаемого время прибытия рейса
   */
  get estimatedDateTimeArrival(): Date {
    if (this.timesA && this.timesA.length >= 2) {
      if (this.isDateNull(this.timesA[1])) {
        return new Date(this.timesA[1]);
      } else {
        return null;
      }
    }
  }

  /**
   * Функция получения Ожидаемого время отправления рейса
   */
  get estimatedDateTimeDeparture(): Date {
    if (this.timesD && this.timesD.length >= 2) {
      if (this.isDateNull(this.timesD[1])) {
        return new Date(this.timesD[1]);
      } else {
        return null;
      }
    }
  }

  // Функция получения Времени касания
  get touchDownDateTime(): Date {
    if (this.timesA && this.timesA.length >= 3) {
      if (this.timesA[2] && this.isDateNull(this.timesA[2])) {
        return new Date(this.timesA[2]);
      } else {
        return null;
      }
    }
  }

  // Функция получения Времени установки тормозных колодок
  get blockOnDateTime(): Date {
    if (this.isDateNull(this.dtBlockA)) {
      return new Date(this.dtBlockA);
    } else {
      return null;
    }
  }

  // Функция получения Времени взлета
  get takeOffDateTime(): Date {
    if (this.timesD && this.timesD.length >= 3) {
      if (this.isDateNull(this.timesD[2])) {
        return new Date(this.timesD[2]);
      } else {
        return null;
      }
    }
  }

  // Функция получения Времени снятия тормозных колодок
  get blockOffDateTime(): Date {
    if (this.isDateNull(this.dtBlockD)) {
      return new Date(this.dtBlockD);
    } else {
      return null;
    }
  }
}

/**
 * Статусы процессов для прилетных и вылетных рейсов при регистрации,
 * посадке, вылету, приелту и прочие
 */
export class ReferanceStatuses {
  id: number;
  name: string;

  getTitle(language: string): string {
    if ((this.name) && (this.name.length >= 1)) {
        if (language == 'EN') {
            return this.name[0];
        } else if (language == 'RU') {
            return this.name[1];
        }
    } else {
        return null;
    }
  }

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }

  // Функция получения имен в строку
  /*get names(): string {
    if (this.name[0] && this.name[1]) {
      return this.name[0] + ', ' + this.name[1];
    } else {
      return null;
    }
  }*/
}

export class ReferanceDelaySourceOperator {
  id: number;
  name: string;
  delaySourceId: number;
  delaySourceName: Array<string>;
  dtRange: Array<Date>;
}

export class FilterParams {
  start: Date;
  finish: Date;
  flight: string;
  airport: number;
  movement: number;
  airline: number;
  aircraft: number;
  tail: string;
  geo: number;
  terminal: number;
  stand: number;
  delay: number;
}

export class ReferanceGeo {
  constructor() {
    this.id = undefined;
    this.name = ['', '']
  }

  id: number;
  dtRange: Array<Date>;
  lastupdate: Date;
  name: Array<string>;

  get nameEnglish(): string {
    if ((this.name) && (this.name.length >= 1)) {
      return this.name[0];
    } else {
      return null;
    }
  }

  get nameLocal(): string {
    if ((this.name) && (this.name.length === 2)) {
      return this.name[1];
    } else {
      return null;
    }
  }
}
