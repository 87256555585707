import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';

export interface TimeZoneItem {
	offset: string;
	name: string;
}
export class TimeZone {
	// Список часовых поясов
	list$ = new BehaviorSubject<TimeZoneItem[]>(null);

	constructor() {
		// Получение списка часовых поясов
		const zoneNames = moment.tz.names();
		const arr = [];
		for (const item in zoneNames) {
			arr.push({
				offset: moment.tz(zoneNames[item]).format('Z'),
				name: zoneNames[item],
			});
		}
		this.list$.next(arr);
	}

	/**
	 * Функция возвращает предполагаемый часовой пояс пользователя.
	 * Часовой пояс определяется из API браузера.
	 */
	getUserTimeZone() {
		return moment.tz.guess(true);
	}

	getUserTimeZoneOffset() {
		return moment.tz(moment.tz.guess(true)).format('Z');
	}
}
