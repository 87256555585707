import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TimeFormat } from '@shared/models/time-format';
import { SettingsService } from '@core/services/settings/settings.service';

@Pipe({ standalone: true, name: 'dateFormat' })
export class DateFormat implements PipeTransform {
	constructor(private settingsService: SettingsService) {}

	transform(value: string | Date, dateFormat: string, timeZone: TimeFormat): any {
		return value
			? moment(value)
					.tz(timeZone === TimeFormat.Z ? 'GMT' : this.settingsService.user.timeZone$.getValue())
					.format(dateFormat)
			: null;
	}
}
