import { ApplicationConfig } from '../settings';

/**
 * Описание объекта рабочего стола для рейсов
 */
export interface Workspace {
	id: string; // Идентификатор
	name: string; // Наименование
	rule: string; // Права доступа
}

export class WeightBalanceSettings {
	// Список рабочих столов
	private _workspaces: Workspace[];
	private defaultConfig: ApplicationConfig = null;

	/**
	 * Функция устанавливает значение рабочих столов путем
	 * преобразования массива строк в массив рабочих столов.
	 * @param {string[]} value - Массив строк, представляющих рабочие столы.
	 */
	setWorkspaces(value: string[]) {
		this._workspaces = this.convertStringArrayToWorkspaceArray(value);
	}

	/**
	 * Функция возвращает массив объектов Workspace.
	 */
	getWorkspaces(): Workspace[] {
		return this._workspaces;
	}

	/**
	 * Функция преобразует массив строк в массив объектов рабочих столов.
	 * @param stringArray - Массив строк. Каждая строка представляет рабочий стол
	 * и его свойства, разделенные знаком «##». Свойства каждого стола
	 * разделяются знаком «;;;».
	 * @returns Функция возвращает массив объектов Workspace.
	 */
	private convertStringArrayToWorkspaceArray(stringArray: Array<string>) {
		if (stringArray && stringArray.length > 0) {
			const workspaceArray: Workspace[] = [];
			stringArray.forEach(item => {
				item.split('##').forEach(folder => {
					const workspaceParam = folder.split(';;;');
					workspaceArray.push({
						id: workspaceParam[0],
						name: workspaceParam[1],
						rule: workspaceParam[2],
					});
				});
			});
			return workspaceArray;
		} else {
			return [];
		}
	}

	defaultSettings() {
		return {
			displayedPeriod: 24,
			linearCenteringGraphicsType: 'takeoff',
		};
	}

	setDefaultConfig(config: ApplicationConfig) {
		this.defaultConfig = config;
	}
}
