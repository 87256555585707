import { createReducer, on } from '@ngrx/store';
import { SettingsActions } from './settings.actions';

export const settingsFeatureKey = 'settings';

export interface State {}

export const initialState: State = {};

export const reducer = createReducer(initialState);
