<ul class="nav nav-tabs">
  <li class="nav-item">
    <a id="tab-playlists" [ngClass]="{'active': showTab('tab-playlists')}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Modal Informing module tab - playlists@@adm.infMod.tab.playlists">
      Playlists
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-medias" [ngClass]="{'active': showTab('tab-medias')}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Modal Informing module tab - medias@@adm.infMod.tab.medias">
      Medias
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-clients" [ngClass]="{'active': showTab('tab-clients')}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Modal Informing module tab - clients@@adm.infMod.tab.clients">
      Clients
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-specifications" [ngClass]="{'active': showTab('tab-specifications')}" class="nav-link"
      (click)="changeTab($event)"
      i18n="adm|Modal Informing module tab - specifications@@adm.infMod.tab.specifications">
      Specifications
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-airlines" [ngClass]="{'active': showTab('tab-airlines')}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Modal Informing module tab - airlines@@adm.infMod.tab.airlines">
      Airlines
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-airports" [ngClass]="{'active': showTab('tab-airports')}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Modal Informing module tab - airports@@adm.infMod.tab.airports">
      Airports
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-statuses" [ngClass]="{'active': showTab('tab-statuses')}" class="nav-link" (click)="changeTab($event)"
      i18n="adm|Modal Informing module tab - statuses@@adm.infMod.tab.statuses">
      Statuses
    </a>
  </li>
</ul>

<!-- Tab Playlists -->
<div *ngIf="showTab('tab-playlists')" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button"
          class="btn btn-sm btn-light-gray mr-1"
          data-toggle="modal"
          data-target=".bd-example-modal-xl"
          (click)="createPlaylist()">
          <i class="material-icons">add</i>
          <span i18n="adm|Informing module tab playlists - button new@@adm.infMod.tab.playlists.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
          i18n="adm|Informing module tab playlists - table data column name@@adm.infMod.tab.playlists.table.column.name">Name</th>
        <th class="text-center text-uppercase"
          i18n="adm|Informing module tab playlists - table data column dtRange@@adm.infMod.tab.playlists.table.column.dtRange">Date Range</th>
        <th class="text-center text-uppercase"
          i18n="adm|Informing module tab playlists - table data column media names@@adm.infMod.tab.playlists.table.column.mediaNames">Media Names</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="3" class="p-5">
          <div class="loading">
            <div class="cssload-spin-box"></div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let playlist of playlists" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadPlaylist(playlist.id)">
        <td>{{ playlist.name }}</td>
        <td>{{ playlist.dtRangeStart | date: 'dd.MM.yyyy' }} - {{ playlist.dtRangeFinish | date: 'dd.MM.yyyy' }}</td>
        <td>{{ playlist.mediaNames }}</td>
      </tr>
    </tbody>
  </table>
</div>
<!-- Tab Medias -->
<div *ngIf="showTab('tab-medias')" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button"
          class="btn btn-sm btn-light-gray mr-1"
          data-toggle="modal"
          data-target=".bd-example-modal-xl"
          (click)="createMedia()">
          <i class="material-icons">add</i>
          <span i18n="adm|Informing module tab medias - button new@@adm.infMod.tab.medias.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
          i18n="adm|Informing module tab medias - table data column name@@adm.infMod.tab.medias.table.column.name">Name</th>
        <th class="text-center text-uppercase"
          i18n="adm|Informing module tab medias - table data column duration@@adm.infMod.tab.medias.table.column.duration">Duration (seconds)</th>
        <th class="text-center text-uppercase"
          i18n="adm|Informing module tab medias - table data column dtRange@@adm.infMod.tab.medias.table.column.dtRange">Date Range</th>
        <th class="text-uppercase"
          i18n="adm|Informing module tab medias - table data column last update@@adm.infMod.tab.medias.table.column.lastUpdate">Last update</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="4" class="p-5">
          <div class="loading">
            <div class="cssload-spin-box"></div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let media of medias" data-toggle="modal" data-target=".bd-example-modal-xl" (click)="loadMedia(media.id)">
        <td>{{ media.name }}</td>
        <td>{{ media.duration }}</td>
        <td>{{ media.dtRange[0] | date: 'dd.MM.yyyy' }} - {{ media.dtRange[1] | date: 'dd.MM.yyyy' }}</td>
        <td>{{ media.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Tab Clients -->
<div *ngIf="showTab('tab-clients')" class="bg-white p-3">
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase">ID</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab clients - table data column description@@adm.infMod.tab.clients.table.column.description">Description</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab clients - table data column specification@@adm.infMod.tab.clients.table.column.specification">Specification</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab clients - table data column template@@adm.infMod.tab.clients.table.column.template">Template</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab clients - table data column num@@adm.infMod.tab.clients.table.column.num">Num</th>
        <th class="text-center text-uppercase"
          i18n="adm|Informing module tab clients - table data column start record@@adm.infMod.tab.clients.table.column.startRecord">Start Record</th>
        <th class="text-uppercase"
        i18n="adm|Informing module tab clients - table data column last update@@adm.infMod.tab.clients.table.column.lastUpdate">Last update</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="7" class="p-5">
          <div class="loading">
            <div class="cssload-spin-box"></div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let client of clients" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadClient(client.id)">
        <td>{{ client.id }}</td>
        <td>{{ client.description }}</td>
        <td>{{ client.specificationName }}</td>
        <td>{{ client.templateName }}</td>
        <td>{{ client.num }}</td>
        <td>{{ client.startRecord }}</td>
        <td>{{ client.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Tab Specifications -->
<div *ngIf="showTab('tab-specifications')" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button" class="btn btn-sm btn-light-gray mr-1" data-toggle="modal" data-target=".bd-example-modal-xl"
          (click)="createSpecification()">
          <i class="material-icons">add</i>
          <span i18n="adm|Informing module tab specifications - button new@@adm.infMod.tab.specifications.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab specifications - table data column name@@adm.infMod.tab.specifications.table.column.name">Name</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab specifications - table data column description@@adm.infMod.tab.specifications.table.column.description">Description</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab specifications - table data column geo type@@adm.infMod.tab.specifications.table.column.geoType">Geo Type</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab specifications - table data column client type@@adm.infMod.tab.specifications.table.column.clientType">Client Type</th>
        <th class="text-uppercase"
        i18n="adm|Informing module tab specifications - table data column last update@@adm.infMod.tab.specifications.table.column.lastUpdate">Last update</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="5" class="p-5">
          <div class="loading">
            <div class="cssload-spin-box"></div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let specification of specifications" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadSpecification(specification.id)">
        <td>{{ specification.name }}</td>
        <td>{{ specification.description }}</td>
        <td>{{language==='EN' || specification.geoTypeLocal==='' ? specification.geoTypeEnglish : specification.geoTypeLocal}}</td>
        <td>{{language==='EN' || specification.clientTypeLocal==='' ? specification.clientTypeEnglish : specification.clientTypeLocal}}</td>
        <td>{{ specification.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Tab Airlines -->
<div *ngIf="showTab('tab-airlines')" class="bg-white p-3">
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airlines - table data column iata@@adm.infMod.tab.airlines.table.column.iata">Iata</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airlines - table data column icao@@adm.infMod.tab.airlines.table.column.icao">Icao</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airlines - table data column code@@adm.infMod.tab.airlines.table.column.code">Code</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airlines - table data column name@@adm.infMod.tab.airlines.table.column.name">Name</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airlines - table data column branding@@adm.infMod.tab.airlines.table.column.branding">Branding</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airlines - table data column dtRange@@adm.infMod.tab.airlines.table.column.dtRange">Date Range</th>
        <th class="text-uppercase"
        i18n="adm|Informing module tab airlines - table data column last update@@adm.infMod.tab.airlines.table.column.lastUpdate">Last update</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="7" class="p-5">
          <div class="loading">
            <div class="cssload-spin-box"></div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let airline of informationAirlines" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadInformationAirline(airline.id)">
        <td>{{ airline.iata }}</td>
        <td>{{ airline.icao }}</td>
        <td>{{ airline.code.join('/') }}</td>
        <td>{{ airline.name.join('/') }}</td>
        <td>{{ airline.branding }}</td>
        <td>{{ airline.dtRange[0] | date: 'dd.MM.yyyy' }} - {{ airline.dtRange[1] | date: 'dd.MM.yyyy' }}</td>
        <td>{{ airline.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Tab Airports -->
<div *ngIf="showTab('tab-airports')" class="bg-white p-3">
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airports - table data column iata@@adm.infMod.tab.airports.table.column.iata">Iata</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airports - table data column icao@@adm.infMod.tab.airports.table.column.icao">Icao</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airports - table data column code@@adm.infMod.tab.airports.table.column.code">Code</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airports - table data column name@@adm.infMod.tab.airports.table.column.name">Name</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airports - table data column md name@@adm.infMod.tab.airports.table.column.mdName">Md Name</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab airports - table data column dtRange@@adm.infMod.tab.airports.table.column.dtRange">Date Range</th>
        <th class="text-uppercase"
        i18n="adm|Informing module tab airports - table data column last update@@adm.infMod.tab.airports.table.column.lastUpdate">Last update</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="7" class="p-5">
          <div class="loading">
            <div class="cssload-spin-box"></div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let airport of informationAirports" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadInformationAirport(airport.id)">
        <td>{{ airport.iata }}</td>
        <td>{{ airport.icao }}</td>
        <td>{{ airport.code.join('/') }}</td>
        <td>{{ airport.name.join('/') }}</td>
        <td>{{ airport.mdName.join('/') }}</td>
        <td>{{ airport.dtRange[0] | date: 'dd.MM.yyyy' }} - {{ airport.dtRange[1] | date: 'dd.MM.yyyy' }}</td>
        <td>{{ airport.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Tab Statuses -->
<div *ngIf="showTab('tab-statuses')" class="bg-white p-3">
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab statuses - table data column process name@@adm.infMod.tab.statuses.table.column.processName">Process Name</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab statuses - table data column name@@adm.infMod.tab.statuses.table.column.name">Name</th>
        <th class="text-center text-uppercase"
        i18n="adm|Informing module tab statuses - table data column operational name@@adm.infMod.tab.statuses.table.column.operationalName">Operational Name</th>
        <th class="text-uppercase"
        i18n="adm|Informing module tab statuses - table data column last update@@adm.infMod.tab.statuses.table.column.lastUpdate">Last update</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="4" class="p-5">
          <div class="loading">
            <div class="cssload-spin-box"></div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let status of informationStatuses" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadInformationStatus(status.id)">
        <td>{{ status.processName.join('/') }}</td>
        <td>{{ status.name.join('/') }}</td>
        <td>{{ status.operationalName.join('/') }}</td>
        <td>{{ status.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Modal Playlist -->
<div *ngIf="showTab('tab-playlists')">
  <form #playlistForm="ngForm">
    <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-append'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module playlists - header for append mode@@adm.infMod.modal.playlists.headerAppend">Playlist append</span>&nbsp;{{playlist.name}}
            </h5>
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module playlist - header for append mode@@adm.infMod.modal.playlist.headerEdit">Edit playlist</span>&nbsp;{{playlist.name}}
            </h5>
            <button #modalAddPlaylist type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-12">
                <label i18n="adm|Modal Informing module playlist - field name@@adm.infMod.modal.playlist.field.name">Name</label>
                <input id="playlistName" type="text" class="form-control form-control-sm" name="playlistName"
                  [(ngModel)]="playlist.name">
              </div>
            </div>
            <div class="row mb-4">
              <div class=" col-6">
                <label i18n="adm|Modal Informing module playlist - field dtRange start@@adm.infMod.modal.playlist.field.dtRangeStart">Date range start</label>
                <input type="date" class="form-control" name="playlistStart"
                  [ngModel]="playlist.dtRangeStart | date: 'yyyy-MM-dd' : 'UTC+0'"
                  (blur)="playlist.dtRangeStart = parseDate($event.target.value)">
                <!--<div class="invalid-feedback d-block">
                  <p>Please enter date range start</p>
                </div>-->
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module playlist - field dtRange finish@@adm.infMod.modal.playlist.field.dtRangeFinish">Date range finish</label>
                <input type="date" class="form-control" name="playlistFinish"
                  [ngModel]="playlist.dtRangeFinish | date: 'yyyy-MM-dd' : 'UTC+0'"
                  (blur)="playlist.dtRangeFinish = parseDate($event.target.value)">
                <!--<div class="invalid-feedback d-block">
                  <p>Please enter date range finish</p>
                </div>-->
              </div>
            </div>
            <div class="card p-3">
              <p class="font-weight-bold text-blue"
                i18n="adm|Modal Informing module playlist - text playlist media@@adm.infMod.modal.playlist.text.media">
                Media for playback
              </p>
              <div class="row">
                <div class="col-4" *ngFor="let media of medias">
                  <label>
                    <input type="checkbox" name="media[]" id="{{ media.id }}" value="{{ media.id }}"
                      [checked]="playlist.media.includes(media.id)" (change)="updateArray(playlist.media, media.id)">
                    {{ media.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button" class="btn btn-sm btn-blue" (click)="addPlaylist()">
                <span i18n="adm|Modal Informing module playlist - append@@adm.infMod.modal.playlist.button.append">Append</span>
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button" class="btn btn-sm btn-blue" (click)="updatePlaylist(playlist.id)">
                <span i18n="adm|Modal Informing module playlist - save@@adm.infMod.modal.playlist.button.save">Save</span>
              </button>
            </div>
            <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal">
              <span i18n="adm|Modal Informing module playlist - close@@adm.infMod.modal.playlist.button.close">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal Media -->
<div *ngIf="showTab('tab-medias')">
  <form #mediaForm="ngForm">
    <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-append'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module medias - header for append mode@@adm.infMod.modal.medias.headerAppend">Media append</span>&nbsp;{{media.name}}
            </h5>
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module medias - header for edit mode@@adm.infMod.modal.medias.headerEdit">Edit media</span>&nbsp;{{media.name}}
            </h5>
            <button #modalAddMedia type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <label i18n="adm|Modal Informing module medias - field file@@adm.infMod.modal.medias.field.file">File</label>
                <video *ngIf="media.videoLink; else inputVideo" controls="controls" class="d-block">
                  <source src="{{apiInformationalUrl + media.videoLink}}">
                </video>
                <ng-template #inputVideo>
                  <input #fileInput type="file" (change)="onFileSelected($event)">
                </ng-template>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module medias - field name@@adm.infMod.modal.medias.field.name">Name</label>
                <input id="mediaName" type="text" class="form-control form-control-sm" name="mediaName"
                  [(ngModel)]="media.name" value="{{ media.name }}">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button" class="btn btn-sm btn-blue" (click)="addMedia()">
                <span i18n="adm|Modal Informing module medias - append@@adm.infMod.modal.medias.button.append">Append</span>
              </button>
            </div>
            <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal">
              <span i18n="adm|Modal Informing module medias - close@@adm.infMod.modal.medias.button.close">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal Clients -->
<div *ngIf="showTab('tab-clients')">
  <form #clientForm="ngForm">
    <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module clients - header for append mode@@adm.infMod.modal.clients.headerAppend">Edit client</span>
            </h5>
            <button #modalAddClient type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-12">
                <label i18n="adm|Modal Informing module clients - field description@@adm.infMod.modal.clients.field.description">Description</label>
                <input id="description" type="text" class="form-control form-control-sm" name="description"
                  [(ngModel)]="client.description" value="{{ client.description }}">
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module clients - field specification@@adm.infMod.modal.clients.field.specification">Specification</label>
                <select id="specificationId" class="form-control form-control-sm" name="specificationId"
                  [value]="client.specificationId" (change)="client.specificationId = +$event.target.value">
                  <option [value]="" disabled [selected]="client.specificationId==undefined"
                    i18n="adm|Modal Informing module - option select@@adm.infMod.modal.option.select">
                    Select...
                  </option>
                  <option *ngFor="let spec of specifications" [value]="spec.id"
                    [selected]="spec.id == client.specificationId">
                    {{ spec.name }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module clients - field template@@adm.infMod.modal.clients.field.template">Template</label>
                <select id="templateId" class="form-control form-control-sm" name="templateId"
                  [value]="client.templateId" (change)="client.templateId = +$event.target.value">
                  <option [value]="" disabled [selected]="client.templateId==undefined"
                    i18n="adm|Modal Informing module - option select@@adm.infMod.modal.option.select">
                    Select...
                  </option>
                  <option *ngFor="let template of informationTemplates" [value]="template.id"
                    [selected]="template.id == client.templateId">
                    {{ template.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label i18n="adm|Modal Informing module clients - field num@@adm.infMod.modal.clients.field.num">Num</label>
                <input id="num" type="number" class="form-control form-control-sm" name="num" [(ngModel)]="client.num"
                  value="{{ client.num }}">
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module clients - field start record@@adm.infMod.modal.clients.field.startRecord">Start Record</label>
                <input id="startRecord" type="number" class="form-control form-control-sm" name="startRecord"
                  [(ngModel)]="client.startRecord" value="{{ client.startRecord }}">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-update'">
              <button type="button" class="btn btn-sm btn-blue" (click)="updateClient(client.id)">
                <span i18n="adm|Modal Informing module clients - save@@adm.infMod.modal.clients.button.save">Save</span>
              </button>
            </div>
            <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal">
              <span i18n="adm|Modal Informing module clients - close@@adm.infMod.modal.clients.button.close">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal Specifications -->
<div *ngIf="showTab('tab-specifications')">
  <form #specificationForm="ngForm">
    <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module specifications - header for edit mode@@adm.infMod.modal.specifications.headerEdit">Edit client</span>
            </h5>
            <button #modalAddSpecification type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module specifications - field name@@adm.infMod.modal.specifications.field.name">Name</label>
                <input id="specificationName" type="text" class="form-control form-control-sm" name="specificationName"
                  [(ngModel)]="specification.name" value="{{ specification.name }}">
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module specifications - field description@@adm.infMod.modal.specifications.field.description">Description</label>
                <input id="description" type="text" class="form-control form-control-sm" name="description"
                  [(ngModel)]="specification.description" value="{{ specification.description }}">
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label i18n="adm|Modal Informing module specifications - field geo type@@adm.infMod.modal.specifications.field.geoType">Geo Type</label>
                <ng-select id="geoTypeId"
                           name="geoTypeId"
                           class="custom-sm p-0"
                           [items]="geoTypes"
                           [multiple]="false"
                           [hideSelected]="true"
                           bindValue="id"
                           [bindLabel]="language==='EN' ? 'nameEnglish' : 'nameLocal'"
                           [(ngModel)]="specification.geoTypeId">
                  <ng-template ng-option-tmp let-item="item">
                    {{language==='EN' || item.nameLocal==='' ? item.nameEnglish : item.nameLocal}}
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module specifications - field client type@@adm.infMod.modal.specifications.field.clientType">Client Type</label>
                <ng-select id="clientTypeId"
                           name="clientTypeId"
                           class="custom-sm p-0"
                           [items]="clientTypes"
                           [multiple]="false"
                           [hideSelected]="true"
                           bindValue="id"
                           [bindLabel]="language==='EN' ? 'nameEnglish' : 'nameLocal'"
                           [(ngModel)]="specification.clientTypeId">
                  <ng-template ng-option-tmp let-item="item">
                    {{language==='EN' || item.nameLocal==='' ? item.nameEnglish : item.nameLocal}}
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button" class="btn btn-sm btn-blue" (click)="addSpecification()">
                <span i18n="adm|Modal Informing module specifications - append@@adm.infMod.modal.specifications.button.append">Append</span>
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button" class="btn btn-sm btn-blue"
                (click)="updateSpecification(specification.id)">
                <span i18n="adm|Modal Informing module specifications - save@@adm.infMod.modal.specifications.button.save">Save</span>
              </button>
            </div>
            <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal">
              <span i18n="adm|Modal Informing module specifications - close@@adm.infMod.modal.specifications.button.close">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal Airlines -->
<div *ngIf="showTab('tab-airlines')">
  <form #airlineForm="ngForm">
    <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module airlines - header for edit mode@@adm.infMod.modal.airlines.headerEdit">Edit Airline</span>
            </h5>
            <button #modalAddAirline type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card p-3 mb-2">
              <div class="d-flex align-items-center">
                <input type="checkbox" name="branding" id="branding" class="mr-2"
                  [(ngModel)]="informationAirline.branding"> Branding
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <label class="card__header--important"
                  i18n="adm|Modal Informing module airlines - field logo@@adm.infMod.modal.airlines.field.logo">
                  Logo
                </label>
                <div class="card p-3 mb-2" *ngIf="informationAirline.logoLink">
                  <img src="{{ apiInformationalUrl + informationAirline.logoLink }}" alt="">
                </div>
                <input #logoInput type="file" (change)="logoSelected($event, 'logo')">
              </div>
              <div class="col-4">
                <label class="card__header--important"
                  i18n="adm|Modal Informing module airlines - field logo brand@@adm.infMod.modal.airlines.field.logoBrand">
                  Logo Brand
                </label>
                <div class="card p-3 mb-2" *ngIf="informationAirline.brandLink">
                  <img src="{{ apiInformationalUrl + informationAirline.brandLink }}" alt="">
                </div>
                <input #logoBrandInput type="file" (change)="logoSelected($event, 'logo_brand')">
              </div>
              <div class="col-4">
                <label class="card__header--important"
                  i18n="adm|Modal Informing module airlines - field logo brand business@@adm.infMod.modal.airlines.field.logoBrandBusiness">
                  Logo Brand Business
                </label>
                <div class="card p-3 mb-2" *ngIf="informationAirline.brandBusinessLink">
                  <img src="{{ apiInformationalUrl + informationAirline.brandBusinessLink }}" alt="">
                </div>
                <input #logoBrandBusinessInput type="file" (change)="logoSelected($event, 'logo_brand_business')">
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module airlines - field iata@@adm.infMod.modal.airlines.field.iata">IATA</label>
                <input id="informationAirlineIata" type="text" class="form-control form-control-sm"
                  name="informationAirlineIata" [(ngModel)]="informationAirline.iata"
                  value="{{ informationAirline.iata }}" disabled>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module airlines - field icao@@adm.infMod.modal.airlines.field.icao">ICAO</label>
                <input id="informationAirlineIcao" type="text" class="form-control form-control-sm"
                  name="informationAirlineIcao" [(ngModel)]="informationAirline.icao"
                  value="{{ informationAirline.icao }}" disabled>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module airlines - field name english@@adm.infMod.modal.airlines.field.nameEnglish">Name English</label>
                <input id="informationAirlineNameEnglish" type="text" class="form-control form-control-sm"
                  name="informationAirlineNameEnglish" [(ngModel)]="informationAirline.nameEnglish"
                  value="{{ informationAirline.nameEnglish }}" disabled>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module airlines - field name local@@adm.infMod.modal.airlines.field.nameLocal">Name Local</label>
                <input id="informationAirlineNameLocal" type="text" class="form-control form-control-sm"
                  name="informationAirlineNameLocal" [(ngModel)]="informationAirline.nameLocal"
                  value="{{ informationAirline.nameLocal }}" disabled>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="filter-date-from" class="mr-2 mb-0"
                  i18n="adm|Modal Informing module airlines - field date from@@adm.infMod.modal.airlines.field.dateFrom">
                  Date from
                </label>
                <input id="informationAirlineStart" type="text" class="form-control form-control-sm"
                  name="informationAirlineStart"
                  value="{{ informationAirline.dtRangeStart | date: 'dd.MM.yyyy HH:mm' }}" disabled>
              </div>
              <div class="col-6">
                <label for="filter-date-from" class="mr-2 mb-0"
                  i18n="adm|Modal Informing module airlines - field date to@@adm.infMod.modal.airlines.field.dateTo">
                  Date to
                </label>
                <input id="informationAirlineFinish" type="text" class="form-control form-control-sm"
                  name="informationAirlineFinish"
                  value="{{ informationAirline.dtRangeFinish | date: 'dd.MM.yyyy HH:mm' }}" disabled>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-update'">
              <button type="button" class="btn btn-sm btn-blue" (click)="updateInformationAirline(informationAirline.id)">
                <span i18n="adm|Modal Informing module airlines - save@@adm.infMod.modal.airlines.button.save">Save</span>
              </button>
            </div>
            <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal">
              <span i18n="adm|Modal Informing module airlines - close@@adm.infMod.modal.airlines.button.close">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal Airports -->
<div *ngIf="showTab('tab-airports')">
  <form #airportForm="ngForm">
    <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module airports - header for edit mode@@adm.infMod.modal.airports.headerEdit">Edit airport</span>
            </h5>
            <button #modalAddAirport type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module airports - field name english@@adm.infMod.modal.airports.field.nameEnglish">Name for information board english</label>
                <input id="informationAirportNameEnglish" type="text" class="form-control form-control-sm"
                  name="informationAirportNameEnglish" [(ngModel)]="informationAirport.nameEnglish">
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module airports - field name local@@adm.infMod.modal.airports.field.nameLocal">Name for information board local</label>
                <input id="informationAirportNameLocal" type="text" class="form-control form-control-sm"
                  name="informationAirportNameLocal" [(ngModel)]="informationAirport.nameLocal">
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module airports - field iata@@adm.infMod.modal.airports.field.iata">IATA</label>
                <input id="informationAirportIata" type="text" class="form-control form-control-sm"
                  name="informationAirportIata" [(ngModel)]="informationAirport.iata"
                  value="{{ informationAirport.iata }}" disabled>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module airports - field icao@@adm.infMod.modal.airports.field.icao">ICAO</label>
                <input id="informationAirportIcao" type="text" class="form-control form-control-sm"
                  name="informationAirportIcao" [(ngModel)]="informationAirport.icao"
                  value="{{ informationAirport.icao }}" disabled>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module airports - field md name english@@adm.infMod.modal.airports.field.mdNameEnglish">Defaul name english</label>
                <input id="informationAirportMDNameEnglish" type="text" class="form-control form-control-sm"
                  name="informationAirportMDNameEnglish" value="{{ informationAirport.nameMDEnglish }}" disabled>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module airports - field md name local@@adm.infMod.modal.airports.field.mdNameLocal">Defaul name local</label>
                <input id="informationAirportMDNameLocal" type="text" class="form-control form-control-sm"
                  name="informationAirportMDNameLocal" value="{{ informationAirport.nameMDLocal }}" disabled>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module airports - field code english@@adm.infMod.modal.airports.field.codeEnglish">Code English</label>
                <input id="informationAirportCodeEnglish" type="text" class="form-control form-control-sm"
                  name="informationAirportCodeEnglish" value="{{ informationAirport.codeEnglish }}" disabled>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module airports - field code local@@adm.infMod.modal.airports.field.codeLocal">Code Local</label>
                <input id="informationAirportCodeLocal" type="text" class="form-control form-control-sm"
                  name="informationAirportCodeLocal" value="{{ informationAirport.codeLocal }}" disabled>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="filter-date-from" class="mr-2 mb-0"
                  i18n="adm|Modal Informing module airports - field date from@@adm.infMod.modal.airports.field.dateFrom">
                  Date from
                </label>
                <input id="informationAirportStart" type="text" class="form-control form-control-sm"
                  name="informationAirportStart"
                  value="{{ informationAirport.dtRangeStart | date: 'dd.MM.yyyy HH:mm' }}" disabled>
              </div>
              <div class="col-6">
                <label for="filter-date-from" class="mr-2 mb-0"
                  i18n="adm|Modal Informing module airports - field date to@@adm.infMod.modal.airports.field.dateTo">
                  Date to
                </label>
                <input id="informationAirportFinish" type="text" class="form-control form-control-sm"
                  name="informationAirportFinish"
                  value="{{ informationAirport.dtRangeFinish | date: 'dd.MM.yyyy HH:mm' }}" disabled>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-update'">
              <button type="button" class="btn btn-sm btn-blue" (click)="updateInformationAirport(informationAirport.id)">
                <span i18n="adm|Modal Informing module airports - save@@adm.infMod.modal.airports.button.save">Save</span>
              </button>
            </div>
            <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal">
              <span i18n="adm|Modal Informing module airports - close@@adm.infMod.modal.airports.button.close">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal Statuses -->
<div *ngIf="showTab('tab-statuses')">
  <form #statusesForm="ngForm">
    <div class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Informing module statuses - header for edit mode@@adm.infMod.modal.statuses.headerEdit">Edit status</span>
            </h5>
            <button #modalAddStatuses type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module statuses - field name english@@adm.infMod.modal.statuses.field.nameEnglish">Name English</label>
                <input id="informationStatusNameEnglish" type="text" class="form-control form-control-sm"
                  name="informationStatusNameEnglish" [(ngModel)]="informationStatus.nameEnglish">
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module statuses - field name local@@adm.infMod.modal.statuses.field.nameLocal">Name Local</label>
                <input id="informationStatusNameLocal" type="text" class="form-control form-control-sm"
                  name="informationStatusNameLocal" [(ngModel)]="informationStatus.nameLocal">
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module statuses - field iata@@adm.infMod.modal.statuses.field.iata">IATA</label>
                <input id="processNameEnglish" type="text" class="form-control form-control-sm"
                  name="processNameEnglish" [(ngModel)]="informationStatus.processNameEnglish" disabled>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module statuses - field icao@@adm.infMod.modal.statuses.field.icao">ICAO</label>
                <input id="processNameLocal" type="text" class="form-control form-control-sm" name="processNameLocal"
                  [(ngModel)]="informationStatus.processNameLocal" disabled>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Informing module statuses - field md name english@@adm.infMod.modal.statuses.field.mdNameEnglish">Defaul name english</label>
                <input id="operationalNameEnglish" type="text" class="form-control form-control-sm"
                  name="operationalNameEnglish" value="{{ informationStatus.operationalNameEnglish }}" disabled>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Informing module statuses - field md name local@@adm.infMod.modal.statuses.field.mdNameLocal">Defaul name local</label>
                <input id="operationalNameLocal" type="text" class="form-control form-control-sm"
                  name="operationalNameLocal" value="{{ informationStatus.operationalNameLocal }}" disabled>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-update'">
              <button type="button" class="btn btn-sm btn-blue" (click)="updateInformationStatus(informationStatus.id)">
                <span i18n="adm|Modal Informing module statuses - save@@adm.infMod.modal.statuses.button.save">Save</span>
              </button>
            </div>
            <button type="button" class="btn btn-sm btn-gray-blue-dark" data-dismiss="modal">
              <span i18n="adm|Modal Informing module statuses - close@@adm.infMod.modal.statuses.button.close">Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>