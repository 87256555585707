import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { LostFoundModuleRestApiService } from 'src/app/lost-found-module/services/lost-found-rest-api.service';
import { File } from '../../../../types/files';
import { EventEmitter, Output } from "@angular/core";

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.less']
})
export class AttachmentsComponent implements OnInit {
  @Input() baggageId: string;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() attachmentsAvailable = new EventEmitter<boolean>();

  files: Array<File> = [];
  loadingDocProcess = null;
  uploadData = new FormData();
  fileNote = '';
  fileName = '';

  constructor(
    public restApi: LostFoundModuleRestApiService,
    private fileSaverService: FileSaverService,
  ) { }

  ngOnInit(): void {
    this.loadDocuments();
  }

  loadDocuments() {
    if (this.baggageId) {
      this.restApi.getFiles(this.baggageId, 'document').then(data => {
        this.files = data;
        if (this.files.length > 0) {
          this.attachmentsAvailable.emit(true);
        }
      });
    }
  }

  loadDocument(id: string, name: string) {
    return this.restApi.getFile(this.baggageId, 'document', id).subscribe(data => {
      const blob = new Blob([data], { type: 'application' });
      this.fileSaverService.save(blob, name);
    });
  }

  addDocument(files) {
    this.uploadData.append('file', files[0]);
    this.fileName = files[0].name;
  }

  uploadDocument() {
    this.uploadData.append('note', this.fileNote);
    this.restApi.uploadFiles(this.baggageId, 'document', this.uploadData).subscribe(data => {
      if (data?.total && data?.loaded) {
        this.loadingDocProcess = Math.round(data.loaded * 100 / data.total);
      } else if (data.status === 201) {
        this.loadingDocProcess = null;
        this.loadDocuments();
        this.fileInput.nativeElement.value = null;
        this.fileNote = '';
        this.fileName = '';
        this.uploadData.delete('file');
        this.uploadData.delete('note');
      } else {
        this.loadingDocProcess = null;
      }
    });
  }

  deleteDocument(id: string) {
    this.restApi.deleteFile(this.baggageId, 'document', id).then(data => {
      this.loadDocuments();
    });
  }

}
