import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
	selectItem,
	selectItemList,
	selectError,
	selectState,
} from '../state/master-data.selectors';
import { MasterDataActions } from '../state/master-data.actions';
import { SelectRecords200ResponseInner } from '../../../shared/data-provider/master-data/model/selectRecords200ResponseInner';
import { Region } from '../../../shared/data-provider/master-data/model/region';
import { BehaviorSubject, Observable } from 'rxjs';
import { inject, Injectable, OnInit } from '@angular/core';
import { Directory } from '../models/directory';
import { ApiResponse } from '@shared/data-provider/master-data/model/apiResponse';

@Injectable()
export class MasterDataStore {
	// Текст ошибки при работы с сетью или БД
	error$: Observable<any>;
	// Состояние выполнения запросов на бэк
	state$: Observable<ApiResponse>;
	// Текущий редактируемый элемент справочника
	item$: Observable<SelectRecords200ResponseInner>;
	// Список элементов в справочнике
	itemList$: Observable<SelectRecords200ResponseInner[]>;
	// Признак успешного выполнения запроса
	commandSuccess$: BehaviorSubject<string>;

	private store = inject(Store);
	private readonly actions$ = inject(Actions);

	/**
	 * Конструктор по умолчанию
	 * @param directory Наименование справочника
	 * @param store Переменная для работы с функционалом Store из ngrx
	 * @param actions$ Переменная для работы с функционалом Actions из ngrx
	 */
	constructor(private directory: Directory) {
		this.error$ = this.store.select(selectError);
		this.item$ = this.store.select(selectItem);
		this.itemList$ = this.store.select(selectItemList);
		this.state$ = this.store.select(selectState);
		this.commandSuccess$ = new BehaviorSubject('default');

		this.actions$
			.pipe(
				ofType(
					MasterDataActions.createItemSuccess,
					MasterDataActions.updateItemSuccess,
					MasterDataActions.deleteItemSuccess
				)
			)
			.subscribe(() => {
				this.listItems();
				this.commandSuccess$.next('success');
			});

		this.listItems();
	}
	/**
	 * Функция устанавливает время действия записи равное 00:00:00 текущего дня
	 * @
	 * @param id Идентификатор записи
	 * @param item Текущий элемент
	 */
	deleteItem(id: string, item: SelectRecords200ResponseInner) {
		const currentDay = new Date();
		currentDay.setHours(0);
		currentDay.setMinutes(0);
		currentDay.setSeconds(0);
		item.dtRange[1] = currentDay.toISOString();
		this.store.dispatch(
			MasterDataActions.updateItem({
				name: this.directory,
				id: id,
				payload: item,
			})
		);
	}

	/**
	 * Функция чтения элемента из справочника
	 * @param id Идентификатор записи
	 */
	readItem(id: string) {
		this.store.dispatch(
			MasterDataActions.readItem({ name: this.directory, id: id })
		);
	}

	/**
	 * Функция чтения списка элементов из справочника
	 */
	listItems() {
		this.store.dispatch(MasterDataActions.listItem({ name: this.directory }));
	}

	/**
	 * Функция создания нового элемента
	 */
	newItem() {
		switch (this.directory) {
			case Directory.Regions:
				this.store.dispatch(
					MasterDataActions.newItem({ payload: {} as Region })
				);
				break;
		}
	}

	/**
	 * Функция сохранения элемента
	 * @param item Элемент
	 */
	saveItem(item: SelectRecords200ResponseInner) {
		this.store.dispatch(
			MasterDataActions.createItem({ name: this.directory, payload: item })
		);
	}

	/**
	 * Функция обновления элемента
	 * @param id Идентификатор элемента
	 * @param item Элемент для обновления
	 */
	updateItem(id: string, item: SelectRecords200ResponseInner) {
		this.store.dispatch(
			MasterDataActions.updateItem({
				name: this.directory,
				id: id,
				payload: item,
			})
		);
	}
}
