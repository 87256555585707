
<div class="card mb-3">
  <div class="card-body">
    <div class="d-flex justify-content-between mb-3">
      <p class="font-weight-bold text-blue h5 mb-0"
        i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition">
          Crew composition
      </p>
      <button type="button"
              class="btn btn-xs ml-2 btn-ico btn-light-gray"
              ngbTooltip="Change crew composition"
              data-toggle="modal"
              data-target=".crew-composition"
              (click)="createDataExport()"
              [disabled]="loading"
              i18n-ngbTooltip="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.changeCrewcomposition">
        <i class="material-icons">assessment</i>
      </button>
    </div>
    <div class="input-group flex-nowrap">
      <div class="input-group-prepend custom-sm">
        <span class="input-group-text font-small py-0">
          <span i18n="wb|Main weight balance tab flight@@wb.main.tab.flight.groupCrewComposition.composition">Composition</span>
          <span class="required-asterisk">*</span>
        </span>
      </div>
      <!-- [items]="crewComposition" -->
      <input type="text"
            class="w-100 form-control custom-sm"
            *ngIf="allCount.nonStandard; else: crewCompositionName"
            disabled
            value="{{allCount.cockpit}}/{{allCount.cabin}} NON-STANDARD">
      <ng-template #crewCompositionName>
        <ng-select [items]="crewComposition"
                  class="custom-sm bg-white w-100"
                  bindLabel="name"
                  bindValue="name"
                  [selectOnTab]="true"
                  [clearable]="false"
                  disabled
                  [ngModel]="crewCalc.schema.name">
          <ng-template ng-option-tmp let-item="item">
            <span class="font-weight-bold">{{item.name}}</span>&nbsp;({{item.dow}}&nbsp;/&nbsp;{{item.doi}})
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
            <span class="font-weight-bold">{{item.name}}</span>&nbsp;(DOW {{item.dow}}&nbsp;/&nbsp;DOI {{item.doi}})
            <!-- &nbsp;{{allCount.nonStandard ? 'NON-STANDARD' : ''}} -->
          </ng-template>
        </ng-select>
      </ng-template>
      </div>
      <small *ngIf="preliminary.length > 0 && preliminary.join(',') !== '0,0'"
             class="form-text text-muted"><span i18n="wb@@wb.main.tab.crewComposition.provisional">Provisional
        </span>&nbsp;<span class="font-weight-bold">{{ preliminary[0] }}<span *ngIf="preliminary.length > 1">/{{
            preliminary[1] }}</span></span></small>
  </div>
</div>

<div class="modal fade bd-example-modal-xl crew-composition mw-100" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xxl load--max-width">
    <div class="modal-content bg-light-gray">
      <form #compositionForm="ngForm">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle">
            <span i18n="wb|Main weight balance@@wb.modal.crewComposition.header.name">Composition of crew</span>
          </h5>
          <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-md-5 pr-0">
              <div class="input-group flex-nowrap">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0">
                    <span i18n="wb|Main weight balance@@wb.modal.crewComposition.label.composition">Composition</span>
                    <span class="required-asterisk">*</span>
                  </span>
                </div>
                <ng-select [items]="crewComposition"
                          class="custom-sm bg-white w-100"
                          bindLabel="nameDowDoi"
                          bindValue="name"
                          name="crewComposition"
                          [selectOnTab]="true"
                          [clearable]="false"
                          [(ngModel)]="dataExport.schema.name"
                          (change)="updateDowDoiAfterCrewChange($event)">
                  <ng-template ng-option-tmp let-item="item">
                    <span class="font-weight-bold">{{item.name}}</span>&nbsp;({{item.dow}}&nbsp;/&nbsp;{{item.doi}})
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item">
                    <span class="font-weight-bold">{{item.name}}</span>&nbsp;(DOW {{item.dow}}&nbsp;/&nbsp;DOI
                    {{item.doi}})
                  </ng-template>
                </ng-select>
              </div>
              <small *ngIf="preliminary.length > 0 && preliminary.join(',') !== '0,0'"
                     class="form-text text-muted"><span i18n="wb@@wb.main.tab.crewComposition.provisional">Provisional
                </span>&nbsp;<span class="font-weight-bold">{{ preliminary[0] }}<span *ngIf="preliminary.length > 1">/{{
                    preliminary[1] }}</span></span></small>
            </div>
            <div class="col-md-3 pr-0">
              <div class="input-group flex-nowrap mr-2">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                    i18n="wb|Main weight balance@@wb.modal.crewComposition.label.totalOperationalCrew">
                    Total operational crew
                  </span>
                </div>
                <input type="text" class="form-control custom-sm" value="{{allCount.cockpit}}/{{allCount.cabin}}" disabled />
              </div>
            </div>
            <div class="col pr-0">
              <div class="input-group flex-nowrap mr-2">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                    i18n="wb|Main weight balance@@wb.modal.crewComposition.label.xcr">
                    XCR
                  </span>
                </div>
                <input type="text" class="form-control custom-sm" value="{{allCount.xcr}}" disabled />
              </div>
            </div>
            <div class="col">
              <div class="input-group flex-nowrap mr-2">
                <div class="input-group-prepend custom-sm">
                  <span class="input-group-text font-small py-0"
                    i18n="wb|Main weight balance@@wb.modal.crewComposition.label..dhc">
                    DHC
                  </span>
                </div>
                <input type="text" class="form-control custom-sm" value="{{allCount.dhc}}" disabled />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold text-blue h5"
                i18n="wb|Main weight balance@@wb.modal.crewComposition.title.additionalCrew">
                Additional crew
              </p>
              <div class="row">
                <div class="col-4">
                  <div class="d-flex justify-content-between">
                    <p class="font-weight-bold text-dark-gray"
                      i18n="wb|Main weight balance@@wb.modal.crewComposition.title.cockpit">
                      Cockpit
                    </p>
                    <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItem(dataExport.additionalSeats.cockpit)">
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                  <table class="table-striped table-border w-100" style="font-size: 0.9em;">
                    <thead class="bg-blue color-white text-center font-small">
                      <tr class="text-uppercase">
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.seat">Seat</th>
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.type">Type</th>
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.role">Role</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of dataExport.additionalSeats.cockpit; let i = index">
                        <td>
                          <ng-select [items]="cockpitLocations"
                                      class="custom-sm bg-white w-100"
                                      bindLabel="location"
                                      bindValue="location"
                                      [selectOnTab]="true"
                                      [clearable]="false"
                                      [name]="'cockpitSeat' + i"
                                      (change)="setCockpitLocations()"
                                      required
                                      [(ngModel)]="item.seat">
                            <ng-template ng-option-tmp let-item="item">
                              <span class="font-weight-bold">{{item.location}}</span>&nbsp;(Free {{item.free}})
                            </ng-template>
                          </ng-select>
                        </td>
                        <td>
                          <ng-select [items]="CREW_ADDITIONAL.type"
                                    class="custom-sm bg-white w-100"
                                    bindLabel="name"
                                    bindValue="value"
                                    [selectOnTab]="true"
                                    [clearable]="false"
                                    [name]="'cockpitType' + i"
                                    required
                                    [(ngModel)]="item.type">
                          </ng-select>
                        </td>
                        <td>
                          <ng-select [items]="CREW_ADDITIONAL.role"
                                    class="custom-sm bg-white w-100"
                                    bindLabel="name"
                                    bindValue="value"
                                    [selectOnTab]="true"
                                    [clearable]="false"
                                    [name]="'cockpitRole' + i"
                                    required
                                    [(ngModel)]="item.role">
                          </ng-select>
                        </td>
                        <td>
                          <i class="material-icons m-0 text-red font-weight-bold pointer"
                            (click)="deleteElement(dataExport.additionalSeats.cockpit, i); setCabinLocations();">clear</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-4">
                  <div class="d-flex justify-content-between">
                    <p class="font-weight-bold text-dark-gray"
                      i18n="wb|Main weight balance@@wb.modal.crewComposition.title.cabin">
                      Cabin
                    </p>
                    <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItem(dataExport.additionalSeats.cabin)">
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                  <table class="table-striped table-border w-100" style="font-size: 0.9em;">
                    <thead class="bg-blue color-white text-center font-small">
                      <tr class="text-uppercase">
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.seat">Seat</th>
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.type">Type</th>
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.role">Role</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of dataExport.additionalSeats.cabin; let i = index">
                        <td>
                          <ng-select [items]="cabinLocations"
                                    class="custom-sm bg-white w-100"
                                    bindLabel="location"
                                    bindValue="location"
                                    [selectOnTab]="true"
                                    [clearable]="false"
                                    [name]="'cabinSeat' + i"
                                    (change)="setCabinLocations()"
                                    required
                                    [(ngModel)]="item.seat">
                            <ng-template ng-option-tmp let-item="item">
                              <span class="font-weight-bold">{{item.location}}</span>&nbsp;(Free {{item.free}})
                            </ng-template>
                          </ng-select>
                        </td>
                        <td>
                          <ng-select [items]="CREW_ADDITIONAL.type"
                                    class="custom-sm bg-white w-100"
                                    bindLabel="name"
                                    bindValue="value"
                                    [selectOnTab]="true"
                                    [clearable]="false"
                                    [name]="'cabinType' + i"
                                    required
                                    [(ngModel)]="item.type">
                          </ng-select>
                        </td>
                        <td>
                          <ng-select [items]="CREW_ADDITIONAL.role"
                                    class="custom-sm bg-white w-100"
                                    bindLabel="name"
                                    bindValue="value"
                                    [selectOnTab]="true"
                                    [clearable]="false"
                                    [name]="'cabinRole' + i"
                                    required
                                    [(ngModel)]="item.role">
                          </ng-select>
                        </td>
                        <td>
                          <i class="material-icons m-0 text-red font-weight-bold pointer"
                            (click)="deleteElement(dataExport.additionalSeats.cabin, i); setCockpitLocations();">clear</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-4">
                  <div class="d-flex justify-content-between">
                    <p class="font-weight-bold text-dark-gray"
                      i18n="wb|Main weight balance@@wb.modal.crewComposition.title.crewInPassengersSeats">
                      Crew in passengers seats
                    </p>
                    <button class="btn-ico btn btn-xs btn-light-gray"
                            (click)="addItem(dataExport.additionalSeats.passengerSeats)">
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                  <table class="table-striped table-border w-100" style="font-size: 0.9em;">
                    <thead class="bg-blue color-white text-center font-small">
                      <tr class="text-uppercase">
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.seat">Seat</th>
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.type">Type</th>
                        <th i18n="wb|Main weight balance@@wb.modal.crewComposition.table.column.role">Role</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of dataExport.additionalSeats.passengerSeats; let i = index">
                        <td>
                          <ng-select [items]="passengersLocations"
                                    class="custom-sm bg-white w-100"
                                    bindLabel="name"
                                    bindValue="name"
                                    [selectOnTab]="true"
                                    [clearable]="false"
                                    [name]="'inCabinSeat' + i"
                                    (change)="setPassengerLocations()"
                                    required
                                    [(ngModel)]="item.seat">
                            <ng-template ng-option-tmp let-item="item">
                              <span class="font-weight-bold">{{item.name}}</span>&nbsp;(Free {{item.free}})
                            </ng-template>
                          </ng-select>
                        </td>
                        <td>
                          <ng-select [items]="CREW_ADDITIONAL.type"
                                    class="custom-sm bg-white w-100"
                                    bindLabel="name"
                                    bindValue="value"
                                    [selectOnTab]="true"
                                    [clearable]="false"
                                    [name]="'inCabinType' + i"
                                    required
                                    [(ngModel)]="item.type">
                          </ng-select>
                        </td>
                        <td>
                          <ng-select [items]="CREW_ADDITIONAL.role"
                                    class="custom-sm bg-white w-100"
                                    bindLabel="name"
                                    bindValue="value"
                                    [selectOnTab]="true"
                                    [clearable]="false"
                                    required
                                    [name]="'inCabinRole' + i"
                                    [(ngModel)]="item.role">
                          </ng-select>
                        </td>
                        <td>
                          <i class="material-icons m-0 text-red font-weight-bold pointer"
                            (click)="deleteElement(dataExport.additionalSeats.passengerSeats, i); setPassengerLocations();">clear</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-blue" (click)="saveComposition()" tabindex="2">
            <span i18n="sch|Flight card - button save@@sch.flightCard.button.save">Save</span>
          </button>
          <button type="button" class="btn btn-sm btn-gray-blue-dark ml-2" data-dismiss="modal" tabindex="2" (click)="createDataExport()">
            <span i18n="sch|Flight card - button close@@sch.flightCard.button.close">Close</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>




<div *ngIf="error.errorMessage" class="ms-modal-message">
  <div class="modal-content w-50">
    <div class="modal-header" [ngClass]="{'bg-info': error.errorType == 'info',
                                    'bg-warning': error.errorType == 'warning',
                                    'bg-danger': error.errorType == 'error'}">
      <h5 *ngIf="error.errorType == 'info'" class="modal-title"
        i18n="cmn|Modal message error - header information@@cmd.main.modalMessage.errMes.header.inf">
        Information
      </h5>
      <h5 *ngIf="error.errorType == 'warning'" class="modal-title"
        i18n="cmn|Modal message error - header watning@@cmd.main.modalMessage.errMes.header.warn">
        Warning
      </h5>
      <h5 *ngIf="error.errorType == 'error'" class="modal-title"
        i18n="cmn|Modal message error - header error@@cmd.main.modalMessage.errMes.header.err">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="white-space: pre-line">
      {{error.errorMessage}}
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-blue" (click)="userAnswer = true"
        i18n="cmn|Modal message error - button yes@@cmd.main.modalMessage.errMes.button.yes">
        Yes
      </button>
      <button type="button" *ngIf="modalType==='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="userAnswer = false" i18n="cmn|Modal message error - button no@@cmd.main.modalMessage.errMes.button.no">
        No
      </button>
      <button type="button" *ngIf="modalType!=='setAnswer'" class="btn btn-sm btn-gray-blue-dark"
        (click)="error.errorMessage = null"
        i18n="cmn|Modal create message error - button close@@cmn.modalCreateMessage.buttons.close">
        Close
      </button>
    </div>
  </div>
</div>
