import { Language as LanguageList } from '@shared/models/language';
import { BehaviorSubject } from 'rxjs';

export interface LanguageItem {
	code: string;
	name: string;
}

export class Language {
	private namesForUser = new Map([
		[LanguageList.en, 'English'],
		[LanguageList.ru, 'Русский'],
	]);
	list$ = new BehaviorSubject<LanguageItem[]>(null);

	constructor() {
		const languageNames = Object.values(LanguageList);
		const arr: LanguageItem[] = [];
		languageNames.forEach(name => {
			if (this.namesForUser.get(name) !== undefined) {
				arr.push({ code: name, name: this.namesForUser.get(name) });
			} else {
				arr.push({ code: name, name: name });
			}
		});
		this.list$.next(arr);
	}

	private getKeyByValue(value: string) {
		const index = Object.values(LanguageList).indexOf(value as unknown as LanguageList);
		return Object.keys(LanguageList)[index];
	}
}
