import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { LostFoundModuleRestApiService } from 'src/app/lost-found-module/services/lost-found-rest-api.service';
import { BagData, Baggage, Flight, StatementData } from 'src/app/lost-found-module/types/statements';
import { ChatData } from '../../../types/chats';
import { References } from 'src/app/lost-found-module/types/types';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '@core/services/settings/settings.service';

@Component({
  selector: 'baggage-compare-modal',
  templateUrl: './baggage-compare-modal.component.html',
  styleUrls: ['./baggage-compare-modal.component.less']
})

export class BaggageCompareModalComponent implements OnInit {

  @Input() baggage: Baggage = new Baggage();
  @Input() statement: StatementData;
  @Input() references: References;
  @Output() openChatEvent: EventEmitter<ChatData> = new EventEmitter<ChatData>();
  @Output() getBagDataEvent = new EventEmitter<Array<BagData>>();

  @ViewChild('closeButton') closeButton: any;
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;

  baggageCompareArray = [];
  bagData: Array<BagData> = [];

  constructor(
    public restApi: LostFoundModuleRestApiService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    // надо обновлять каждый раз, когда меняются характеристики
    this.loadBagData();
  }

  addToCompare(val) {
    this.baggageCompareArray.push(val);
  }

  removeToCompare(id) {
    this.baggageCompareArray = this.baggageCompareArray.filter(el => el.baggage.id !== id);
  }

  checkCompareItem(id: string) {
    return this.baggageCompareArray.find(el => el.baggage.id === id);
  }

  async makeBaggageSelected(id: string, bagData: BagData) {
    const xRequestId = this.settingsService.general.randomUuid;
    const selected = this.bagData.find(item => item.selected);
    if (selected) {
      const compareSelectedArr = this.baggageCompareArray.find(item => item.selected);
      if (compareSelectedArr) {
        compareSelectedArr.selected = false;
      }
      await this.makeBaggageUnselected(id, selected);
    }
    bagData.selected = true;
    await this.restApi.makeBaggageSelected(id, bagData.baggage.id, xRequestId);
    this.loadBagData();
  }

  async makeBaggageUnselected(id: string, bagData: BagData) {
    const xRequestId = this.settingsService.general.randomUuid;
    bagData.selected = false;
    await this.restApi.makeBaggageUnselected(id, bagData.baggage.id, xRequestId);
    this.loadBagData();
  }

  async loadBagData(id: string = this.baggage.id) {
    if (id) {
      const xRequestId = this.settingsService.general.randomUuid;
      const data = await this.restApi.getFromBagData(id, xRequestId);
      this.bagData = data.sort((a, b) => b.score - a.score);
      this.updateBaggageCompareArray(this.bagData);
      this.getBagDataEvent.emit(this.bagData);
    }
  }

  updateBaggageCompareArray(bagData: Array<BagData>) {
    this.baggageCompareArray.forEach((baggage: BagData) => {
      const finded = bagData.find(el => el.baggage.id === baggage.baggage.id);
      if (finded) {
        baggage.selected = finded.selected;
      }
    })
  }

  openChat(baggageItem: BagData) {
    this.closeButton.nativeElement.click();
    const data = {
      statementId: this.statement.id,
      statementName: this.statement.name,
      statementBaggageId: this.baggage.id,
      externalId: baggageItem.statement.id,
      externalName: baggageItem.statement.name,
      externalBaggageId: baggageItem.baggage.id
    };
    this.openChatEvent.emit(data);
  }

  getFlightsString(flights: Flight[]) {
    if (flights && flights.length > 0) {
      return flights.map(el => el.flight).join(' / ');
    } else {
      return '';
    }
  }

  getRoutes(flights: Flight[]) {
    if (flights && flights.length > 0) {
      let res = flights[0].arrival;
      flights.forEach(flight => {
        res += '/' + flight.departure
      });
      return res;
    } else {
      return '';
    }
  }

  getCodeExternalElements(array) {
    if (array && array.length > 0) {
      return array.map(el => this.getById(this.references.baggage_elements.data, el)?.code).sort().join('');
    }
    return '';
  }

  getExternalElementsString(array) {
    if (array && array.length > 0) {
      return array.map(el => el.code).sort().join('');
    }
    return '';
  }

  getInternalElements(array) {
    if (array && array.length > 0) {
      return array.map(item => {
        return {
          name: item.categoryName[0],
          items: item.internals.map(el => {
            return { name: el.name[0], description: el.description };
          })
        };
      });
    }
  }

  getById(array, id) {
    if (array && id) {
      return array.find(el => el.id === id) || null;
    }
  }

  getTypeName(id: number) {
    const type = this.getById(this.references.statement_types.data, id);
    if (type && type.name) {
      return this.settingsService.language == 'EN' ? type.name[0] : type.name[1];
    }
    return '';
  }

  closeModal() {
    this.popovers.forEach(popover => popover.close());
  }

}
