<div nz-row nzJustify="space-between">
	<div nz-col>
		<button
			nz-button
			nzType="default"
			(click)="addNew()"
			i18n="@@ref.main.tab.tails.buttonNew">
			New
		</button>
		<button
			nz-button
			nz-dropdown
			[nzDropdownMenu]="menu"
			i18n="@@ref.main.tab.tails.buttonExport">
			Export
			<span nz-icon nzType="down"></span>
		</button>
		<nz-dropdown-menu #menu="nzDropdownMenu">
			<ul nz-menu>
				<li nz-menu-item (click)="downloadCsvFile()">CSV</li>
				<li nz-menu-item (click)="downloadXmlFile()">XML</li>
			</ul>
		</nz-dropdown-menu>
	</div>

	<div nz-col nz-row>
		<div nz-col>
			<nz-input-group
				[nzAddOnBefore]="searchValueBeforeTemplate"
				[nzSuffix]="iataClearTpl">
				<input
					type="text"
					nz-input
					[(ngModel)]="settingValue.searchIata"
					placeholder="IATA" />
			</nz-input-group>
			<ng-template #searchValueBeforeTemplate>
				<span i18n="@@ref.main.tab.tails.fieldSearch">Search</span>
			</ng-template>
			<ng-template #iataClearTpl>
				<span
					nz-icon
					class="ant-input-clear-icon"
					nzTheme="fill"
					nzType="close-circle"
					*ngIf="settingValue.searchIata"
					(click)="settingValue.searchIata = ''"></span>
			</ng-template>
		</div>
		<div nz-col>
			<nz-input-group [nzSuffix]="icaoClearTpl">
				<input
					type="text"
					nz-input
					[(ngModel)]="settingValue.searchIcao"
					placeholder="ICAO" />
			</nz-input-group>
			<ng-template #icaoClearTpl>
				<span
					nz-icon
					class="ant-input-clear-icon"
					nzTheme="fill"
					nzType="close-circle"
					*ngIf="settingValue.searchIcao"
					(click)="settingValue.searchIcao = ''"></span>
			</ng-template>
		</div>
		<div nz-col>
			<nz-input-group [nzSuffix]="nameClearTpl">
				<input
					type="text"
					nz-input
					[(ngModel)]="settingValue.searchName"
					placeholder="Name"
					i18n-placeholder="@@ref.main.tab.tails.fieldSearchName" />
			</nz-input-group>
			<ng-template #nameClearTpl>
				<span
					nz-icon
					class="ant-input-clear-icon"
					nzTheme="fill"
					nzType="close-circle"
					*ngIf="settingValue.searchName"
					(click)="settingValue.searchName = ''"></span>
			</ng-template>
		</div>
	</div>
</div>

<nz-table
	#sortTable
	[nzData]="listOfDisplayAirports"
	nzTableLayout="fixed"
	[nzLoading]="settingValue.loadingList"
	nzSize="small"
	nzPaginationType="small"
	nzShowSizeChanger
	nzPageSize="15"
	[nzPageIndex]="settingValue.pageIndex"
	(nzPageIndexChange)="onPageIndexChanged($event)"
	[nzPageSizeOptions]="[15, 30, 50]">
	<thead>
		<tr>
			<th
				*ngFor="let column of listOfColumn"
				[nzSortFn]="column.compare"
				[nzSortPriority]="column.priority">
				{{ column.title }}
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let item of sortTable.data" (click)="openAirport(item.id)">
			<td>{{ item.iata }}</td>
			<td>{{ item.icao }}</td>
			<td>{{ item.codeLocal }}</td>
			<td>{{ item.nameLocal }}</td>
			<td>{{ item.lastupdate | date : 'dd.MM.yyy HH:mm' }}</td>
		</tr>
	</tbody>
</nz-table>

<nz-modal
	[(nzVisible)]="settingValue.isVisibleModal"
	[nzTitle]="modalTitle"
	[nzContent]="modalContent"
	[nzFooter]="modalFooter"
	(nzOnCancel)="closeModal()"
	nzWidth="1000px">
	<nz-spin [nzSpinning]="settingValue.loadingModal">
		<ng-template #modalTitle>
			<span *ngIf="!airport.id">
				<span i18n="@@ref.main.modal.airport.headerAppend"
					>Airports append</span
				>
				&nbsp;{{ airport.nameLocal }}
			</span>
			<span *ngIf="airport.id">
				<span i18n="@@ref.main.modal.airports.headerEdit">Edit airport</span>
				&nbsp;{{ airport.nameLocal }}
			</span>
		</ng-template>

		<ng-template #modalContent>
			<form nz-form [formGroup]="airportForm" nzLayout="vertical">
				<div nz-row [nzGutter]="16">
					<div nz-col nzSpan="24" *ngIf="airportForm.errors?.allEmpty">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airport.err.iataIcaoLocalCodeReq">
									IATA or ICAO or Local code is required
								</span>
							</nz-form-label>
						</nz-form-item>
					</div>
					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airport.field.iataCode">
									IATA code
								</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="iataErrorTpl">
								<input nz-input formControlName="iata" />
								<ng-template #iataErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span
											i18n="@@ref.main.modal.airport.err.iataIcaoLocalCodeReq">
											IATA or ICAO or Local code is required
										</span>
									</ng-container>
									<ng-container *ngIf="airportForm.hasError('reqLength')">
										<span i18n="@@ref.main.modal.airport.err.iataCodeMinLength">
											IATA code minimum length is
										</span>
										{{
											airportForm.controls['iata'].errors.reqLength.reqLength
										}}
									</ng-container>
									<ng-container
										*ngIf="control.hasError('onlyEnglishAndNumbers')">
										<span i18n="@@ref.main.modal.airport.err.iataCodeEnOnly">
											IATA code is English only
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airports.field.icaoCode">
									ICAO code
								</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="icaoErrorTpl">
								<input nz-input formControlName="icao" />
								<ng-template #icaoErrorTpl let-control>
									<ng-container *ngIf="airportForm.hasError('required')">
										<span
											i18n="@@ref.main.modal.airport.err.iataIcaoLocalCodeReq">
											IATA or ICAO or Local code is required
										</span>
									</ng-container>
									<ng-container *ngIf="control.hasError('reqLength')">
										<span i18n="@@ref.main.modal.airport.err.icaoCodeMinLength">
											ICAO code minimum length is
										</span>
										{{
											airportForm.controls['icao'].errors.reqLength.reqLength
										}}
									</ng-container>
									<ng-container
										*ngIf="control.hasError('onlyEnglishAndNumbers')">
										<span i18n="@@ref.main.modal.airport.err.icaoCodeEnOnly">
											ICAO code is English only
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label>
								<span i18n="@@ref.main.modal.airport.field.englishCode">
									English code
								</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="codeEnErrorTpl">
								<input nz-input formControlName="codeEn" />
								<ng-template #codeEnErrorTpl let-control>
									<ng-container *ngIf="control.hasError('onlyEnglish')">
										<span i18n="@@ref.main.modal.airport.err.englishCodeEnOnly">
											Code is English only
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airport.field.localCode">
									Local code
								</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="codeRuErrorTpl">
								<input nz-input formControlName="codeRu" />
								<ng-template #codeRuErrorTpl let-control>
									<ng-container *ngIf="airportForm.hasError('required')">
										<span
											i18n="@@ref.main.modal.airports.err.iataIcaoLocalCodeReq">
											IATA or ICAO or Local code is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airport.field.englishName">
									English name
								</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="nameEnErrorTpl">
								<input nz-input formControlName="nameEn" />
								<ng-template #nameEnErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.airport.err.englishNameReq">
											English name is required
										</span>
									</ng-container>
									<ng-container *ngIf="control.hasError('onlyEnglish')">
										<span i18n="@@ref.main.modal.airport.err.englishNameEnOnly">
											Name is English only
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airport.field.localName">
									Local name
								</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="nameRuErrorTpl">
								<input nz-input formControlName="nameRu" />
								<ng-template #nameRuErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.airports.err.localNameReq">
											Local name is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="6">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airports.field.tzWinter">
									Timezone winter
								</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="tzWinterErrorTpl">
								<input
									nz-input
									type="number"
									formControlName="winterTzOffset" />
								<ng-template #tzWinterErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.airports.err.tzWinterReq">
											Timezone winter is required
										</span>
									</ng-container>
									<ng-container *ngIf="control.hasError('maxMinValue')">
										<span i18n="@@ref.main.modal.airports.err.tzWinterLimit">
											Timezone limit
											{{
												airportForm.controls['winterTzOffset'].errors
													.maxMinValue.minValue
											}}
											{{
												airportForm.controls['winterTzOffset'].errors
													.maxMinValue.maxValue
											}}
											hours
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="6">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airports.field.tzSummer">
									Timezone summer
								</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="tzSummerErrorTpl">
								<input
									nz-input
									type="number"
									formControlName="summerTzOffset" />
								<ng-template #tzSummerErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.airports.err.tzSummerReq">
											Timezone summer is required
										</span>
									</ng-container>
									<ng-container *ngIf="control.hasError('maxMinValue')">
										<span i18n="@@ref.main.modal.airports.err.tzSummerLimit">
											Timezone limit
											{{
												airportForm.controls['summerTzOffset'].errors
													.maxMinValue.minValue
											}}
											{{
												airportForm.controls['summerTzOffset'].errors
													.maxMinValue.maxValue
											}}
											hours
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airports.field.city">City</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="cityErrorTpl">
								<nz-select
									[nzOptions]="listOfDisplayCities"
									formControlName="cityId">
								</nz-select>
								<ng-template #cityErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.airport.err.city">
											City is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airports.field.dtRangeStart">
									Date range start
								</span>
							</nz-form-label>
							<nz-form-control [nzErrorTip]="dtRangeStartErrorTpl">
								<nz-date-picker
									formControlName="dtRangeStart"
									nzFormat="dd.MM.yyyy"
									nzUtcOffset="00:00"></nz-date-picker>
								<ng-template #dtRangeStartErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.airports.err.dtRangeStartReq">
											Date range start is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.airports.field.dtRangeFinish">
									Date range finish
								</span>
							</nz-form-label>
							<nz-form-control [nzErrorTip]="dtRangeFinishErrorTpl">
								<nz-date-picker
									formControlName="dtRangeFinish"
									[nzFormat]="'dd.MM.yyyy'"
									nzUtcOffset="00:00"></nz-date-picker>
								<ng-template #dtRangeFinishErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.airports.err.dtRangeFinishReq">
											Date range finish is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>
				</div>
			</form>
		</ng-template>

		<ng-template #modalFooter>
			<div nz-row nzJustify="space-between">
				<div nz-col>
					<button
						*ngIf="airport.id"
						nz-button
						nzType="default"
						(click)="duplicate()"
						i18n="@@ref.main.modal.tail.button.duplicate">
						Duplicate
					</button>
					<button
						*ngIf="airport.id"
						nz-button
						nzType="primary"
						nzDanger
						(click)="showDeleteConfirm()">
						Delete
					</button>
				</div>

				<div nz-col>
					<button
						*ngIf="airport.id"
						nz-button
						nzType="primary"
						(click)="save()"
						[nzLoading]="settingValue.saving"
						i18n="@@ref.main.modal.tail.button.save"
						[disabled]="!airportForm.valid">
						Save
					</button>
					<button
						*ngIf="!airport.id"
						nz-button
						nzType="primary"
						(click)="add()"
						[nzLoading]="settingValue.saving"
						i18n="@@ref.main.modal.tail.button.append"
						[disabled]="!airportForm.valid">
						Append
					</button>
					<button
						nz-button
						nzType="default"
						(click)="closeModal()"
						i18n="@@ref.main.modal.tail.button.close">
						Close
					</button>
				</div>
			</div>
		</ng-template>
	</nz-spin>
</nz-modal>
