import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import {
	ResourceType,
	Operation,
	Reference,
	Modification,
	ServiceSchedule,
	Resource,
	Division,
} from '../types/techprocesses';
import { retry, catchError } from 'rxjs/operators';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable({
	providedIn: 'root',
})
export class TechProcessesRestApiService {
	private apiTechProcessesUrl = null;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiTechProcessesUrl = config.apiTechProcessesURL;
		});
	}

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	// Resource Types
	getResourceTypes(): Observable<ResourceType[]> {
		return this.http
			.get<ResourceType[]>(this.apiTechProcessesUrl + '/admin/resource_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	getResourceType(id: number): Observable<ResourceType> {
		return this.http
			.get<ResourceType>(this.apiTechProcessesUrl + '/admin/resource_types/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addResourceType(data: any): Observable<ResourceType> {
		return this.http
			.post<ResourceType>(this.apiTechProcessesUrl + '/admin/resource_types', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateResourceType(id: number, data: any): Observable<ResourceType> {
		return this.http
			.put<ResourceType>(
				this.apiTechProcessesUrl + '/admin/resource_types/' + id,
				JSON.stringify(data),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Operations
	getOperations(): Observable<Operation[]> {
		return this.http
			.get<Operation[]>(this.apiTechProcessesUrl + '/admin/operations')
			.pipe(retry(1), catchError(this.handleError));
	}

	getOperation(id: number): Observable<Operation> {
		return this.http
			.get<Operation>(this.apiTechProcessesUrl + '/admin/operations/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addOperation(data: any): Observable<Operation> {
		return this.http
			.post<Operation>(this.apiTechProcessesUrl + '/admin/operations', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateOperation(id: number, data: any): Observable<Operation> {
		return this.http
			.put<Operation>(this.apiTechProcessesUrl + '/admin/operations/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Modifications
	getModifications(): Observable<Modification[]> {
		return this.http
			.get<Modification[]>(this.apiTechProcessesUrl + '/admin/modifications')
			.pipe(retry(1), catchError(this.handleError));
	}

	getModification(id: number): Observable<Modification> {
		return this.http
			.get<Modification>(this.apiTechProcessesUrl + '/admin/modifications/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addModification(data: any): Observable<Modification> {
		return this.http
			.post<Modification>(this.apiTechProcessesUrl + '/admin/modifications', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateModification(id: number, data: any): Observable<Modification> {
		return this.http
			.put<Modification>(this.apiTechProcessesUrl + '/modifications/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Service schedules
	getServiceSchedules(): Observable<ServiceSchedule[]> {
		return this.http
			.get<ServiceSchedule[]>(this.apiTechProcessesUrl + '/admin/service_schedules')
			.pipe(retry(1), catchError(this.handleError));
	}

	getServiceSchedule(id: number): Observable<ServiceSchedule> {
		return this.http
			.get<ServiceSchedule>(this.apiTechProcessesUrl + '/admin/service_schedules/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addServiceSchedule(data: any): Observable<ServiceSchedule> {
		return this.http
			.post<ServiceSchedule>(
				this.apiTechProcessesUrl + '/admin/service_schedules',
				JSON.stringify(data),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateServiceSchedule(id: number, data: any): Observable<ServiceSchedule> {
		return this.http
			.put<ServiceSchedule>(
				this.apiTechProcessesUrl + '/service_schedules/' + id,
				JSON.stringify(data),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Справочники
	// getModifiers(): Promise<Reference[]> {
	//   return this.http.get<Reference[]>(this.apiTechProcessesUrl + '/admin/modifiers')
	//   .pipe(
	//     retry(1),
	//     catchError(this.handleError)
	//   ).toPromise()
	// }

	getReference(link): Promise<Reference[]> {
		return this.http
			.get<Reference[]>(this.apiTechProcessesUrl + '/master-data/' + link)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getServiceScheduleTypes(): Promise<Reference[]> {
		return this.http
			.get<Reference[]>(this.apiTechProcessesUrl + '/admin/service_schedule_types')
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Resource
	getResources(): Observable<Resource[]> {
		return this.http
			.get<Resource[]>(this.apiTechProcessesUrl + '/admin/resources')
			.pipe(retry(1), catchError(this.handleError));
	}

	getResource(id: number): Observable<Resource> {
		return this.http
			.get<Resource>(this.apiTechProcessesUrl + '/admin/resources/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addResource(data: any): Observable<Resource> {
		return this.http
			.post<Resource>(this.apiTechProcessesUrl + '/admin/resources', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateResource(id: number, data: any): Observable<Resource> {
		return this.http
			.put<Resource>(this.apiTechProcessesUrl + '/admin/resources/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Resource
	getDivisions(): Observable<Division[]> {
		return this.http
			.get<Division[]>(this.apiTechProcessesUrl + '/admin/divisions')
			.pipe(retry(1), catchError(this.handleError));
	}

	getDivision(id: number): Observable<Division> {
		return this.http
			.get<Division>(this.apiTechProcessesUrl + '/admin/divisions/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addDivision(data: any): Observable<Division> {
		return this.http
			.post<Division>(this.apiTechProcessesUrl + '/admin/divisions', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateDivision(id: number, data: any): Observable<Division> {
		return this.http
			.put<Division>(this.apiTechProcessesUrl + '/admin/divisions/' + id, JSON.stringify(data), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	handleError(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		window.alert(errorMessage);
		return throwError(error.error);
	}
}
