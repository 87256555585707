import { Component } from '@angular/core';
import { NgZorroAntdModule } from '../../../ng-zorro-antd.module';
import { SettingsService } from '@core/services/settings/settings.service';

enum Season {
	WINTER,
	SPRING,
	SUMMER,
	AUTUMN,
}

@Component({
	selector: 'mfp-home',
	standalone: true,
	imports: [NgZorroAntdModule],
	templateUrl: './home.component.html',
	styleUrl: './home.component.less',
})
export class HomeComponent {
	constructor(private settingsService: SettingsService) {}

	get userFullName() {
		return this.settingsService.user.getFirstName() + ' ' + this.settingsService.user.getLastName();
	}

	get userLogin() {
		return this.settingsService.user.getLogin();
	}

	get userDefaultAvatarImageUrl() {
		const month = new Date().getMonth() + 1;
		switch (this.getSeason(month)) {
			case Season.WINTER:
				return 'assets/img/general/season_winter.svg';
			case Season.SPRING:
				return 'assets/img/general/season_spring.svg';
			case Season.SUMMER:
				return 'assets/img/general/season_summer.svg';
			case Season.AUTUMN:
				return 'assets/img/general/season_autumn.svg';
		}
	}

	getSeason(mountNumber: number) {
		const seasons = [Season.WINTER, Season.SPRING, Season.SUMMER, Season.AUTUMN];
		return seasons[Math.floor((mountNumber / 12) * 4) % 4];
	}
}
