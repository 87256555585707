import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { RouteNetwork } from '../types/referance-route-network';
import { retry, catchError } from 'rxjs/operators';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable({
	providedIn: 'root',
})
export class RouteNetworkService {
	private apiWeightBalanceUrl = null;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiWeightBalanceUrl = config.apiWeightBalanceURL;
		});
	}

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	getRouteNetworks(): Promise<RouteNetwork[]> {
		return this.http
			.get<RouteNetwork[]>(this.apiWeightBalanceUrl + '/admin/route_networks')
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getRouteNetwork(id: number): Promise<RouteNetwork> {
		return this.http
			.get<RouteNetwork>(this.apiWeightBalanceUrl + '/admin/route_networks/' + id)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	addRouteNetworks(routeNetwork: RouteNetwork): Promise<RouteNetwork> {
		return this.http
			.post<RouteNetwork>(
				this.apiWeightBalanceUrl + '/admin/route_networks',
				JSON.stringify(routeNetwork),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	saveRouteNetworks(id: number, routeNetwork: RouteNetwork): Promise<RouteNetwork> {
		return this.http
			.put<RouteNetwork>(
				this.apiWeightBalanceUrl + '/admin/route_networks/' + id,
				JSON.stringify(routeNetwork),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	deleteRouteNetworks(id: number): Promise<RouteNetwork> {
		return this.http
			.delete<RouteNetwork>(this.apiWeightBalanceUrl + '/admin/route_networks/' + id)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	handleError(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		window.alert(errorMessage);
		return throwError(error.error);
	}
}
