import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Flight } from './flight';
import { Passenger } from './passenger';
import { Baggage } from './baggage';
import { ReferanceAirport, ReferanceAirline } from '../referance-module-data/referance';

import { CurrentFlights } from './current-flights'
import { InstallationWorkplace } from './installation-workplace';
import { DictReasonSolutionPass } from './dict-reason-solution-pass';
import { Passengers } from './passengers';
import { WantedList } from './wanted-list';
import { DictReasonWanted } from './dict-reason-wanted';
import { FindPassenger } from './find-passenger';

import { DictGeo } from './dict-geo';
import { DictTerminal } from '../administration-module/types/boardingcontrol';
import { ActiveFlightsFirstWorkplace } from './active-flights-first-workplace';
import { ShowHistory } from './show-histroy';
import { DictReasonSolutionStop } from './dict-reason-solution-stop';
import {Passport} from './passport';
import { SettingsService } from "@core/services/settings/settings.service";

@Injectable({
  providedIn: 'root'
})
export class BoardingControlRestApiService {
	private apiReferenceUrl: string;
	private apiBoardingControlUrl: string;

  constructor(private http: HttpClient,
		private settingsService: SettingsService) {
			settingsService.general.applicationConfig$.subscribe(config => {
				this.apiReferenceUrl = config.apiReferanceURL;
				this.apiBoardingControlUrl = config.apiBoardingControlURL;
			});
		}

  setDefaultHttpHeader(requestId?): Object {
    // Формирование заголовков для отслеживания запросов
    // X-Correlation-ID идентификатор пользовательской сессии
    // X-Request-ID идентификатор события / запроса
    const httpOptions = {};
    httpOptions['headers'] = { 'Content-Type' : 'application/json',
                               'X-Correlation-ID' : this.settingsService.general.userSessionUuid,
                               'X-Request-ID' : (requestId === undefined) ? this.settingsService.general.randomUuid : requestId };
    return httpOptions;
  }

  // Airports
  getAirports(xRequestId?): Observable<ReferanceAirport[]> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<ReferanceAirport[]>(this.apiReferenceUrl +
                                             '/airports',
                                             httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAirport(id: number, xRequestId?): Observable<ReferanceAirport> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<ReferanceAirport>(this.apiReferenceUrl +
                                           '/airports/' + id,
                                           httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Airlines
  getAirlines(xRequestId?): Observable<ReferanceAirline[]> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<ReferanceAirline[]>(this.apiReferenceUrl +
                                             '/airlines',
                                             httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getGeo(xRequestId?): Observable<DictGeo[]> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<DictGeo[]>(this.apiBoardingControlUrl +
                                    '?getgeos', httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  setEveryone(selectflightid: number, xRequestId?): Observable<DictTerminal> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<DictTerminal>(this.apiBoardingControlUrl +
                                        '?seteveryoneout=' + selectflightid,
                                        httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteWanted(id: number, xRequestId?): Observable<WantedList> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<WantedList>(this.apiBoardingControlUrl +
                                      '?deletewanted=' + id,
                                      httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  setFirstWorkplaceActiveWanted(fwp: any, xRequestId?): Observable<any> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<any>(this.apiBoardingControlUrl +
                               '?setfirstworkplacewanted', JSON.stringify(fwp),
                               httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  /*setExitClearArea(id: number, user: string, idwp: number, xRequestId?): Observable<any> {

    const httpOptions = this.setDefaultHttpHeader();

    console.log('ID EXIT ');

    return this.http.post<any>(this.apiBoardingControlUrl +'?exitcleararea=' + id + '&user=' + user + '&idwp=' + idwp, httpOptions);
  }*/

  setExitClearArea(id: number, user: string, idwp: number, xRequestId?): Observable<any> {

    const httpOptions = this.setDefaultHttpHeader();

    console.log('ID EXIT ');

    return this.http.post<any>(this.apiBoardingControlUrl +
      '?exitcleararea=' + id +
      '&user=' + user +
      '&idwp=' + idwp,httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  // Получение из справочник решений
  getReasonSolutionPass(idw: number, xRequestId?): Observable<DictReasonSolutionPass[]> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<DictReasonSolutionPass[]>(this.apiBoardingControlUrl +
                                                   '?getsolutionpass=' + idw,
                                                   httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getReasonSolutionStop(idw: number, xRequestId?): Observable<DictReasonSolutionStop[]> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<DictReasonSolutionStop[]>(this.apiBoardingControlUrl +
                                                   '?getsolutionstop=' + idw,
                                                   httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Получение из справочник причина задержания
  getReasonWanted(xRequestId?): Observable<DictReasonWanted[]> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<DictReasonWanted[]>(this.apiBoardingControlUrl +
                                             '?getreasonwanted',
                                             httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  setFirstWorkplaceActive(actFlights: any, xRequestId): Observable<any> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<any>(this.apiBoardingControlUrl +
                               '?updateactivefirstworkplace',
                               JSON.stringify(actFlights),
                               httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Активные рейсы в чистой зоне
  getActiveFlights(xRequestId?): Observable<ActiveFlightsFirstWorkplace[]> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<ActiveFlightsFirstWorkplace[]>(this.apiBoardingControlUrl +
                                                        '?getactiveflights',
                                                        httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Получение паспортных данных
  getPassportData(flight_id: number, passenge_name: string, pnr: string, xRequestId?) {
    /**/
    const httpOptions = this.setDefaultHttpHeader();

    //console.log('loadPassportData?getpassportadlpnl=' + flight_id + '&passenge_name=' + passenge_name+ '&pnr=' + pnr);

    return this.http.get<Passport>(this.apiBoardingControlUrl + '?getpassportadlpnl=' + flight_id + '&passenge_name=' + passenge_name+ '&pnr=' + pnr, httpOptions)
    //return this.http.get<Passport>('http://localhost/monitorsoft/monitorsoft/get.php?getpassportadlpnl=' + flight_id + '&passenge_name=' + passenge_name+ '&pnr=' + pnr, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Получение список розыска
  getWantedLists(xRequestId?): Observable<WantedList[]> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<WantedList[]>(this.apiBoardingControlUrl +
                                       '?getwantedlist',
                                       httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Получение инфо о рабочем месте
  getInfoWorkplace(guid: string, lang: string, xRequestId?) {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<InstallationWorkplace>(this.apiBoardingControlUrl +
                                                '?getinfo=' + guid +
                                                '&lang=' + lang,
                                                httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Получаем из базы данные для сравнение с баркодом
  getCurrentFlights(lang: string, minus: string, plus: string, xRequestId?) {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<CurrentFlights>(this.apiBoardingControlUrl +
                                         '?getcurrentflights=' + lang +
                                         '&minus=' + minus +
                                         '&plus=' + plus,
                                         httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Фильтр
  findPassengerInClearArea(filter: string, idFlight?: number, xRequestId?) {
    let param = '';
    if ((idFlight != 0) && (idFlight != undefined)) {
      param = '?filterpass=' + filter + '&idflight=' + idFlight;
    } else {
      param = '?filterpass=' + filter;
    }


    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<FindPassenger>(this.apiBoardingControlUrl +
                                        param,
                                        httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // список пассажиров на 3 вкладке при клике на рейс
  getPassengerInClearArea(idFlight: number, filter?: string, xRequestId?) {
    let param = '';
    if ((filter != '') && (filter != undefined)) {
      param = '?filtergetpassengers=' + idFlight + '&filter=' + filter;
    } else {
      param = '?filtergetpassengers=' + idFlight;
    }


    const httpOptions = this.setDefaultHttpHeader();
    return this.http.get<FindPassenger>(this.apiBoardingControlUrl +
                                        param,
                                        httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getHistroy(bar: string, xRequestId?): Observable<ShowHistory> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<ShowHistory>(this.apiBoardingControlUrl +
                                       '?showhistory=' + bar,
                                       httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Ищем дубликат
  getDuplicate(seat: string, idflight: number, xRequestId?): Observable<HttpResponse<boolean>> {
    const param = '?getduplicate&seat=' + seat + '&idflight=' + idflight;

    return this.http.post<HttpResponse<any>>(this.apiBoardingControlUrl +
                                             param,
                                             {observe: 'response'})
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  // Вставляем считанный баркод в таблицу
  insertBarcode(barcode: any, xRequestId?): Observable<HttpResponse<any>> {

    return this.http.post<HttpResponse<any>>(this.apiBoardingControlUrl +
                                             '?setbarcode',
                                             JSON.stringify(barcode),
                                             {observe: 'response'})
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  // Ищем есть ли такой баркод уже ?
  haveBarcodeTable(barcode: any, xRequestId?): Observable<any> {

    return this.http.post<any>(this.apiBoardingControlUrl +
                               '?havebarcode=' + barcode,
                               {observe: 'response'})
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  insertWantedList(wanted: any, xRequestId?): Observable<HttpResponse<any>> {

    return this.http.post<HttpResponse<any>>(this.apiBoardingControlUrl +
                                             '?insertwantedlist',
                                             JSON.stringify(wanted),
                                             {observe: 'response'})
    .pipe(
      map(resp => {
        return resp;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  // Вставляем в таблицу first-workplace
  insertPassengers(passenger: any, xRequestId?): Observable<Passengers> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<Passengers>(this.apiBoardingControlUrl +
                                      '?setpassenger',
                                      JSON.stringify(passenger),
                                      httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Вставляем в таблицу incorrect
  insertDictIncorrect(incorrect: any, xRequestId?): Observable<Passengers> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<Passengers>(this.apiBoardingControlUrl +
                                      '?insertincorrect',
                                      JSON.stringify(incorrect),
                                      httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Вставляем в таблицу installation place
  insertInstallationPlace(guid: any, xRequestId?): Observable<any> {

    const httpOptions = this.setDefaultHttpHeader();
    return this.http.post<any>(this.apiBoardingControlUrl +
                               '?insertnewinstallation=' + guid +
                               '&idw=-1',
                               httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAirline(id: number, xRequestId?): Observable<ReferanceAirline> {

    return this.http.get<ReferanceAirline>(this.apiReferenceUrl +
                                           '/airlines/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getFlights(xRequestId?): Observable<Flight[]> {

    return this.http.get<Flight[]>(this.apiBoardingControlUrl +
                                   '/flightsv1/')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPassengers(xRequestId?): Observable<Passenger[]> {

    return this.http.get<Passenger[]>(this.apiBoardingControlUrl +
                                      '/passengers/')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getBaggages(xRequestId?): Observable<Baggage[]> {

    return this.http.get<Baggage[]>(this.apiBoardingControlUrl +
                                    '/baggages/')
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
    // console.warn(JSON.stringify(error));


    const errorMessage = '';
    const errorDetail: any = null;
    // if (error.error instanceof ErrorEvent) {
    //   errorDetail = error.error;
    //   errorDetail.status = error.status;
    //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    // } else {
    //   console.warn('Catch unsupported error');
    //   console.warn(error);
    // }

    if (errorDetail) {
      return throwError(errorDetail);
    } else {
      return throwError(errorMessage);
    }
  }
}
