export function getLocalName(arr: Array<string>, language: string) {
	if (arr && arr.length > 0) {
		if (arr.length === 2 && language == 'RU') {
			return arr[1];
		}
		if (arr.length === 1 || language == 'EN') {
			return arr[0];
		}
		return '';
	}
	return '';
}
