<div class="row" [formGroup]="baggageForm">
    <div class="col-5 pr-0">
        <div class="card">
            <div class="card-body px-2">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <p class="font-weight-bold text-blue h5 m-0"
                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.placeOfBaggage">Place of baggage</p>
                    <div>
                        <button type="button" class="btn btn-ico btn-light-gray btn-xs mr-2" data-toggle="modal"
                            [attr.data-target]="'#baggage-messages-modal' + baggageId"
                            (click)="loadMessages(statementForm.get('id').value, baggageForm.get('id').value)">
                            <span class="material-icons">email</span>
                        </button>
                        <button type="button" class="btn btn-ico btn-light-gray btn-xs" (click)="removeBaggage();">
                            <span class="material-icons">delete</span>
                        </button>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col pr-0">
                        <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0">
                                    <span
                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.state">Status</span>&nbsp;
                                    <span class="required-asterisk"
                                        *ngIf="checkRequiredInput(baggageForm.get('statusId'))">*</span>
                                </span>
                            </div>
                            <ng-select [items]="baggage_statuses" class="w-100 custom-sm select-long"
                                bindLabel="nameLocal" bindValue="id" appendTo="body" [selectOnTab]="true"
                                [clearable]="false" [loading]="selectFuc.selectLoadAnimation.baggage_statuses_animation"
                                formControlName="statusId" (change)="changeStatus($event.id)">
                                <ng-template ng-label-tmp let-item="item">
                                    {{ language == 'EN' ? item.nameEnglish : item.nameLocal }}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    {{ language == 'EN' ? item.nameEnglish : item.nameLocal }}
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0">
                                    <span
                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.tagNumber">Tag</span>&nbsp;
                                    <span class="required-asterisk"
                                        *ngIf="checkRequiredInput(baggageForm.get('tag'))">*</span>
                                </span>
                            </div>
                            <input type="text" class="w-100 form-control custom-sm" formControlName="tag"
                                appUpperCase />
                        </div>
                    </div>
                    <div class="col-auto text-center pl-0 pt-1" *ngIf="showedBlock.noTag">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input"
                                [id]="'noTag_' + baggageId" [checked]="baggageForm.get('noTag').value"
                                formControlName="noTag" (change)="changeNoTag()">
                            <label class="custom-control-label" [for]="'noTag_' + baggageId"
                                i18n="laf|Statement tabs@@laf.statement.tabs.baggage.noTag">No tag</label>
                        </div>
                    </div>
                </div>
                <div class="input-group flex-nowrap mb-3" formGroupName="weight">
                    <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.weightChecked">
                                Weight сhecked in</span>&nbsp;
                            <span class="required-asterisk"
                                *ngIf="checkRequiredInput(baggageForm.get('weight').get('expected'))">*</span>
                        </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" formControlName="expected" (change)="changeBaggageWeight()" />
                    <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0 border-left-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.weightDelivered">
                                Delivered</span>&nbsp;
                            <span class="required-asterisk"
                                *ngIf="checkRequiredInput(baggageForm.get('weight').get('actual'))">*</span>
                        </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" formControlName="actual" (change)="changeBaggageWeight()" />
                    <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0 border-left-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.weightLost">Lost</span>&nbsp;
                        </span>
                    </div>
                    <input type="number" class="w-100 form-control custom-sm" formControlName="pilferage" />
                </div>
                <div class="accordion" [id]="'accordionBaggage'+ (baggageForm.get('id').value || baggageIndex)">
                    <div class="card" formGroupName="characteristics">
                        <div class="card-header p-0" [id]="'headingOne' + baggageId">
                            <h2 class="mb-0">
                                <button type="button" data-toggle="collapse"
                                    class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                    [attr.aria-controls]="'collapseOne' + baggageId"
                                    [attr.data-target]="'#collapseOne' + baggageId"
                                    aria-expanded="true"
                                    [ngClass]="{'border border-danger': !baggageForm.get('characteristics').valid && baggageForm.get('characteristics').touched}">
                                    <div>
                                        <span class="text-light-blue"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.bag">Bag</span>&nbsp;
                                        <span class="required-asterisk" *ngIf="checkRequiredInput(baggageForm.get('characteristics').get('colorId')) ||
                            checkRequiredInput(baggageForm.get('characteristics').get('typeId'))">*</span>&nbsp;
                                        <span class="text-dark-gray font-small">
                                            {{ getCharacteristicCode() }}
                                        </span>
                                    </div>
                                    <!-- <span class="text-gray-blue-dark font-small">
                    {{ getCharacteristicText() }}
                  </span> -->
                                </button>
                            </h2>
                        </div>
                        <div [id]="'collapseOne' + baggageId" class="collapse show"
                            [attr.aria-labelledby]="'headingOne' + baggageId"
                            [attr.data-parent]="'#accordionBaggage' + baggageId">
                            <div class="card-body px-2">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.characteristicsCode">
                                                        Characteristics code
                                                    </span>&nbsp;
                                                    <span class="required-asterisk"
                                                        *ngIf="checkRequiredInput(baggageForm.get('characteristics').get('typeId'))">*</span>
                                                </span>
                                            </div>
                                            <input type="text" class="w-100 form-control custom-sm"
                                                (input)="getCharacteristicFromCode($event.target.value)"
                                                (blur)="$event.target.value = ''"
                                                [placeholder]="getCharacteristicCode()" maxlength="7" />
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-h-29 btn-ico btn-outline-secondary"
                                                    (click)="copyText(getCharacteristicCode())">
                                                    <i class="material-icons text-dark-gray" ngbTooltip="Copy code"
                                                        i18n-ngbTooltip="laf|Statement tabs@@laf.statement.tabs.baggage.copyCode">content_copy</i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-6 pr-0">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.color">Color</span>&nbsp;
                                                    <span class="required-asterisk"
                                                        *ngIf="checkRequiredInput(baggageForm.get('characteristics').get('colorId'))">*</span>
                                                </span>
                                            </div>
                                            <ng-select class="w-100 custom-sm p-0 select-long" bindLabel="code"
                                                bindValue="id" appendTo="body" [selectOnTab]="true"
                                                [loading]="references.baggage_colors.loading"
                                                [items]="references.baggage_colors.data" formControlName="colorId">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="font-small">
                                                        <span
                                                            class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}/{{item.nameLocal}}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.type">Type</span>&nbsp;
                                                    <span class="required-asterisk"
                                                        *ngIf="checkRequiredInput(baggageForm.get('characteristics').get('typeId'))">*</span>
                                                </span>
                                            </div>
                                            <ng-select
                                                class="w-100 custom-sm p-0 select-extra-long ng-select-nowrap-option"
                                                bindLabel="code" bindValue="id" appendTo="body" [selectOnTab]="true"
                                                [loading]="references.baggage_types.loading"
                                                [items]="references.baggage_types.data" formControlName="typeId"
                                                (change)="changeBaggageType($event.id)">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="font-small">
                                                        <span
                                                            class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}/{{item.nameLocal}}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.material">Material</span>&nbsp;
                                                    <span class="required-asterisk"
                                                        *ngIf="checkRequiredInput(baggageForm.get('characteristics').get('materialId'))">*</span>
                                                </span>
                                            </div>
                                            <!-- [disabled]="baggageItem.characteristics.typeId === 13 || baggageItem.characteristics.typeId === 14"-->
                                            <ng-select class="w-100 custom-sm p-0 select-long" bindLabel="code"
                                                bindValue="id" appendTo="body" [selectOnTab]="true"
                                                [loading]="references.baggage_materials.loading"
                                                [items]="references.baggage_materials.data"
                                                formControlName="materialId">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="font-small">
                                                        <span
                                                            class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}/{{item.nameLocal}}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3" formArrayName="externalElements">
                                    <div class="col-6" *ngFor="let element of externalElements.controls; let i = index"
                                        [ngClass]="{'pr-0': i % 2 === 0}">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.external">External</span>&nbsp;{{
                                                    i+1 }}&nbsp;
                                                    <!-- <span
                            *ngIf="statement.typeId == 1 || statement.typeId == 3 || statement.typeId == 2 || statement.typeId == 5 || statement.typeId == 7 || statement.typeId == 8"
                            class="required-asterisk">*</span> -->
                                                </span>
                                            </div>
                                            <ng-select class="w-100 custom-sm p-0 airports-long" bindLabel="code"
                                                bindValue="id" [loading]="references.baggage_elements.loading"
                                                [items]="references.baggage_elements.data" [clearable]="false"
                                                [formControl]="externalElements.controls[i]">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="font-small">
                                                        <span
                                                            class="font-weight-bold">{{item.code}}</span>&nbsp;{{item.nameEnglish}}/{{item.nameLocal}}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-6 pr-0">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0"
                                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.brand">Brand</span>
                                            </div>
                                            <ng-select class="w-100 custom-sm p-0 select-long"
                                                       bindLabel="code"
                                                       bindValue="code"
                                                       appendTo="body"
                                                       [selectOnTab]="true"
                                                       [loading]="references.baggage_brands.loading"
                                                       [items]="references.baggage_brands.data"
                                                       [addTag]="true"
                                                       formControlName="brand">
                                              <ng-template ng-option-tmp
                                                           let-item="item">
                                                <div class="font-small">
                                                  {{item.code}}
                                                </div>
                                              </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0"
                                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.keyCode">
                                                    Key code
                                                </span>
                                            </div>
                                            <input type="text" class="w-100 form-control custom-sm"
                                                formControlName="lockCode" appUpperCase />
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend custom-sm">
                                        <span class="input-group-text font-small py-0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                                    </div>
                                    <textarea class="form-control form-control-sm" formControlName="description"
                                        appUpperCase
                                        rows="2">{{ baggageForm.get('characteristics').get('description).value }}</textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-0" [id]="'headingTwo' + baggageId">
                            <h2 class="mb-0">
                                <button type="button"
                                    class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                    data-toggle="collapse"
                                    [attr.data-target]="'#collapseTwo' + baggageId"
                                    aria-expanded="false"
                                    [attr.aria-controls]="'collapseTwo' + baggageId"
                                    [ngClass]="{'border border-danger': !baggageForm.get('route').valid && baggageForm.get('route').touched}">
                                    <span>
                                        <span class="text-light-blue"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.pantry">
                                            Baggage route
                                        </span>&nbsp;
                                        <span class="text-dark-gray font-small">{{ getStrFlight(routes) }}</span>
                                    </span>
                                </button>
                            </h2>
                        </div>
                        <div [id]="'collapseTwo' + baggageId" class="collapse"
                            [attr.aria-labelledby]="'headingTwo' + baggageId"
                            [attr.data-parent]="'#accordionBaggage' + baggageId">
                            <div class="card-body px-2">
                                <app-routes-table [routes]="baggageForm.controls.route"
                                    [referenceAirport]="references.airports" [referenceAirline]="references.airlines"
                                    [arrayName]="'route'" [disabled]="baggageForm.controls.routeExtendsFlight.value"
                                    [parentForm]="baggageForm" [showCopyButton]="showedBlock.copyRoute && baggageIndex !== 0"
                                    (copyRoute)="copyRoute(baggageIndex)">
                                </app-routes-table>
                                <div class="custom-control custom-switch mb-2" *ngIf="showedBlock.route">
                                    <input type="checkbox" class="custom-control-input"
                                        [id]="'routeExtendsFlight_' + baggageId"
                                        formControlName="routeExtendsFlight">
                                    <label class="custom-control-label"
                                        [for]="'routeExtendsFlight_' + baggageId"
                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.routeFlightCoincides">
                                        The route coincides with the passenger's route
                                    </label>
                                </div>
                                <ng-container *ngIf="showedBlock.possibleTransfer">
                                    <hr>
                                    <div class="row m-0" formGroupName="possibleTransfer">
                                        <div class="font-weight-bold col-auto pl-0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.possibleTransfer">
                                            Possible transfer:
                                        </div>
                                        <div formArrayName="airports" class="col-auto p-0 mb-2"
                                            *ngFor="let airport of possibleTransferAirports.controls; let i_possible = index">
                                            <div class="input-group flex-nowrap">
                                                <ng-select class="w-100 custom-sm select-long" appendTo="body"
                                                    style="min-width: 100px;"
                                                    [items]="references.airports.data" bindLabel="iata" bindValue="id"
                                                    [virtualScroll]="true" [loading]="references.airports.loading"
                                                    (scroll)="selectFuc.onScroll($event, references.airports, 'airports')"
                                                    (scrollToEnd)="selectFuc.onScrollToEnd(references.airports, 'airports')"
                                                    [searchFn]="selectFuc.customSearchAirport"
                                                    [formControlName]="i_possible">
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <div class="font-small">
                                                            <span
                                                                class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                                <button type="button" class="btn btn-h-29 btn-ico"
                                                    (click)="removePossibleTransfer(i_possible)">
                                                    <span class="material-icons text-red">close</span>
                                                </button>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-h-29 btn-ico"
                                            (click)="addPossibleTransfer()">
                                            <span class="material-icons text-green">add</span>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="card" formGroupName="characteristics" *ngIf="showedBlock.contentBaggage">
                        <div class="card-header p-0" [id]="'headingThree' + baggageId">
                            <h2 class="mb-0">
                                <button
                                    class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                    type="button" data-toggle="collapse" aria-expanded="false"
                                    [attr.data-target]="'#collapseThree' + baggageId"
                                    [attr.aria-controls]="'collapseThree' + baggageId">
                                    <div class="d-flex">
                                        <span class="d-flex align-items-center text-light-blue align-midle"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content">
                                            Content
                                        </span>
                                        <span class="text-dark-gray ml-2"
                                            *ngIf="internalElements && internalElements.length > 0">
                                            {{ internalElementsCount(internalElements) }}
                                        </span>
                                    </div>
                                </button>
                            </h2>
                        </div>
                        <div [id]="'collapseThree' + baggageId" class="collapse"
                            [attr.aria-labelledby]="'headingThree' + baggageId"
                            [attr.data-parent]="'#accordionBaggage' + baggageId">
                            <div class="card-body px-2">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p *ngIf="!internalElements || internalElementsCount(internalElements) === 0; else internalElementSum"
                                            class="m-0 text-center text-gray-blue-dark"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content.annotation">
                                            No items
                                        </p>
                                        <ng-template #internalElementSum>
                                            <p class="m-0 text-gray-blue-dark">
                                                <span
                                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content.totalItems">
                                                    Total recorded
                                                </span>:&nbsp; {{internalElementsCount(internalElements)}}
                                            </p>
                                            <ng-container formArrayName="internalElements">
                                                <div *ngFor="let internalGroup of internalElements.controls; let i=index"
                                                    [formGroupName]="i" class="text-dark-gray">
                                                    <span class="font-weight-bold">{{
                                                        getLocalName(internalGroup.get('categoryName')) }}: </span>
                                                    {{ internalElementList(internalGroup.get('internals')) }}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </div>
                                    <div>
                                        <button type="button" class="btn btn-sm btn-blue" data-toggle="modal"
                                            [attr.data-target]="'#baggage-contend-modal' + baggageId"
                                            *ngIf="internalElementsCount(internalElements) !== 0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content.button.edit">
                                            Edit
                                        </button>
                                        <button type="button" class="btn btn-sm btn-blue" data-toggle="modal"
                                            [attr.data-target]="'#baggage-contend-modal' + baggageId"
                                            *ngIf="internalElementsCount(internalElements) === 0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.content.button.add">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" formGroupName="additionalInfo">
                        <div class="card-header p-0" [id]="'headingFour' + baggageId">
                            <h2 class="mb-0">
                                <button
                                    class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                    type="button" data-toggle="collapse" aria-expanded="true"
                                    [attr.data-target]="'#collapseFour' + baggageId"
                                    [attr.aria-controls]="'collapseFour' + baggageId">
                                    <div class="d-flex">
                                        <span class="text-light-blue"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.additionalInformation">
                                            Additional Information</span>&nbsp;
                                        <span class="text-dark-gray ml-2" *ngIf="baggageForm.get('additionalInfo').get('excessBaggage').value ||
                            baggageForm.get('additionalInfo').get('insurance').value ||
                            baggageForm.get('additionalInfo').get('limitationOfLiability').value ||
                            baggageForm.get('additionalInfo').get('customsInfo').value">
                                            <span class="material-icons">done</span>
                                        </span>
                                    </div>
                                    <div>
                                    </div>
                                </button>
                            </h2>
                        </div>
                        <div [id]="'collapseFour' + baggageId" class="collapse"
                            [attr.aria-labelledby]="'headingFour' + baggageId"
                            [attr.data-parent]="'#accordionBaggage' + baggageId">
                            <div class="card-body px-2">
                                <div class="row mb-3" *ngIf="showedBlock.excessBaggage">
                                    <div class="col">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0">
                                                    <span
                                                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.excessBaggage">Excess
                                                        baggage</span>
                                                </span>
                                            </div>
                                            <input type="text" class="w-100 form-control custom-sm"
                                                formControlName="excessBaggage" />
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch col-auto">
                                        <input type="checkbox" class="custom-control-input"
                                            [id]="'insurance_' + baggageId"
                                            formControlName="insurance">
                                        <label class="custom-control-label"
                                            [for]="'insurance_' + baggageId"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.insurance">Insurance</label>
                                    </div>
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend custom-sm">
                                        <span class="input-group-text font-small py-0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.limitationResponsibility">
                                            Limitation responsibility
                                        </span>
                                    </div>
                                    <textarea class="form-control form-control-sm" rows="2"
                                        formControlName="limitationOfLiability">{{ baggageForm.get('additionalInfo').get('limitationOfLiability').value }}
                  </textarea>
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend custom-sm">
                                        <span class="input-group-text font-small py-0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.informationCustoms">
                                            Information customs
                                        </span>
                                    </div>
                                    <textarea class="form-control form-control-sm" rows="2"
                                        formControlName="customsInfo">{{ baggageForm.get('additionalInfo').get('customsInfo').value }}</textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="showedBlock.contactsInBaggage" formGroupName="passengerTag">
                        <div class="card-header p-0" [id]="'headingFive' + baggageId">
                            <h2 class="mb-0">
                                <button
                                    class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                                    type="button" data-toggle="collapse" aria-expanded="false"
                                    [attr.data-target]="'#collapseFive' + baggageId"
                                    [attr.aria-controls]="'collapseFive' + baggageId">
                                    <div class="d-flex align-items-center">
                                        <span class="text-light-blue"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.claim.contactsInBaggage">
                                            Contacts in baggage
                                        </span>
                                        <span class="text-dark-gray ml-2" *ngIf="baggageForm.get('passengerTag').get('address').value || baggageForm.get('passengerTag').get('phone').value ||
                            baggageForm.get('passengerTag').get('email').value || baggageForm.get('passengerTag').get('note').value ||
                            baggageForm.get('passengerTag').get('passenger').value">
                                            <span class="material-icons">done</span>
                                        </span>
                                    </div>
                                </button>
                            </h2>
                        </div>
                        <div [id]="'collapseFive' + baggageId" class="collapse"
                            [attr.aria-labelledby]="'headingFive' + baggageId"
                            [attr.data-parent]="'#accordionBaggage' + baggageId">
                            <div class="card-body px-2">
                                <div class="input-group flex-nowrap mb-3">
                                    <div class="input-group-prepend custom-sm">
                                        <span class="input-group-text font-small py-0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.passengerTag.surname">
                                            Surname
                                        </span>
                                    </div>
                                    <input type="text" class="w-100 form-control custom-sm" appUpperCase
                                        formControlName="passenger" />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend custom-sm">
                                        <span class="input-group-text font-small py-0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.passengerTag.address">
                                            Address
                                        </span>
                                    </div>
                                    <textarea class="form-control form-control-sm" rows="2" appUpperCase
                                        formControlName="address">{{ baggageForm.get('passengerTag').get('address').value }}
                  </textarea>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-6 pr-0">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0"
                                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.passengerTag.phone">
                                                    Phone
                                                </span>
                                            </div>
                                            <input type="text" class="w-100 form-control custom-sm"
                                                formControlName="phone" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group flex-nowrap">
                                            <div class="input-group-prepend custom-sm">
                                                <span class="input-group-text font-small py-0"
                                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.passengerTag.email">
                                                    Email
                                                </span>
                                            </div>
                                            <input type="text" class="w-100 form-control custom-sm"
                                                formControlName="email" appUpperCase />
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend custom-sm">
                                        <span class="input-group-text font-small py-0"
                                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.passengerTag.note">
                                            Note
                                        </span>
                                    </div>
                                    <textarea class="form-control form-control-sm" rows="2" appUpperCase
                                        formControlName="note">{{ baggageForm.get('passengerTag').get('note').value }}
                  </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-4 pr-0">
        <div class="accordion" [id]="'accordionFlight' + baggageId">
            <div class="card" formGroupName="forwarding" *ngIf="showedBlock.departure">
                <div class="card-header p-0" [id]="'headingDaparture' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="true"
                            [attr.data-target]="'#collapseDeparture' + baggageId"
                            [attr.aria-controls]="'collapseDeparture' + baggageId"
                            formGroupName="departure" [ngClass]="{'border border-danger':
                          !baggageForm.get('forwarding').get('departure').valid
                          && baggageForm.get('forwarding').get('departure').touched}">
                            <div class="d-flex align-items-center">
                                <span class="text-light-blue"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.departure">Departure</span>&nbsp;
                                <span class="text-dark-gray font-small" formArrayName="flights"
                                    *ngIf="routesForwardingDeparture.length > 0">
                                    {{ (routesForwardingDeparture.at(0).get('airline').value || '') }} {{
                                    routesForwardingDeparture.at(0).get('flight').value }} / {{
                                    routesForwardingDeparture.at(0).get('dt').value | date: 'dd.MM' : 'UTC+0' }}
                                </span>
                            </div>
                            <span class="text-gray-blue-dark d-flex align-items-center font-small"
                                *ngIf="baggageForm.get('forwarding').get('departure').get('tag').value">
                                <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.tag">Tag:</span>&nbsp;
                                {{ baggageForm.get('forwarding').get('departure').get('tag').value }}
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapseDeparture' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingDaparture' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId" formGroupName="departure">
                    <div class="card-body px-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.baggageTag">Baggage tag</span>
                            </div>
                            <input type="text" class="w-100 form-control custom-sm" formControlName="tag"
                                appUpperCase />
                        </div>
                        <app-routes-table [routes]="routesForwardingDeparture" [referenceAirport]="references.airports"
                            [referenceAirline]="references.airlines" [arrayName]="'flights'"
                            [parentForm]="baggageForm.get('forwarding').get('departure')">
                        </app-routes-table>
                    </div>
                </div>
            </div>
            <div class="card" formGroupName="forwarding" *ngIf="showedBlock.arrival">
                <div class="card-header p-0" [id]="'headingArrival' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="false"
                            [attr.data-target]="'#collapseArrival' + baggageId"
                            [attr.aria-controls]="'collapseArrival' + baggageId"
                            formGroupName="arrival" [ngClass]="{'border border-danger':
                        !baggageForm.get('forwarding').get('arrival').valid
                        && baggageForm.get('forwarding').get('arrival').touched}">
                            <div d-flex align-items-center>
                                <span class="text-light-blue"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.arrival">Arrival</span>&nbsp;
                                <span class="text-dark-gray font-small" formArrayName="flights"
                                    *ngIf="routesForwardingArrival.length > 0">
                                    {{
                                    (routesForwardingArrival.at(routesForwardingArrival.length-1).get('airline').value
                                    || '') }}
                                    {{ routesForwardingArrival.at(routesForwardingArrival.length-1).get('flight').value
                                    }} / {{
                                    routesForwardingArrival.at(routesForwardingArrival.length-1).get('dt').value | date:
                                    'dd.MM' : 'UTC+0'
                                    }}
                                </span>
                            </div>
                            <span class="text-gray-blue-dark d-flex align-items-center font-small"
                                *ngIf="baggageForm.get('forwarding').get('arrival').get('tag').value">
                                <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.tag">Tag:</span>&nbsp;
                                {{ baggageForm.get('forwarding').get('arrival').get('tag').value }}
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapseArrival' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingArrival' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId" formGroupName="arrival">
                    <div class="card-body px-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.baggageTag">Baggage tag</span>
                            </div>
                            <input type="text" class="w-100 form-control custom-sm" formControlName="tag"
                                appUpperCase />
                        </div>
                        <app-routes-table [routes]="routesForwardingArrival" [referenceAirport]="references.airports"
                            [referenceAirline]="references.airlines" [arrayName]="'flights'"
                            [parentForm]="baggageForm.get('forwarding').get('arrival')">
                        </app-routes-table>
                    </div>
                </div>
            </div>
            <div class="card" formGroupName="storage" *ngIf="showedBlock.storage">
                <div class="card-header p-0" [id]="'headingStorage' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="false"
                            [attr.data-target]="'#collapseStorage' + baggageId"
                            [attr.aria-controls]="'collapseStorage' + baggageId">
                            <span class="d-flex align-items-center">
                                <span class="text-light-blue"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storage">Storage</span>&nbsp;
                                <span class="text-dark-gray  font-small">
                                    {{getById(ADMIN_DATA.storages,
                                    baggageForm.get('storage').get('id').value)?.name}}</span>
                            </span>
                            <span class="text-gray-blue-dark d-flex align-items-center  font-small"
                                *ngIf="baggageForm.get('storage').get('dtStart').value || baggageForm.get('storage').get('dtFinish').value">
                                <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storage.from"
                                    *ngIf="baggageForm.get('storage').get('dtStart').value"> From</span>&nbsp;
                                {{baggageForm.get('storage').get('dtStart').value | date: 'dd.MM.yyyy' : 'UTC+0'}}&nbsp;
                                <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storage.to"
                                    *ngIf="baggageForm.get('storage').get('dtFinish').value">to</span>&nbsp;
                                {{baggageForm.get('storage').get('dtFinish').value | date: 'dd.MM.yyyy' : 'UTC+0'}}
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapseStorage' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingStorage' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId">
                    <div class="card-body px-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.storageLocation">Storage
                                    location</span>
                            </div>
                            <ng-select class="w-100 custom-sm p-0 select-long"
                                       bindLabel="name"
                                       bindValue="name"
                                       appendTo="body"
                                       [selectOnTab]="true"
                                       [items]="ADMIN_DATA.storages"
                                       [addTag]="true"
                                       (change)="setStorageBeginDate(baggageForm.get('storage').get('dtStart'), $event)"
                                       formControlName="name">
                            </ng-select>
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.period">
                                    Period
                                </span>
                            </div>
                            <input type="date" class="form-control custom-sm"
                                (change)="parseDateReactiveForm(baggageForm.get('storage').get('dtStart'), $event.target.value)"
                                [value]="baggageForm.get('storage').get('dtStart').value | date: 'yyyy-MM-dd'">
                            <input type="date" class="form-control custom-sm"
                                (change)="parseDateReactiveForm(baggageForm.get('storage').get('dtFinish'), $event.target.value, '23:59:00.000Z')"
                                [value]="baggageForm.get('storage').get('dtFinish').value | date: 'yyyy-MM-dd'">
                        </div>
                        <div class="custom-control custom-switch mb-3">
                            <input type="checkbox" class="custom-control-input"
                                [id]="'opened_' + baggageId" formControlName="opened">
                            <label class="custom-control-label" [for]="'opened_' + baggageId"
                                i18n="laf|Statement tabs@@laf.statement.tabs.baggage.wasOpened">Was opened</label>
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                            </div>
                            <textarea class="form-control form-control-sm" rows="2" appUpperCase
                                formControlName="note">{{ baggageForm.get('storage').get('note').value }}</textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" formGroupName="delivery" *ngIf="showedBlock.delivery">
                <div class="card-header p-0" [id]="'headingDelivery' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="false"
                            [attr.data-target]="'#collapseDelivery' + baggageId"
                            [attr.aria-controls]="'collapseDelivery' + baggageId">
                            <span class="d-flex align-items-center">
                                <span class="text-light-blue"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.delivery">Delivery</span>&nbsp;
                                <span class="text-dark-gray font-small">{{
                                    baggageForm.get('delivery').get('address').value }}</span>
                            </span>
                            <span class="text-gray-blue-dark d-flex align-items-center font-small"
                                *ngIf="baggageForm.get('delivery').get('dt').value && !baggageForm.get('delivery').get('actualDt').value">
                                <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.plan">
                                    Plan
                                </span>&nbsp;
                                {{ baggageForm.get('delivery').get('dt').value | date: 'dd.MM.yyyy' : 'UTC+0'}}&nbsp;
                            </span>
                            <span class="text-gray-blue-dark d-flex align-items-center font-small"
                                *ngIf="baggageForm.get('delivery').get('actualDt').value">
                                <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.fact">
                                    Fact
                                </span>&nbsp;
                                {{ baggageForm.get('delivery').get('actualDt').value | date: 'dd.MM.yyyy' : 'UTC+0'}}
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapseDelivery' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingDelivery' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId">
                    <div class="card-body px-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.deliveryAddress">
                                    Delivery address
                                </span>
                            </div>
                            <ng-select [items]="LOCAL_REFERENCES.address_types" class="w-100 custom-sm"
                                [bindLabel]="language == 'EN' ? 'nameEnglish' : 'nameLocal'"
                                [bindValue]="language == 'EN' ? 'nameEnglish' : 'nameLocal'"
                                formControlName="address">
                            </ng-select>
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.scheduledDateTime">
                                    Scheduled date/time
                                </span>
                            </div>
                            <input type="date" class="form-control custom-sm" #scheduledDate
                                (change)="parseDateReactiveForm(baggageForm.get('delivery').get('dt'), $event.target.value, scheduledTime.value)"
                                [value]="baggageForm.get('delivery').get('dt').value | date: 'yyyy-MM-dd'">
                            <input type="time" class="form-control custom-sm" #scheduledTime
                                (change)="parseDateReactiveForm(baggageForm.get('delivery').get('dt'), scheduledDate.value, $event.target.value)"
                                [value]="baggageForm.get('delivery').get('dt').value | date: 'HH:mm'">
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.actualDateTime">
                                    Actual date/time
                                </span>
                            </div>
                            <input type="date" class="form-control custom-sm" #actualDate
                                (change)="parseDateReactiveForm(baggageForm.get('delivery').get('actualDt'), $event.target.value, actualTime.value)"
                                [value]="baggageForm.get('delivery').get('actualDt').value | date: 'yyyy-MM-dd'">
                            <input type="time" class="form-control custom-sm" #actualTime
                                (change)="parseDateReactiveForm(baggageForm.get('delivery').get('actualDt'), actualDate.value, $event.target.value)"
                                [value]="baggageForm.get('delivery').get('actualDt').value | date: 'HH:mm'">
                        </div>
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.delivers">
                                    Delivers
                                </span>
                            </div>
                            <ng-select class="w-100 custom-sm" formControlName="courier"
                                [items]="['Яндекс', 'Uber', 'СДЭК', 'Почта России']" [addTag]="true">
                            </ng-select>
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                            </div>
                            <textarea class="form-control form-control-sm" rows="2" appUpperCase
                                formControlName="note">{{ baggageForm.get('delivery').get('note').value }}</textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" formGroupName="worldTracer" *ngIf="showedBlock.worldTracer">
                <div class="card-header p-0" [id]="'headingWorldTracer' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="false" disabled
                            [attr.data-target]="'#collapseWorldTracer' + baggageId"
                            [attr.aria-controls]="'collapseWorldTracer' + baggageId">
                            <span>
                                <span class="text-dark-gray"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.worldTracer">WorldTracer</span>&nbsp;
                                <span class="text-dark-gray">{{ baggageForm.get('worldTracer').get('statement').value
                                    }}</span>
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapseWorldTracer' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingWorldTracer' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId">
                    <div class="card-body px-2">
                        <div class="input-group flex-nowrap mb-3">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.status">Status</span>
                            </div>
                            <ng-select class="w-100 custom-sm p-0" bindLabel="name" bindValue="id"
                                [items]="ADMIN_DATA.worldTracer" formControlName="statusId">
                            </ng-select>
                        </div>
                        <div class="input-group flex-nowrap">
                            <div class="input-group-prepend custom-sm">
                                <span class="input-group-text font-small py-0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.number">Number</span>
                            </div>
                            <input type="text" class="w-100 form-control custom-sm" formControlName="statement"
                                appUpperCase />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" formGroupName="damage" *ngIf="showedBlock.damage">
                <div class="card-header p-0" [id]="'headingDamage' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="false" [ngClass]="{'border border-danger':
                        baggageForm.get('damage').get('damages').hasError('noValue')
                        && baggageForm.get('damage').get('damages').touched}"
                            [attr.data-target]="'#collapseDamage' + baggageId"
                            [attr.aria-controls]="'collapseDamage' + baggageId">
                            <span>
                                <span class="text-light-blue"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage">Damage</span>&nbsp;
                                <span *ngIf="checkRequiredInput(baggageForm.get('damage').get('damages'))"
                                    class="required-asterisk">*</span>&nbsp;
                                <span class="text-dark-gray" [ngClass]="{'text-red': damages.length === 0}">
                                    {{ damagesCodesString.length }}
                                </span>
                            </span>
                            <span class="text-gray-blue-dark d-flex align-items-center" *ngIf="damagesCodesString">
                                {{ damagesCodesString.join(' / ') }}
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapseDamage' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingDamage' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId">
                    <div class="card-body px-2">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <div>
                                <p *ngIf="damagesCodesString.length === 0; else damageElementSum"
                                    class="m-0 text-center text-gray-blue-dark"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.annotation">No items</p>
                                <ng-template #damageElementSum>
                                    <p class="m-0 text-center text-gray-blue-dark">
                                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.totalItems">
                                            Total recorded</span>:&nbsp;{{damagesCodesString.length}}
                                    </p>
                                </ng-template>
                            </div>
                            <div>
                                <button type="button" class="btn btn-sm btn-blue" data-toggle="modal"
                                    [attr.data-target]="'#baggage-damage-modal' + baggageId"
                                    *ngIf="damagesCodesString.length !== 0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.button.edit">
                                    Edit
                                </button>
                                <button type="button" class="btn btn-sm btn-blue" data-toggle="modal"
                                    [attr.data-target]="'#baggage-damage-modal' + baggageId"
                                    *ngIf="damagesCodesString.length === 0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.button.add">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" formGroupName="pilferage" *ngIf="showedBlock.pilferage">
                <div class="card-header p-0" [id]="'headingShortage' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="false" [ngClass]="{'border border-danger':
                    baggageForm.get('pilferage').get('items').hasError('required')
                    && baggageForm.get('pilferage').get('items').touched}"
                            [attr.data-target]="'#collapseShortage' + baggageId"
                            [attr.aria-controls]="'collapseShortage' + baggageId">
                            <span>
                                <span class="text-light-blue"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.pilferage">
                                    Pilferage
                                </span>&nbsp;
                                <span *ngIf="checkRequiredInput(baggageForm.get('pilferage').get('items'))"
                                    class="required-asterisk">*</span>&nbsp;
                                <span class="text-gray-blue-dark">
                                    {{ pilferageItems.length }}
                                </span>
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapseShortage' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingShortage' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId">
                    <div class="card-body px-2">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <div>
                                <p *ngIf="pilferageItems.length === 0; else pilferageElementSum"
                                    class="m-0 text-center text-gray-blue-dark"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.annotation">No items</p>
                                <ng-template #pilferageElementSum>
                                    <p class="m-0 text-center text-gray-blue-dark">
                                        <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.damage.totalItems">
                                            Total recorded</span>:&nbsp;{{pilferageItems.length}}
                                    </p>
                                </ng-template>
                            </div>
                            <div>
                                <button type="button" class="btn btn-sm btn-blue" data-toggle="modal"
                                    [attr.data-target]="'#pilferage-modal' + baggageId"
                                    *ngIf="pilferageItems.length !== 0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.pilferage.button.edit">
                                    Edit
                                </button>
                                <button type="button" class="btn btn-sm btn-blue" data-toggle="modal"
                                    [attr.data-target]="'#pilferage-modal' + baggageId"
                                    *ngIf="pilferageItems.length === 0"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.pilferage.button.add">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="showedBlock.photo">
                <div class="card-header p-0" [id]="'headingPhoto' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="false"
                            [attr.data-target]="'#collapsePhoto' + baggageId"
                            [attr.aria-controls]="'collapsePhoto' + baggageId">
                            <span>
                                <span class="text-light-blue"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.photo">Photo</span>&nbsp;
                                <span class="text-dark-gray" *ngIf="photoAvailable">
                                    <span class="material-icons">done</span>
                                </span>
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapsePhoto' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingPhoto' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId">
                    <div class="card-body px-2">
                        <app-photo [baggageId]="baggageForm.get('id').value"
                            (photoAvailable)="photoAvailable = $event"></app-photo>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="showedBlock.attachments">
                <div class="card-header p-0" [id]="'headingAttachments' + baggageId">
                    <h2 class="mb-0">
                        <button
                            class="btn btn-block text-left collapsed d-flex justify-content-between align-items-center"
                            type="button" data-toggle="collapse" aria-expanded="false"
                            [attr.data-target]="'#collapseAttachments' + baggageId"
                            [attr.aria-controls]="'collapseAttachments' + baggageId">
                            <span>
                                <span class="text-light-blue"
                                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.attachments">Attachments</span>&nbsp;
                                <span class="text-dark-gray" *ngIf="attachmentsAvailable">
                                    <span class="material-icons">done</span>
                                </span>
                            </span>
                        </button>
                    </h2>
                </div>
                <div [id]="'collapseAttachments' + baggageId" class="collapse"
                    [attr.aria-labelledby]="'headingAttachments' + baggageId"
                    [attr.data-parent]="'#accordionFlight' + baggageId">
                    <div class="card-body px-2">
                        <app-attachments [baggageId]="baggageForm.get('id').value"
                            (attachmentsAvailable)="attachmentsAvailable = $event"></app-attachments>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3">
            <div class="card-body px-2">
                <p class="font-weight-bold text-blue h5 mb-3"
                    i18n="laf|Statement tabs@@laf.statement.tabs.baggage.results">
                    Results</p>
                <div class="mb-3">
                    <div class="input-group flex-nowrap">
                        <div class="input-group-prepend custom-sm">
                            <span class="input-group-text font-small py-0">
                                <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.faultStation">
                                    Fault station
                                </span>&nbsp;
                                <span class="required-asterisk"
                                    *ngIf="checkRequiredInput(baggageForm.get('airportId'))">*</span>
                            </span>
                        </div>
                        <ng-select class="w-100 custom-sm" [items]="references.airports.data" bindLabel="iata"
                            bindValue="id" [virtualScroll]="true" [loading]="references.airports.loading"
                            (scroll)="selectFuc.onScroll($event, references.airports, 'airports')"
                            (scrollToEnd)="selectFuc.onScrollToEnd(references.airports, 'airports')"
                            [searchFn]="selectFuc.customSearchAirport" formControlName="airportId">
                            <ng-template ng-option-tmp let-item="item">
                                <div class="font-small">
                                    <span class="font-weight-bold">{{item.iata}}</span>&nbsp;{{item.name}}
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div *ngIf="airportId?.invalid && (airportId?.dirty || airportId?.touched)">
                        <div class="text-danger font-small" *ngIf="airportId?.errors?.['missingFaultAirport']"
                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.missingFaultAirport">
                            The fault airport is missing from the passenger's itinerary. Select the airport
                            corresponding to the route
                        </div>
                        <div class="text-danger font-small" *ngIf="airportId?.errors?.['faultAirportDoesNotMatch']"
                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.faultAirportDoesNotMatch">
                            The fault airport does not match the selected fault code. Specify the correct airport or
                            change the fault code
                        </div>
                    </div>
                </div>
                <div class="input-group flex-nowrap mb-3">
                    <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0">
                            <span i18n="laf|Statement tabs@@laf.statement.tabs.baggage.code">Code</span>&nbsp;
                            <span class="required-asterisk"
                                *ngIf="checkRequiredInput(baggageForm.get('irregularityCode1Id'))">*</span>
                        </span>
                    </div>
                    <ng-select class="w-100 custom-sm select-long ng-select-nowrap-option"
                        [items]="references.irregularity_codes.data" bindValue="id" bindLabel="code"
                        [loading]="references.irregularity_codes.loading"
                        (scroll)="selectFuc.onScroll($event, references.irregularity_codes, 'irregularity_codes')"
                        (scrollToEnd)="selectFuc.onScrollToEnd(references.irregularity_codes, 'irregularity_codes')"
                        formControlName="irregularityCode1Id" style="display: contents;">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="font-weight-bold">{{item.code}}</span>&nbsp;{{ language ===
                            'EN' ? item.nameEnglish : item.nameLocal }}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <div class="font-small d-flex">
                                <div class="font-weight-bold my-auto">{{item.code}}</div>
                                <div class="d-flex flex-column pl-2">
                                    <div>{{item.nameEnglish}}</div>
                                    <div>{{item.nameLocal}}</div>
                                </div>
                                <!-- <span class="font-weight-bold"></span>&nbsp;{{item.nameEnglish}} / {{item.nameLocal}} -->
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="input-group">
                    <div class="input-group-prepend custom-sm">
                        <span class="input-group-text font-small py-0"
                            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.note">Note</span>
                    </div>
                    <textarea class="form-control form-control-sm" rows="2" appUpperCase
                        formControlName="irregularityInfo">{{ baggageForm.get('irregularityInfo').value }}</textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="col-3" *ngIf="showedBlock.search">
        <button type="button" class="btn btn-sm btn-blue float-right mb-3 w-100"
            i18n="laf|Statement tabs@@laf.statement.tabs.baggage.baggageTracer" disabled>
            Baggage tracer
        </button>
        <div class="card mb-3">
            <div class="card-body px-2">
                <p class="font-weight-bold text-blue h5" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.search">
                    Search</p>
                <table class="table-striped table-border w-100 mb-3">
                    <thead class="text-dark-gray font-small">
                        <tr>
                            <th class="text-center" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.rating">
                                Rating
                            </th>
                            <th i18n="laf|Statement tabs@@laf.statement.tabs.baggage.claim">Claim</th>
                            <th class="text-center" i18n="laf|Statement tabs@@laf.statement.tabs.baggage.selected">
                                Selected
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let baggageMatch of bagData; let bm_i = index;">
                            <tr *ngIf="bm_i < 5">
                                <td class="text-center">{{ baggageMatch.score?.toFixed(2) }}</td>
                                <td class="text-center">{{ baggageMatch.statement.name }}</td>
                                <td class="text-center">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <input type="checkbox" *ngIf="!baggageMatch.selected"
                                            [checked]="baggageMatch.selected"
                                            (click)="compareModal.makeBaggageSelected(baggageItem.id, baggageMatch)">
                                        <input type="checkbox" *ngIf="baggageMatch.selected"
                                            [checked]="baggageMatch.selected"
                                            (click)="compareModal.makeBaggageUnselected(baggageItem.id, baggageMatch)">
                                        <span class="material-icons text-dark-gray ml-2 pointer font-medium"
                                            ngbTooltip="Chat"
                                            i18n-ngbTooltip="laf|Statement tabs@@laf.statement.tabs.baggage.chat"
                                            (click)="openChatFromSearch(baggageItem.id, baggageMatch)">question_answer</span>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div class="d-flex justify-content-between">
                    <button type="button" class="btn btn-sm btn-blue"
                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.manualSearch"
                        [routerLink]="['/lostfound/manual']"
                        [queryParams]="{baggageId: baggageItem.id, statementId: statementForm.get('id').value}">
                        Manual search
                    </button>
                    <button type="button" class="btn btn-sm btn-blue"
                        i18n="laf|Statement tabs@@laf.statement.tabs.baggage.openComparison" data-toggle="modal"
                        [attr.data-target]="'#compare-card' + baggageId">
                        Open comparison
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade bd-example-modal-xxl compare-card" [id]="'compare-card' + baggageId"
    data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <baggage-compare-modal #compareModal [baggage]="baggageItem" [statement]="statement" [references]="references"
        (openChatEvent)="openChat($event)" (getBagDataEvent)="getBagData($event)">
    </baggage-compare-modal>
</div>
<!-- Модальное окно редактирования содержимого багажа -->
<div class="modal fade" [id]="'baggage-contend-modal' + baggageId" data-backdrop="static"
    tabindex="-1" aria-labelledby="modalContentBaggage" aria-hidden="true">
    <content-modal [references]="references" [internalElements]="internalElements"
        [parentForm]="baggageForm.get('characteristics')">
    </content-modal>
</div>
<!-- Модальное окно отправки уведомлений поссажиру -->
<div class="modal fade" [id]="'baggage-messages-modal' + baggageId" data-backdrop="static"
    tabindex="-1" aria-labelledby="modalMessagesBaggage" aria-hidden="true">
    <message-modal [recipientEmail]="statementForm.get('passenger').get('email').value"></message-modal>
</div>
<!-- Модальное окно редактирования повреждений по багажу -->
<div class="modal fade" [id]="'baggage-damage-modal' + baggageId" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <baggage-damage-modal [damage_types]="references.baggage_damage_types"
        [damage_levels]="references.baggage_damage_levels" [damages]="damages" [parentForm]="baggageForm.get('damage')">
    </baggage-damage-modal>
</div>
<!-- Модальное окно редактирования недостачи по багажу -->
<div class="modal fade" [id]="'pilferage-modal' + baggageId" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="myPilferageLabel" aria-hidden="true">
    <app-pilferage-modal [pilferage]="pilferageItems" [parentForm]="baggageForm.get('pilferage')">
    </app-pilferage-modal>
</div>

<app-modal-message></app-modal-message>