import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterDataRoutingModule } from './master-data-routing.module';
import { MasterDataComponent } from './components/master-data.component';
import { MasterDataItemComponent } from './components/master-data-item/master-data-item.component';
import { MasterDataListComponent } from './components/master-data-list/master-data-list.component';
import { EffectsModule } from '@ngrx/effects';
import { MasterDataEffects } from './state/master-data.effects';
import { StoreModule } from '@ngrx/store';
import * as fromMasterData from './state/master-data.reducer';

import { registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import en from '@angular/common/locales/en';
registerLocaleData(ru);
registerLocaleData(en);

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { ApiModule } from '../../shared/data-provider/master-data-api/api.module';
import { Configuration, ConfigurationParameters } from '../../shared/data-provider/master-data-api/configuration';
import { MasterDataApiModule } from '@shared/data-provider/master-data/api.module';
import {
	MasterDataConfiguration,
	MasterDataConfigurationParameters,
} from '@shared/data-provider/master-data/configuration';
import { TailsComponent } from './components/tails/tails.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AcTypesComponent } from './components/ac-types/ac-types.component';
import { AcKindsComponent } from './components/ac-kinds/ac-kinds.component';
import { AcClassesComponent } from './components/ac-classes/ac-classes.component';
import { AirlinesComponent } from './components/airlines/airlines.component';
import { AirportsComponent } from './components/airports/airports.component';
import { CitiesComponent } from './components/cities/cities.component';
import { CountriesComponent } from './components/countries/countries.component';
import { RegionsComponent } from './components/regions/regions.component';
import { DelaysComponent } from './components/delays/delays.component';
import { DelaySourcesComponent } from './components/delay-sources/delay-sources.component';
import { MovementTypesComponent } from './components/movement-types/movement-types.component';
import { RegularitiesComponent } from './components/regularities/regularities.component';
import { SeasonsComponent } from './components/seasons/seasons.component';
import { StandsComponent } from './components/stands/stands.component';
import { StandTypesComponent } from './components/stand-types/stand-types.component';
import { TerminalsComponent } from './components/terminals/terminals.component';
import { SettingsService } from '@core/services/settings/settings.service';

function apiConfigFactory(settingsService: SettingsService) {
	const configParams: ConfigurationParameters = {
		basePath: settingsService.general.applicationConfig$.getValue().apiReferanceURL,
	};
	return new Configuration(configParams);
}

function masterDataApiConfigFactory(settingsService: SettingsService) {
	const configParams: MasterDataConfigurationParameters = {
		basePath: settingsService.general.applicationConfig$.getValue().apiReferanceURL,
	};
	return new MasterDataConfiguration(configParams);
}

@NgModule({
	declarations: [
		MasterDataComponent,
		MasterDataItemComponent,
		MasterDataListComponent,
		TailsComponent,
		AcTypesComponent,
		AcKindsComponent,
		AcClassesComponent,
		AirlinesComponent,
		AirportsComponent,
		CitiesComponent,
		CountriesComponent,
		RegionsComponent,
		DelaysComponent,
		DelaySourcesComponent,
		MovementTypesComponent,
		RegularitiesComponent,
		SeasonsComponent,
		StandsComponent,
		StandTypesComponent,
		TerminalsComponent,
	],
	imports: [
		ApiModule.forRoot(() => apiConfigFactory(inject(SettingsService))),
		MasterDataApiModule.forRoot(() => masterDataApiConfigFactory(inject(SettingsService))),
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MasterDataRoutingModule,
		StoreModule.forFeature(fromMasterData.masterDataFeatureKey, fromMasterData.reducer),
		EffectsModule.forFeature([MasterDataEffects]),
		NzLayoutModule,
		NzIconModule,
		NzButtonModule,
		NzTabsModule,
		NzTableModule,
		NzInputModule,
		NzDropDownModule,
		NzFormModule,
		NzModalModule,
		NzSelectModule,
		NzDatePickerModule,
		NzSpinModule,
		NzProgressModule,
		NzSwitchModule,
	],
	providers: [SettingsService],
})
export class MasterDataModule {}
