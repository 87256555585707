export enum Directory {
	Regions = 'regions',
	Countries = 'countries',
	Cities = 'cities',
	Airports = 'airports',
	Local_regions = 'local_regions',
	Aircraft_kinds = 'aircraft_kinds',
	Aircraft_classes = 'aircraft_classes',
	Aircraft_types = 'aircraft_types',
	Tails = 'tails',
	Airlines = 'airlines',
	Delays = 'delays',
	Delay_sources = 'delay_sources',
	Regularities = 'regularities',
	Movement_types = 'movement_types',
	Seasons = 'seasons',
	Service_schedule_types = 'service_schedule_types',
	Stand_types = 'stand_types',
	Stands = 'stands',
	Terminals = 'terminals',
}
