import { ApplicationConfig } from '../settings';

/**
 * Описание объекта папки
 */
export interface Folder {
	id: string; // Идентификатор
	name: string; // Наименование
	rule: string; // Права доступа
}

export class ComManSetting {
	// Список папок
	private _folders: Folder[];
	private defaultConfig: ApplicationConfig = null;

	/**
	 * Функция устанавливает значение свойства папки путем преобразования массива
	 * строк в массив папок.
	 * @param {string[]} value - Массив строк, представляющих имена папок.
	 */
	setFolders(value: string[]) {
		this._folders = this.convertStringArrayToFolderArray(value);
	}

	/**
	 * Функция возвращает массив объектов Folder.
	 * @returns Массив объектов Folder.
	 */
	getFolders(): Folder[] {
		return this._folders;
	}

	/**
	 * Функция преобразует массив строк в массив объектов папок.
	 * @param stringArray - Массив строк. Каждая строка представляет папку и ее
	 * свойства, разделенные знаком «##». Свойства каждой папки разделяются знаком
	 * «;;;».
	 * @returns Функция возвращает массив объектов Folder.
	 */
	private convertStringArrayToFolderArray(stringArray: Array<string>) {
		if (stringArray && stringArray.length > 0) {
			const folderArray: Folder[] = [];
			stringArray.forEach(item => {
				item.split('##').forEach(folder => {
					const folderParam = folder.split(';;;');
					folderArray.push({
						id: folderParam[0],
						name: folderParam[1],
						rule: folderParam[2],
					});
				});
			});
			return folderArray;
		} else {
			return [];
		}
	}

	defaultSettings() {
		return {
			perPage: 20,
			comManUserFilters: [],
			copyTextForReply: false,
			favoriteFolderId: null,
			showFolderThree: true,
		};
	}

	setDefaultConfig(config: ApplicationConfig) {
		this.defaultConfig = config;
	}

	getSitatexTypeRuAddress() {
		return this.defaultConfig.sitatexTypeRuAddress;
	}
}
