import { createReducer, on } from '@ngrx/store';
import { MasterDataActions } from './master-data.actions';
import { SelectRecords200ResponseInner } from '@shared/data-provider/master-data/model/selectRecords200ResponseInner';
import { ApiResponse } from '@shared/data-provider/master-data/model/apiResponse';

export const masterDataFeatureKey = 'masterData';

export interface State {
	item: SelectRecords200ResponseInner;
	itemList: SelectRecords200ResponseInner[];
	error: {
		status: number;
		statusText: string;
		url: string;
		name: string;
		message: string;
		error: {
			type: string;
			message: string;
			detail: string;
		};
	};
	state: ApiResponse;
}

export const initialState: State = {
	item: null,
	itemList: [],
	error: null,
	state: {},
};

export const reducer = createReducer(
	initialState,
	on(
		MasterDataActions.newItem,
		(state, { payload }): State => ({
			...state,
			item: payload,
			error: null,
		})
	),
	on(
		MasterDataActions.createItemSuccess,
		(state, { payload }): State => ({
			...state,
			state: payload,
			error: null,
		})
	),
	on(
		MasterDataActions.updateItemSuccess,
		(state, { payload }): State => ({
			...state,
			state: payload,
			error: null,
		})
	),
	on(
		MasterDataActions.listItemSuccess,
		(state, { payload }): State => ({
			...state,
			itemList: payload,
			error: null,
		})
	),
	on(
		MasterDataActions.readItemSuccess,
		(state, { payload }): State => ({
			...state,
			item: payload,
			error: null,
		})
	),
	on(
		MasterDataActions.errorMessage,
		(state, { payload }): State => ({
			...state,
			error: payload,
		})
	)
);
