<ul class="nav nav-tabs">
  <li class="nav-item">
    <a id="tab-resource-types"
      [ngClass]="{'active': activeTab==='tab-resource-types'}"
      class="nav-link active"
      (click)="changeTab($event)"
      i18n="adm|Techprocesses module tabs - resource types@@adm.techMod.tabs.resourceTypes">
      Resource types
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-operations"
      [ngClass]="{'active': activeTab==='tab-operations'}"
      class="nav-link"
      (click)="changeTab($event)"
      i18n="adm|Techprocesses module tabs - operations@@adm.techMod.tabs.operations">
      Operations
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-modifications"
      [ngClass]="{'active': activeTab==='tab-modifications'}"
      class="nav-link"
      (click)="changeTab($event)"
      i18n="adm|Techprocesses module tabs - modifications@@adm.techMod.tabs.modifications">
      Modifications
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-service-schedules"
      [ngClass]="{'active': activeTab==='tab-service-schedules'}"
      class="nav-link"
      (click)="changeTab($event)"
      i18n="adm|Techprocesses module tabs - service schedules@@adm.techMod.tabs.serviceSchedules">
      Service schedules
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-resources"
      [ngClass]="{'active': activeTab==='tab-resources'}"
      class="nav-link"
      (click)="changeTab($event)"
      i18n="adm|Techprocesses module tabs - resources@@adm.techMod.tabs.resources">
      Resource
    </a>
  </li>
  <li class="nav-item">
    <a id="tab-divisions"
      [ngClass]="{'active': activeTab==='tab-divisions'}"
      class="nav-link"
      (click)="changeTab($event)"
      i18n="adm|Techprocesses module tabs - divisions@@adm.techMod.tabs.divisions">
      Divisions
    </a>
  </li>
</ul>

<!-- Tab resource types -->
<div *ngIf="activeTab==='tab-resource-types'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button"
                class="btn btn-sm btn-light-gray mr-1"
                data-toggle="modal"
                data-target=".bd-example-modal-xl"
                (click)="createResourceType()">
          <i class="material-icons">add</i>
          <span i18n="adm|Techprocesses module tab resource types - button new@@adm.techMod.tab.resourceTypes.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
          i18n="adm|Techprocesses module table resource types - name@@adm.techMod.table.resourceTypes.name">
          Name
        </th>
        <th class="text-uppercase"
          i18n="adm|Techprocesses module table resource types - last update@@adm.techMod.table.resourceTypes.lastUpdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let type of resourceTypes" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadResourceType(type.id)">
        <td>{{ type.name }}</td>
        <td>{{ type.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>

  <form #resourceTypesForm="ngForm">
    <div #modalAddResource class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-append'"
              class="modal-title"
              id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module resource types - header for append mode@@adm.techMod.modal.resourceTypes.headerAppend">Resource type append</span> {{ resourceType.name }}
            </h5>
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module resource types - header for edit mode@@adm.techMod.modal.resourceTypes.headerEdit">Edit resource type</span> {{ resourceType.name }}
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Techprocesses module resource types - field resource type name@@adm.techMod.modal.resourceTypes.field.resourceTypeName">
                  Resource type name
                </label>
                <input id="resourseName"
                      type="text"
                      class="form-control form-control-sm"
                      name="resourseName"
                      #resourseName
                      [(ngModel)]="resourceType.name" required>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Techprocesses module resource types - field resource type class@@adm.techMod.modal.resourceTypes.field.resourceTypeClass">
                  Resource class
                </label>
                <ng-select [items]="references['resource_classes']"
                            class="custom-sm"
                            [bindLabel]="language==='EN' ? 'nameEnglish' : 'nameLocal'"
                            bindValue="id"
                            name="classId"
                            #classId
                            id="classId"
                            [(ngModel)]="resourceType.classId"
                            required>
                  <ng-template ng-option-tmp let-item="item">
                    {{language==='EN' || item.nameLocal==='' ? item.nameEnglish : item.nameLocal}}
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="addResourceType()"
                      i18n="adm|Modal Techprocesses module resource types - append@@adm.techMod.modal.resourceTypes.button.append">
                Append
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="confirmAction(deleteResourceType, resourceType.id)"
                      i18n="adm|Modal Techprocesses module resource types - delete@@adm.techMod.modal.resourceTypes.button.delete">
                Delete
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="updateResourceType(resourceType.id)"
                      i18n="adm|Modal Techprocesses module resource types - save@@adm.techMod.modal.resourceTypes.button.save">
                      Save
              </button>
            </div>
            <button type="button"
                    class="btn btn-sm btn-secondary"
                    data-dismiss="modal"
                    i18n="adm|Modal Techprocesses module resource types - close@@adm.techMod.modal.resourceTypes.button.close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Tab operations -->
<div *ngIf="activeTab==='tab-operations'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button"
                class="btn btn-sm btn-light-gray mr-1"
                data-toggle="modal"
                data-target=".bd-example-modal-xl"
                (click)="createOperation()">
          <i class="material-icons">add</i>
          <span i18n="adm|Techprocesses module tab operations - button new@@adm.techMod.tab.operations.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
            i18n="adm|Techprocesses module table operations - name@@adm.techMod.table.operations.name">
          Name
        </th>
        <th class="text-uppercase"
            i18n="adm|Techprocesses module table operations - last update@@adm.techMod.table.operations.lastUpdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let operation of operations" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadOperation(operation.id)">
        <td>{{ operation.name }}</td>
        <td>{{ operation.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>

  <form #operationForm="ngForm">
    <div #modalAddOperation class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-append'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module operation - header for append mode@@adm.techMod.modal.operation.headerAppend">Operation append</span> {{ operation.name }}
            </h5>
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module operation - header for edit mode@@adm.techMod.modal.operation.headerEdit">Edit operation</span> {{ operation.name }}
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-12">
                <label i18n="adm|Modal Techprocesses module operations - field operation name@@adm.techMod.modal.operations.field.operationName">
                  Operation name
                </label>
                <input id="operationName"
                        type="text"
                        class="form-control form-control-sm"
                        name="operationName"
                        [(ngModel)]="operation.name"
                        required>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6"><label class="card__header--important d-flex">Versions</label></div>
              <div class="col-6 text-right">
                <button type="button" class="btn btn-sm btn-blue" (click)="addVersion()">
                  <i class="material-icons">add_circle_outline</i>
                  <span i18n="adm|Modal Techprocesses module operations - add version@@adm.techMod.modal.operations.button.addVersion">Add version</span>
                </button>
                <button type="button" class="btn btn-sm btn-red ml-2" (click)="deleteVersion()"
                  [disabled]="deleteQueue.size==0">
                  <i class="material-icons">delete</i>
                  <span i18n="adm|Modal Techprocesses module operations - delete@@adm.techMod.modal.operations.button.delete">Delete</span>
                </button>
              </div>
            </div>
            <div class="card p-3 mb-2" *ngFor="let version of operation.versions; let ver_i = index">
              <div class="d-flex">
                <div class="pr-3">
                  <input type="checkbox"
                          name="checkboxRoute"
                          value="{{ver_i}}"
                          (click)="addToDeleteQueue(ver_i)">
                </div>
                <div class="w-100">
                  <div class="row mb-3">
                    <div class="col-8">
                      <label i18n="adm|Modal Techprocesses module operations - field description@@adm.techMod.modal.operations.field.description">
                        Description
                      </label>
                      <input id="description_{{ver_i}}"
                              type="text"
                              class="form-control form-control-sm"
                              name="description_{{ver_i}}"
                              [(ngModel)]="version.description"
                              required>
                    </div>
                    <div class="col-4">
                      <label i18n="adm|Modal Techprocesses module operations - field duration@@adm.techMod.modal.operations.field.duration">
                        Duration
                      </label>
                      <input id="duration_{{ver_i}}"
                              type="number"
                              class="form-control form-control-sm"
                              name="duration_{{ver_i}}"
                              [(ngModel)]="version.duration"
                              required>
                    </div>
                  </div>
                  <p class="font-weight-bold text-blue"
                    i18n="adm|Modal Techprocesses module operations - resourses@@adm.techMod.modal.operations.text.resourses">
                    Resourses
                  </p>
                  <table class="table table-hover w-100">
                    <thead>
                      <tr>
                        <th width="40%" i18n="adm|Modal Techprocesses module operations - table resourses column type@@adm.techMod.modal.operations.table.resourses.column.type">
                          Fileld
                        </th>
                        <th width="20%" i18n="adm|Modal Techprocesses module operations - table resourses column duration@@adm.techMod.modal.operations.table.resourses.column.duration">
                          Duration
                        </th>
                        <th width="20%" i18n="adm|Modal Techprocesses module operations - table resourses column amount@@adm.techMod.modal.operations.table.resourses.column.amount">
                          Amount
                        </th>
                        <th class="text-center" i18n="adm|Modal Techprocesses module operations - table resourses column delete@@adm.techMod.modal.operations.table.resourses.column.delete">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let resource of version.resources; let res_i = index">
                        <td>
                          <ng-select [items]="resourceTypes"
                                      class="custom-sm"
                                      bindLabel="name"
                                      bindValue="id"
                                      name="typeId_{{ver_i}}_{{res_i}}"
                                      id="typeId_{{ver_i}}_{{res_i}}"
                                      [(ngModel)]="resource.typeId"
                                      (blur)="resource.typeName = getById(resourceTypes, resource.typeId).name"
                                      required>
                            <ng-template ng-option-tmp let-item="item">
                              {{ item.name }}
                            </ng-template>
                          </ng-select>
                          <input type="hidden" name="typeName" [(ngModel)]="resource.typeName">
                        </td>
                        <td>
                          <input id="duration_{{ver_i}}_{{res_i}}"
                                  type="number"
                                  class="form-control form-control-sm"
                                  name="duration_{{ver_i}}_{{res_i}}"
                                  [(ngModel)]="resource.duration"
                                  required>
                        </td>
                        <td>
                          <input id="amount_{{ver_i}}_{{res_i}}"
                                type="number"
                                class="form-control form-control-sm"
                                name="amount_{{ver_i}}_{{res_i}}"
                                [(ngModel)]="resource.amount"
                                required>
                        </td>
                        <td class="text-center">
                          <button type="button"
                                  class="btn btn-outline-danger btn-sm"
                                  (click)="deleteElement(operation.versions[ver_i].resources, res_i)">
                            <i class="material-icons m-0">clear</i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <button type="button" class="btn btn-sm btn-blue float-right" (click)="addResourceItem(ver_i)">
                        <i class="material-icons m-0">add</i>
                        <span i18n="adm|Modal Techprocesses module operation - add resource@@adm.techMod.modal.operation.button.addResource">Add resource</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="addOperation()"
                      i18n="adm|Modal Techprocesses module operation - append@@adm.techMod.modal.operation.button.append">
                Append
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="confirmAction(deleteOperation, operation.id)"
                      i18n="adm|Modal Techprocesses module operation - delete@@adm.techMod.modal.operation.button.delete">
                Delete
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                    class="btn btn-sm btn-blue"
                    (click)="updateOperation(operation.id)"
                    i18n="adm|Modal Techprocesses module operation - save@@adm.techMod.modal.operation.button.save">
                Save
              </button>
            </div>
            <button type="button"
                    class="btn btn-sm btn-secondary"
                    data-dismiss="modal"
                    i18n="adm|Modal Techprocesses module operation - close@@adm.techMod.modal.operation.button.close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Tab modifications -->
<div *ngIf="activeTab==='tab-modifications'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button"
                class="btn btn-sm btn-light-gray mr-1"
                data-toggle="modal"
                data-target=".bd-example-modal-xl"
                (click)="createModification()">
          <i class="material-icons">add</i>
          <span i18n="adm|Techprocesses module tab modifications - button new@@adm.techMod.tab.modifications.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
          i18n="adm|Techprocesses module table modifications - name@@adm.techMod.table.modifications.name">
          Name
        </th>
        <th class="text-uppercase"
          i18n="adm|Techprocesses module table modifications - last update@@adm.techMod.table.modifications.lastUpdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let modification of modifications" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadModification(modification.id)">
        <td>{{ modification.name }}</td>
        <td>{{ modification.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>

  <form #modificationForm="ngForm">
    <div #modalAddOperation class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-append'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module modification - header for append mode@@adm.techMod.modal.modification.headerAppend">Modification append</span> {{ modification.name }}
              Modification append {{ modification.name }}
            </h5>
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module modification - header for edit mode@@adm.techMod.modal.modification.headerEdit">Edit modification</span> {{ modification.name }}
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-12">
                <label i18n="adm|Modal Techprocesses module modifications - field modification name@@adm.techMod.modal.modifications.field.modificationName">
                  Modification name
                </label>
                <input id="modificationName"
                      type="text"
                      class="form-control form-control-sm"
                      name="modificationName"
                      [(ngModel)]="modification.name"
                      required>
              </div>
            </div>
            <div class="card p-3">
              <p class="font-weight-bold text-blue"
                i18n="adm|Modal Techprocesses module modifications - modifiers@@adm.techMod.modal.modifications.text.modifiers">
                Modifiers
              </p>
              <table class="table table-hover w-100">
                <thead>
                  <tr>
                    <th width="40%" i18n="adm|Modal Techprocesses module modifications - table modifiers column type@@adm.techMod.modal.modifications.table.modifiers.column.type">
                      Type
                    </th>
                    <th width="40%" i18n="adm|Modal Techprocesses module modifications - table modifiers column value@@adm.techMod.modal.modifications.table.modifiers.column.value">
                      Value
                    </th>
                    <th class="text-center" i18n="adm|Modal Techprocesses module modifications - table modifiers column delete@@adm.techMod.modal.modifications.table.modifiers.column.delete">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let modifer of modification.modifiers; let i = index">
                    <td>
                      <ng-select [items]="modifiers"
                                  class="custom-sm"
                                  [bindLabel]="language==='EN' ? 'nameEnglish' : 'nameLocal'"
                                  bindValue="id"
                                  name="modifer_{{i}}"
                                  id="modifer_{{i}}"
                                  [(ngModel)]="modifer.id"
                                  (blur)="getById(this.modifiers, modifer.id).name"
                                  required>
                        <ng-template ng-option-tmp let-item="item">
                          {{language==='EN' || item.nameLocal==='' ? item.nameEnglish : item.nameLocal}}
                        </ng-template>
                      </ng-select>
                    </td>
                    <td>
                      <ng-select [items]="references[linkReferences[modifer.id]]"
                                  class="custom-sm"
                                  [bindLabel]="language==='EN' ? 'nameEnglish' : 'nameLocal'"
                                  bindValue="id"
                                  name="modiferVal_{{i}}"
                                  id="modiferVal_{{i}}"
                                  [(ngModel)]="modifer.value"
                                  required>
                        <ng-template ng-option-tmp let-item="item">
                          {{language==='EN' || item.nameLocal==='' ? item.nameEnglish : item.nameLocal}}
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="text-center">
                      <button type="button"
                              class="btn btn-outline-danger btn-sm"
                              (click)="deleteElement(modification.modifiers, i)">
                        <i class="material-icons m-0">clear</i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-12 mt-2">
                  <button type="button" class="btn btn-sm btn-blue float-right" (click)="addModificationModifiers(modification.modifiers)">
                    <i class="material-icons m-0">add</i>
                    <span i18n="adm|Modal Techprocesses module filters - add condition@@adm.techMod.modal.filters.button.addCondition">Add condition</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="addModification()"
                      i18n="adm|Modal Techprocesses module modifications - append@@adm.techMod.modal.modifications.button.append">
                Append
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="confirmAction(deleteModification, modification.id)"
                      i18n="adm|Modal Techprocesses module modification - delete@@adm.techMod.modal.modification.button.delete">
                Delete
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="updateModification(modification.id)"
                      i18n="adm|Modal Techprocesses module modification - save@@adm.techMod.modal.modification.button.save">
                Save
              </button>
            </div>
            <button type="button"
                    class="btn btn-sm btn-secondary"
                    data-dismiss="modal"
                    i18n="adm|Modal Techprocesses module modification - close@@adm.techMod.modal.modification.button.close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Tab service schedules -->
<div *ngIf="activeTab==='tab-service-schedules'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button"
                class="btn btn-sm btn-light-gray mr-1"
                data-toggle="modal"
                data-target=".bd-example-modal-xl"
                (click)="createServiceSchedule()">
          <i class="material-icons">add</i>
          <span i18n="adm|Techprocesses module tab service schedules - button new@@adm.techMod.tab.serviceSchedules.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
          i18n="adm|Techprocesses module table service schedules - name@@adm.techMod.table.serviceSchedules.name">
          Name
        </th>
        <th class="text-center text-uppercase"
          i18n="adm|Techprocesses module table service schedules - modifers@@adm.techMod.table.serviceSchedules.modifers">
          Modifers
        </th>
        <th class="text-center text-uppercase"
          i18n="adm|Techprocesses module table service schedules - time@@adm.techMod.table.serviceSchedules.time">
          Time
        </th>
        <th class="text-uppercase"
          i18n="adm|Techprocesses module table service schedules - last update@@adm.techMod.table.serviceSchedules.lastUpdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let service of serviceSchedules" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadServiceSchedule(service.id)">
        <td>{{ service.description }}</td>
        <td>
          <span *ngFor="let modifer of service.modification.modifiers; let i = index">
              {{language == 'EN' || modifer.nameLocal === '' ? modifer.nameEnglish : modifer.nameLocal}}:
              {{language == 'EN' ||  getById(references[linkReferences[modifer.id]], modifer.value ).nameLocal===''
              ?  getById(references[linkReferences[modifer.id]], modifer.value ).nameEnglish
              :  getById(references[linkReferences[modifer.id]], modifer.value ).nameLocal}};
          </span>
        </td>
        <td>{{ service.time }}</td>
        <td>{{ service.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>

  <form #serviceShedulesForm="ngForm">
    <div #modalAddOperation class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xxl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-append'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module service schedules - header for append mode@@adm.techMod.modal.serviceSchedules.headerAppend">Service schedule append</span>
            </h5>
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module service schedules - header for edit mode@@adm.techMod.modal.serviceSchedules.headerEdit">Edit service schedule</span>
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-12">
                <label i18n="adm|Modal Techprocesses module service schedules - field description@@adm.techMod.modal.serviceSchedules.field.description">
                  Description
                </label>
                <input id="description"
                        type="text"
                        class="form-control form-control-sm"
                        name="description"
                        [(ngModel)]="serviceSchedule.description"
                        required>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-5">
                <label i18n="adm|Modal Techprocesses module service schedules - field modification@@adm.techMod.modal.serviceSchedules.field.modification">
                  Modification
                </label>
                <ng-select [items]="modifications"
                            class="custom-sm"
                            bindLabel="name"
                            bindValue="id"
                            name="modification"
                            id="modification"
                            [(ngModel)]="serviceSchedule.modification.id"
                            required>
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.name}}
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-5">
                <label i18n="adm|Modal Techprocesses module service schedules - field schedule type@@adm.techMod.modal.serviceSchedules.field.scheduleType">
                  Schedule Type
                </label>
                <ng-select [items]="serviceScheduleTypes"
                            class="custom-sm"
                            [bindLabel]="language==='EN' ? 'nameEnglish' : 'nameLocal'"
                            bindValue="id"
                            name="serviceScheduleTypeId"
                            id="serviceScheduleTypeId"
                            [(ngModel)]="serviceSchedule.modification.id"
                            required>
                  <ng-template ng-option-tmp let-item="item">
                    {{language==='EN' || item.nameLocal==='' ? item.nameEnglish : item.nameLocal}}
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-2">
                <label i18n="adm|Modal Techprocesses module service schedules - field time@@adm.techMod.modal.serviceSchedules.field.time">
                  Time
                </label>
                <input id="time"
                        type="number"
                        class="form-control form-control-sm"
                        name="time"
                        [ngModel]="serviceSchedule.time"
                        (input)="selectTime($event.target.value)"
                        required>
              </div>
            </div>
            <div class="mb-2" *ngIf="serviceSchedule.modification.id">
              <div *ngFor="let modifer of serviceSchedule.modification.modifiers; let i = index">
                <p class="font-weight-bold text-center" *ngIf="modifer">
                  {{language == 'EN' || !modifer || modifer.nameLocal === '' ? modifer.nameEnglish : modifer.nameLocal}}:
                  {{language == 'EN' ||  getById(references[linkReferences[modifer.id]], modifer.value ).nameLocal===''
                  ?  getById(references[linkReferences[modifer.id]], modifer.value ).nameEnglish
                  :  getById(references[linkReferences[modifer.id]], modifer.value ).nameLocal}}</p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <p class="font-weight-bold text-blue"
                  i18n="adm|Modal Techprocesses module service shedule - operations@@adm.techMod.modal.serviceShedule.operations">
                  Operations
                </p>
                <div class="row">
                  <div class="col-6">
                    <table class="table table-hover w-100">
                      <thead>
                        <tr>
                          <th class="text-center" width="30%"
                              i18n="adm|Modal Techprocesses module service shedule - table operations column operation@@adm.techMod.modal.serviceShedule.table.operations.column.operation">
                            Operation
                          </th>
                          <th class="text-center"
                            i18n="adm|Modal Techprocesses module service shedule - table operations column start@@adm.techMod.modal.serviceShedule.table.operations.column.start">
                            Start
                          </th>
                          <th class="text-center"
                            i18n="adm|Modal Techprocesses module service shedule - table operations column finish@@adm.techMod.modal.serviceShedule.table.operations.column.finish">
                            Finish
                          </th>
                          <th class="text-center" width="10%"
                            i18n="adm|Modal Techprocesses module service shedule - table operations column delete@@adm.techMod.modal.serviceShedule.table.operations.column.delete">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let process of serviceSchedule.processes; let i = index">
                          <td>
                            <ng-select [items]="operations"
                                        class="custom-sm"
                                        bindLabel="name"
                                        bindValue="id"
                                        name="process_id_{{i}}"
                                        id="process_id_{{i}}"
                                        [(ngModel)]="process.id"
                                        required>
                              <ng-template ng-option-tmp let-item="item">
                                {{ item.name }}
                              </ng-template>
                            </ng-select>
                          </td>
                          <td>
                            <input id="start_{{i}}"
                                    type="number"
                                    class="form-control form-control-sm"
                                    name="start_{{i}}"
                                    [(ngModel)]="process.start"
                                    (blur)="createDiagram(process)"
                                    required>
                          </td>
                          <td>
                            <input id="finish_{{i}}"
                                    type="number"
                                    class="form-control form-control-sm"
                                    name="finish_{{i}}"
                                    [(ngModel)]="process.finish"
                                    (blur)="createDiagram(process)"
                                    required>
                          </td>
                          <td class="text-center">
                            <button type="button" class="btn btn-outline-danger btn-xs" (click)="deleteElement(serviceSchedule.processes, i)">
                              <i class="material-icons m-0">clear</i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-6">
                    <div class="graph">
                      <div *ngFor="let process of serviceSchedule.processes; let i = index" class="example-boundary">
                        <div class="example-box" cdkDragBoundary=".example-boundary" cdkDragLockAxis="x" cdkDrag
                          [ngClass]="{'stick-class': stickClass == i}"
                          [style.width.%]="100 * (process.finish - process.start) / serviceSchedule.time"
                          [style.left.%]="100 * (process.start) / serviceSchedule.time"
                          (cdkDragMoved)="dragMoved($event, i)"
                          (cdkDragEnded)="dragEnd($event, i)"
                          #id>
                        </div>
                        <div class="sizer" cdkDragBoundary=".example-boundary" cdkDragLockAxis="x" cdkDrag (cdkDragEnded)="selectWidth($event, id, i)"
                          [style.left.%]="(100 * (process.start) / serviceSchedule.time) + (100 * (process.finish - process.start) / serviceSchedule.time)">
                        </div>
                      </div>
                      <div class="timeline">
                        <div class="time" *ngFor="let line of timeline">
                          {{ line }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mt-2">
                    <button type="button" class="btn btn-sm btn-blue float-right" (click)="addOperationServiceSchedule(serviceSchedule.processes)">
                      <i class="material-icons m-0">add</i>
                      <span i18n="adm|Modal Techprocesses module service shedule - add operation@@adm.techMod.modal.serviceShedule.button.addOperation">Add operation</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="addServiceSchedule()"
                      i18n="adm|Modal Techprocesses module service schedule - append@@adm.techMod.modal.serviceSchedule.button.append">
                Append
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="confirmAction(deleteServiceSchedule, serviceSchedule.id)"
                      i18n="adm|Modal Techprocesses module service schedule - delete@@adm.techMod.modal.serviceSchedule.button.delete">
                Delete
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="updateServiceSchedule(serviceSchedule.id)"
                      i18n="adm|Modal Techprocesses module service schedule - save@@adm.techMod.modal.serviceSchedule.button.save">
                Save
              </button>
            </div>
            <button type="button"
                    class="btn btn-sm btn-secondary"
                    data-dismiss="modal"
                    i18n="adm|Modal Techprocesses module service schedule - close@@adm.techMod.modal.serviceSchedule.button.close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Tab resource -->
<div *ngIf="activeTab==='tab-resources'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button"
                class="btn btn-sm btn-light-gray mr-1"
                data-toggle="modal"
                data-target=".bd-example-modal-xl"
                (click)="createResource()">
          <i class="material-icons">add</i>
          <span i18n="adm|Techprocesses module tab resources - button new@@adm.techMod.tab.resources.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
          i18n="adm|Techprocesses module table resource - name@@adm.techMod.table.resource.name">
          Name
        </th>
        <th class="text-uppercase"
          i18n="adm|Techprocesses module table resource - last update@@adm.techMod.table.resource.lastUpdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let resource of resources" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadResource(resource.id)">
        <td>{{ resource.names }}</td>
        <td>{{ resource.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>

  <form #resourceForm="ngForm">
    <div #modalAddResource class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-append'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module resource - header for append mode@@adm.techMod.modal.resource.headerAppend">Resource append</span> {{ resource.name }}
            </h5>
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module resource - header for edit mode@@adm.techMod.modal.resource.headerEdit">Edit resource</span> {{ resource.name }}
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-4">
                <label i18n="adm|Modal Techprocesses module resource - field resource name first@@adm.techMod.modal.resource.field.resourceNameFirst">
                  Resource name First
                </label>
                <input id="resourceNameFirst"
                        type="text"
                        class="form-control form-control-sm"
                        name="resourceNameFirst"
                        [(ngModel)]="resource.nameFirst"
                        required>
              </div>
              <div class="col-4">
                <label i18n="adm|Modal Techprocesses module resource - field resource name second@@adm.techMod.modal.resource.field.resourceNameSecond">
                  Resource name Second
                </label>
                <input id="resourceNameSecond"
                        type="text"
                        class="form-control form-control-sm"
                        name="resourceNameSecond"
                        [(ngModel)]="resource.nameSecond"
                        required>
              </div>
              <div class="col-4">
                <label i18n="adm|Modal Techprocesses module resource - field resource class@@adm.techMod.modal.resource.field.resourceClass">
                  Class
                </label>
                <ng-select [items]="references['resource_classes']"
                            class="custom-sm"
                            [bindLabel]="language==='EN' ? 'nameEnglish' : 'nameLocal'"
                            bindValue="id"
                            name="classId"
                            id="classId"
                            [(ngModel)]="resource.classId"
                            required>
                  <ng-template ng-option-tmp let-item="item">
                    {{language==='EN' || item.nameLocal==='' ? item.nameEnglish : item.nameLocal}}
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Techprocesses module resource - field type@@adm.techMod.modal.resource.field.type">
                  Type
                </label>
                <ng-select [items]="resourceTypes"
                            class="custom-sm"
                            bindLabel="name"
                            bindValue="id"
                            name="typeId"
                            id="typeId"
                            [(ngModel)]="resource.typeId"
                            (blur)="resource.typeName = getById(resourceTypes, resource.typeId).name"
                            required>
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <input type="hidden" name="typeName" [(ngModel)]="resource.typeName">
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Techprocesses module resource - field division@@adm.techMod.modal.resource.field.division">
                  Division
                </label>
                <ng-select [items]="divisions"
                            class="custom-sm"
                            bindLabel="name"
                            bindValue="id"
                            name="divisionId"
                            id="divisionId"
                            [(ngModel)]="resource.divisionId"
                            (blur)="resource.divisionName = getById(divisions, resource.divisionId).name"
                            required>
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <input type="hidden" name="divisionName" [(ngModel)]="resource.divisionName">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="addResource()"
                      i18n="adm|Modal Techprocesses module resource - append@@adm.techMod.modal.resource.button.append">
                Append
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="confirmAction(deleteResource, resource.id)"
                      i18n="adm|Modal Techprocesses module resource - delete@@adm.techMod.modal.resource.button.delete">
                Delete
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="updateResource(resource.id)"
                      i18n="adm|Modal Techprocesses module resource - save@@adm.techMod.modal.resource.button.save">
                Save
              </button>
            </div>
            <button type="button"
                    class="btn btn-sm btn-secondary"
                    data-dismiss="modal"
                    i18n="adm|Modal Techprocesses module resource - close@@adm.techMod.modal.resource.button.close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Tab division -->
<div *ngIf="activeTab==='tab-divisions'" class="bg-white p-3">
  <div class="col">
    <div class="row my-2">
      <div class="col-6 pl-0">
        <button type="button"
                class="btn btn-sm btn-light-gray mr-1"
                data-toggle="modal"
                data-target=".bd-example-modal-xl"
                (click)="createDivision()">
          <i class="material-icons">add</i>
          <span i18n="adm|Techprocesses module tab division - button new@@adm.techMod.tab.division.button.new">New</span>
        </button>
      </div>
    </div>
  </div>
  <table class="table table-hover w-100">
    <thead class="">
      <tr>
        <th class="text-center text-uppercase"
          i18n="adm|Techprocesses module table division - name@@adm.techMod.table.division.name">
          Name
        </th>
        <th class="text-uppercase"
          i18n="adm|Techprocesses module table division - last update@@adm.techMod.table.division.lastUpdate">
          Last update
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let division of divisions" data-toggle="modal" data-target=".bd-example-modal-xl"
        (click)="loadDivision(division.id)">
        <td>{{ division.name }}</td>
        <td>{{ division.lastupdate | date: 'dd.MM.yyyy HH:mm' }}</td>
      </tr>
    </tbody>
  </table>

  <form #divisionForm="ngForm">
    <div #modalAddResource class="modal fade bd-example-modal-xl" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content bg-light-gray">
          <div class="loading" *ngIf="loading">
            <div class="cssload-spin-box"></div>
          </div>
          <div class="modal-header">
            <h5 *ngIf="activeState==='action-append'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module division - header for append mode@@adm.techMod.modal.division.headerAppend">Division append</span> {{ division.name }}
            </h5>
            <h5 *ngIf="activeState==='action-update'" class="modal-title" id="exampleModalScrollableTitle">
              <span i18n="adm|Modal Techprocesses module division - header for edit mode@@adm.techMod.modal.division.headerEdit">Edit division</span> {{ division.name }}
            </h5>
            <button #closebutton type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-4">
              <div class="col-6">
                <label i18n="adm|Modal Techprocesses module division - field name@@adm.techMod.modal.division.field.name">
                  Name
                </label>
                <input id="resourseName"
                        type="text"
                        class="form-control form-control-sm"
                        name="resourseName"
                        [(ngModel)]="division.name"
                        required>
              </div>
              <div class="col-6">
                <label i18n="adm|Modal Techprocesses module division - field parent@@adm.techMod.modal.division.field.parent">
                  Parent
                </label>
                <ng-select [items]="divisions"
                            class="custom-sm"
                            bindLabel="name"
                            bindValue="id"
                            name="parentId"
                            id="parentId"
                            [(ngModel)]="division.parentId"
                            required>
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div *ngIf="activeState==='action-append'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      click)="addDivision()"
                      i18n="adm|Modal Techprocesses module division - append@@adm.techMod.modal.division.button.append">
                Append
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-red"
                      (click)="confirmAction(deleteDivision, division.id)"
                      i18n="adm|Modal Techprocesses module division - delete@@adm.techMod.modal.division.button.delete">
                Delete
              </button>
            </div>
            <div *ngIf="activeState==='action-update'">
              <button type="button"
                      class="btn btn-sm btn-blue"
                      (click)="updateDivision(division.id)"
                      i18n="adm|Modal Techprocesses module division - save@@adm.techMod.modal.division.button.save">
                Save
              </button>
            </div>
            <button type="button"
                    class="btn btn-sm btn-secondary"
                    data-dismiss="modal"
                    i18n="adm|Modal Techprocesses module division - close@@adm.techMod.modal.division.button.close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="errorMessage !== ''" class="ms-modal-message">
  <div class="modal-content w-50">
    <div class="modal-header" [ngClass]="{'bg-info': errorType === 'info',
                                          'bg-warning': errorType === 'warning',
                                          'bg-danger': errorType === 'error'}">
      <h5 *ngIf="errorType === 'info'"
          i18n="adm|Modal Techprocesses module - header information@@adm.techMod.modal.header.information"
          class="modal-title">
        Information
      </h5>
      <h5 *ngIf="errorType === 'warning'"
          i18n="adm|Modal Techprocesses module - header warning@@adm.techMod.modal.header.warning"
          class="modal-title">
        Warning
      </h5>
      <h5 *ngIf="errorType === 'error'"
          i18n="adm|Modal Techprocesses module - header error@@adm.techMod.modal.header.error"
          class="modal-title">
        Error
      </h5>
      <button type="button" class="close" (click)="clearErrorMess()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{ errorMessage }}
    </div>
    <div class="modal-footer">
      <button type="button"
              *ngIf="userAction === 'deleteItem'"
              class="btn btn-sm btn-blue"
              (click)="executableFunction(removableId)"
              i18n="adm|Modal Techprocesses module contacts - button yes@@adm.techMod.modal.contacts.button.yes">
        Yes
      </button>
      <button type="button"
              *ngIf="userAction === 'delete' || userAction === 'deleteItem'"
              class="btn btn-sm btn-gray-blue-dark"
              (click)="clearErrorMess()"
              i18n="adm|Modal Techprocesses module contacts - button no@@adm.techMod.modal.contacts.button.no">
        No
      </button>
    </div>
  </div>
</div>