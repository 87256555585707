/**
 * Master data
 * Master data server api. Test key: special-key.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ApiResponse } from '../model/apiResponse';
// @ts-ignore
import { DelaySourceOperator } from '../model/delaySourceOperator';
// @ts-ignore
import { SelectRecords200ResponseInner } from '../model/selectRecords200ResponseInner';
// @ts-ignore
import { Status } from '../model/status';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          elem =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          k =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Create new record
   *
   * @param delaySourceOperator record to insert
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDelaySourceOperator(
    delaySourceOperator?: DelaySourceOperator,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ApiResponse>;
  public createDelaySourceOperator(
    delaySourceOperator?: DelaySourceOperator,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ApiResponse>>;
  public createDelaySourceOperator(
    delaySourceOperator?: DelaySourceOperator,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ApiResponse>>;
  public createDelaySourceOperator(
    delaySourceOperator?: DelaySourceOperator,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/delay_source_operators`;
    return this.httpClient.request<ApiResponse>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: delaySourceOperator,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create new record
   *
   * @param name Name of master data
   * @param _export export to files
   * @param selectRecords200ResponseInner record to insert
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    _export?: 'null' | 'csv' | 'xml',
    selectRecords200ResponseInner?: SelectRecords200ResponseInner,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ApiResponse>;
  public insertRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    _export?: 'null' | 'csv' | 'xml',
    selectRecords200ResponseInner?: SelectRecords200ResponseInner,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ApiResponse>>;
  public insertRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    _export?: 'null' | 'csv' | 'xml',
    selectRecords200ResponseInner?: SelectRecords200ResponseInner,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ApiResponse>>;
  public insertRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    _export?: 'null' | 'csv' | 'xml',
    selectRecords200ResponseInner?: SelectRecords200ResponseInner,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling insertRecord.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (_export !== undefined && _export !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>_export,
        'export'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/${this.configuration.encodeParam({
      name: 'name',
      value: name,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'regions' | 'countries' | 'cities' | 'airports' | 'local_regions' | 'aircraft_kinds' | 'aircraft_classes' | 'aircraft_types' | 'tails' | 'airlines' | 'delays' | 'delay_sources' | 'regularities' | 'movement_types' | 'seasons' | 'service_schedule_types' | 'stand_types' | 'stands' | 'terminals'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ApiResponse>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: selectRecords200ResponseInner,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get record
   * @param id Name of master data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectDelaySourceOperator(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<DelaySourceOperator>;
  public selectDelaySourceOperator(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<DelaySourceOperator>>;
  public selectDelaySourceOperator(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<DelaySourceOperator>>;
  public selectDelaySourceOperator(
    id: string,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling selectDelaySourceOperator.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/delay_source_operators/${this.configuration.encodeParam(
      {
        name: 'id',
        value: id,
        in: 'path',
        style: 'simple',
        explode: false,
        dataType: 'string',
        dataFormat: undefined,
      }
    )}`;
    return this.httpClient.request<DelaySourceOperator>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get master data
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectDelaySourceOperators(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Array<DelaySourceOperator>>;
  public selectDelaySourceOperators(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Array<DelaySourceOperator>>>;
  public selectDelaySourceOperators(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Array<DelaySourceOperator>>>;
  public selectDelaySourceOperators(
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/delay_source_operators`;
    return this.httpClient.request<Array<DelaySourceOperator>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get master data
   *
   * @param id ID of delay source
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectOperatorsBySource(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Array<DelaySourceOperator>>;
  public selectOperatorsBySource(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Array<DelaySourceOperator>>>;
  public selectOperatorsBySource(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Array<DelaySourceOperator>>>;
  public selectOperatorsBySource(
    id: string,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling selectOperatorsBySource.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/delay_source_operators_by_delay_source/${this.configuration.encodeParam(
      {
        name: 'id',
        value: id,
        in: 'path',
        style: 'simple',
        explode: false,
        dataType: 'string',
        dataFormat: undefined,
      }
    )}`;
    return this.httpClient.request<Array<DelaySourceOperator>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get record
   * @param name Name of master data
   * @param id Name of master data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<SelectRecords200ResponseInner>;
  public selectRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<SelectRecords200ResponseInner>>;
  public selectRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<SelectRecords200ResponseInner>>;
  public selectRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    id: string,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling selectRecord.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling selectRecord.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/${this.configuration.encodeParam({
      name: 'name',
      value: name,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'regions' | 'countries' | 'cities' | 'airports' | 'local_regions' | 'aircraft_kinds' | 'aircraft_classes' | 'aircraft_types' | 'tails' | 'airlines' | 'delays' | 'delay_sources' | 'regularities' | 'movement_types' | 'seasons' | 'service_schedule_types' | 'stand_types' | 'stands' | 'terminals'",
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<SelectRecords200ResponseInner>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get master data
   *
   * @param name Name of master data
   * @param _export export to files
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectRecords(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    _export?: 'null' | 'csv' | 'xml',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'application/xml' | 'text/csv';
      context?: HttpContext;
    }
  ): Observable<Array<SelectRecords200ResponseInner>>;
  public selectRecords(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    _export?: 'null' | 'csv' | 'xml',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'application/xml' | 'text/csv';
      context?: HttpContext;
    }
  ): Observable<HttpResponse<Array<SelectRecords200ResponseInner>>>;
  public selectRecords(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    _export?: 'null' | 'csv' | 'xml',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json' | 'application/xml' | 'text/csv';
      context?: HttpContext;
    }
  ): Observable<HttpEvent<Array<SelectRecords200ResponseInner>>>;
  public selectRecords(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    _export?: 'null' | 'csv' | 'xml',
    observe: any = 'body',
    reportProgress = false,
    options?: {
      httpHeaderAccept?: 'application/json' | 'application/xml' | 'text/csv';
      context?: HttpContext;
    }
  ): Observable<any> {
    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling selectRecords.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (_export !== undefined && _export !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>_export,
        'export'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/xml',
        'text/csv',
      ];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/${this.configuration.encodeParam({
      name: 'name',
      value: name,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'regions' | 'countries' | 'cities' | 'airports' | 'local_regions' | 'aircraft_kinds' | 'aircraft_classes' | 'aircraft_types' | 'tails' | 'airlines' | 'delays' | 'delay_sources' | 'regularities' | 'movement_types' | 'seasons' | 'service_schedule_types' | 'stand_types' | 'stands' | 'terminals'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<Array<SelectRecords200ResponseInner>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get statuses
   *
   * @param process Name of process
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectStatuses(
    process: 'arrival' | 'baggage' | 'checkin' | 'boarding' | 'departure',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Array<Status>>;
  public selectStatuses(
    process: 'arrival' | 'baggage' | 'checkin' | 'boarding' | 'departure',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Array<Status>>>;
  public selectStatuses(
    process: 'arrival' | 'baggage' | 'checkin' | 'boarding' | 'departure',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Array<Status>>>;
  public selectStatuses(
    process: 'arrival' | 'baggage' | 'checkin' | 'boarding' | 'departure',
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (process === null || process === undefined) {
      throw new Error(
        'Required parameter process was null or undefined when calling selectStatuses.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/statuses/${this.configuration.encodeParam({
      name: 'process',
      value: process,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'arrival' | 'baggage' | 'checkin' | 'boarding' | 'departure'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<Array<Status>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update record
   * @param id Name of master data
   * @param delaySourceOperator record to update
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDelaySourceOperator(
    id: string,
    delaySourceOperator?: DelaySourceOperator,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ApiResponse>;
  public updateDelaySourceOperator(
    id: string,
    delaySourceOperator?: DelaySourceOperator,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ApiResponse>>;
  public updateDelaySourceOperator(
    id: string,
    delaySourceOperator?: DelaySourceOperator,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ApiResponse>>;
  public updateDelaySourceOperator(
    id: string,
    delaySourceOperator?: DelaySourceOperator,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateDelaySourceOperator.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/delay_source_operators/${this.configuration.encodeParam(
      {
        name: 'id',
        value: id,
        in: 'path',
        style: 'simple',
        explode: false,
        dataType: 'string',
        dataFormat: undefined,
      }
    )}`;
    return this.httpClient.request<ApiResponse>(
      'put',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: delaySourceOperator,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update record
   * @param name Name of master data
   * @param id Name of master data
   * @param selectRecords200ResponseInner record to insert
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    id: string,
    selectRecords200ResponseInner?: SelectRecords200ResponseInner,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ApiResponse>;
  public updateRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    id: string,
    selectRecords200ResponseInner?: SelectRecords200ResponseInner,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ApiResponse>>;
  public updateRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    id: string,
    selectRecords200ResponseInner?: SelectRecords200ResponseInner,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ApiResponse>>;
  public updateRecord(
    name:
      | 'regions'
      | 'countries'
      | 'cities'
      | 'airports'
      | 'local_regions'
      | 'aircraft_kinds'
      | 'aircraft_classes'
      | 'aircraft_types'
      | 'tails'
      | 'airlines'
      | 'delays'
      | 'delay_sources'
      | 'regularities'
      | 'movement_types'
      | 'seasons'
      | 'service_schedule_types'
      | 'stand_types'
      | 'stands'
      | 'terminals',
    id: string,
    selectRecords200ResponseInner?: SelectRecords200ResponseInner,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling updateRecord.'
      );
    }
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateRecord.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (BearerAuth) required
    localVarCredential = this.configuration.lookupCredential('BearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/${this.configuration.encodeParam({
      name: 'name',
      value: name,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'regions' | 'countries' | 'cities' | 'airports' | 'local_regions' | 'aircraft_kinds' | 'aircraft_classes' | 'aircraft_types' | 'tails' | 'airlines' | 'delays' | 'delay_sources' | 'regularities' | 'movement_types' | 'seasons' | 'service_schedule_types' | 'stand_types' | 'stands' | 'terminals'",
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ApiResponse>(
      'put',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: selectRecords200ResponseInner,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
