import { Component, OnInit } from '@angular/core';
import { AdministrationRestApiService } from './services/administration-rest-api.service';
import { KeycloakService } from 'keycloak-angular';
import { SettingsService } from '@core/services/settings/settings.service';
import { Module } from '@shared/models/module-name'


@Component({
  selector: 'app-administration-module',
  templateUrl: './administration-module.component.html',
  styleUrls: ['./administration-module.component.less']
})
export class AdministrationModuleComponent implements OnInit {

  baseURL: string;

  activeModule = 'module-communication';
  constructor(
    public restApi: AdministrationRestApiService,
    private keycloakService: KeycloakService,
		private settingsService: SettingsService
  ) {
    settingsService.general.applicationConfig$.subscribe(config => {
			if (config) {
				this.baseURL = config.apiInformationalURL;
			}
		})
  }

  async ngOnInit() {
    // Ожидание авторизации пользователя, для получения доступа к группам прав
    await this.keycloakService.isLoggedIn();
  }

  changeModule(event: { target: { id: string; }; }) {
    this.activeModule = event.target.id;
  }

  get isComModule() {
		return this.settingsService.user.isUserHasRoleForAccessToModule(Module.ComMan)
  }

  get isInforming() {
		return this.settingsService.user.isUserHasRoleForAccessToModule(Module.VisualInforming);
  }

  get isWorkflow() {
		return this.settingsService.user.isUserHasRoleForAccessToModule(Module.Workflow);
  }

  get isBalance() {
		return this.settingsService.user.isUserHasRoleForAccessToModule(Module.WeightBalance);
  }

  get isLostFound() {
		return this.settingsService.user.isUserHasRoleForAccessToModule(Module.LostFound);
  }

  get isFlights() {
		return this.settingsService.user.isUserHasRoleForAccessToModule(Module.Schedule);
  }
}
