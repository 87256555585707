import { Component, Input } from '@angular/core';
import { DAMAGES, DamagesList } from 'src/app/lost-found-module/types/const';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { LANGUAGES } from '@shared/const';
import { SettingsService } from '@core/services/settings/settings.service';

@Component({
  selector: 'baggage-damage-modal',
  templateUrl: './baggage-damage-modal.component.html',
  styleUrls: ['./baggage-damage-modal.component.less']
})
export class BaggageDamageModalComponent {

  @Input() damage_types;
  @Input() damage_levels;
  @Input() damages: UntypedFormArray;
  @Input() parentForm: UntypedFormGroup;

  LANGUAGES = LANGUAGES;
  DAMAGES = Object.values(DAMAGES);
  DamagesList = DamagesList;

  constructor(
    private settingsService: SettingsService
  ) {}

  changeType(damageItem: UntypedFormGroup, event) {
    if (event && event.code) {
      damageItem.get('type').patchValue(event.code);
    } else {
      damageItem.get('type').patchValue('');
    }
    this.changeValue(damageItem);
  }

  changeLevel(damageItem: UntypedFormGroup, event) {
    if (event && event.code) {
      damageItem.get('level').patchValue(event.code);
    } else {
      damageItem.get('level').patchValue('');
    }
    this.changeValue(damageItem);
  }

  changeValue(damageItem: UntypedFormGroup) {
    // const damageItem = (this.parentForm.get('damages') as FormArray).at(i);
    if (!damageItem.get('side').value && !damageItem.get('typeId').value && !damageItem.get('levelId').value) {
      damageItem.get('side').removeValidators([Validators.required]);
      damageItem.get('typeId').removeValidators([Validators.required]);
      damageItem.get('levelId').removeValidators([Validators.required]);
      damageItem.get('side').updateValueAndValidity();
      damageItem.get('typeId').updateValueAndValidity();
      damageItem.get('levelId').updateValueAndValidity();
      this.parentForm.markAsUntouched();
    } else {
      damageItem.get('side').setValidators([Validators.required]);
      damageItem.get('typeId').setValidators([Validators.required]);
      damageItem.get('levelId').setValidators([Validators.required]);
      damageItem.get('side').updateValueAndValidity();
      damageItem.get('typeId').updateValueAndValidity();
      damageItem.get('levelId').updateValueAndValidity();
    }
  }

  closeForm() {
    if (!this.parentForm.valid) {
      this.parentForm.markAllAsTouched();
    }
    else {
      return;
    }
  }

	get language() {
		return this.settingsService.language;
	}
}
