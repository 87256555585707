import { ModuleName } from '@settings/global-settings';
import { GlobalI18n } from '@settings/global-i18n';
import { mtk2Lat, mtk2Ru } from './transliteration';

// Массив разрешенных символов для SITATEX
const symbolsForSitatex = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'];

const globalI18n = new GlobalI18n();

// ID каналов
const channelIds = {
    aftnId: 1,
    sitatexId: 2,
    emailId: 3
};

// Функция проверки корректности адресов
export function checkValidAddress(element, channelId, language = '') {
    let checkAddressLengthError = '';
    let checkAddressValidSymbolError = '';
    if (channelId === channelIds.aftnId && element.length !== 8) {
        if (checkAddressLengthError.length === 0) {
            checkAddressLengthError = globalI18n.getMessage(ModuleName.ComMan, 'addAFTNAddressLength') +
                ': ' + element;
        } else {
            checkAddressLengthError += ',' + element;
        }
    }

    if (channelId === channelIds.aftnId && checkAFTNAddressCorrectness(element, language).length !== 0) {
        if (checkAddressValidSymbolError.length === 0) {
            checkAddressValidSymbolError = globalI18n.getMessage(ModuleName.ComMan, 'addAFTNAddressBadChartec') +
                ' ' + element + ' (' + checkAFTNAddressCorrectness(element, language) + ')';
        } else {
            checkAddressValidSymbolError = ', ' + element + ' (' + checkAFTNAddressCorrectness(element, language) + ')';
        }
    }

    if (channelId === channelIds.sitatexId && element.length != 7) {
        if (checkAddressLengthError.length === 0) {
            checkAddressLengthError = globalI18n.getMessage(ModuleName.ComMan, 'addSITAAddressLength') +
                ': ' + element;
        } else {
            checkAddressLengthError += ',' + element;
        }
    }

    if (channelId === channelIds.sitatexId && checkSITAAddressCorrectness(element).length !== 0) {
        if (checkAddressValidSymbolError.length === 0) {
            checkAddressValidSymbolError = globalI18n.getMessage(ModuleName.ComMan, 'addSITAAddressBadChartec') +
                ' ' + element + ' (' + checkSITAAddressCorrectness(element) + ')';
        } else {
            checkAddressValidSymbolError = ', ' + element + ' (' + checkSITAAddressCorrectness(element) + ')';
        }
    }

    if (channelId === channelIds.emailId) {
        // const pattern = /[^@]+@[^@]+\.[a-zA-Z]{2,6}/;
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(element)) {
            checkAddressValidSymbolError = globalI18n.getMessage(ModuleName.ComMan, 'addEmailAddressBadChartec') + ': ' + element;
        }
    }

    let error = '';

    // Для корректного добавления сообщений друг к другу по длине адреса
    // и корректности символов, каждое проверяется на наличие сообщений
    // и добавляется через разделитель точку и пробелом
    if (checkAddressLengthError.length > 0) {
        error = checkAddressLengthError + '. ';
    }
    if (checkAddressValidSymbolError.length > 0) {
        error += checkAddressValidSymbolError + '. ';
    }

    return error;
}

function checkAFTNAddressCorrectness(text, language): string {
    text = text.toUpperCase();
    const badChartec: string[] = [];
    const messageArray = text.split('');
    let arrayIndex = -1;
    messageArray.forEach(element => {
        if (language == 'RU') {
            arrayIndex = mtk2Ru.indexOf(element);
        } else if (language === 'LAT') {
            arrayIndex = mtk2Lat.indexOf(element);
        } else {
            arrayIndex = mtk2Ru.indexOf(element);
            if (arrayIndex === -1) {
                arrayIndex = mtk2Lat.indexOf(element);
            }
        }
        if (arrayIndex === -1) {
            badChartec.push(element);
        }
    });
    if (badChartec.length !== 0) {
        return badChartec.join(',');
    } else {
        return '';
    }
}

function checkSITAAddressCorrectness(text): string {
    text = text.toUpperCase();
    const badChartec: string[] = [];
    const messageArray = text.split('');
    let arrayIndex = -1;
    messageArray.forEach(element => {
        arrayIndex = symbolsForSitatex.indexOf(element);
        if (arrayIndex === -1) {
            badChartec.push(element);
        }
    });
    if (badChartec.length !== 0) {
        return badChartec.join(',');
    } else {
        return '';
    }
}

export function correctingAddress(address, channel) {
	let result = null;
	switch (channel) {
		case channelIds.aftnId: {
			if (address.length === 8) {
				result = address;
			}
			break;
		}
		case channelIds.sitatexId: {
			if (address.length === 7) {
				result = address;
			}
			break;
		}
		case channelIds.emailId: {
			const pattern = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
			const found = address.match(pattern);
			if (found) {
				result = found[0];
			}
			break;
		}
	}
	return result;
}