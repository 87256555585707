import { Component, Injectable } from '@angular/core';
import { UserNotification } from '@settings/global-settings';

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.less'],
})
@Injectable({
	providedIn: 'root',
})
export class NotificationsComponent {
	userNotifications: Array<UserNotification>;

	constructor() {
		this.getNotifications();
	}

	getNotifications() {
		this.userNotifications = []; //this.globalSettings.getNotifications();
	}

	showNotification(title: string, message: string) {
		// this.globalSettings.showNotification(title, message);
		this.getNotifications();
	}
}
