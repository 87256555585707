import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ApiResponse } from '@shared/data-provider/master-data/model/apiResponse';
import { SelectRecords200ResponseInner } from '@shared/data-provider/master-data/model/selectRecords200ResponseInner';
import { Directory } from '../models/directory';

export const MasterDataActions = createActionGroup({
	source: 'MasterData',
	events: {
		'Create Item': props<{
			name: Directory;
			payload: SelectRecords200ResponseInner;
		}>(),
		'Create Item Success': props<{ payload: ApiResponse }>(),
		'Delete Item': props<{
			name: Directory;
			id: string;
			payload: SelectRecords200ResponseInner;
		}>(),
		'Delete Item Success': props<{ payload: ApiResponse }>(),
		'Error Message': props<{ payload: any }>(),
		'List Item': props<{ name: Directory }>(),
		'List Item Success': props<{ payload: SelectRecords200ResponseInner[] }>(),
		'New Item': props<{ payload: SelectRecords200ResponseInner }>(),
		'Read Item': props<{ name: Directory; id: string }>(),
		'Read Item Success': props<{ payload: SelectRecords200ResponseInner }>(),
		'Update Item': props<{
			name: Directory;
			id: string;
			payload: SelectRecords200ResponseInner;
		}>(),
		'Update Item Success': props<{ payload: ApiResponse }>(),
	},
});
