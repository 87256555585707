<nz-tabset
	nzType="card"
	[nzSelectedIndex]="selectedTab">
	<nz-tab *ngFor="let tab of LIST_OF_REFERENCES">
		<a
			*nzTabLink
			nz-tab-link
			[routerLink]="[tab.slug]">
			{{ language === 'EN' ? tab.nameEn : tab.nameRu }}
		</a>
	</nz-tab>
</nz-tabset>

<router-outlet></router-outlet>
