import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	Flight,
	Flights,
	FlightCombined,
	ReferanceStatuses,
	ReferanceDelaySourceOperator,
	ReferanceGeo,
} from '../schedule-module-data/flight';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
	ReferanceAirline,
	ReferanceAirport,
	ReferanceMovement,
	ReferanceDelay,
	ReferanceDelaySource,
	ReferanceAircraft,
	ReferanceTail,
	ReferanceStand,
	ReferanceTerminal,
} from '../referance-module-data/referance';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable({
	providedIn: 'root',
})
export class ScheduleModuleRestApiService {
	private apiScheduleUrl: string;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiScheduleUrl = config.apiScheduleURL;
		});
	}

	// Http Options
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	// HttpClient API get() method => Fetch employees list
	getFlights(filterParams?): Observable<Flights> {
		let url = '/flightsv1';
		if (filterParams) {
			const params = [];
			for (const key in filterParams) {
				if ((key == 'start' || key == 'finish') && filterParams[key]) {
					params.push(key + '=' + filterParams[key].toISOString());
				} else if (filterParams[key]) {
					params.push(key + '=' + filterParams[key]);
				}
			}
			if (params.length > 0) {
				url += '?' + params.join('&');
			}
		}
		return this.http
			.get<Flights>(this.apiScheduleUrl + url)
			.pipe(retry(1), catchError(this.handleError));
	}

	getFlightsCombined(filterParams?): Observable<FlightCombined[]> {
		let url = '/flightsv2';
		if (filterParams) {
			const params = [];
			for (const key in filterParams) {
				if ((key == 'start' || key == 'finish') && filterParams[key]) {
					params.push(key + '=' + filterParams[key].toISOString());
				} else if (filterParams[key]) {
					params.push(key + '=' + filterParams[key]);
				}
			}
			if (params.length > 0) {
				url += '?' + params.join('&');
			}
		}
		return this.http
			.get<FlightCombined[]>(this.apiScheduleUrl + url)
			.pipe(retry(1), catchError(this.handleError));
	}

	// HttpClient API get() method => Fetch employee
	getFlight(id: number): Observable<Flight> {
		return this.http
			.get<Flight>(this.apiScheduleUrl + '/flights/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	getMessagesForFlight(id: number): Observable<Array<any>> {
		console.log(this.apiScheduleUrl + '/messagesForFlight/' + id);
		return this.http
			.get<Array<any>>(this.apiScheduleUrl + '/messagesForFlight/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	addFlight(flight: any): Observable<Flight> {
		return this.http
			.post<Flight>(this.apiScheduleUrl + '/flights', JSON.stringify(flight), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateFlight(id: number, flight: Flight): Observable<Flight> {
		return this.http
			.put<Flight>(this.apiScheduleUrl + '/flights/' + id, JSON.stringify(flight), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	addNextFlight(id: number, nextId: number): Observable<any> {
		return this.http
			.put(this.apiScheduleUrl + '/nextFlight/' + id, { id: nextId }, this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Airlines
	getAirlines(): Observable<ReferanceAirline[]> {
		// /master_data
		return this.http
			.get<ReferanceAirline[]>(this.apiScheduleUrl + '/master_data/airlines')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Airports
	getAirports(): Observable<ReferanceAirport[]> {
		// /master_data
		return this.http
			.get<ReferanceAirport[]>(this.apiScheduleUrl + '/master_data/airports')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Movements
	getMovements(): Observable<ReferanceMovement[]> {
		// /master_data
		return this.http
			.get<ReferanceMovement[]>(this.apiScheduleUrl + '/master_data/movement_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	// GEO
	getGeoTypes(): Observable<ReferanceGeo[]> {
		// /master_data
		return this.http
			.get<ReferanceGeo[]>(this.apiScheduleUrl + '/master_data/geo_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Delays
	getDelays(): Observable<ReferanceDelay[]> {
		// /master_data
		return this.http
			.get<ReferanceDelay[]>(this.apiScheduleUrl + '/master_data/delays')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Delay Sources
	getDelaySources(): Observable<ReferanceDelaySource[]> {
		// /master_data
		return this.http
			.get<ReferanceDelaySource[]>(this.apiScheduleUrl + '/master_data/delay_sources')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Delay Source Operators
	getDelaySourceOperators(id: number): Observable<ReferanceDelaySourceOperator[]> {
		// /master_data
		return this.http
			.get<ReferanceDelaySourceOperator[]>(
				this.apiScheduleUrl + '/delay_source_operators_by_delay_source/' + id
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Aircraft Types
	getAircraftTypes(): Observable<ReferanceAircraft[]> {
		// /master_data
		return this.http
			.get<ReferanceAircraft[]>(this.apiScheduleUrl + '/master_data/aircraft_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Tails
	getTails(): Observable<ReferanceTail[]> {
		// /master_data
		return this.http
			.get<ReferanceTail[]>(this.apiScheduleUrl + '/master_data/tails')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Statuses arrival
	getStatusesArrival(): Promise<ReferanceStatuses[]> {
		// /master_data
		return this.http
			.get<ReferanceStatuses[]>(this.apiScheduleUrl + '/master_data/statuses/arrival')
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Statuses baggage
	getStatusesBaggage(): Observable<ReferanceStatuses[]> {
		// /master_data
		return this.http
			.get<ReferanceStatuses[]>(this.apiScheduleUrl + '/master_data/statuses/baggage')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Statuses checkin
	getStatusesCheckin(): Observable<ReferanceStatuses[]> {
		// /master_data
		return this.http
			.get<ReferanceStatuses[]>(this.apiScheduleUrl + '/master_data/statuses/checkin')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Statuses boarding
	getStatusesBoarding(): Observable<ReferanceStatuses[]> {
		// /master_data
		return this.http
			.get<ReferanceStatuses[]>(this.apiScheduleUrl + '/master_data/statuses/boarding')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Statuses departure
	getStatusesDeparture(): Observable<ReferanceStatuses[]> {
		// /master_data
		return this.http
			.get<ReferanceStatuses[]>(this.apiScheduleUrl + '/master_data/statuses/departure')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Stands
	getStands(): Observable<ReferanceStand[]> {
		// /master_data
		return this.http
			.get<ReferanceStand[]>(this.apiScheduleUrl + '/admin/stands')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Terminals
	getTerminals(): Observable<ReferanceTerminal[]> {
		// /master_data
		return this.http
			.get<ReferanceTerminal[]>(this.apiScheduleUrl + '/admin/terminals')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Error handling
	handleError(error) {
		let errorMessage = '';
		let errorDetail: any = null;
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorDetail = error.error;
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		if (errorDetail) {
			return throwError(errorDetail);
		} else {
			return throwError(errorMessage);
		}
	}
}
