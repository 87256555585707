import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { ModuleName } from '@settings/global-settings';
import { MasterDataService } from '@shared/data-provider/master-data-api/api/masterData.service';
import { AircraftClass } from '@shared/data-provider/master-data-api/model/aircraftClass';
import { AircraftKind } from '@shared/data-provider/master-data-api/model/aircraftKind';
import { AircraftType } from '@shared/data-provider/master-data-api/model/aircraftType';
import { convertDateToUTC } from '@shared/functions/dateFunctions';
import { NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs';
import { MasterDataFunctionsService } from '../../services/master-data.service';
import { CustomFormValidators } from '@shared/custom-form-validators';
import { SettingsService } from '@core/services/settings/settings.service';

interface ExtendedAircraftType extends AircraftType {
	nameEn?: string;
	nameRu?: string;
	nameLocal?: string;
	dtRangeStart?: Date;
	dtRangeFinish?: Date;
}

@Component({
	selector: 'app-ac-types',
	templateUrl: './ac-types.component.html',
	styleUrls: ['./ac-types.component.less'],
})
export class AcTypesComponent implements OnInit {
	constructor(
		private apiMasterData: MasterDataService,
		private settingsService: SettingsService,
		private modal: NzModalService,
		private fb: UntypedFormBuilder,
		private globalI18n: GlobalI18n,
		private functionsService: MasterDataFunctionsService
	) {}

	private formValidators: CustomFormValidators = new CustomFormValidators();

	private _types: ExtendedAircraftType[] = [];
	type: ExtendedAircraftType;
	typeForm: FormGroup;

	listOfDisplayAircraftTypes: ExtendedAircraftType[] = [];
	listOfDisplayAircraftKinds: { value: number; label: string }[] = [];
	listOfDisplayAircraftClasses: { value: number; label: string }[] = [];

	settingValue = {
		loadingList: false,
		loadingModal: false,
		saving: false,
		searchIata: '',
		searchIcao: '',
		searchName: '',
		isVisibleModal: false,
		loadedPercent: null,
		pageIndex: 1,
	};

	listOfColumn = [];

	ngOnInit() {
		this.loadAircraftKinds();
		this.loadAircraftClasses();
		this.loadAircraftTypes();
		this.listOfColumn = [
			{
				title: 'IATA',
				compare: (a: ExtendedAircraftType, b: ExtendedAircraftType) => a.iata.localeCompare(b.iata),
				priority: false,
			},
			{
				title: 'ICAO',
				compare: (a: ExtendedAircraftType, b: ExtendedAircraftType) => a.icao.localeCompare(b.icao),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(ModuleName.MasterData, 'columnName'),
				compare: (a: ExtendedAircraftType, b: ExtendedAircraftType) => a.nameLocal.localeCompare(b.nameLocal),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(ModuleName.MasterData, 'columnLastUpdate'),
				compare: (a: ExtendedAircraftType, b: ExtendedAircraftType) =>
					Date.parse(a.lastupdate) - Date.parse(b.lastupdate),
				priority: false,
			},
		];
	}

	loadAircraftKinds() {
		this.apiMasterData.selectRecords('aircraft_kinds').subscribe((data: AircraftKind[]) => {
			this.listOfDisplayAircraftKinds = data.map(item => ({
				value: item.id,
				label: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
			}));
		});
	}

	loadAircraftClasses() {
		this.apiMasterData.selectRecords('aircraft_classes').subscribe((data: AircraftClass[]) => {
			this.listOfDisplayAircraftClasses = data.map(item => ({
				value: item.id,
				label: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
			}));
		});
	}

	loadAircraftTypes() {
		this.settingValue.loadingList = true;
		this.apiMasterData.selectRecords('aircraft_types').subscribe((data: AircraftType[]) => {
			this._types = data.map(tail => {
				return {
					...tail,
					nameEn: tail.name[0],
					nameRu: tail.name[1],
					nameLocal: this.settingsService.language == 'EN' ? tail.name[0] : tail.name[1],
					dtRangeStart: new Date(tail.dtRange[0]),
					dtRangeFinish: new Date(tail.dtRange[1]),
				};
			});
			this.settingValue.loadingList = false;
		});
	}

	get listOfDisplayTypes(): ExtendedAircraftType[] {
		// При установленных фильтрах необходимо сбросить пагинацию на 1 страницу
		if (
			this.settingValue.searchIata.length !== 0 ||
			this.settingValue.searchIcao.length !== 0 ||
			this.settingValue.searchName.length !== 0
		) {
			this.onPageIndexChanged(1);
		}
		return this._types.filter(item => {
			return (
				item.iata.toLowerCase().indexOf(this.settingValue.searchIata.toLowerCase()) != -1 &&
				item.icao.toLowerCase().indexOf(this.settingValue.searchIcao.toLowerCase()) != -1 &&
				(item.nameRu.toLowerCase().indexOf(this.settingValue.searchName.toLowerCase()) != -1 ||
					item.nameEn.toLowerCase().indexOf(this.settingValue.searchName.toLowerCase()) != -1)
			);
		});
	}

	openAircraftType(id: number) {
		this.settingValue.loadingList = true;
		return this.apiMasterData
			.selectRecord('aircraft_types', id.toString())
			.pipe(
				map((type: AircraftType) => {
					return {
						...type,
						nameEn: type.name[0],
						nameRu: type.name[1],
						nameLocal: this.settingsService.language == 'EN' ? type.name[0] : type.name[1],
						dtRangeStart: new Date(type.dtRange[0]),
						dtRangeFinish: new Date(type.dtRange[1]),
					};
				})
			)
			.subscribe((type: ExtendedAircraftType) => {
				this.type = type;
				this.typeForm = this.createTypeForm(type);
				this.showModal();
				this.settingValue.loadingList = false;
			});
	}

	addType() {
		this.type = {};
		this.typeForm = this.createTypeForm();
		this.showModal();
	}

	createTypeForm(type?: ExtendedAircraftType) {
		return this.fb.group({
			iata: [
				type ? type.iata : '',
				[Validators.required, this.formValidators.onlyEnglishAndNumbers(), this.formValidators.lengthValidator(3)],
			],
			icao: [
				type ? type.icao : '',
				[Validators.required, this.formValidators.onlyEnglishAndNumbers(), this.formValidators.lengthValidator(4)],
			],
			nameEn: [type ? type.nameEn : '', [Validators.required, this.formValidators.onlyEnglish()]],
			nameRu: [type ? type.nameRu : '', [Validators.required]],
			mtow: [type ? type.mtow : null, [this.formValidators.maxValue(99999)]],
			kindId: [type ? type.kindId : null],
			classId: [type ? type.classId : null],
			dtRangeStart: [type ? convertDateToUTC(new Date(type.dtRangeStart)) : new Date(), [Validators.required]],
			dtRangeFinish: [
				type ? convertDateToUTC(new Date(type.dtRangeFinish)) : new Date('2099-12-31 23:59'),
				[Validators.required],
			],
		});
	}

	add() {
		this.typeForm.markAllAsTouched();
		if (this.typeForm.valid) {
			this.settingValue.saving = true;
			this.apiMasterData.insertRecord('aircraft_types', null, this.createFromForm()).subscribe({
				next: () => {
					this.closeModal();
					this.loadAircraftTypes();
				},
				error: err => {
					this.displayError(err);
				},
				complete: () => {
					this.settingValue.saving = false;
				},
			});
		}
	}

	save() {
		this.typeForm.markAllAsTouched();
		if (this.typeForm.valid) {
			this.settingValue.saving = true;
			this.apiMasterData.updateRecord('aircraft_types', this.type.id.toString(), this.createFromForm()).subscribe({
				next: () => {
					this.closeModal();
					this.loadAircraftTypes();
				},
				error: err => this.displayError(err),
				complete: () => {
					this.settingValue.saving = false;
				},
			});
		}
	}

	delete() {
		this.settingValue.saving = true;
		this.typeForm.get('dtRangeFinish').setValue(new Date());
		this.apiMasterData.updateRecord('aircraft_types', this.type.id.toString(), this.createFromForm()).subscribe({
			next: () => {
				this.closeModal();
				this.loadAircraftTypes();
			},
			error: err => this.displayError(err),
			complete: () => {
				this.settingValue.saving = false;
			},
		});
	}

	duplicate() {
		this.type.id = undefined;
		this.type.lastupdate = undefined;
	}

	showDeleteConfirm(): void {
		this.modal.confirm({
			nzTitle: this.globalI18n.getMessage(ModuleName.MasterData, 'deleteItem'),
			nzOkText: this.globalI18n.getMessage(ModuleName.MasterData, 'yes'),
			nzOkType: 'primary',
			nzOkDanger: true,
			nzOnOk: () => this.delete(),
			nzCancelText: this.globalI18n.getMessage(ModuleName.MasterData, 'no'),
			nzOnCancel: () => console.log('Cancel'),
		});
	}

	createFromForm(): AircraftType {
		return {
			...this.type,
			...this.typeForm.value,
			name: [this.typeForm.get('nameEn').value, this.typeForm.get('nameRu').value],
			dtRange: [this.typeForm.get('dtRangeStart').value, this.typeForm.get('dtRangeFinish').value],
		};
	}

	downloadCsvFile() {
		this.functionsService.downloadCsvFile('aircraft_types', this.settingValue.loadingList);
	}

	downloadXmlFile() {
		this.functionsService.downloadXmlFile('aircraft_types', this.settingValue.loadingList);
	}

	onPageIndexChanged(index: number) {
		this.settingValue.pageIndex = index;
	}

	showModal(): void {
		this.settingValue.isVisibleModal = true;
	}

	closeModal(): void {
		this.settingValue.isVisibleModal = false;
	}

	displayError(err): void {
		this.modal.create({
			nzTitle: 'Error',
			nzContent: err.message,
			nzClosable: false,
		});
	}
}
