import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WeightBalanceRoutingModule } from './weight-balance-routing.module';
import { WeightBalanceComponent } from './components/weight-balance.component';
import { WeightBalanceItemComponent } from './components/weight-balance-item/weight-balance-item.component';
import { WeightBalanceListComponent } from './components/weight-balance-list/weight-balance-list.component';
import { EffectsModule } from '@ngrx/effects';
import { WeightBalanceEffects } from './state/weight-balance.effects';
import { StoreModule } from '@ngrx/store';
import * as fromWeightBalance from './state/weight-balance.reducer';

@NgModule({
	declarations: [
		WeightBalanceComponent,
		WeightBalanceItemComponent,
		WeightBalanceListComponent,
	],
	imports: [
		CommonModule,
		WeightBalanceRoutingModule,
		EffectsModule.forFeature([WeightBalanceEffects]),
		StoreModule.forFeature(
			fromWeightBalance.weightBalanceFeatureKey,
			fromWeightBalance.reducer
		),
	],
})
export class WeightBalanceModule {}
