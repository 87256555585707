<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom">
		<div class="row align-items-center">
			<div class="col">
				<button
					type="button"
					class="btn btn-light-gray"
					data-toggle="modal"
					data-target=".bd-example-modal-xl"
					(click)="createSlot()">
					<i class="material-icons">add</i>
					<span i18n="slc|Control panel - add slot@@slc.controlPanel.button.addSlot"> Add slot </span>
				</button>
			</div>
			<div class="col">
				<div class="input-group">
					<div class="input-group-prepend">
						<span
							class="input-group-text"
							i18n="slc|Control panel - season select@@slc.controlPanel.field.season">
							Season
						</span>
					</div>
					<ng-select
						class="form-control form-control-sm p-0 border-0 rounded-0 rounded-right"
						[items]="referanceSeasons"
						[bindLabel]="language == 'EN' ? 'nameEnglish' : 'nameLocal'"
						bindValue="id"
						name="paramSlotsSeason"
						id="paramSlotsSeason"
						[(ngModel)]="paramSlotsSeason"
						(change)="selectSlotFilterSeason($event.id)">
					</ng-select>
				</div>
			</div>
			<div class="col">
				<div class="input-group">
					<div class="input-group-prepend">
						<span
							class="input-group-text"
							i18n="slc|Control panel - slot schedule select@@slc.controlPanel.field.slotSchedule">
							Slot schedule
						</span>
					</div>
					<ng-select
						class="form-control form-control-sm p-0 border-0"
						[items]="referanceScheduleTypes"
						[bindLabel]="language == 'EN' ? 'nameEnglish' : 'nameLocal'"
						bindValue="id"
						name="paramSlotsSchedule"
						id="paramSlotsSchedule"
						[(ngModel)]="paramSlotsSchedule"
						(change)="selectSlotFilterSchedule($event.id)">
					</ng-select>
				</div>
			</div>
		</div>
	</nz-header>
	<nz-content class="h-100 bg-white p-1">
		<table class="table w-100 slot-coordination-table">
			<thead>
				<tr class="text-light type-head table__main-header">
					<th
						class="text-uppercase head-border-top text-center"
						i18n="slc|Slot table - header column airline@@slc.table.header.column.airline">
						Airline
					</th>
					<th
						class="text-uppercase head-border__top text-center"
						i18n="slc|Slot table - header column flight@@slc.table.header.column.flight">
						Flight
					</th>
					<th
						class="text-uppercase head-border__top text-center"
						i18n="slc|Slot table - header column movement@@slc.table.header.column.movement">
						Movement
					</th>
					<th
						class="text-uppercase head-border__top text-center"
						i18n="slc|Slot table - header column route@@slc.table.header.column.route">
						Route
					</th>
					<th
						class="text-uppercase head-border__top text-center"
						i18n="slc|Slot table - header cheader olumn period@@slc.table.header.column.period">
						Periods
					</th>
				</tr>
			</thead>
			<tbody *ngFor="let slot of slots; let slot_index = index">
				<ng-container *ngFor="let routes of slot.routes; let routes_index = index">
					<tr
						class="bg-white"
						data-toggle="modal"
						data-target=".bd-example-modal-xl"
						(click)="loadSlot(slot.id, routes_index)">
						<td
							class=""
							*ngIf="routes_index == 0"
							[attr.rowspan]="slot.routes.length">
							<div class="font-large font-weight-bold py-2 text-center">
								{{ slot.airline }}
							</div>
						</td>
						<td
							class=""
							*ngIf="routes_index == 0"
							[attr.rowspan]="slot.routes.length">
							<div class="input-group-sm pr-2 pl-2 text-center">
								{{ slot.flight }}
							</div>
						</td>
						<td
							class="body-border__right--gray-normal"
							*ngIf="routes_index == 0"
							[attr.rowspan]="slot.routes.length">
							<div class="input-group-sm pr-2 pl-2 text-center">
								{{ slot.movementType }}
							</div>
						</td>
						<td
							class="body-border__right--gray-normal"
							[ngClass]="{ 'border-top-0 border-left-0 rounded-0': routes_index == slot.routes.length - 1 }">
							<div
								class="row"
								*ngFor="let route of routes.route">
								<div class="col-6 text-center">
									<span
										class="font-small font-weight-normal text-gray-blue-dark"
										i18n="slc|Slot table - header row departure@@slc.table.header.row.departure">
										departure </span
									><br />
									<span class="font-large font-weight-bold">{{ route.timeD }}</span
									><br />
									<span class="font-small">{{ route.airportD }}</span>
								</div>
								<div class="col-6 text-center">
									<span
										class="font-small font-weight-normal text-gray-blue-dark"
										i18n="slc|Slot table - header row arrival@@slc.table.header.row.arrival">
										arrival </span
									><br />
									<span class="font-large font-weight-bold">{{ route.timeA }}</span
									><br />
									<span class="font-small">{{ route.airportA }}</span>
								</div>
							</div>
						</td>
						<td class="body-border__right--gray-normal">
							<div
								class="input-group-sm pr-2 pl-2 text-center"
								*ngFor="let period of routes.periods">
								{{ period.start | date : 'dd.MM.yyy' }} - {{ period.finish | date : 'dd.MM.yyy' }} <br />
								<span
									class="font-small font-weight-normal text-gray-blue-dark"
									i18n="slc|Slot table - header row days@@slc.table.header.row.period">
									Days: </span
								>&nbsp;<b>{{ period.frequency }}</b
								>&nbsp;<span
									class="font-small font-weight-normal text-gray-blue-dark"
									i18n="slc|Slot table - header row count@@slc.table.header.row.count">
									Count:
								</span>
								{{ period.count }}
							</div>
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>

		<!-- Module -->
		<div
			class="modal fade bd-example-modal-xl mw-100"
			data-backdrop="static"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myExtraLargeModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-xl load--max-width">
				<form #slotForm="ngForm">
					<div
						class="loading"
						*ngIf="loading">
						<div class="cssload-spin-box"></div>
					</div>
					<div class="modal-content bg-light-gray">
						<div class="modal-header">
							<h5
								class="modal-title"
								id="exampleModalScrollableTitle"
								i18n="slc|Slot card - header name@@slc.slotCard.header.name">
								Slot
							</h5>
							<button
								#slotModalForm
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row mb-2">
								<div class="col-4 pr-2">
									<div class="card">
										<div class="card-body">
											<p class="m-0 h6 card__header--important">
												<i class="material-icons">directions</i>
												<span i18n="slc|Slot card - group movement name@@slc.slotCard.groupMovement.name"
													>Movement</span
												>
											</p>
											<div class="row">
												<div class="col">
													<label class="col-form-label col-form-label-sm">
														<span i18n="slc|Slot card - group movement type@@slc.slotCard.groupMovement.type">Type</span
														><span class="required-asterisk">*</span>
													</label>
													<!-- Type -->
													<div class="input-group p-0">
														<ng-select
															class="form-control custom-sm p-0"
															[items]="referanceMovements"
															bindLabel="nameAndCode"
															bindValue="id"
															#movementType="ngModel"
															name="movementType"
															id="movementType"
															[(ngModel)]="slot.movementTypeId"
															[virtualScroll]="true"
															required>
														</ng-select>
														<div
															*ngIf="!movementType?.valid && (movementType?.dirty || movementType?.touched)"
															class="invalid-feedback d-block">
															<div
																*ngIf="movementType.errors.required"
																i18n="slc|Slot card - group movement req@@slc.slotCard.groupMovement.type.req">
																Movement type is required
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-8 pl-0">
									<div class="card">
										<div class="card-body">
											<p class="m-0 h6 card__header--important">
												<i class="material-icons">import_contacts</i>
												<span i18n="slc|Slot card - group season name@@slc.slotCard.groupSeason.name">Season</span>
											</p>
											<div class="row">
												<div class="col">
													<label class="col-form-label col-form-label-sm">
														<span i18n="slc|Slot card - group season season@@slc.slotCard.groupSeason.season"
															>Season</span
														><span class="required-asterisk">*</span>
													</label>
													<ng-select
														class="form-control custom-sm p-0"
														[items]="referanceSeasons"
														bindLabel="names"
														bindValue="id"
														#seasonID="ngModel"
														name="seasonID"
														id="seasonID"
														[(ngModel)]="slot.seasonID"
														[virtualScroll]="true"
														required>
													</ng-select>
												</div>
												<div class="col pl-0">
													<label class="col-form-label col-form-label-sm">
														<span
															i18n="slc|Slot card - group season slot schedule@@slc.slotCard.groupSeason.slotSchedule"
															>Slot Schedule</span
														><span class="required-asterisk">*</span>
													</label>
													<ng-select
														class="form-control custom-sm p-0"
														[items]="referanceScheduleTypes"
														bindLabel="names"
														bindValue="id"
														#scheduleTypeID="ngModel"
														name="scheduleTypeID"
														id="scheduleTypeID"
														[(ngModel)]="slot.scheduleTypeID"
														[virtualScroll]="true"
														required>
													</ng-select>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 mt-2">
									<div class="card">
										<div class="card-body">
											<p class="m-0 h6 card__header--important">
												<i class="material-icons">apartment</i>
												<span i18n="slc|Slot card - group airline name@@slc.slotCard.groupAirline.name">Airline</span>
											</p>
											<div class="row">
												<div class="col-2 pr-1">
													<label class="col-form-label col-form-label-sm">
														<span i18n="slc|Slot card - group airline iata@@slc.slotCard.groupAirline.iata">IATA</span
														><span class="required-asterisk">*</span>
													</label>
													<ng-select
														class="form-control custom-sm p-0"
														[items]="referanceAirline"
														bindLabel="iata"
														bindValue="id"
														#airlineIata="ngModel"
														name="airlineIata"
														id="airlineIata"
														[(ngModel)]="slot.airlineId"
														[virtualScroll]="true"
														[loading]="referanceAirlinesLoading"
														(scroll)="onScrollReferanceAirlines($event)"
														(scrollToEnd)="onScrollReferanceAirlinesToEnd()"
														required>
													</ng-select>
													<div
														*ngIf="!airlineIata?.valid && (airlineIata?.dirty || airlineIata?.touched)"
														class="invalid-feedback d-block">
														<div
															*ngIf="airlineIata.errors.required"
															i18n="slc|Slot card - group airline iata req@@slc.slotCard.groupAirline.iata.req">
															IATA is required
														</div>
													</div>
												</div>
												<div class="col-2 pl-0 pr-1">
													<label class="col-form-label col-form-label-sm">
														<span i18n="slc|Slot card - group airline icao@@slc.slotCard.groupAirline.icao">ICAO</span
														><span class="required-asterisk">*</span>
													</label>
													<ng-select
														class="form-control custom-sm p-0"
														[items]="referanceAirline"
														bindLabel="icao"
														bindValue="id"
														#airlineIcao="ngModel"
														name="airlineIcao"
														id="airlineIcao"
														[(ngModel)]="slot.airlineId"
														[virtualScroll]="true"
														[loading]="referanceAirlinesLoading"
														(scroll)="onScrollReferanceAirlines($event)"
														(scrollToEnd)="onScrollReferanceAirlinesToEnd()"
														required>
													</ng-select>
													<div
														*ngIf="!airlineIcao?.valid && (airlineIcao?.dirty || airlineIcao?.touched)"
														class="invalid-feedback d-block">
														<div
															*ngIf="airlineIcao.errors.required"
															i18n="slc|Slot card - group airline icao req@@slc.slotCard.groupAirline.icao.req">
															ICAO is required
														</div>
													</div>
												</div>
												<div class="col-5 pl-0 pr-1">
													<label class="col-form-label col-form-label-sm">
														<span i18n="slc|Slot card - group airline field name@@slc.slotCard.groupAirline.field.name"
															>Name</span
														><span class="required-asterisk">*</span>
													</label>
													<ng-select
														class="form-control custom-sm p-0"
														[items]="referanceAirline"
														bindLabel="name"
														bindValue="id"
														name="airlineName"
														id="airlineName"
														[(ngModel)]="slot.airlineId"
														[virtualScroll]="true"
														[loading]="referanceAirlinesLoading"
														#airlineName="ngModel"
														(scroll)="onScrollReferanceAirlines($event)"
														(scrollToEnd)="onScrollReferanceAirlinesToEnd()"
														required>
													</ng-select>
													<div
														*ngIf="!airlineName?.valid && (airlineName?.dirty || airlineName?.touched)"
														class="invalid-feedback d-block">
														<div
															*ngIf="airlineName.errors.required"
															i18n="slc|Slot card - group airline name req@@slc.slotCard.groupAirline.name.req">
															Name is required
														</div>
													</div>
												</div>
												<div class="col-3 pl-0">
													<label class="col-form-label col-form-label-sm">
														<span i18n="slc|Slot card - group airline flight@@slc.slotCard.groupAirline.flight"
															>Flight</span
														><span class="required-asterisk">*</span>
													</label>
													<input
														type="text"
														class="form-control form-control-sm"
														name="flightinput"
														[(ngModel)]="slot.flight"
														#flightinput="ngModel"
														pattern="^[/д//Д/a-zA-Z0-9]{0,}$"
														maxlength="5"
														required />
													<div
														*ngIf="!flightinput?.valid && (flightinput?.dirty || flightinput?.touched)"
														class="invalid-feedback d-block">
														<div
															*ngIf="flightinput.errors.required"
															i18n="slc|Slot card - group airline flight req@@slc.slotCard.groupAirline.flight.req">
															Flight code is required
														</div>
														<div
															*ngIf="flightinput.errors.pattern"
															i18n="
																slc|Slot card - group airline flight english or
																number@@slc.slotCard.groupAirline.flight.engOrNumber">
															Flight is English only or number
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- Route tabs -->
							<ul class="nav nav-tabs">
								<li
									class="nav-item"
									*ngFor="let routes of slot.routes; let i = index"
									(click)="clearDeleteQueue()">
									<a
										class="nav-link text-dark"
										[ngClass]="{ active: i == activeTabRoute }"
										data-toggle="tab"
										href="#routes_{{ i }}">
										<span i18n="slc|Slot card - group route name@@slc.slotCard.groupRoute.name">Route</span>&nbsp;{{
											i + 1
										}}
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link p-0"
										(click)="addRoutes()">
										<i class="material-icons pt-2 px-2">add</i>
									</a>
								</li>
							</ul>
							<!-- Route points  -->
							<div class="tab-content">
								<div
									class="tab-pane fade border-bottom border-right border-left p-3 bg-white"
									[ngClass]="{ 'show active': i == activeTabRoute }"
									id="routes_{{ i }}"
									*ngFor="let routes of slot.routes; let i = index">
									<div class="row my-1">
										<div class="col">
											<p class="m-0 h6 card__header--important">
												<i class="material-icons">map</i>
												<span i18n="slc|Slot card - group route points name@@slc.slotCard.groupRoute.pointsName"
													>Points</span
												>
											</p>
										</div>
										<div class="col">
											<div class="mb-1 float-right">
												<button
													type="button"
													class="btn btn-sm btn-light-gray mr-1"
													(click)="addRoutePoint(i)">
													<i class="material-icons">add_circle_outline</i>
													<span
														i18n="
															slc|Slot card - group route button add route
															point@@slc.slotCard.groupRoute.button.addRoutePoint"
														>Add route point</span
													>
												</button>
												<button
													type="button"
													class="btn btn-sm btn-red"
													(click)="deleteRoutePoint(i)"
													[disabled]="deleteQueueRoute.size == 0">
													<i class="material-icons">delete</i>
													<span i18n="slc|Slot card - group route button delete@@slc.slotCard.groupRoute.button.delete"
														>Delete</span
													>
												</button>
											</div>
										</div>
									</div>

									<table class="table table-borderless table-hover table-sm">
										<thead>
											<tr class="text-muted detail-head table__mail-header--subhead">
												<th class="font-small text-center text-uppercase head-border-top"></th>
												<th
													class="font-small text-center text-uppercase head-border-top"
													i18n="
														slc|Slot card - group route table points column city\airport
														dep@@slc.slotCard.groupRoute.table.points.heade.col.cityAirportDeparture">
													CITY\AIRPORT
												</th>
												<th
													class="font-small text-center text-uppercase head-border-top"
													i18n="
														slc|Slot card - group route table points column
														departure@@slc.slotCard.groupRoute.table.points.heade.col.departure">
													DEP
												</th>
												<th
													class="font-small text-center text-uppercase head-border-top"
													i18n="
														slc|Slot card - group route table points column day
														departure@@slc.slotCard.groupRoute.table.points.heade.col.dayDeparture">
													DAY
												</th>
												<th
													class="font-small text-center text-uppercase head-border-top"
													i18n="
														slc|Slot card - group route table points column city\airport
														arrival@@slc.slotCard.groupRoute.table.points.heade.col.cityAirportarrival">
													CITY\AIRPORT
												</th>
												<th
													class="font-small text-center text-uppercase head-border-top"
													i18n="
														slc|Slot card - group route table points column
														arrival@@slc.slotCard.groupRoute.table.points.heade.col.arrival">
													ARR
												</th>
												<th
													class="font-small text-center text-uppercase head-border-top head-border-right-bold"
													i18n="
														slc|Slot card - group route table points column day
														arrival@@slc.slotCard.groupRoute.table.points.heade.col.dayArrival">
													DAY
												</th>
												<th
													class="font-small text-center text-uppercase head-border-top"
													i18n="
														slc|Slot card - group route table points column
														aircraft@@slc.slotCard.groupRoute.table.points.heade.col.aircraft">
													Aircraft
												</th>
												<th
													class="font-small text-center text-uppercase head-border-top"
													i18n="
														slc|Slot card - group route table points column
														geo@@slc.slotCard.groupRoute.table.points.heade.col.geo">
													GEO
												</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let route of routes.route; let j = index">
												<td class="text-center">
													<div class="input-group-sm pr-2 pl-2">
														<input
															type="checkbox"
															name="checkboxRoute"
															value="{{ j }}"
															(click)="addToDeleteQueueRoute(j)"
															aria-label="Checkbox for following text input" />
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0 slot__airport">
														<ng-select
															class="form-control custom-sm p-0"
															[items]="referanceAirport"
															bindLabel="names"
															bindValue="id"
															name="airportIdD_{{ i }}_{{ j }}"
															id="airportIdD_{{ i }}_{{ j }}"
															[(ngModel)]="route.airportIdD"
															[virtualScroll]="true"
															[loading]="referanceAirportsLoading"
															(scroll)="onScrollReferanceAirports($event)"
															(scrollToEnd)="onScrollReferanceAirportsToEnd()"
															required>
														</ng-select>
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0">
														<input
															type="time"
															class="form-control my-1"
															[ngModel]="route.timeD"
															(input)="route.timeD = $event.target.value"
															name="timeD_{{ i }}_{{ j }}" />
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0">
														<input
															type="number"
															max="5"
															class="form-control"
															[(ngModel)]="route.dayD"
															name="dayD_{{ i }}_{{ j }}" />
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0 slot__airport">
														<ng-select
															class="form-control custom-sm p-0"
															[items]="referanceAirport"
															bindLabel="names"
															bindValue="id"
															name="airportIdA_{{ i }}_{{ j }}"
															id="airportIdA_{{ i }}_{{ j }}"
															[(ngModel)]="route.airportIdA"
															[virtualScroll]="true"
															[loading]="referanceAirportsLoading"
															(scroll)="onScrollReferanceAirports($event)"
															(scrollToEnd)="onScrollReferanceAirportsToEnd()"
															required>
														</ng-select>
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0">
														<input
															type="time"
															class="form-control my-1"
															[ngModel]="route.timeA"
															(input)="route.timeA = $event.target.value"
															name="timeA_{{ i }}_{{ j }}" />
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0">
														<input
															type="number"
															max="5"
															class="form-control"
															[(ngModel)]="route.dayA"
															name="dayA_{{ i }}_{{ j }}" />
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0">
														<div class="input-group-sm p-0">
															<ng-select
																class="form-control airports-long custom-sm p-0"
																[items]="referanceAircraftTypes"
																bindLabel="names"
																bindValue="id"
																name="aircraftTypeId_{{ i }}_{{ j }}"
																id="aircraftTypeId_{{ i }}_{{ j }}"
																[(ngModel)]="route.aircraftTypeId"
																required>
																<ng-template
																	ng-option-tmp
																	let-item="item">
																	<span>{{ item.names }}</span>
																</ng-template>
															</ng-select>
														</div>
													</div>
												</td>
												<td class="text-center">
													<div class="input-group-sm p-0">
														<div class="input-group-sm p-0 slot__airport">
															<ng-select
																class="form-control form-control-sm p-0 border-0"
																[items]="referanceGeoTypes"
																[bindLabel]="language == 'EN' ? 'nameEnglish' : 'nameLocal'"
																bindValue="id"
																name="geoTypeId_{{ i }}_{{ j }}"
																id="geoTypeId_{{ i }}_{{ j }}"
																[(ngModel)]="route.geoTypeId">
															</ng-select>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
									<hr />
									<!-- Periods  -->
									<div class="row my-1">
										<div class="col">
											<p class="m-0 h6 card__header--important">
												<i class="material-icons">query_builder</i>
												<span i18n="slc|Slot card - group route period name@@slc.slotCard.groupRoute.period.name"
													>Periods</span
												>
											</p>
										</div>
										<div class="col">
											<div class="my-2 float-right">
												<button
													type="button"
													class="btn btn-sm btn-light-gray mr-1"
													(click)="addPeriod(i)">
													<i class="material-icons">add_circle_outline</i>
													<span
														i18n="
															slc|Slot card - group route table points button add
															period@@slc.slotCard.groupRoute.table.points.button.addPeriod"
														>Add period</span
													>
												</button>
												<button
													type="button"
													class="btn btn-sm btn-red"
													(click)="deletePeriod(i)"
													[disabled]="deleteQueuePeriod.size == 0">
													<i class="material-icons">delete</i>
													<span
														i18n="
															slc|Slot card - group route table points button
															delete@@slc.slotCard.groupRoute.table.points.button.delete"
														>Delete</span
													>
												</button>
											</div>
										</div>
									</div>
									<!-- Table periods -->
									<table class="table table-borderless table-hover table-sm">
										<thead>
											<tr class="text-muted detail-head table__mail-header--subhead">
												<th class="font-small text-center text-uppercase"></th>
												<th
													class="font-small text-center text-uppercase"
													i18n="
														slc|Slot card - group route table points header col
														from@@slc.slotCard.groupRoute.table.points.header.col.from">
													From
												</th>
												<th
													class="font-small text-center text-uppercase"
													i18n="
														slc|Slot card - group route table points header col
														to@@slc.slotCard.groupRoute.table.points.header.col.to">
													To
												</th>
												<th
													class="font-small text-center text-uppercase"
													i18n="
														slc|Slot card - group route table points header col
														frequency@@slc.slotCard.groupRoute.table.points.header.col.frequency">
													Frequency
												</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let period of routes.periods; let j = index">
												<td class="text-center">
													<div class="input-group-sm p-0">
														<input
															type="checkbox"
															name="checkboxPeriod"
															value="{{ j }}"
															(click)="addToDeleteQueuePeriod(j)"
															aria-label="Checkbox for following text input" />
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0">
														<input
															type="date"
															class="form-control my-1"
															[ngModel]="period.start | date : 'yyyy-MM-dd' : 'UTC+0'"
															(blur)="period.start = parseDate($event.target.value)"
															name="dateStart_{{ i }}_{{ j }}" />
													</div>
												</td>
												<td class="text-center body-border__right--gray-normal">
													<div class="input-group-sm p-0">
														<input
															type="date"
															class="form-control my-1"
															[ngModel]="period.finish | date : 'yyyy-MM-dd' : 'UTC+0'"
															(blur)="period.finish = parseDate($event.target.value)"
															name="dateFinish_{{ i }}_{{ j }}" />
													</div>
												</td>
												<td class="body-border__right--gray-normal">
													<div class="input-group-sm p-0 text-center">
														<input
															type="checkbox"
															class="light mr-1"
															name="frequency[{{ i }}][]"
															(change)="period.frequency = FieldsChange(period.frequency, 0, '1', $event)"
															[checked]="period.frequency[0] == '1'" />
														<label
															class="mr-3 mb-0"
															i18n="
																slc|Slot card - group route table points data row
																monday@@slc.slotCard.groupRoute.table.points.data.row.monday">
															Mo
														</label>
														<input
															type="checkbox"
															class="light mr-1"
															name="frequency[{{ i }}][]"
															(change)="period.frequency = FieldsChange(period.frequency, 1, '2', $event)"
															[checked]="period.frequency[1] == '2'" />
														<label
															class="mr-3 mb-0"
															i18n="
																slc|Slot card - group route table points data row
																tuesday@@slc.slotCard.groupRoute.table.points.data.row.tuesday">
															Tu
														</label>
														<input
															type="checkbox"
															class="light mr-1"
															name="frequency[{{ i }}][]"
															(change)="period.frequency = FieldsChange(period.frequency, 2, '3', $event)"
															[checked]="period.frequency[2] == '3'" />
														<label
															class="mr-3 mb-0"
															i18n="
																slc|Slot card - group route table points data row
																wednesday@@slc.slotCard.groupRoute.table.points.data.row.wednesday">
															We
														</label>
														<input
															type="checkbox"
															class="light mr-1"
															name="frequency[{{ i }}][]"
															(change)="period.frequency = FieldsChange(period.frequency, 3, '4', $event)"
															[checked]="period.frequency[3] == '4'" />
														<label
															class="mr-3 mb-0"
															i18n="
																slc|Slot card - group route table points data row
																thursday@@slc.slotCard.groupRoute.table.points.data.row.thursday">
															Th
														</label>
														<input
															type="checkbox"
															class="light mr-1"
															name="frequency[{{ i }}][]"
															(change)="period.frequency = FieldsChange(period.frequency, 4, '5', $event)"
															[checked]="period.frequency[4] == '5'" />
														<label
															class="mr-3 mb-0"
															i18n="
																slc|Slot card - group route table points data row
																friday@@slc.slotCard.groupRoute.table.points.data.row.friday">
															Fr
														</label>
														<input
															type="checkbox"
															class="light mr-1"
															name="frequency[{{ i }}][]"
															(change)="period.frequency = FieldsChange(period.frequency, 5, '6', $event)"
															[checked]="period.frequency[5] == '6'" />
														<label
															class="mr-3 mb-0"
															i18n="
																slc|Slot card - group route table points data row
																saturday@@slc.slotCard.groupRoute.table.points.data.row.saturday">
															Sa
														</label>
														<input
															type="checkbox"
															class="light mr-1"
															name="frequency[{{ i }}][]"
															(change)="period.frequency = FieldsChange(period.frequency, 6, '7', $event)"
															[checked]="period.frequency[6] == '7'" />
														<label
															class="mr-3 mb-0"
															i18n="
																slc|Slot card - group route table points data row
																sunday@@slc.slotCard.groupRoute.table.points.data.row.sunday">
															Su
														</label>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<div *ngIf="showButton('state-append')">
								<button
									type="button"
									class="btn btn-sm btn-blue"
									(click)="addSlot()">
									<span i18n="slc|Slot card - button append@@slc.slotCard.button.append">Append</span>
								</button>
							</div>
							<div *ngIf="showButton('state-update')">
								<button
									type="button"
									class="btn btn-sm btn-blue"
									(click)="updateSlot(slot.id)">
									<span i18n="slc|Slot card - button save@@slc.slotCard.button.save">Save</span>
								</button>
							</div>
							<!--<button type="button"
											class="btn btn-sm btn-red">
								<span i18n="slc|Slot card - button delete@@slc.slotCard.button.delete">Delete</span>
							</button>-->
							<button
								type="button"
								class="btn btn-sm btn-secondary"
								data-dismiss="modal">
								<span i18n="slc|Slot card - button close@@slc.slotCard.button.close">Close</span>
							</button>
						</div>
						<div
							*ngIf="errorMessage !== ''"
							class="ms-modal-message">
							<div class="modal-content w-25">
								<div
									class="modal-header"
									[ngClass]="{
										'bg-info': errorType === 'info',
										'bg-warning': errorType === 'warning',
										'bg-danger': errorType === 'error'
									}">
									<h5
										*ngIf="errorType === 'info'"
										class="modal-title"
										i18n="slc|Modal error - header information@@slc.modal.errMes.header.inf">
										Information
									</h5>
									<h5
										*ngIf="errorType === 'warning'"
										class="modal-title"
										i18n="slc|Modal error - header warning@@slc.modal.errMes.header.warn">
										Warning
									</h5>
									<h5
										*ngIf="errorType === 'error'"
										class="modal-title"
										i18n="slc|Modal error - header error@@slc.modal.errMes.header.err">
										Error
									</h5>
									<button
										type="button"
										class="close"
										(click)="clearErrorMess()"
										aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<p style="white-space: break-spaces">{{ errorMessage }}</p>
								</div>
								<div class="modal-footer">
									<button
										type="button"
										class="btn btn-sm btn-blue"
										(click)="clearErrorMess()"
										i18n="slc|Modal error - button close@@slc.modal.errMes.button.close">
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</nz-content>
	<!-- <nz-footer></nz-footer> -->
</nz-layout>
