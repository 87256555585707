import { createReducer, on } from '@ngrx/store';
import { WeightBalanceActions } from './weight-balance.actions';

export const weightBalanceFeatureKey = 'weightBalance';

export interface State {}

export const initialState: State = {};

export const reducer = createReducer(initialState);
