import { createSelector, createFeatureSelector } from '@ngrx/store';
import { masterDataFeatureKey, State } from './master-data.reducer';

export const selectMasterDataStore =
	createFeatureSelector<State>(masterDataFeatureKey);

export const selectError = createSelector(
	selectMasterDataStore,
	(state: State) => {
		return state.error;
	}
);

export const selectItem = createSelector(
	selectMasterDataStore,
	(state: State) => {
		return state.item;
	}
);

export const selectState = createSelector(
	selectMasterDataStore,
	(state: State) => {
		return state.state;
	}
);

export const selectItemList = createSelector(
	selectMasterDataStore,
	(state: State) => {
		return state.itemList;
	}
);
