import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	ReferanceAirport,
	ReferanceAirline,
	ReferanceAircraft,
	ReferanceMovement,
	ReferanceDelay,
	ReferanceSeason,
	ReferanceRegion,
	ReferanceCountry,
	ReferanceCity,
	ReferanceAircraftKind,
	ReferanceAircraftClass,
	ReferanceAircraftType,
	ReferanceTail,
	ReferanceDelaySource,
	ReferanceRegularitie,
	ReferanceStand,
	ReferanceStandType,
	ReferanceTerminal,
} from '../referance-module-data/referance';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable({
	providedIn: 'root',
})
export class ReferanceModuleRestApiService {
	private apiReferanceUrl: string;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiReferanceUrl = config.apiReferanceURL;
		});
	}

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	// Airports
	getAirports(): Promise<ReferanceAirport[]> {
		return this.http
			.get<ReferanceAirport[]>(this.apiReferanceUrl + '/airports')
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getAirport(id: number): Promise<ReferanceAirport> {
		return this.http
			.get<ReferanceAirport>(this.apiReferanceUrl + '/airports/' + id)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	createAirport(airport: any): Observable<ReferanceAirport> {
		return this.http
			.post<ReferanceAirport>(this.apiReferanceUrl + '/airports', JSON.stringify(airport), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateAirport(id: string, airport: ReferanceAirport): Promise<ReferanceAirport> {
		return this.http
			.put<ReferanceAirport>(this.apiReferanceUrl + '/airports/' + id, JSON.stringify(airport), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	deleteAirport(id: string) {
		return this.http
			.delete<ReferanceAirport>(this.apiReferanceUrl + '/airports/' + id, this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Airlines
	getAirlines(): Observable<ReferanceAirline[]> {
		return this.http
			.get<ReferanceAirline[]>(this.apiReferanceUrl + '/airlines')
			.pipe(retry(1), catchError(this.handleError));
	}

	getAirline(id: number): Observable<ReferanceAirline> {
		return this.http
			.get<ReferanceAirline>(this.apiReferanceUrl + '/airlines/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createAirline(airline): Observable<ReferanceAirline> {
		return this.http
			.post<ReferanceAirline>(this.apiReferanceUrl + '/airlines', JSON.stringify(airline), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateAirline(id: string, airline: ReferanceAirline): Observable<ReferanceAirline> {
		return this.http
			.put<ReferanceAirline>(this.apiReferanceUrl + '/airlines/' + id, JSON.stringify(airline), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	deleteAirline(id: string) {
		return this.http
			.delete<ReferanceAirline>(this.apiReferanceUrl + '/airlines/' + id, this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Aircraft types
	getAircraftTypes(): Observable<ReferanceAircraftType[]> {
		return this.http
			.get<ReferanceAircraftType[]>(this.apiReferanceUrl + '/aircraft_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	getAircraftType(id: number): Observable<ReferanceAircraftType> {
		return this.http
			.get<ReferanceAircraftType>(this.apiReferanceUrl + '/aircraft_types/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createAircraftType(aircraftType: ReferanceAircraftType): Observable<ReferanceAircraftType> {
		return this.http
			.post<ReferanceAircraftType>(
				this.apiReferanceUrl + '/aircraft_types',
				JSON.stringify(aircraftType),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateAircraftType(id: string, aircraftType: ReferanceAircraftType): Observable<ReferanceAircraftType> {
		return this.http
			.put<ReferanceAircraftType>(
				this.apiReferanceUrl + '/aircraft_types/' + id,
				JSON.stringify(aircraftType),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	deleteAircraftType(id: string) {
		return this.http
			.delete<ReferanceAircraftType>(this.apiReferanceUrl + '/aircraft_types/' + id, this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Movements
	getMovements(): Observable<ReferanceMovement[]> {
		return this.http
			.get<ReferanceMovement[]>(this.apiReferanceUrl + '/movement_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	getMovement(id: number): Observable<ReferanceMovement> {
		return this.http
			.get<ReferanceMovement>(this.apiReferanceUrl + '/movement_types/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createMovement(movement: ReferanceMovement): Observable<ReferanceMovement> {
		return this.http
			.post<ReferanceMovement>(this.apiReferanceUrl + '/movement_types', JSON.stringify(movement), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateMovement(id, movement): Observable<ReferanceMovement> {
		return this.http
			.put<ReferanceMovement>(
				this.apiReferanceUrl + '/movement_types/' + id,
				JSON.stringify(movement),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	deleteMovement(id: string) {
		return this.http
			.delete<ReferanceMovement>(this.apiReferanceUrl + '/movement_types/' + id, this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Delays
	getDelays(): Observable<ReferanceDelay[]> {
		return this.http
			.get<ReferanceDelay[]>(this.apiReferanceUrl + '/delays')
			.pipe(retry(1), catchError(this.handleError));
	}

	getDelay(id: number): Observable<ReferanceDelay> {
		return this.http
			.get<ReferanceDelay>(this.apiReferanceUrl + '/delays/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createDelay(delay: ReferanceDelay): Observable<ReferanceDelay> {
		return this.http
			.post<ReferanceDelay>(this.apiReferanceUrl + '/delays', JSON.stringify(delay), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateDelay(id: string, delay: ReferanceDelay): Observable<ReferanceDelay> {
		return this.http
			.put<ReferanceDelay>(this.apiReferanceUrl + '/delays/' + id, JSON.stringify(delay), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Delay Sources
	getDelaySources(): Observable<ReferanceDelaySource[]> {
		return this.http
			.get<ReferanceDelaySource[]>(this.apiReferanceUrl + '/delay_sources')
			.pipe(retry(1), catchError(this.handleError));
	}

	getDelaySource(id: number): Observable<ReferanceDelaySource> {
		return this.http
			.get<ReferanceDelaySource>(this.apiReferanceUrl + '/delay_sources/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createDelaySource(delay: ReferanceDelaySource): Observable<ReferanceDelaySource> {
		return this.http
			.post<ReferanceDelaySource>(this.apiReferanceUrl + '/delay_sources', JSON.stringify(delay), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateDelaySource(id: string, delay: ReferanceDelaySource): Observable<ReferanceDelaySource> {
		return this.http
			.put<ReferanceDelaySource>(this.apiReferanceUrl + '/delay_sources/' + id, JSON.stringify(delay), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Seasons
	getSeasons(): Observable<ReferanceSeason[]> {
		return this.http
			.get<ReferanceSeason[]>(this.apiReferanceUrl + '/seasons')
			.pipe(retry(1), catchError(this.handleError));
	}

	getSeason(id: number): Observable<ReferanceSeason> {
		return this.http
			.get<ReferanceSeason>(this.apiReferanceUrl + '/seasons/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createSeason(season: ReferanceSeason): Observable<ReferanceSeason> {
		return this.http
			.post<ReferanceSeason>(this.apiReferanceUrl + '/seasons', JSON.stringify(season), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateSeason(id: string, season: ReferanceSeason): Observable<ReferanceSeason> {
		return this.http
			.put<ReferanceSeason>(this.apiReferanceUrl + '/seasons/' + id, JSON.stringify(season), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	deleteSeason(id: string) {
		return this.http
			.delete<ReferanceSeason>(this.apiReferanceUrl + '/seasons/' + id, this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Regions
	getRegions(): Observable<ReferanceRegion[]> {
		return this.http
			.get<ReferanceRegion[]>(this.apiReferanceUrl + '/regions')
			.pipe(retry(1), catchError(this.handleError));
	}

	getRegion(id: number): Observable<ReferanceRegion> {
		return this.http
			.get<ReferanceRegion>(this.apiReferanceUrl + '/regions/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createRegion(region: ReferanceRegion): Observable<ReferanceRegion> {
		return this.http
			.post<ReferanceRegion>(this.apiReferanceUrl + '/regions', JSON.stringify(region), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateRegion(id: string, region: ReferanceRegion): Observable<ReferanceRegion> {
		return this.http
			.put<ReferanceRegion>(this.apiReferanceUrl + '/regions/' + id, JSON.stringify(region), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Countries
	getCountries(): Observable<ReferanceCountry[]> {
		return this.http
			.get<ReferanceCountry[]>(this.apiReferanceUrl + '/countries')
			.pipe(retry(1), catchError(this.handleError));
	}

	getCountry(id: number): Observable<ReferanceCountry> {
		return this.http
			.get<ReferanceCountry>(this.apiReferanceUrl + '/countries/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createCountry(country: ReferanceCountry): Observable<ReferanceCountry> {
		return this.http
			.post<ReferanceCountry>(this.apiReferanceUrl + '/countries', JSON.stringify(country), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateCountry(id: string, country: ReferanceCountry): Observable<ReferanceCountry> {
		return this.http
			.put<ReferanceCountry>(this.apiReferanceUrl + '/countries/' + id, JSON.stringify(country), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Cities
	getCities(): Observable<ReferanceCity[]> {
		return this.http
			.get<ReferanceCity[]>(this.apiReferanceUrl + '/cities')
			.pipe(retry(1), catchError(this.handleError));
	}

	getCity(id: number): Observable<ReferanceCity> {
		return this.http
			.get<ReferanceCity>(this.apiReferanceUrl + '/cities/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createCity(city: ReferanceCity): Observable<ReferanceCity> {
		return this.http
			.post<ReferanceCity>(this.apiReferanceUrl + '/cities', JSON.stringify(city), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateCity(id: string, city: ReferanceCity): Observable<ReferanceCity> {
		return this.http
			.put<ReferanceCity>(this.apiReferanceUrl + '/cities/' + id, JSON.stringify(city), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Aircraft Kinds
	getAircraftKinds(): Observable<ReferanceAircraftKind[]> {
		return this.http
			.get<ReferanceAircraftKind[]>(this.apiReferanceUrl + '/aircraft_kinds')
			.pipe(retry(1), catchError(this.handleError));
	}

	getAircraftKind(id: number): Observable<ReferanceAircraftKind> {
		return this.http
			.get<ReferanceAircraftKind>(this.apiReferanceUrl + '/aircraft_kinds/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createAircraftKind(kind: ReferanceAircraftKind): Observable<ReferanceAircraftKind> {
		return this.http
			.post<ReferanceAircraftKind>(this.apiReferanceUrl + '/aircraft_kinds', JSON.stringify(kind), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateAircraftKind(id: string, kind: ReferanceAircraftKind): Observable<ReferanceAircraftKind> {
		return this.http
			.put<ReferanceAircraftKind>(
				this.apiReferanceUrl + '/aircraft_kinds/' + id,
				JSON.stringify(kind),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Aircraft Classes
	getAircraftClasses(): Observable<ReferanceAircraftClass[]> {
		return this.http
			.get<ReferanceAircraftClass[]>(this.apiReferanceUrl + '/aircraft_classes')
			.pipe(retry(1), catchError(this.handleError));
	}

	getAircraftClass(id: number): Observable<ReferanceAircraftClass> {
		return this.http
			.get<ReferanceAircraftClass>(this.apiReferanceUrl + '/aircraft_classes/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createAircraftClass(aircraft_class: ReferanceAircraftClass): Observable<ReferanceAircraftClass> {
		return this.http
			.post<ReferanceAircraftClass>(
				this.apiReferanceUrl + '/aircraft_classes',
				JSON.stringify(aircraft_class),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateAircraftClass(id: string, aircraft_class: ReferanceAircraftClass): Observable<ReferanceAircraftClass> {
		return this.http
			.put<ReferanceAircraftClass>(
				this.apiReferanceUrl + '/aircraft_classes/' + id,
				JSON.stringify(aircraft_class),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Tails
	getTails(): Promise<ReferanceTail[]> {
		return this.http
			.get<ReferanceTail[]>(this.apiReferanceUrl + '/tails')
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getTail(id: number): Promise<ReferanceTail> {
		return this.http
			.get<ReferanceTail>(this.apiReferanceUrl + '/tails/' + id)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	createTail(tail: ReferanceTail): Observable<ReferanceTail> {
		return this.http
			.post<ReferanceTail>(this.apiReferanceUrl + '/tails', JSON.stringify(tail), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateTail(id: string, tail: ReferanceTail): Promise<ReferanceTail> {
		return this.http
			.put<ReferanceTail>(this.apiReferanceUrl + '/tails/' + id, JSON.stringify(tail), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	// Regularities
	getRegularities(): Observable<ReferanceRegularitie[]> {
		return this.http
			.get<ReferanceRegularitie[]>(this.apiReferanceUrl + '/regularities')
			.pipe(retry(1), catchError(this.handleError));
	}

	getRegularitie(id: number): Observable<ReferanceRegularitie> {
		return this.http
			.get<ReferanceRegularitie>(this.apiReferanceUrl + '/regularities/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createRegularitie(regularitie: ReferanceRegularitie): Observable<ReferanceRegularitie> {
		return this.http
			.post<ReferanceRegularitie>(this.apiReferanceUrl + '/regularities', JSON.stringify(regularitie), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateRegularitie(id: string, regularitie: ReferanceRegularitie): Observable<ReferanceRegularitie> {
		return this.http
			.put<ReferanceRegularitie>(
				this.apiReferanceUrl + '/regularities/' + id,
				JSON.stringify(regularitie),
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Stand
	getStands(): Observable<ReferanceStand[]> {
		return this.http
			.get<ReferanceStand[]>(this.apiReferanceUrl + '/stands')
			.pipe(retry(1), catchError(this.handleError));
	}

	getStand(id: number): Observable<ReferanceStand> {
		return this.http
			.get<ReferanceStand>(this.apiReferanceUrl + '/stands/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createStand(stand: ReferanceStand): Observable<ReferanceStand> {
		return this.http
			.post<ReferanceStand>(this.apiReferanceUrl + '/stands', JSON.stringify(stand), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateStand(id: string, stand: ReferanceStand): Observable<ReferanceStand> {
		return this.http
			.put<ReferanceStand>(this.apiReferanceUrl + '/stands/' + id, JSON.stringify(stand), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Stand Type
	getStandTypes(): Observable<ReferanceStandType[]> {
		return this.http
			.get<ReferanceStandType[]>(this.apiReferanceUrl + '/stand_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	getStandType(id: number): Observable<ReferanceStandType> {
		return this.http
			.get<ReferanceStandType>(this.apiReferanceUrl + '/stand_types/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createStandType(standType: ReferanceStandType): Observable<ReferanceStandType> {
		return this.http
			.post<ReferanceStandType>(this.apiReferanceUrl + '/stand_types', JSON.stringify(standType), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateStandType(id: string, standType: ReferanceStandType): Observable<ReferanceStandType> {
		return this.http
			.put<ReferanceStandType>(this.apiReferanceUrl + '/stand_types/' + id, JSON.stringify(standType), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Terminals
	getTerminals(): Observable<ReferanceTerminal[]> {
		return this.http
			.get<ReferanceTerminal[]>(this.apiReferanceUrl + '/terminals')
			.pipe(retry(1), catchError(this.handleError));
	}

	getTerminal(id: number): Observable<ReferanceTerminal> {
		return this.http
			.get<ReferanceTerminal>(this.apiReferanceUrl + '/terminals/' + id)
			.pipe(retry(1), catchError(this.handleError));
	}

	createTerminal(terminal: ReferanceTerminal): Observable<ReferanceTerminal> {
		return this.http
			.post<ReferanceTerminal>(this.apiReferanceUrl + '/terminals', JSON.stringify(terminal), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	updateTerminal(id: string, terminal: ReferanceTerminal): Observable<ReferanceTerminal> {
		return this.http
			.put<ReferanceTerminal>(this.apiReferanceUrl + '/terminals/' + id, JSON.stringify(terminal), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	downloadFile(link: string) {
		return this.http
			.get(this.apiReferanceUrl + '/' + link, { responseType: 'blob' })
			.pipe(retry(1), catchError(this.handleError));
	}

	getFieldsFromBD(referanceName: string): Observable<[]> {
		return this.http
			.get<[]>(this.apiReferanceUrl + '/fields/' + referanceName)
			.pipe(retry(1), catchError(this.handleError));
	}

	importDataValidation(importData: any, referanceName: string) {
		return this.http
			.post(this.apiReferanceUrl + '/validate/' + referanceName, JSON.stringify(importData), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	startImport(importData: any, referanceName: string) {
		return this.http
			.post(this.apiReferanceUrl + '/import/' + referanceName, JSON.stringify(importData), this.httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Error handling
	handleError(error) {
		let errorMessage = '';
		let errorDetail: any = null;
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorDetail = error.error;
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		if (errorDetail) {
			return throwError(errorDetail);
		} else {
			return throwError(errorMessage);
		}
	}
}
