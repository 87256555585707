import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { MasterDataConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { MasterDataService } from './api/masterData.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    MasterDataService ]
})
export class MasterDataApiModule {
    public static forRoot(configurationFactory: () => MasterDataConfiguration): ModuleWithProviders<MasterDataApiModule> {
        return {
            ngModule: MasterDataApiModule,
            providers: [ { provide: MasterDataConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: MasterDataApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('MasterDataApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
