import { NgModule } from '@angular/core';
import { AppAuthGuard } from '../../app.authguard';
import { RouterModule, Routes } from '@angular/router';
import { AcTypesComponent } from './components/ac-types/ac-types.component';
import { MasterDataComponent } from './components/master-data.component';
import { TailsComponent } from './components/tails/tails.component';
import { AcKindsComponent } from './components/ac-kinds/ac-kinds.component';
import { AcClassesComponent } from './components/ac-classes/ac-classes.component';
import { AirlinesComponent } from './components/airlines/airlines.component';
import { AirportsComponent } from './components/airports/airports.component';
import { CitiesComponent } from './components/cities/cities.component';
import { CountriesComponent } from './components/countries/countries.component';
import { RegionsComponent } from './components/regions/regions.component';
import { DelaysComponent } from './components/delays/delays.component';
import { DelaySourcesComponent } from './components/delay-sources/delay-sources.component';
import { MovementTypesComponent } from './components/movement-types/movement-types.component';
import { RegularitiesComponent } from './components/regularities/regularities.component';
import { SeasonsComponent } from './components/seasons/seasons.component';
import { StandsComponent } from './components/stands/stands.component';
import { StandTypesComponent } from './components/stand-types/stand-types.component';
import { TerminalsComponent } from './components/terminals/terminals.component';

const routes: Routes = [
	{
		path: 'master-data',
		component: MasterDataComponent,
		canActivate: [AppAuthGuard],
		data: {
			roles: ['aac_masterdata'],
		},
		children: [
			{ path: 'tails', component: TailsComponent },
			{ path: 'aircraft-types', component: AcTypesComponent },
			{ path: 'aircraft-kinds', component: AcKindsComponent },
			{ path: 'aircraft-classes', component: AcClassesComponent },
			{ path: 'airlines', component: AirlinesComponent },
			{ path: 'airports', component: AirportsComponent },
			{ path: 'cities', component: CitiesComponent },
			{ path: 'countries', component: CountriesComponent },
			{ path: 'regions', component: RegionsComponent },
			{ path: 'delays', component: DelaysComponent },
			{ path: 'delay-sources', component: DelaySourcesComponent },
			{ path: 'movement-types', component: MovementTypesComponent },
			{ path: 'regularities', component: RegularitiesComponent },
			{ path: 'seasons', component: SeasonsComponent },
			{ path: 'stands', component: StandsComponent },
			{ path: 'stand-types', component: StandTypesComponent },
			{ path: 'terminals', component: TerminalsComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class MasterDataRoutingModule {}
