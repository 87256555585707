import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from '@core/services/settings/settings.service';
import { LanguageItem } from '@core/services/settings/language';
import { ModuleItem } from '@core/services/settings/modules';
import { TimeZoneItem } from '@core/services/settings/time-zone';
import { TimeItem } from '@core/services/settings/time';
import { environment } from '../../../../environments/environment';
import { TimeFormat } from '@shared/models/time-format';
import { Subscription } from 'rxjs';

@Component({
	selector: 'aac-settings',
	// changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './settings.component.html',
	styleUrl: './settings.component.less',
})
export class SettingsComponent implements OnInit, OnDestroy {
	private _timeZone = null;
	languageList: LanguageItem[] = [];
	moduleList: ModuleItem[] = [];
	timeZoneList: TimeZoneItem[] = [];
	timeList: TimeItem[] = [];
	exampleDateTimeForUserTimeFormat: Date;
	userCurrentTimeFormat = TimeFormat.Z;
	subscriptionList = new Subscription();

	constructor(private settingsService: SettingsService) {
		settingsService.languageList.list$.subscribe(languages => {
			if (languages) {
				this.languageList = languages;
			}
		});

		this.subscriptionList.add(
			settingsService.module.availableForUser$.subscribe(modules => {
				if (modules) {
					// Сортировка модулей в алфавитном порядке
					modules.sort((a, b) => a.name.localeCompare(b.name));
					this.moduleList = modules;
				}
			})
		);

		this.subscriptionList.add(
			settingsService.timeZone.list$.subscribe(list => {
				if (list) {
					this.timeZoneList = list;
				}
			})
		);

		this.subscriptionList.add(
			settingsService.time.list$.subscribe(timeList => {
				if (timeList) {
					this.timeList = timeList;
				}
			})
		);

		this.subscriptionList.add(
			settingsService.user.settings$.subscribe(settings => {
				if (settings) {
					this.userCurrentTimeFormat = settings.time as TimeFormat;
					this.exampleDateTimeForUserTimeFormat = new Date();
				}
			})
		);

		this.subscriptionList.add(
			settingsService.user.timeZone$.subscribe(timeZone => {
				if (timeZone) {
					this._timeZone = timeZone;
				}
			})
		);
	}

	ngOnInit() {
		this.exampleDateTimeForUserTimeFormat = new Date();
	}

	ngOnDestroy() {
		this.subscriptionList.unsubscribe();
	}

	timeZoneAutoDetect() {
		// TODO привязать к параметру в конфиге, прокинть в сервис настроек
		this.timeZone = this.settingsService.timeZone.getUserTimeZone();
	}

	get time() {
		return this.settingsService.user.getTime();
	}

	set time(code: string) {
		this.settingsService.user.setTime(code);
	}

	get timeZone() {
		return this._timeZone;
	}

	set timeZone(name) {
		this.settingsService.user.setTimeZone(name);
	}

	get loadableModule() {
		return this.settingsService.user.getLoadableModule();
	}

	set loadableModule(id) {
		this.settingsService.user.setLoadableModule(id);
	}

	get language() {
		return this.settingsService.user.getLanguage();
	}

	set language(name) {
		this.settingsService.user.setLanguage(name);
	}

	get userFullName() {
		return this.settingsService.user.getFirstName() + ' ' + this.settingsService.user.getLastName();
	}

	get userLogin() {
		return this.settingsService.user.getLogin();
	}

	getVersion() {
		return environment.applicationVersion;
	}

	getVersionHashAndDate() {
		return environment.applicationBuild;
	}
}
