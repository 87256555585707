import { TimeFormat } from '@shared/models/time-format';
import { BehaviorSubject } from 'rxjs';

export interface TimeItem {
	code: string;
	name: string;
}

export class Time {
	private namesForUser = new Map([
		[TimeFormat.Z, 'Zulu time'],
		[TimeFormat.L, 'Local time'],
	]);

	list$ = new BehaviorSubject<TimeItem[]>(null);

	constructor() {
		const timeNameList = Object.values(TimeFormat);
		const arr: TimeItem[] = [];
		timeNameList.forEach(name => {
			if (this.namesForUser.get(name) !== undefined) {
				arr.push({ code: this.getKeyByValue(name), name: this.namesForUser.get(name) });
			} else {
				arr.push({ code: this.getKeyByValue(name), name: name });
			}
		});
		this.list$.next(arr);
	}

	private getKeyByValue(value: string) {
		const index = Object.values(TimeFormat).indexOf(value as unknown as TimeFormat);
		return Object.keys(TimeFormat)[index];
	}
}
