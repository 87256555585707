import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LostFoundModuleRestApiService } from 'src/app/lost-found-module/services/lost-found-rest-api.service';
import { Chat, ChatData, ChatMessage } from 'src/app/lost-found-module/types/chats';
import { StatementData } from 'src/app/lost-found-module/types/statements';
import { SettingsService } from '@core/services/settings/settings.service';

@Component({
	selector: 'app-chats-tab',
	templateUrl: './chats-tab.component.html',
	styleUrls: ['./chats-tab.component.less'],
})
export class ChatsTabComponent implements OnInit {
	@Input() statement: StatementData;
	@Input() statementForm: UntypedFormGroup;

	chats: Array<Chat> = [];
	chatMessages: Array<ChatMessage> = [];
	activeChat: Chat = new Chat();
	message: string;
	loading = false;

	constructor(public restApi: LostFoundModuleRestApiService, private settingsService: SettingsService) {}

	async ngOnInit(): Promise<void> {
		await this.loadChats();

		if (this.chats.length > 0) {
			this.activeChat = this.chats[0];
			this.loadChat(this.activeChat);
		}
	}

	async loadChats() {
		const xRequestId = this.settingsService.general.randomUuid;
		this.loading = true;
		this.chats = [];
		for (const baggage of this.statement.baggage) {
			const data = await this.restApi.getChats(this.statement.id, baggage.id, xRequestId);
			data.forEach(el => {
				this.chats.push(new Chat(el));
			});
			this.loading = false;
		}
	}

	loadChat(chat: Chat) {
		this.activeChat = chat;
		const xRequestId = this.settingsService.general.randomUuid;
		this.restApi.loadChat(chat.statementId, chat.statementBaggageId, chat.externalBaggageId, xRequestId).then(data => {
			this.chatMessages = data.sort((a, b) => {
				if (new Date(a.dt) > new Date(b.dt)) {
					return 1;
				} else {
					return -1;
				}
			});
			setTimeout(() => {
				const height = document.getElementById('chat-block').scrollHeight;
				document.getElementById('chat-block').scrollTo({ top: height, behavior: 'smooth' });
			}, 0);
		});
	}

	openChat(ids: ChatData) {
		const chat = this.chats.find(
			item => item.externalBaggageId === ids.externalBaggageId && item.statementBaggageId === ids.statementBaggageId
		);
		if (chat) {
			this.activeChat = chat;
			this.loadChat(chat);
		} else {
			this.createChat(ids);
			this.chatMessages = [];
		}
	}

	createChat(ids: ChatData) {
		this.activeChat = new Chat({
			externalBaggageId: ids.externalBaggageId,
			statementBaggageId: ids.statementBaggageId,
			statementId: ids.statementId,
			name: ids.externalName,
		});
		this.chats.push(this.activeChat);
	}

	sendMessage() {
		if (this.message) {
			this.restApi
				.sendMessage(
					this.activeChat.statementId,
					this.activeChat.statementBaggageId,
					this.activeChat.externalBaggageId,
					this.message
				)
				.then(async () => {
					const message = new ChatMessage(this.message);
					this.chatMessages.push(message);
					this.message = '';
					if (!this.activeChat.baggage) {
						await this.loadChats();
					}
				});
		}
	}
}
