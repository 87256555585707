<div nz-row nzJustify="space-between">
	<div nz-col>
		<button
			nz-button
			nzType="default"
			(click)="addTail()"
			i18n="@@ref.main.tab.tails.buttonNew">
			New
		</button>
		<button
			nz-button
			nz-dropdown
			[nzDropdownMenu]="menu"
			i18n="@@ref.main.tab.tails.buttonExport">
			Export
			<span nz-icon nzType="down"></span>
		</button>
		<nz-dropdown-menu #menu="nzDropdownMenu">
			<ul nz-menu>
				<li nz-menu-item>
					<a (click)="downloadCsvFile()">CSV</a>
				</li>
				<li nz-menu-item>
					<a (click)="downloadCsvFile()">XML</a>
				</li>
			</ul>
		</nz-dropdown-menu>
		<!-- <button nz-button nzType="default">
      <span nz-icon nzType="upload" nzTheme="outline"></span>
      <span i18n="@@ref.main.tab.tails.buttonImport">Import</span>
    </button> -->
	</div>

	<div nz-col>
		<nz-input-group
			[nzAddOnBefore]="searchValueBeforeTemplate"
			[nzSuffix]="searchValueClearTpl">
			<input
				type="text"
				nz-input
				[(ngModel)]="settingValue.searchValue"
				placeholder="TAIL"
				i18n-placeholder="@@ref.main.tab.tails.fieldSearchName" />
		</nz-input-group>
		<ng-template #searchValueBeforeTemplate>
			<span i18n="@@ref.main.tab.tails.fieldSearch">Search</span>
		</ng-template>
		<ng-template #searchValueClearTpl>
			<span
				nz-icon
				class="ant-input-clear-icon"
				nzTheme="fill"
				nzType="close-circle"
				*ngIf="settingValue.searchValue"
				(click)="settingValue.searchValue = ''"></span>
		</ng-template>
	</div>
</div>

<nz-table
	#sortTable
	[nzData]="listOfDisplayTails"
	nzTableLayout="fixed"
	[nzLoading]="settingValue.loadingList"
	nzSize="small"
	nzPaginationType="small"
	nzShowSizeChanger
	nzPageSize="15"
	[nzPageIndex]="settingValue.pageIndex"
	(nzPageIndexChange)="onPageIndexChanged($event)"
	[nzPageSizeOptions]="[15, 30, 50]">
	<thead>
		<tr>
			<th
				*ngFor="let column of listOfColumn"
				[nzSortFn]="column.compare"
				[nzSortPriority]="column.priority">
				{{ column.title }}
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let tail of sortTable.data" (click)="openTail(tail.id)">
			<td>{{ tail.tail }}</td>
			<td>{{ tail.lastupdate | date : 'dd.MM.yyy HH:mm' }}</td>
		</tr>
	</tbody>
</nz-table>

<nz-modal
	[(nzVisible)]="settingValue.isVisibleModal"
	[nzTitle]="modalTitle"
	[nzContent]="modalContent"
	[nzFooter]="modalFooter"
	(nzOnCancel)="closeModal()"
	nzWidth="1000px">
	<nz-spin [nzSpinning]="settingValue.loadingModal">
		<ng-template #modalTitle>
			<span *ngIf="!tail.id">
				<span i18n="@@ref.main.modal.tail.headerAppend">Tail append</span
				>&nbsp;{{ tail.tail }}
			</span>
			<span *ngIf="tail.id">
				<span i18n="@@ref.main.modal.tail.headerEdit"> Edit tail </span>&nbsp;{{
					tail.tail
				}}
			</span>
		</ng-template>

		<ng-template #modalContent>
			<form nz-form [formGroup]="tailForm" nzLayout="vertical">
				<div nz-row [nzGutter]="16">
					<div nz-col nzSpan="6">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.tail.field.tail">Tail</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="tailErrorTpl">
								<input nz-input formControlName="tail" />
								<ng-template #tailErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.tail.err.tailReq">
											Tile is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="6">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.tail.field.mtow">MTOW</span>
							</nz-form-label>
							<nz-form-control nzHasFeedback [nzErrorTip]="mtowErrorTpl">
								<input nz-input formControlName="mtow" type="number" />
								<ng-template #mtowErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.tail.err.mtowReq">
											MTOW is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.tail.field.type">Type</span>
							</nz-form-label>
							<nz-form-control
								nzHasFeedback
								[nzErrorTip]="aircraftTypeIdErrorTpl">
								<nz-select
									[nzOptions]="listOfDisplayAircraftTypes"
									formControlName="aircraftTypeId">
								</nz-select>
								<ng-template #aircraftTypeIdErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.tail.err.typeReq">
											Type is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.tail.field.dtRangeStart">
									Date range start
								</span>
							</nz-form-label>
							<nz-form-control [nzErrorTip]="dtRangeStartErrorTpl">
								<nz-date-picker
									formControlName="dtRangeStart"
									nzFormat="dd.MM.yyyy"
									nzUtcOffset="00:00"></nz-date-picker>
								<ng-template #dtRangeStartErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.tail.err.dtRangeStartReq">
											Date range start is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>

					<div nz-col nzSpan="12">
						<nz-form-item>
							<nz-form-label nzRequired>
								<span i18n="@@ref.main.modal.tail.field.dtRangeFinish">
									Date range finish
								</span>
							</nz-form-label>
							<nz-form-control [nzErrorTip]="dtRangeFinishErrorTpl">
								<nz-date-picker
									formControlName="dtRangeFinish"
									[nzFormat]="'dd.MM.yyyy'"
									nzUtcOffset="00:00"></nz-date-picker>
								<ng-template #dtRangeFinishErrorTpl let-control>
									<ng-container *ngIf="control.hasError('required')">
										<span i18n="@@ref.main.modal.tail.err.dtRangeFinishReq">
											Date range finish is required
										</span>
									</ng-container>
								</ng-template>
							</nz-form-control>
						</nz-form-item>
					</div>
				</div>
			</form>
		</ng-template>

		<ng-template #modalFooter>
			<div nz-row nzJustify="space-between">
				<div nz-col>
					<button
						*ngIf="tail.id"
						nz-button
						nzType="default"
						(click)="duplicate()"
						i18n="@@ref.main.modal.tail.button.duplicate">
						Duplicate
					</button>
					<button
						*ngIf="tail.id"
						nz-button
						nzType="primary"
						nzDanger
						(click)="showDeleteConfirm()">
						Delete
					</button>
				</div>

				<div nz-col>
					<button
						*ngIf="tail.id"
						nz-button
						nzType="primary"
						(click)="save()"
						[nzLoading]="settingValue.saving"
						i18n="@@ref.main.modal.tail.button.save">
						Save
					</button>
					<button
						*ngIf="!tail.id"
						nz-button
						nzType="primary"
						(click)="add()"
						[nzLoading]="settingValue.saving"
						i18n="@@ref.main.modal.tail.button.append">
						Append
					</button>
					<button
						nz-button
						nzType="default"
						(click)="closeModal()"
						i18n="@@ref.main.modal.tail.button.close">
						Close
					</button>
				</div>
			</div>
		</ng-template>
	</nz-spin>
</nz-modal>
