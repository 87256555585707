import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {
	ReferanceAirline,
	ReferanceAirport,
	ReferanceMovement,
	ReferanceAircraft,
	ReferanceGeoTypes,
	ReferanceSeason,
	ReferanceRegulature,
	ReferanceChannels,
	ReferenceKeycloakUser,
} from '../reports-module-data/referance';
import { ReportParams, ReportDailyPlan, ReportSynchron, ReportMediaLogs } from './reports';
import { SettingsService } from '@core/services/settings/settings.service';

@Injectable({
	providedIn: 'root',
})
export class ReportsModuleRestApiService {
	private apiSlotCoordinationUrl: string;
	private apiInformationalUrl: string;
	private apiReportUrl: string;
	private apiLostFoundUrl: string;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiSlotCoordinationUrl = config.apiSlotCoordinationURL;
			this.apiInformationalUrl = config.apiInformationalURL;
			this.apiReportUrl = config.apiReportURL;
			this.apiLostFoundUrl = config.apiLostFoundURL;
		});
	}

	setDefaultHttpHeader(requestId?): Object {
		// Формирование заголовков для отслеживания запросов
		// X-Correlation-ID идентификатор пользовательской сессии
		// X-Request-ID идентификатор события / запроса
		const httpOptions = {};
		httpOptions['headers'] = {
			'Content-Type': 'application/json',
			'X-Correlation-ID': this.settingsService.general.userSessionUuid,
			'X-Request-ID': requestId === undefined ? this.settingsService.general.randomUuid : requestId,
		};
		return httpOptions;
	}

	getReportDailyPlan(paramArray: ReportParams): Observable<ReportDailyPlan> {
		let params = new HttpParams();
		if (paramArray.start != null) {
			params = params.append('start', paramArray.start);
		}
		if (paramArray.finish != null) {
			params = params.append('finish', paramArray.finish);
		}
		if (paramArray.airline != null) {
			params = params.append('airline', paramArray.airline);
		}
		if (paramArray.movement != null) {
			params = params.append('movement', paramArray.movement);
		}
		if (paramArray.geo != null) {
			params = params.append('geo', paramArray.geo);
		}
		if (paramArray.aircraft != null) {
			params = params.append('aircraft', paramArray.aircraft);
		}
		if (paramArray.airport != null) {
			params = params.append('airport', paramArray.airport);
		}
		if (paramArray.season != null) {
			params = params.append('season', paramArray.season);
		}
		if (paramArray.regularity != null) {
			params = params.append('regularity', paramArray.regularity);
		}
		console.log(params);
		console.log(this.apiSlotCoordinationUrl + '/reports/daily_plan');
		return this.http
			.get<any>(this.apiSlotCoordinationUrl + '/reports/daily_plan', { params })
			.pipe(retry(1), catchError(this.handleError));
	}

	// Универсальный отчет
	getReport(paramArray: ReportParams): Observable<ReportDailyPlan> {
		console.log(paramArray);
		let params = new HttpParams();
		if (paramArray.start != null) {
			params = params.append('start', paramArray.start);
		}
		if (paramArray.finish != null) {
			params = params.append('finish', paramArray.finish);
		}
		if (paramArray.airline != null) {
			params = params.append('airline', paramArray.airline);
		}
		if (paramArray.movement != null) {
			params = params.append('movement', paramArray.movement);
		}
		if (paramArray.geo != null) {
			params = params.append('geo', paramArray.geo);
		}
		if (paramArray.aircraft != null) {
			params = params.append('aircraft', paramArray.aircraft);
		}
		if (paramArray.airport != null) {
			params = params.append('airport', paramArray.airport);
		}
		if (paramArray.season != null) {
			params = params.append('season', paramArray.season);
		}
		if (paramArray.regularity != null) {
			params = params.append('regularity', paramArray.regularity);
		}
		console.log(params);
		console.log(this.apiSlotCoordinationUrl + '/reports/' + paramArray.name); // daily_plan
		return this.http
			.get<any>(this.apiSlotCoordinationUrl + '/reports/' + paramArray.name, { params }) // daily_plan
			.pipe(retry(1), catchError(this.handleError));
	}

	// Прлучаем отчет
	getReportData(paramArray: ReportParams, url: string, port: string): Observable<any> {
		let params = new HttpParams();
		const exceptions = ['id', 'period'];
		for (const key in paramArray) {
			if (
				Object.prototype.hasOwnProperty.call(paramArray, key) &&
				paramArray[key] !== null &&
				paramArray[key] !== undefined &&
				!exceptions.includes(key)
			) {
				params = params.append(key, paramArray[key]);
			}
		}
		if (paramArray.forwarding?.length > 0) {
			url += '/' + paramArray.forwarding;
		}
		return this.http
			.get<any>(port + url, { params }) // daily_plan
			.pipe(retry(1), catchError(this.handleError));
	}

	downloadReport(paramArray: ReportParams, url: string, port: string) {
		let params = new HttpParams();
		Object.keys(paramArray).forEach(item => {
			if (paramArray[item] != null) {
				params = params.set(item, paramArray[item]);
			}
		});

		return this.http
			.get(port + url, { params, responseType: 'blob' })
			.pipe(retry(1), catchError(this.handleError));
	}

	getReportSynchron(paramArray: ReportParams): Observable<ReportSynchron[]> {
		console.log(paramArray);
		let params = new HttpParams();
		if (paramArray.start != null) {
			params = params.append('start', paramArray.start);
		}
		if (paramArray.finish != null) {
			params = params.append('finish', paramArray.finish);
		}
		if (paramArray.airline != null) {
			params = params.append('airline', paramArray.airline);
		}
		if (paramArray.movement != null) {
			params = params.append('movement', paramArray.movement);
		}
		if (paramArray.geo != null) {
			params = params.append('geo', paramArray.geo);
		}
		if (paramArray.aircraft != null) {
			params = params.append('aircraft', paramArray.aircraft);
		}
		if (paramArray.airport != null) {
			params = params.append('airport', paramArray.airport);
		}
		if (paramArray.season != null) {
			params = params.append('season', paramArray.season);
		}
		if (paramArray.regularity != null) {
			params = params.append('regularity', paramArray.regularity);
		}
		if (paramArray.full == true) {
			params = params.append('full', paramArray.regularity);
		}
		console.log(params);
		console.log(this.apiSlotCoordinationUrl + '/reports/synchron');
		return this.http
			.get<any>(this.apiSlotCoordinationUrl + '/reports/synchron', { params })
			.pipe(retry(1), catchError(this.handleError));
	}

	getReportMediaLogs(paramArray: ReportParams): Observable<ReportMediaLogs[]> {
		console.log(paramArray);
		let params = new HttpParams();
		if (paramArray.start != null) {
			params = params.append('start', paramArray.start);
		}
		if (paramArray.finish != null) {
			params = params.append('finish', paramArray.finish);
		}
		console.log(params);
		console.log(this.apiInformationalUrl + '/reports/media_logs');
		return this.http
			.get<ReportMediaLogs[]>(this.apiInformationalUrl + '/reports/media_logs', { params })
			.pipe(retry(1), catchError(this.handleError));
	}

	// Movements
	getChannels(): Observable<ReferanceChannels[]> {
		return this.http
			.get<ReferanceChannels[]>(this.apiReportUrl + '/reports/cks_stats/channels')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Movements
	getMovements(): Observable<ReferanceMovement[]> {
		return this.http
			.get<ReferanceMovement[]>(this.apiSlotCoordinationUrl + '/movement_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Airports
	getAirports(): Observable<ReferanceAirport[]> {
		return this.http
			.get<ReferanceAirport[]>(this.apiLostFoundUrl + '/master_data/airports')
			.pipe(retry(1), catchError(this.handleError));
	}

	getUserAirports(): Observable<any> {
		return this.http
			.get<any>(this.apiLostFoundUrl + '/users/airports')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Aircraft Types
	getAircraftTypes(): Observable<ReferanceAircraft[]> {
		return this.http
			.get<ReferanceAircraft[]>(this.apiSlotCoordinationUrl + '/aircraft_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Airlines
	getAirlines(): Observable<ReferanceAirline[]> {
		return this.http
			.get<ReferanceAirline[]>(this.apiLostFoundUrl + '/master_data/airlines')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Geo Types
	getGeoTypes(): Observable<ReferanceGeoTypes[]> {
		return this.http
			.get<ReferanceGeoTypes[]>(this.apiSlotCoordinationUrl + '/geo_types')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Seasons
	getSeasons(): Observable<ReferanceSeason[]> {
		return this.http
			.get<ReferanceSeason[]>(this.apiSlotCoordinationUrl + '/seasons')
			.pipe(retry(1), catchError(this.handleError));
	}

	// Regularities
	getRegularities(): Observable<ReferanceRegulature[]> {
		return this.http
			.get<ReferanceRegulature[]>(this.apiSlotCoordinationUrl + '/regularities')
			.pipe(retry(1), catchError(this.handleError));
	}

	getReference(name, xRequestId?): Promise<any[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<any[]>(this.apiLostFoundUrl + '/master_data/' + name, httpOptions)
			.pipe(retry(1), catchError(this.handleError))
			.toPromise();
	}

	getKeycloakUsers(xRequestId?): Observable<ReferenceKeycloakUser[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		let params = new HttpParams();
		params = params.append('type', 'lost_found');
		httpOptions['params'] = params;
		return this.http
			.get<ReferenceKeycloakUser[]>(this.apiLostFoundUrl + '/admin/users', httpOptions)
			.pipe(retry(1), catchError(this.handleError));
	}

	handleError(error: { error: { message: string }; status: any; message: any }) {
		console.log(error);
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		// Вывод ошибки временно убран
		// в текущий момент нет разделения на отдельные модули системы, от сюда
		// нет разделения на загружаемый контент что создает ошибки на площадках
		// где не работают все модули бэкенда
		// window.alert(errorMessage);
		return throwError(errorMessage);
	}
}
