import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { SettingsEffects } from './state/settings.effects';
import { StoreModule } from '@ngrx/store';
import * as fromSettings from './state/settings.reducer';
import { FormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { SettingsComponent } from './components/settings.component';
import { DateFormat } from '../../pipes/date-format';

@NgModule({
	declarations: [SettingsComponent],
	imports: [
		CommonModule,
		FormsModule,
		NgZorroAntdModule,
		SettingsRoutingModule,
		EffectsModule.forFeature([SettingsEffects]),
		StoreModule.forFeature(fromSettings.settingsFeatureKey, fromSettings.reducer),
		DateFormat,
	],
})
export class SettingsModule {}
