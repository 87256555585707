/**
 * Перечень модулей системы
 */
export enum Module {
  Unknown = 0, // Модуль не задан
  Schedule, // Суточный план полетов
  SlotCoord, // Сезонное расписание
  Workflow, // Технические процессы обслуживания воздушных судов
  MobileApron, // Мобильный перрон
  Reports, // Отчеты и логи
  MasterData, // Нормативно справочная информация
  ComMan, // Обработки сообщений AFTN / SITA/ Email
  BoardingControl, // Контроль пассажиров в чистой зоне
  Administration, // Администрирование системы
  ParkingPlace, // Места стоянок
  WeightBalance, // Центровка воздушных судов
  LostFound, // Поиск багажа
  MaterialAccounting, // Учет бытового имущества на борту ВС
  Test, // Тестовый модуль для всего подряд
	VisualInforming, // Визуальное информирование
	SoundInforming, // Звуковое информирование
}